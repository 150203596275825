import React from 'react';
import PropTypes from 'prop-types';

import Numeral from 'numeral';

const DeliveryFee = ({ deliveryFee, gstRate, deliveryGST, deliveryDistance }) => {
  return (
    <>
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-8 text-left col-cart-angkor">
          <span>Delivery Fee</span>
          {deliveryDistance &&
            <div className="ml-3"><small>Distance: {deliveryDistance /1000} km</small></div>
          }
        </div>
        <div className="col-3 text-right col-cart-angkor">
          <span>
            <small>{Numeral(deliveryFee).format("$0,0.00")}</small>
          </span>
        </div>
      </div>
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-8 text-left col-cart-angkor">
          <span>{`Delivery GST (${gstRate}%)`}</span>
        </div>
        <div className="col-3 text-right col-cart-angkor">
          <span>
            <small>{Numeral(deliveryGST).format("$0,0.00")}</small>
          </span>
        </div>
      </div>
    </>
  )
};

DeliveryFee.propTypes = {
  deliveryFee: PropTypes.number.isRequired,
};

DeliveryFee.defaultProps = {
  deliveryFee: 0
};

export default DeliveryFee;