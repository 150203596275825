import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { resetPassword } from "../../actions/auth";
import PropTypes from "prop-types";
import {
  renderPasswordField, required
} from "../../utils/renderFields";

import queryString from "query-string";

import {sendGAEvent} from "../../utils/analytics";

const form = reduxForm({
  form: "resetPassword",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.password) {
    errors.password = "Please enter a new password";
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = "Please confirm new password";
  }

  if (formProps.password !== formProps.passwordConfirm) {
    errors.password = "Passwords must match";
  }

  return errors;
}

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.handleRedirectToLogin = this.handleRedirectToLogin.bind(this);

    this.state = {
      redirectTo: undefined
    };
  }

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    if (this.props.authenticated) {
      this.context.router.push("/profile");
    }
  }

  componentWillUpdate() {
    if (this.props.authenticated) {
      this.context.router.push("/profile");
    }
  }

  handleFormSubmit({ password }) {
    sendGAEvent({
      category: "Auth",
      action: "ResetPasword Form Submit",
      label: ``
    });
    const queryParameters = queryString.parse(this.props.location.search);
    const resetToken = queryParameters.token;
    this.props.resetPassword(resetToken, { password });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  handleRedirectToLogin() {
    this.setState({ redirectTo: "/login" });
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.props.message) {
      return (
        <div className="card border-success text-center mx-auto">
        <div className="card-body">
          <div className="alert alert-success">
            <span>{this.props.message}</span>
          </div>
          <button
            type="button"
            className="btn btn-success btn-lg"
            onClick={this.handleRedirectToLogin}
          >
            CLICK ME TO LOG IN
          </button>
        </div>
      </div>
    );
    } else {
      return (
        <div className="row h-100 align-items-center">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header text-center">Reset Password</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>New Password</label>
                    <Field
                      name="password"
                      component={renderPasswordField}
                      validate={[required]}
                      type="password"
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm New Password</label>
                    <Field
                      name="passwordConfirm"
                      component={renderPasswordField}
                      validate={[required]}
                      type="password"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-angkor btn-block"
                    disabled={pristine || submitting}
                  >
                    CHANGE PASSWORD
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }

  }
}

const mapStateToProps = state => {
  return { errorMessage: state.auth.error, message: state.auth.message };
};

export default connect(
  mapStateToProps,
  { resetPassword }
)(form(ResetPassword));
