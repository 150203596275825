import React, { Component } from "react";

import MenuItem from "./MenuItem";
import "./MenuList.css";
import { Link, Element, Events } from "react-scroll";
import ScrollMenu from "react-horizontal-scrolling-menu";
import PromotionItem from "./PromotionItem";

import { sendGAEvent } from "../../utils/analytics";

import Reorder from "./Reorder";

const Loading = (className) => (
  <div className={className}>
    <h5>
      <i className="fa fa-lg fa-spinner fa-spin mr-3" /> Loading...
    </h5>
  </div>
);

const Arrow = (direction) => {
  return direction === "left" ? (
    <i className="fas fa-chevron-left fa-2x" />
  ) : (
    <i className="fas fa-chevron-right fa-2x" />
  );
};
const ArrowLeft = Arrow("left");

const ArrowRight = Arrow("right");

const HorizontalMenuSection = ({ section, text, selected, onSetActive }) => {
  return (
    <Link
      activeClass="active"
      className="nav-link"
      to={section.name}
      spy={true}
      smooth={true}
      duration={0}
      containerId="containerElement"
      onSetActive={onSetActive}
    >
      <strong>{section.name.toUpperCase()}</strong>
    </Link>
  );
};

const HorizontalMenuSectionList = (sections, selected, onSetActive) =>
  sections.map((section) => {
    return (
      <HorizontalMenuSection
        section={section}
        text={section.name}
        key={section.name}
        selected={selected}
        onSetActive={onSetActive}
      />
    );
  });

const HorizontalScrollMenuSections = ({ sections, selected, onSetActive }) => {
  let menuSectionList = HorizontalMenuSectionList(
    sections,
    sections.length > 0 ? sections[0].name : undefined,
    onSetActive
  );
  return (
    <ScrollMenu
      data={menuSectionList}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      selected={selected}
      scrollToSelected={true}
      innerWrapperClass={`nav nav-pills`}
      scrollBy={0}
    />
  );
};

const VerticalScrollMenuSections = ({ sections }) => {
  return (
    <nav
      id="navbar-sections"
      className="navbar navbar-light bg-light mt-2 mb-2"
    >
      <nav className="nav nav-pills flex-sm-column">
        {sections.map((section, index) => (
          <Link
            key={section.name}
            activeClass="active"
            className="nav-link"
            to={section.name}
            spy={true}
            smooth={true}
            duration={0}
            containerId="containerElement"
          >
            <strong>{section.name.toUpperCase()}</strong>
          </Link>
        ))}
      </nav>
    </nav>
  );
};

class MenuList extends Component {
  constructor(props, context) {
    super(props, context);

    this.determineClassName = this.determineClassName.bind(this);

    this.handleSelectMenu = this.handleSelectMenu.bind(this);
    this.handleSelectPromotion = this.handleSelectPromotion.bind(this);
    this.handleSelectReorder = this.handleSelectReorder.bind(this);
    this.handleSelectMenuSection = this.handleSelectMenuSection.bind(this);

    this.state = {
      selectedMenu: undefined,
      selectedMenuSection: 0,
      selectedMenuItem: undefined,
      promotionSelected: false,
      reorderSelected: false,
      height: 600,
    };
  }

  updateDimensions() {
    let update_height = window.innerHeight - 66 - 57 - 53;
    this.setState({ height: update_height });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleSelectMenu(menu) {
    sendGAEvent({
      category: "Menu",
      action: "Select menu",
      label: `${menu.name}`,
    });
    this.setState({
      selectedMenu: menu,
      promotionSelected: false,
      reorderSelected: false,
      selectedMenuSection: menu.sections[0]._id,
    });
  }

  handleSelectPromotion(promotionItems) {
    sendGAEvent({
      category: "Menu",
      action: "Select promotion",
      label: `${promotionItems[0].name}`,
    });
    this.setState({
      selectedMenu: undefined,
      promotionSelected: true,
      reorderSelected: false,
      selectedMenuSection: promotionItems[0]._id,
    });
  }

  handleSelectReorder() {
    sendGAEvent({
      category: "Menu",
      action: "Select reorder",
      label: `reorder; `,
    });
    this.setState({
      selectedMenu: undefined,
      promotionSelected: false,
      reorderSelected: true,
    });
  }

  determineClassName(menu, index) {
    let className = "btn";
    if (this.state.selectedMenu) {
      className =
        this.state.selectedMenu._id === menu._id ? `btn underline` : "btn ";
    } else if (
      this.state.promotionSelected === false &&
      this.state.reorderSelected === false
    ) {
      className = index === 0 ? `btn underline` : "btn ";
    }
    return className;
  }

  handleSelectMenuSection = (sectionName) => {
    sendGAEvent({
      category: "Menu",
      action: "Select menu section",
      label: `${sectionName}`,
    });
    this.setState({ selectedMenuSection: sectionName });
  };

  render() {
    const {
      tenant,
      restaurant,
      menus = [],
      promotionItems = [],
      fromOnlineOrder = false,
      pickupDate,
      pickupTime,
    } = this.props;
    const showOnlineOrdering = restaurant.settings.online_ordering;

    if (menus.length <= 0) {
      return <Loading className="center" />;
    }

    const menu = this.state.selectedMenu ? this.state.selectedMenu : menus[0];

    const sections = this.state.promotionSelected
      ? promotionItems
      : (menu.sections || []).filter((section) => !section.hidden_from_public);

    return (
      <>
        <div className="border-bottom mb-3 pb-3">
          {menus.map((menu, index0) => (
            <button
              key={menu._id}
              type="button"
              className={this.determineClassName(menu, index0)}
              onClick={() => this.handleSelectMenu(menu)}
            >
              <strong>{menu.name.toUpperCase()}</strong>
            </button>
          ))}
          {/* {promotionItems.length > 0 &&
            <button
              key={0}
              type="button"
              className={this.state.promotionSelected ? "btn underline" : "btn"}
              onClick={() => this.handleSelectPromotion(promotionItems)}
            >
              <strong>{`PROMOTION`}</strong>
            </button>
          } */}
          <button
            type="button"
            className={this.state.reorderSelected ? "btn underline" : "btn"}
            onClick={() => this.handleSelectReorder()}
          >
            <strong>{`REORDER`}</strong>
          </button>
          {menu.duration.name !== "all" && (
            <div className="row">
              <div className="col-12 mt-3">
                <span className="font-weight-bold">
                  Note: {menu.name} items are available from{" "}
                  {menu.duration.time_start} to {menu.duration.time_end}
                </span>
              </div>
            </div>
          )}
        </div>
        {this.state.reorderSelected && <Reorder />}
        {!this.state.reorderSelected && (
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="d-block d-md-none mb-3">
                <HorizontalScrollMenuSections
                  sections={sections.filter(
                    (section) =>
                      !section.hasOwnProperty("disabled") ||
                      (section.hasOwnProperty("disabled") &&
                        section.disabled === false)
                  )}
                  selected={this.state.selectedMenuSection}
                  onSetActive={this.handleSelectMenuSection}
                />
              </div>
              <div className="d-none d-md-block">
                <VerticalScrollMenuSections
                  sections={sections.filter(
                    (section) =>
                      !section.hasOwnProperty("disabled") ||
                      (section.hasOwnProperty("disabled") &&
                        section.disabled === false)
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-9">
              <Element
                name="containerElement"
                className="element"
                id="containerElement"
                style={{
                  position: "relative",
                  height: this.state.height,
                  overflow: "scroll",
                  marginBottom: "15px",
                }}
              >
                {sections
                  .filter(
                    (section) =>
                      !section.hasOwnProperty("disabled") ||
                      (section.hasOwnProperty("disabled") &&
                        section.disabled === false)
                  )
                  .map((section, index1) => (
                    <Element
                      name={section._id}
                      key={section._id}
                      className="element"
                    >
                      <div
                        key={section._id}
                        className="card mt-2 mb-2"
                        id={section.name}
                      >
                        <div className="card-header">
                          <h6>
                            <strong>{section.name.toUpperCase()}</strong>
                          </h6>
                          <p>
                            <small>{section.description}</small>
                          </p>
                          {section.promotion &&
                            section.promotion.type ===
                              "Buy one, get one free" && (
                              <>
                                <div>{section.promotion.description}</div>
                                <div className="pt-3">
                                  <strong className="text-muted">
                                    What you get:
                                  </strong>
                                </div>
                                <ul className="list-unstyled">
                                  <li>{`${section.promotion.discount_on_cheapest_item}% discount to the second item of equal of lesser value`}</li>
                                  {section.promotion
                                    .discount_on_most_expensive_item > 0 && (
                                    <li>{`${section.promotion.discount_on_most_expensive_item}% discount to the most expensive item`}</li>
                                  )}
                                </ul>
                                <div className="pt-3">
                                  <strong className="text-muted">
                                    Conditions:
                                  </strong>
                                </div>
                                <ul className="list-unstyled">
                                  {section.promotion.monday_selected && (
                                    <li className="mr-3 text-muted">{`Monday: ${section.promotion.monday_from} - ${section.promotion.monday_until}`}</li>
                                  )}
                                  {section.promotion.tuesday_selected && (
                                    <li className="mr-3 text-muted">{`Tuesday: ${section.promotion.tuesday_from} - ${section.promotion.tuesday_until}`}</li>
                                  )}
                                  {section.promotion.wedensday_selected && (
                                    <li className="mr-3 text-muted">{`Monday: ${section.promotion.wednesday_from} - ${section.promotion.wednesday_until}`}</li>
                                  )}
                                  {section.promotion.thursday_selected && (
                                    <li className="mr-3 text-muted">{`Thursday: ${section.promotion.thursday_from} - ${section.promotion.thursday_until}`}</li>
                                  )}
                                  {section.promotion.friday_selected && (
                                    <li className="mr-3 text-muted">{`Friday: ${section.promotion.friday_from} - ${section.promotion.friday_until}`}</li>
                                  )}
                                  {section.promotion.saturday_selected && (
                                    <li className="mr-3 text-muted">{`Saturday: ${section.promotion.saturday_from} - ${section.promotion.saturday_until}`}</li>
                                  )}
                                  {section.promotion.sunday_selected && (
                                    <li className="mr-3 text-muted">{`Sunday: ${section.promotion.sunday_from} - ${section.promotion.sunday_until}`}</li>
                                  )}
                                </ul>
                              </>
                            )}
                          {section.promotion &&
                            section.promotion.type ===
                              "% discount on selected items" && (
                              <>
                                <div>{section.promotion.description}</div>
                                <div className="pt-3">
                                  <strong className="text-muted">
                                    What you get:
                                  </strong>
                                </div>
                                <ul className="list-unstyled">
                                  <li className="mr-3 text-muted">{`${section.promotion.discount_on_items_group_1}% discount to specific item`}</li>
                                </ul>
                              </>
                            )}
                        </div>
                        <div className="card-body">
                          {!this.state.promotionSelected &&
                            section &&
                            section.items &&
                            section.items
                              .filter(
                                (item) =>
                                  item.disabled === false &&
                                  !item.hidden_from_public
                              )
                              .map((item) => (
                                <MenuItem
                                  onlineOrdering={menu.online_ordering}
                                  key={item._id}
                                  showOnlineOrdering={showOnlineOrdering}
                                  handleOpenAddToCart={this.handleOpenAddToCart}
                                  tenant={tenant}
                                  restaurant={restaurant}
                                  sectionName={section.name}
                                  menuName={menu.name}
                                  menuDurationName={menu.duration.name}
                                  menuDurationTimeStart={
                                    menu.duration.time_start
                                  }
                                  menuDurationTimeEnd={menu.duration.time_end}
                                  item={item}
                                  fromOnlineOrder={fromOnlineOrder}
                                  pickupDate={pickupDate}
                                  pickupTime={pickupTime}
                                />
                              ))}
                          {this.state.promotionSelected &&
                            section &&
                            !section.is_promotion &&
                            section.items &&
                            section.items
                              .filter(
                                (item) =>
                                  item.disabled === false &&
                                  !item.hidden_from_public
                              )
                              .map((item, index_1) => (
                                <MenuItem
                                  onlineOrdering={true}
                                  key={index_1}
                                  showOnlineOrdering={showOnlineOrdering}
                                  handleOpenAddToCart={this.handleOpenAddToCart}
                                  tenant={tenant}
                                  restaurant={restaurant}
                                  sectionName={item.section_name}
                                  menuName={item.menu_name}
                                  menuDurationName={menu.duration.name}
                                  menuDurationTimeStart={
                                    menu.duration.time_start
                                  }
                                  menuDurationTimeEnd={menu.duration.time_end}
                                  item={item.item}
                                  fromOnlineOrder={fromOnlineOrder}
                                  pickupDate={pickupDate}
                                  pickupTime={pickupTime}
                                />
                              ))}
                          {this.state.promotionSelected &&
                            section &&
                            section.is_promotion === true &&
                            section.items &&
                            section.items.map((item) => (
                              <PromotionItem
                                key={item._id}
                                tenant={tenant}
                                restaurant={restaurant}
                                promotion={item}
                              />
                            ))}
                        </div>
                      </div>
                    </Element>
                  ))}
              </Element>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default MenuList;
