import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import "react-alice-carousel/lib/alice-carousel.css"

export default class BannerCarousel extends React.Component {
  state = {
    currentIndex: 0,
    responsive: {
      
    },
  }

  slideTo = (i) => this.setState({ currentIndex: i })

  onSlideChanged = (e) => this.setState({ currentIndex: e.item })

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })


  render() {
    const { responsive, currentIndex } = this.state;
    const { tenant } = this.props;

    const carouselItems = tenant.carousel_items;
    if (carouselItems) {
      carouselItems.sort((a, b) => a.sequence > b.sequence ? 1 : -1)
    }

    return (
      <AliceCarousel
        dotsDisabled={false}
        buttonsDisabled={true}
        autoPlay={true}
        autoPlayInterval={4000}
        fadeOutAnimation={true}
        playButtonEnabled={false}
        responsive={responsive}
        slideToIndex={currentIndex}
        onSlideChanged={this.onSlideChanged}
      >
        {carouselItems.map((item, index) => (
          <img
            key={item._id}
            className="img-fluid"
            // src={process.env.REACT_APP_API_BASE + `/api/eater/images?imageUrl=${encodeURIComponent(tenant.slug + '/' + item.image.url)}`}
            src={process.env.REACT_APP_S3_BASE_URL + `${encodeURIComponent(tenant.slug + '/' + item.image.url)}`}
            alt={item.caption}
          />
        ))}
      </AliceCarousel>
    )
  }
}