import React from "react";

import PickupLocation from "./PickupLocation";
import PickupTime from "./PickupTime";
import moment from "moment";
import * as Icon from "react-feather";

const PickupPanel = ({
  pickupLocation,
  pickupTimeType,
  pickupDate,
  pickupTime,
  onlineOrderLeadTime,
  restaurant,
  pickupTimeRange,
}) => {

  return (
    <div className="card mt-3">
      <div className="card-header">
        <strong>PICK UP INFORMATION</strong>
      </div>
      <div className="card-body">
        <div className="row border-bottom">
          <div className="col-12">
            <Icon.MapPin size={16} /> <strong>Location: </strong>
            <PickupLocation location={pickupLocation} />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            {pickupTimeType === "Schedule" && (
              <>
                <Icon.Clock size={16} /> <strong>Requested Time </strong>
                <PickupTime
                  pickupTimeType={pickupTimeType}
                  pickupDate={moment(pickupDate)}
                  pickupTime={pickupTime}
                  onlineOrderLeadTime={onlineOrderLeadTime}
                  pickupTimeRange={pickupTimeRange}
                  restaurant={restaurant}
                />
              </>
            )}
            {pickupTimeType === "ASAP" && (
              <>
                <Icon.Clock size={16} /> <strong>{`Estimated Pickup Time: `}</strong>{`${onlineOrderLeadTime} minutes after order placed`} 
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupPanel;
