import React from "react";

import DeliveryLocation from "./DeliveryLocation";
import DeliveryTime from "./DeliveryTime";
import moment from "moment";
import * as Icon from "react-feather";

const DeliveryPanel = ({
  pickupTimeType,
  deliveryLocation,
  deliveryDate,
  deliveryTime,
  onlineOrderLeadTime,
  restaurant,
  pickupTimeRange
}) => {
  return (
    <div className="card mt-3">
      <div className="card-header">
        <strong>DELIVERY INFORMATION</strong>
      </div>
      <div className="card-body">
        <div className="row border-bottom">
          <div className="col-12">
            <Icon.MapPin size={16} /> <strong>Location: </strong>
            <DeliveryLocation location={deliveryLocation} />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <Icon.Clock size={16} /> <strong>Requested Time </strong>
            {pickupTimeType === "ASAP" && (
              <div>
                {`${onlineOrderLeadTime} minutes after order placed`}{" "}
              </div>
            )}
            {pickupTimeType === "Schedule" && (
              <DeliveryTime
                deliveryDate={moment(deliveryDate)}
                deliveryTime={deliveryTime}
                onlineOrderLeadTime={onlineOrderLeadTime}
                restaurant={restaurant}
                pickupTimeRange={pickupTimeRange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPanel;
