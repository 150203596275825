import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Footer.css";

import moment from "moment";

class Footer extends Component {
  componentDidMount() {}

  render() {
    const { tenant, announcements = [], promotions = [] } = this.props;
    const showOnlineOrdering = tenant.settings.online_ordering === true;
    const showOnlineReservation = tenant.settings.online_reservation === true;
    const showGiftcard = tenant.settings.gift_card === true;

    return (
      <footer className="main-footer container">
        <div className="row">
          <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
            <NavLink to="/home">Home</NavLink>
          </div>
          {tenant.restaurants.length > 1 && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/locations">Locations</NavLink>
            </div>
          )}
          <div className="col-5 offset-1 offset-md-2  col-md-3">
            <NavLink to="/menus">Menus</NavLink>
          </div>
          {showOnlineOrdering && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/orderonline">Order Online</NavLink>
            </div>
          )}
          {showOnlineReservation && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/reservation">Reserve A Table</NavLink>
            </div>
          )}
          {showOnlineOrdering && showOnlineReservation &&(
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/orderahead">Order Ahead</NavLink>
            </div>
          )}
          {showGiftcard && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/gift">Gift Cards</NavLink>
            </div>
          )}          
          {promotions.length > 0 && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/promotions">Promotions</NavLink>
            </div>
          )}
          {announcements.length > 0 && (
            <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
              <NavLink to="/announcements">Announcements</NavLink>
            </div>
          )}
          <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
            <NavLink to="/gallery">Gallery</NavLink>
          </div>
          <div className="col-5 offset-1 offset-md-2 col-md-3 mb-3">
            <NavLink to="/contact">Contact</NavLink>
          </div>
        </div>
        <div className="row pt-3 pb-3 border-top ">
          <div className="col-12 text-center align-self-center">
            <span className="copyright ml-3">
              @ {moment().format("YYYY")},{" "}
              {`${tenant.name}, All Rights Reserved.`}
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurant: state.tenant.restaurant
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
