import React, { Component } from "react";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import { Redirect } from "react-router";
import {
  fetchPaymentMethods,
  deletePaymentMethod,
  addPaymentMethod,
  updatePaymentMethod,
} from "../../actions/user";
import PaymentMethodView from "./PaymentMethodView";
import PaymentMethodAdd from "./PaymentMethodAdd";
import PaymentMethodEdit from "./PaymentMethodEdit";

import { sendGAEvent } from "../../utils/analytics";

class PaymentMethodList extends Component {
  constructor(props) {
    super(props);

    this.handleAddPaymentMethod = this.handleAddPaymentMethod.bind(this);
    this.handleEditPaymentMethod = this.handleEditPaymentMethod.bind(this);
    this.handleDeletePaymentMethod = this.handleDeletePaymentMethod.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.saveNewPaymentMethod = this.saveNewPaymentMethod.bind(this);
    this.saveModifiedPaymentMethod = this.saveModifiedPaymentMethod.bind(this);
    this.handleViewProfile = this.handleViewProfile.bind(this);

    this.state = {
      mode: "view",
      selectedPaymentMethod: undefined,
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    this.props.fetchPaymentMethods();
  }

  handleAddPaymentMethod() {
    this.setState({ mode: "add" });
  }

  handleEditPaymentMethod(paymentMethod) {
    sendGAEvent({
      category: "User",
      action: "Edit Payment Method",
      label: ``,
    });
    this.setState({ mode: "edit", selectedPaymentMethod: paymentMethod });
  }

  handleDeletePaymentMethod(paymentMethod) {
    sendGAEvent({
      category: "User",
      action: "Delete Payment Method",
      label: ``,
    });
    this.props.deletePaymentMethod(paymentMethod);
  }

  handleCancel() {
    this.setState({ mode: "view" });
  }

  handleViewProfile() {
    this.setState({
      redirectTo: "/profile",
    });
  }

  saveNewPaymentMethod(stripeToken, email, phone) {
    sendGAEvent({
      category: "User",
      action: "Add Payment Method",
      label: ``,
    });
    this.props.addPaymentMethod(stripeToken, email, phone, () => {
      this.setState({ mode: "view" });
    });
  }

  saveModifiedPaymentMethod(paymentMethodId, stripeToken, email, phone) {
    this.props.updatePaymentMethod(
      paymentMethodId,
      stripeToken,
      email,
      phone,
      () => {
        this.setState({ mode: "view" });
      }
    );
  }

  render() {
    const { paymentMethods = [] } = this.props;
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleViewProfile}
              >
                Profile
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Payment Methods
              </button>
            </li>
          </ol>
        </nav>
        {this.state.mode === "view" && (
          <>
            <div className="row pt-3 pb-6 mb-3 border-bottom">
              <div className="col-6">
                <span className="align-middle">My Payment Methods</span>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.handleAddPaymentMethod}
                >
                  <Icon.Plus size={16} /> {` `}{" "}
                  <span className="d-none d-md-inline">
                    {" "}
                    Add Payment Method
                  </span>
                </button>
              </div>
            </div>
            {paymentMethods.map((paymentMethod) => (
              <PaymentMethodView
                key={paymentMethod._id}
                paymentMethod={paymentMethod}
                onDelete={() =>
                  this.handleDeletePaymentMethod(paymentMethod._id)
                }
                onEdit={() => this.handleEditPaymentMethod(paymentMethod)}
              />
            ))}
          </>
        )}
        {this.state.mode === "add" && (
          <>
            <div className="row pt-3 pb-6 mb-3 border-bottom">
              <div className="col-12">
                <button type="button" className="btn btn-light" disabled>
                  <Icon.Plus size={16} /> {` `}{" "}
                  <span className="d-none d-md-inline">
                    {" "}
                    Add Payment Method
                  </span>
                </button>
              </div>
            </div>
            <PaymentMethodAdd
              onCancel={this.handleCancel}
              onSave={this.saveNewPaymentMethod}
            />
          </>
        )}
        {this.state.mode === "edit" && (
          <>
            <div className="row pt-3 pb-6 mb-3 border-bottom">
              <div className="col-12">
                <button type="button" className="btn btn-light" disabled>
                  <Icon.Edit3 size={16} /> {` `}{" "}
                  <span className="d-none d-md-inline">
                    {" "}
                    Edit Payment Method
                  </span>
                </button>
              </div>
            </div>
            <PaymentMethodEdit
              paymentMethod={this.state.selectedPaymentMethod}
              onCancel={this.handleCancel}
              onSave={this.saveModifiedPaymentMethod}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentMethods: state.user.paymentMethods,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentMethods: () => dispatch(fetchPaymentMethods()),
  deletePaymentMethod: (paymentMethod) =>
    dispatch(deletePaymentMethod(paymentMethod)),
  addPaymentMethod: (stripeToken, email, phone, callback) =>
    dispatch(addPaymentMethod(stripeToken, email, phone, callback)),
  updatePaymentMethod: (paymentMethodId, stripeToken, email, phone, callback) =>
    dispatch(
      updatePaymentMethod(paymentMethodId, stripeToken, email, phone, callback)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList);
