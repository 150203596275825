import React from "react";

const AnnoucementBar = (props) => {
  const { announcements = [] } = props;
  if (announcements.length > 0) {
    return (
      <>
        {announcements.map((announcement) => (
          <div className="alert alert-danger">
            <div className="row">
              <div className="col-12 text-left">
                <i className="fas fa-bullhorn mr-3" />
                <span className="mr-3">{announcement.title}</span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return null;
  }
};



export default AnnoucementBar;
