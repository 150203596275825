import React from "react";
import moment from 'moment-timezone';

const OpeningHours = ({ restaurant }) => {
  let result = [];
  result.push({
    days: "Sunday",
    times: restaurant.regular_business_hours.sunday.is_open
      ? moment(restaurant.regular_business_hours.sunday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format)  +
      " - " +
      moment(restaurant.regular_business_hours.sunday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Monday",
    times: restaurant.regular_business_hours.monday.is_open
      ? moment(restaurant.regular_business_hours.monday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.monday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Tuesday",
    times: restaurant.regular_business_hours.tuesday.is_open
      ? moment(restaurant.regular_business_hours.tuesday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.tuesday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Wednesday",
    times: restaurant.regular_business_hours.wednesday.is_open
      ? moment(restaurant.regular_business_hours.wednesday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.wednesday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Thursday",
    times: restaurant.regular_business_hours.thursday.is_open
      ? moment(restaurant.regular_business_hours.thursday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.thursday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Friday",
    times: restaurant.regular_business_hours.friday.is_open
      ? moment(restaurant.regular_business_hours.friday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.friday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });
  result.push({
    days: "Saturday",
    times: restaurant.regular_business_hours.saturday.is_open
      ? moment(restaurant.regular_business_hours.saturday.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format) +
      " - " +
      moment(restaurant.regular_business_hours.saturday.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)
      : "CLOSED"
  });

  let _result = {};
  for (var i = 0; i < result.length; i++) {
    if (!_result[result[i].times]) {
      _result[result[i].times] = [result[i].days];
    } else {
      _result[result[i].times].push(result[i].days);
    }
  }

  let _result_1 = [];
  for (let [key, value] of Object.entries(_result)) {
    if (value.length > 1) {
      _result_1.push({
        days: value[0] + " - " + value[value.length - 1],
        times: key
      });
    } else {
      _result_1.push({ days: value[0], times: key });
    }
  }

  return (
    <React.Fragment>
      {_result_1.map((item, index) => (
        <div className="row mb-1 text-muted" key={index}>
          <div className="col-6 font-weight-bold text-right">
            {item.days}:
              </div>
          <div className="col-6 text-left">
            {item.times}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default OpeningHours;
