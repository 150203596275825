import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import $ from "jquery";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const user = cookies.get("customer");

const form = reduxForm({
  form: "inviteByTwitter",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.cotent) {
    errors.content = "Please enter content";
  }

  return errors;
}

export class InviteByTwitter extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);

    this.state = {
      message: 'Click the following link to join the AngkorDelight and get $5 off of your first order.',
      referralLink: undefined
    }
  }

  componentDidMount() {
    if (user) {
      const chunks = window.location.href.split("/");
      const referralLink =  chunks[0] +  "//" + chunks[2] + "/referral/" + user.referralToken;
      this.setState({
        referralLink: referralLink
      })
    }
  }

  handleMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  handleFormSubmit() {
    const referralLinkEncoded = encodeURIComponent(this.state.referralLink);
    const url = "https://www.twitter.com/home?status=" + encodeURIComponent(this.state.message + '\n') + referralLinkEncoded;
    window.open(url, "_blank");
    $(".close").click();
  }

  render() {

    const { handleSubmit } = this.props;
    return (
      <>
      <button
        className="btn btn-circle-twitter btn-circle btn-xl"
        disabled={!user}
        data-toggle="modal"
        data-target="#inviteByTwitterModal"
        data-backdrop="static"
      >
        <i className="fab fa-twitter" />
      </button>
      <p>
        Invite with
          <br />
        Twitter
        </p>
      <div
        className="modal fade"
        id="inviteByTwitterModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="inviteByTwitterModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="inviteByTwitterModalLabel">
                  {`Invite with Twitter`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <div className="form-group">
                  {/* <label>Message</label> */}
                  <div>
                    {/* <textarea
                      name="content"
                      className="form-control"
                      value={this.state.message}
                      rows={"5"}
                      onChange={this.handleMessageChange}
                    /> */}
                    <p>{this.state.message}</p>
                    <p>{this.state.referralLink}</p>
                  </div>
                </div>
              </div>

              <div className="modal-footer d-block text-center">
                {/* <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                  </button> */}
                <button type="submit" className="btn btn-primary">
                  <i className="fas fa-share-square" /> {` `}{`Share on Twitter`} 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.user.error,
    message: state.user.message
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(InviteByTwitter));
