import React from 'react';
import PropTypes from 'prop-types';

const DeliveryLocation = ({ location }) => {
  return (
    <address>
      <strong>Address: </strong>{location.address}<br />
      <strong>Suite/Apartment Number: </strong>{location.suiteApartmentNumber}<br />
      <strong>Postal Code: </strong>{location.postalCode}<br />
      {location.addressType === "Business" &&
        <>
          <strong>Busienss Name: </strong>{location.businessName}<br />
        </>
      }
      {location.addressType === "Other" &&
        <>
          <strong>Other: </strong>{location.otherPublicVenueName}<br />
        </>
      }

    </address>
  )
}

DeliveryLocation.propTypes = {
  location: PropTypes.object,
}

export default DeliveryLocation;