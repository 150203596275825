import {
  FETCH_RESERVATIONS,
  SAVE_RESERVATION,
  CANCEL_RESERVATION,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  reservations: [],
  reservation: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RESERVATIONS:
      return { ...state, reservations: action.payload.reservations };
    case CANCEL_RESERVATION:
      return { ...state };
    case SAVE_RESERVATION:
      return {
        ...state
      };
    default:
      return state;
  }
}
