import {
  CHANGE_PICKUP_LOCATION,
  CHANGE_PICKUP_TIME_TYPE,
  CHANGE_PICKUP_DATE,
  CHANGE_PICKUP_TIME,
  CHANGE_PICKUP_PERSON,
  CHANGE_CUSTOMER,
  CANCEL_ORDER,
  LOGIN_TO_PROCEED,
  CHANGE_SPECIAL_REQUEST,
  SAVE_ORDER,
  FETCH_ORDER,
  FETCH_ORDERS,
  CHANGE_ORDER_TYPE,
  SELECT_MENU,
  CHANGE_PICKUP_TIME_WITH_MENU,
  SELECT_RESTAURANT,
  CHANGE_PAYMENT_METHOD,
  ORDER_ERROR,
  CANCEL_EXISTING_ORDER,
  CHANGE_DELIVERY_ADDRESS,
  CLEAR_DELIVERY_ADDRESS,
  CHANGE_DELIVERY_METHOD,
  CHECK_CUSTOMER_BLOCKED,
  SELECT_LOCATION,
} from "../actions/types";

import moment from "moment";

const INITIAL_STATE = {
  message: "",
  error: "",
  orders: [],
  // pickupTimeType: "ASAP"
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload.orderType,
        deliveryMethod: action.payload.deliveryMethod,
      };
    case CHANGE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case SELECT_RESTAURANT:
    case SELECT_LOCATION:
      return {
        ...state,
        pickupLocation: null,
        pickupDate: moment(),
        pickupTime: moment(),
        pickupPerson: null,
        customer: null,
        checkoutStep: 0,
        specialRequests: null,
        paymentMethod: null,
        deliveryAddress: undefined,
        deliveryMethod: undefined,
        orderType: undefined,
      };
    case SELECT_MENU:
      return { ...state, menu: action.payload.menu };
    case CHANGE_SPECIAL_REQUEST:
      return { ...state, specialRequests: action.payload };
    case CHANGE_PICKUP_LOCATION:
      return { ...state, pickupLocation: action.payload };
    case CHANGE_PICKUP_TIME_TYPE:
      return {
        ...state,
        pickupTimeType: action.payload,
      };
    case CHANGE_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload,
        pickupTime: undefined,
      };
    case CHANGE_PICKUP_TIME:
      window.sessionStorage.setItem("pickupTime", action.payload);
      return {
        ...state,
        pickupTime: action.payload,
      };
    case CHANGE_PICKUP_TIME_WITH_MENU:
      return {
        ...state,
        menu: action.payload.menu,
        pickupTime: action.payload.pickupTime,
      };
    case CHANGE_PICKUP_PERSON:
      return { ...state, pickupPerson: action.payload.personName };
    case CHANGE_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
        isBlocked: action.payload.isBlocked,
      };
    case CHANGE_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.payload };
    case CLEAR_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: undefined };
    case CHECK_CUSTOMER_BLOCKED:
      return { ...state, isBlocked: action.payload.isBlocked };
    case CANCEL_ORDER:
      window.sessionStorage.removeItem("pickupTime");
      return {
        ...state,
        pickupLocation: null,
        pickupDate: moment(),
        pickupTime: moment(),
        pickupPerson: null,
        customer: null,
        checkoutStep: 0,
        specialRequests: null,
        paymentMethod: null,
        deliveryAddress: undefined,
        deliveryMethod: undefined,
      };
    case LOGIN_TO_PROCEED:
      return {
        ...state,
        checkoutStep: action.payload.step,
      };
    case SAVE_ORDER:
      window.sessionStorage.removeItem("pickupTime");
      return {
        ...state,
        pickupLocation: null,
        pickupDate: null,
        pickupTime: null,
        pickupPerson: null,
        customer: null,
        specialRequests: null,
        paymentMethod: null,
        coupons: [],
      };
    case FETCH_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
      };
    case CANCEL_EXISTING_ORDER:
      return {
        ...state,
        message: action.payload.message,
        order: action.payload.order,
      };
    case ORDER_ERROR:
      return { ...state, error: action.payload };
    case CHANGE_DELIVERY_METHOD:
      return { ...state, deliveryMethod: action.payload };
    default:
      return state;
  }
}
