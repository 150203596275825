import React, { Component } from "react";
import { connect } from "react-redux";

import Total from "../Cart/Total";
import Tax from "../Cart/Tax";
import Subtotal from "../Cart/Subtotal";
import DeliveryFee from "../Cart/DeliveryFee";
import CartItems from "../Cart/CartItems";
import CartPromotions from "../Cart/CartPromotions";
import CartDiscounts from "../Cart/CartDiscounts";
import PickupPanel from "../Pickup/PickupPanel";
import DeliveryPanel from "../Delivery/DeliveryPanel";
import CancelOrderLink from "../OrderOnline/CancelOrderLink";

import ApplyCoupon from "./ApplyCoupon";
import RedeemGiftCards from "./RedeemGiftCards";

import AddToCartModal from "../Menu/AddToCartModal";

import * as Icon from "react-feather";

import { calculateOrderLeadTime } from "../../utils/leadtime";

import {
  removeFromCart,
  removePromotionFromCart,
  decreaseQuantity,
  increaseQuantity,
  getSubtotal,
  getTax,
  getGST,
  getPST,
  getTotal,
  getQuantity,
  getDeliveryGST,
  getDeliveryFee,
} from "../../actions/cart";
import CartNextOrderCoupons from "../Cart/CartNextOrderCoupons";

import { fetchPlasticCutleryItems } from "../../actions/tenant";

import { sendGAEvent } from "../../utils/analytics";

class CheckoutStep2 extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSpecialRequestsChange =
      this.handleSpecialRequestsChange.bind(this);

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleRemovePromotion = this.handleRemovePromotion.bind(this);
    this.handleIncreaseQuantity = this.handleIncreaseQuantity.bind(this);
    this.handleDecreaseQuantity = this.handleDecreaseQuantity.bind(this);

    this.state = {
      specialRequests: props.specialRequests || "",
    };
  }

  componentDidMount() {
    if (this.props.restaurant.id) {
      this.props.fetchPlasticCutleryItems(this.props.restaurant.id);
    }
  }

  handleSpecialRequestsChange(event) {
    this.setState({ specialRequests: event.target.value });
  }

  handleRemoveItem(item) {
    sendGAEvent({
      category: "Cart",
      action: "Remove menu item from cart",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
    };

    this.props.removeFromCart(item, promoData);
  }

  handleRemovePromotion(promotion) {
    sendGAEvent({
      category: "Cart",
      action: "Remove promotion from cart",
      label: `${promotion.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
    };
    this.props.removePromotionFromCart(promotion, promoData);
  }

  handleDecreaseQuantity(item) {
    sendGAEvent({
      category: "Cart",
      action: "Decrease quantiy",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
    };
    this.props.decreaseQuantity(item, promoData);
  }

  handleIncreaseQuantity(item) {
    sendGAEvent({
      category: "Cart",
      action: "Increase quantity",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
    };
    this.props.increaseQuantity(item, promoData);
  }

  render() {
    const {
      items,
      promotions,
      subtotal,
      tax,
      taxRate,
      gst,
      gstRate,
      pst,
      pstRate,
      total,
      restaurant,
      pickupDate,
      pickupTime,
      onPrevious,
      onSpecialRequestsChange,
      onCancelOrder,
      discounts,
      deliveryAddress,
      deliveryMethod,
      deliveryFee,
      deliveryGST,
      tenant,
      plasticCutleryItems,
      deliveryDistance,
    } = this.props;

    const pickupLocation = {
      name: restaurant.name,
      address: restaurant.address_1,
      city_town: restaurant.city_town,
      state_province: restaurant.state_province,
      postal_code: restaurant.postal_code,
      phone: restaurant.phone,
    };

    const leadTime = calculateOrderLeadTime(deliveryMethod, restaurant.settings);

    return (
      <React.Fragment>
        <div className="row pt-3 pb-3">
          <div className="col-12">
            <h3 className="pb-3 border-bottom">
              <strong>REVIEW YOUR ORDER</strong>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 pb-3">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-8 col-cart-angkor">
                    <strong>{`Item`}</strong>
                  </div>
                  <div className="col-3 col-cart-angkor text-right">
                    <strong>{`Price`}</strong>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <CartItems
                  items={items}
                  changeable={true}
                  handleRemoveItem={this.handleRemoveItem}
                />
                <CartPromotions
                  promotions={promotions}
                  changeable={true}
                  handleRemovePromotion={this.handleRemovePromotion}
                />
                {(!items || items.length === 0) &&
                  (!promotions || promotions.length === 0) && (
                    <div className="row border-bottom pb-3">
                      <div className="col-12">Your cart is empty</div>
                    </div>
                  )}

                <Subtotal subtotal={subtotal} />

                <Tax
                  tax={tax}
                  taxRate={taxRate}
                  gst={gst}
                  gstRate={gstRate}
                  pst={pst}
                  pstRate={pstRate}
                />
                {deliveryMethod === "Delivery" && (
                  <DeliveryFee
                    deliveryFee={deliveryFee}
                    deliveryGST={deliveryGST}
                    gstRate={restaurant ? restaurant.settings.gst_rate : 0}
                    deliveryDistance={deliveryDistance}
                  />
                )}
                <CartDiscounts discounts={discounts} />
                <ApplyCoupon subtotal={subtotal} />
                <RedeemGiftCards />
                <Total total={total} />
                <CartNextOrderCoupons
                  tenantName={tenant.name}
                  discounts={discounts}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 pb-3">
            <div className="card">
              <div className="card-header">
                <strong>{"Order Instructions"}</strong>
              </div>
              <div className="card-body">
                <div className="form-group pt-3">
                  <label>Need plastic cutlery ?</label>
                  <div className="row">
                    {plasticCutleryItems.map((cutleryItem) => (
                      <div className="col-12 col-lg-6" key={cutleryItem._id}>
                        <AddToCartModal
                          menuItem={cutleryItem}
                          buttonLabel={`Add ${cutleryItem.name}`}
                          fixedWidth={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <form>
                  <div className="form-group pt-3">
                    <label>Special Requests</label>
                    <textarea
                      name="specialRequests"
                      className="form-control"
                      value={this.state.specialRequests}
                      onChange={this.handleSpecialRequestsChange}
                    />
                  </div>
                </form>
              </div>
            </div>
            {deliveryMethod === "Pickup" && (
              <PickupPanel
                pickupTimeType={this.props.pickupTimeType}
                pickupLocation={pickupLocation}
                pickupDate={pickupDate}
                pickupTime={pickupTime}
                onlineOrderLeadTime={
                  leadTime
                 }
                 pickupTimeRange={
                   restaurant.settings.pickup_time_interval_in_minutes
                 }
                restaurant={restaurant}
              />
            )}
            {deliveryMethod === "Delivery" && deliveryAddress && (
              <DeliveryPanel
                pickupTimeType={this.props.pickupTimeType}
                deliveryLocation={deliveryAddress}
                deliveryDate={pickupDate}
                deliveryTime={pickupTime}
                onlineOrderLeadTime={
                  leadTime
                }
                pickupTimeRange={
                  restaurant.settings.delivery_time_interval_in_minutes
                }
                restaurant={restaurant}
              />
            )}
          </div>
        </div>

        <div className="row pt-3 pb-3 mt-3 border-top">
          <div className="col-3 text-left">
            <button
              className="btn btn-secondary btn-block"
              onClick={onPrevious}
            >
              <Icon.ChevronLeft size={24} />{" "}
              <span className="d-none d-sm-inline">BACK</span>
            </button>
          </div>
          <div className="col-5 text-left">
            <CancelOrderLink onCancelOrder={onCancelOrder} />
          </div>
          <div className="col-4 text-right">
            <button
              className="btn btn-angkor btn-block"
              onClick={() =>
                onSpecialRequestsChange(this.state.specialRequests)
              }
            >
              <span className="d-none d-sm-inline">CONTINUE</span>{" "}
              <Icon.ChevronRight size={24} />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const taxRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.tax_rate
    : 0;
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;

  return {
    items: state.cart.items,
    promotions: state.cart.promotions,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    taxRate: taxRate,
    gst: getGST(state, gstRate),
    gstRate: gstRate,
    pst: getPST(state, pstRate),
    pstRate: pstRate,
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    removable: false,
    specialRequests: state.order.specialRequests,
    restaurant: state.tenant.restaurant,
    pickupTimeType: state.order.pickupTimeType,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    orderType: state.order.orderType,
    deliveryAddress: state.order.deliveryAddress,
    deliveryMethod: state.order.deliveryMethod,
    deliveryDistance: state.order.deliveryAddress?.distanceInMeter,
    deliveryFee: getDeliveryFee(state),
    deliveryGST: getDeliveryGST(state, gstRate),
    tenant: state.tenant.tenant,
    plasticCutleryItems: state.tenant.plasticCutleryItems || [],
    promotionRules: state.tenant.promotions || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPlasticCutleryItems: (restaurantId) =>
    dispatch(fetchPlasticCutleryItems(restaurantId)),
  removeFromCart: (item, promoData) =>
    dispatch(removeFromCart(item, promoData)),
  removePromotionFromCart: (promo, promoData) =>
    dispatch(removePromotionFromCart(promo, promoData)),
  increaseQuantity: (item, promoData) =>
    dispatch(increaseQuantity(item, promoData)),
  decreaseQuantity: (item, promoData) =>
    dispatch(decreaseQuantity(item, promoData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStep2);
