import {
  FETCH_USER,
  USER_ERROR,
  UPDATE_USER_PROFILE,
  CHANGE_PASSWORD,
  ADD_PAYMENT_METHOD,
  FETCH_PAYMENT_METHODS,
  UPDATE_PAYMNET_METHOD,
  DELETE_PAYMENT_METHOD,
  FETCH_BY_REFERRAL_TOKEN,
  FETCH_CUSTOMER_COUPONS,
  FETCH_CUSTOMER_GIFTCARDS,
} from "../actions/types";

const INITIAL_STATE = { profile: {}, message: "", error: "" };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload.user };
    case FETCH_BY_REFERRAL_TOKEN:
      return { ...state, referrer: action.payload.user };
    case UPDATE_USER_PROFILE:
      return { ...state, user: action.payload.user };
    case CHANGE_PASSWORD:
      return { ...state, user: action.payload.user };
    case FETCH_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload.paymentMethods };
    case ADD_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.payload.paymentMethod],
      };
    case UPDATE_PAYMNET_METHOD:
      return {
        ...state,
        paymentMethods: state.paymentMethods.map((paymentMethod) => {
          if (paymentMethod._id === action.payload.paymentMethod._id) {
            return action.payload.paymentMethod;
          } else {
            return paymentMethod;
          }
        }),
      };

    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (paymentMethod) =>
            paymentMethod._id !== action.payload.paymentMethod._id
        ),
      };
    case FETCH_CUSTOMER_COUPONS:
      return { ...state, coupons: action.payload.coupons };
    case FETCH_CUSTOMER_GIFTCARDS:
      return { ...state, giftCards: action.payload.giftCards };
    case USER_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
