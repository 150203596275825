import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";

import {
  fetchOnlineOrderMenus,
  fetchMostOrderedItems,
} from "../../actions/tenant";
import Cart from "../Cart/Cart";

import MenuList from "../Menu/MenuList";
import Loading from "../Common/Loading";

import {
  getSubtotal,
  getTax,
  getTotal,
  getQuantity,
  clearCart,
} from "../../actions/cart";

// import PreOrderForLaterWarning from "../Menu/PreOrderForLaterWarning";
import * as Icon from "react-feather";
import DeliveryAddressCard from "../Delivery/DeliveryAddressCard";
import PickupPanel from "../Pickup/PickupPanel";
import ChooseRestaurant from "./ChooseRestaurant";

import { sendGAEvent } from "../../utils/analytics";
import CancelOrderLink from "./CancelOrderLink";

import { cancelOrder } from "../../actions/order";

import { CANCEL_ORDER } from "../../actions/types";

import uuidv4 from "uuid/v4";

import { calculateOrderLeadTime } from "../../utils/leadtime";

class BuildOrder extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleNext = this.handleNext.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
  }

  componentDidMount() {
    if (this.props.restaurant) {
      const order_type = this.props.orderType ? this.props.orderType : "ToGo";
      this.props.fetchOnlineOrderMenus(this.props.restaurant._id, order_type);
      this.props.fetchMostOrderedItems(this.props.tenant._id);
    }
  }

  handleNext() {
    sendGAEvent({
      category: "Checkout",
      action: "Proceed to checkout",
      label: ``,
    });
    this.props.history.push("/checkout");
  }

  handleCancelOrder() {
    sendGAEvent({
      category: "Checkout",
      action: "Cancel Order",
      label: ``,
    });
    this.props.clearCart();
    this.props.cancelOrder((dispatch) => {
      dispatch({ type: CANCEL_ORDER, payload: {} });
    });
  }

  mergeMenusWithMostOrderedItems(menus=[], mostOrderedItems) {
    const result = JSON.parse(JSON.stringify(menus));
    if (result.length > 0 && mostOrderedItems?.length > 0) {
      result[0].sections.unshift({
        name: "Picked for you",
        id: uuidv4(),
        items: mostOrderedItems
      })
    }
    return result;
  }

  render() {
    const {
      tenant,
      restaurant,
      menus = [],
      promotionItems = [],
      deliveryMethod,
      pickupDate,
      pickupTime,
      subtotal,
      minimumOrderAmount,
      deliveryAddress,
      mostOrderedItems = []
    } = this.props;

    if (!this.props.restaurant) {
      return <ChooseRestaurant />;
    }
    if (!this.props.orderType || !this.props.deliveryMethod) {
      return <Redirect to="/orderonline" />;
    }

    if (
      this.props.deliveryMethod === "Delivery" &&
      !this.props.deliveryAddress
    ) {
      return <Redirect to="/deliveryaddress" />;
    }

    if (this.props.pickupTimeType === "Schedule" && (!pickupDate || !pickupTime)) {
      return <Redirect to="/pickuptime" />;
    }

    if (menus.length <= 0) {
      return <Loading className="center" />;
    }

    const pickupLocation = {
      name: restaurant.name,
      address: restaurant.address_1,
      city_town: restaurant.city_town,
      state_province: restaurant.state_province,
      postal_code: restaurant.postal_code,
      phone: restaurant.phone,
    };

    const menusWithMostOrderedItems = this.mergeMenusWithMostOrderedItems(menus, mostOrderedItems);

    const leadTime = calculateOrderLeadTime(deliveryMethod, restaurant.settings);


    return (
      <React.Fragment>

        <div className="row">
          <div className="col-12 col-lg-8">
            <MenuList
              tenant={tenant}
              restaurant={restaurant}
              menus={menusWithMostOrderedItems}
              promotionItems={promotionItems}
              fromOnlineOrder={true}
              pickupDate={pickupDate}
              pickupTime={pickupTime}
            />
          </div>
          <div className="col-12 col-lg-4">
            {deliveryMethod === "Delivery" && <DeliveryAddressCard />}
            {deliveryMethod === "Pickup" && (
              <PickupPanel
                pickupLocation={pickupLocation}
                pickupTimeType={this.props.pickupTimeType}
                pickupDate={pickupDate}
                pickupTime={pickupTime}
                onlineOrderLeadTime={
                 leadTime
                }
                pickupTimeRange={
                  deliveryMethod === "Delivery" ? restaurant.settings.delivery_time_interval_in_minutes : restaurant.settings.pickup_time_interval_in_minutes
                }
                restaurant={restaurant}
              />
            )}
            {menusWithMostOrderedItems.length > 0 && <Cart showPreviousButton={false} />}
          </div>
        </div>
        <div className="row pt-3 pb-3 mt-3 border-top">
          <div className="col-3 text-left">
            <button
              className="btn btn-secondary btn-block"
              onClick={() => {
                this.props.history.push("/pickuptime");
              }}
            >
              <Icon.ChevronLeft size={24} />{" "}
              <span className="d-none d-sm-inline">BACK</span>
            </button>
          </div>
          <div className="col-5 text-center">
            <CancelOrderLink onCancelOrder={this.handleCancelOrder} />
          </div>
          <div className="col-4 text-right">
            <button
              className="btn btn-angkor btn-block"
              disabled={
                deliveryMethod === "Delivery"
                  ? subtotal < minimumOrderAmount || !deliveryAddress
                    ? true
                    : false
                  : subtotal > 0
                  ? false
                  : true
              }
              onClick={this.handleNext}
            >
              <span className="d-none d-sm-inline">PROCEED TO CHECKOUT</span>
              <Icon.ChevronRight size={16} />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant?._id
      )
    : [];
  const validMenus = state.tenant.onlineOrderMenus || [];

  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;

  return {
    tenant: state.tenant.tenant,
    orderType: state.order.orderType,
    restaurant: state.tenant.restaurant,
    holidayHours: holidayHours,
    menus: validMenus,
    promotionRules: state.tenant.promotions || [],
    promotionItems: state.tenant.promotionItems || [],
    pickupTimeType: state.order.pickupTimeType,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    menu: state.order.menu,
    items: state.cart.items,
    promotions: state.cart.promotions,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    deliveryAddress: state.order.deliveryAddress,
    deliveryMethod: state.order.deliveryMethod,
    minimumOrderAmount:
      state.tenant.restaurant?.settings.delivery_options.minimum_order_amount,
    mostOrderedItems: state.tenant.mostOrderedItems
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOnlineOrderMenus: (restaurantId, orderType) =>
    dispatch(fetchOnlineOrderMenus(restaurantId, orderType)),
  cancelOrder: (callback) => dispatch(cancelOrder(callback)),
  clearCart: () => dispatch(clearCart()),
  fetchMostOrderedItems: (tenantId) =>
    dispatch(fetchMostOrderedItems(tenantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildOrder));
