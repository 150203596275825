import React from "react";

import moment from "moment";

const PickupDateTable = ({
  pickupDates,
  onSelectPickupDate,
  pickupDate,
  restaurant,
}) => {
  const isValidPickupDate =
    pickupDates.filter(
      (pd) =>
        moment(pd).format("YYYY-MM-DD") ===
        moment(pickupDate).format("YYYY-MM-DD")
    ).length > 0;

  return (
    <>
      {!isValidPickupDate && (
        <div className="alert alert-danger" role="alert">
          Sorry, we are closed on{" "}
          {moment(pickupDate)
            .tz(restaurant.settings.time_zone)
            .format(restaurant.settings.date_format)}
          .
          {pickupDates.length > 0 && (
            <span>Plese choose any one of the following dates.</span>
          )}
        </div>
      )}
      <ul className="time-slot">
        {pickupDates.map((date, index) => (
          <React.Fragment key={index}>
            <li
              key={index}
              className={
                moment(date).isSame(pickupDate, "day") ? "time-selected" : ""
              }
              role="radio"
              tabIndex="-1"
              aria-checked="false"
              onClick={() => onSelectPickupDate(date, pickupDates)}
            >
              {moment(date)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </>
  );
};

export default PickupDateTable;
