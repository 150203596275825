import axios from "axios";
import Cookies from "universal-cookie";

import {
  CHANGE_PICKUP_LOCATION,
  CHANGE_PICKUP_PERSON,
  CHANGE_CUSTOMER,
  SAVE_ORDER,
  ORDER_ERROR,
  AUTH_USER,
  CLEAR_CART,
  FETCH_ORDER,
  FETCH_ORDERS,
  CHANGE_ORDER_TYPE,
  CANCEL_EXISTING_ORDER,
  CHANGE_PAYMENT_METHOD,
  CHANGE_DELIVERY_ADDRESS,
  CLEAR_DELIVERY_ADDRESS,
  CHANGE_DELIVERY_METHOD,
  AUTH_ERROR,
  CHECK_CUSTOMER_BLOCKED,
} from "./types";

import { errorHandler, getData, patchData } from "./index";

const cookies = new Cookies();

export function selectMenu(menu, callback) {
  return (dispatch) => callback(dispatch);
}

export function changeOrderType(orderType, deliveryMethod, callback) {
  callback();
  return {
    type: CHANGE_ORDER_TYPE,
    payload: { orderType, deliveryMethod },
  };
}

export function changePaymentMethod(paymentMethod) {
  return {
    type: CHANGE_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export function changeSpecialRequests(requests, callback) {
  return (dispatch) => callback(dispatch);
}

export function loginToCheckout({ email, password }, successCallback) {
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_API_BASE + `/api/eater/auth/login`, {
        email,
        password,
      })
      .then((response) => {
        successCallback();
        cookies.set("ctoken", response.data.token, { path: "/" });
        cookies.set("customer", response.data.user, { path: "/" });
        dispatch({ type: AUTH_USER });
        dispatch({ type: CHECK_CUSTOMER_BLOCKED, payload: {isBlocked: response.data.isBlocked} });
      })
      .catch((error) => {
        let errorMessage =
          "Email address and/or password is not correct. Please try again";
        dispatch({
          type: AUTH_ERROR,
          payload: errorMessage,
        });
      });
  };
}

export function changePickupLocation(location) {
  return {
    type: CHANGE_PICKUP_LOCATION,
    payload: location,
  };
}

export function changePickupTimeType(pickupTimeType, callback) {
  return (dispatch) => callback(dispatch);
}

export function changePickupDate(pickupDate, callback) {
  return (dispatch) => callback(dispatch);
}

export function changePickupTime(pickupTime, callback) {
  return (dispatch) => callback(dispatch);
}

export function changePickupTimeWithMenu(menu, pickupTime, callback) {
  return (dispatch) => callback(dispatch);
}

export function changePickupPerson(personName) {
  const data = { personName };
  return {
    type: CHANGE_PICKUP_PERSON,
    payload: data,
  };
}

export function changeCustomer(tenantId, firstName, lastName, email, phone) {
  const data = { firstName, lastName, email, phone };
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/tenants/${tenantId}/customerviews?email=${email}`;
  return function (dispatch) {
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: CHANGE_CUSTOMER,
          payload: { customer: data, isBlocked: response.data.isBlocked },
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ORDER_ERROR);
      });
  };
}

export function cancelOrder(callback) {
  return (dispatch) => callback(dispatch);
}

export function saveOrder(
  token,
  restaurantId,
  items,
  promotions,
  discounts,
  discountTotal,
  subtotal,
  tax,
  gst,
  pst,
  total,
  specialRequests,
  pickupLocation,
  pickupDate,
  pickupTime,
  pickupPerson,
  customer,
  payment,
  deliveryMethod,
  deliveryAddress,
  deliveryFee,
  deliveryGST,
  orderLink,
  coupons,
  redeemedGiftCards,
  pickupTimeType,
  successCallback
) {
  return function (dispatch) {
    axios
      .post(
        process.env.REACT_APP_API_BASE +
          `/api/eater/restaurants/${restaurantId}/orders`,
        {
          token,
          items,
          promotions,
          discounts,
          discountTotal,
          subtotal,
          tax,
          gst,
          pst,
          total,
          specialRequests,
          pickupLocation,
          pickupDate,
          pickupTime,
          pickupPerson,
          customer,
          payment,
          deliveryMethod,
          deliveryAddress,
          deliveryFee,
          deliveryGST,
          orderLink,
          coupons,
          redeemedGiftCards,
          pickupTimeType,
          eaterUUID: cookies.get('guid')
        }
      )
      .then((response) => {
        successCallback(response.data);
        dispatch({ type: SAVE_ORDER });
        dispatch({ type: CLEAR_CART });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ORDER_ERROR);
      });
  };
}

export function fetchOrders() {
  const customerId = cookies.get("customer")._id;
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/customers/${customerId}/orders`;
  return (dispatch) => getData(FETCH_ORDERS, ORDER_ERROR, true, url, dispatch);
}

export function fetchOrder(tenantId, orderId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/tenants/${tenantId}/orders/${orderId}`;
  return (dispatch) => getData(FETCH_ORDER, ORDER_ERROR, true, url, dispatch);
}

export function cancelExistingOrder(tenantId, orderId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/tenants/${tenantId}/orders/${orderId}/cancel`;
  return (dispatch) =>
    patchData(CANCEL_EXISTING_ORDER, ORDER_ERROR, true, url, dispatch);
}

export function changeDeliveryAddress(
  delivery_address,
  postal_code,
  suite_apartment_number,
  delivery_address_type,
  business_name,
  other_public_venue_name,
  delivery_distance,
  callback
) {
  const data = {
    address: delivery_address,
    postalCode: postal_code,
    suiteApartmentNumber: suite_apartment_number,
    addressType: delivery_address_type,
    businessName: business_name,
    otherPublicVenueName: other_public_venue_name,
    editingDeliveryAddress: false,
    distanceInMeter: delivery_distance
  };
  callback();
  return {
    type: CHANGE_DELIVERY_ADDRESS,
    payload: data,
  };
}

export function clearDeliveryAddress() {
  return {
    type: CLEAR_DELIVERY_ADDRESS,
    payload: {},
  };
}

export function changeDeliveryMethod(deliveryMethod) {
  return {
    type: CHANGE_DELIVERY_METHOD,
    payload: deliveryMethod,
  };
}

export function fetchCustomerBlocked(tenantId, email) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/tenants/${tenantId}/customerviews?email=${email}`;
  return (dispatch) => getData(CHECK_CUSTOMER_BLOCKED, ORDER_ERROR, false, url, dispatch);
}
