import React, { Component } from "react";
import { connect } from "react-redux";
import Numeral from "numeral";

import {
  removeCouponFromCart,
  applyCoupon,
  applyPromotions,
} from "../../actions/cart";
import { sendGAEvent } from "../../utils/analytics";

class ApplyCoupon extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCouponCodeChange = this.handleCouponCodeChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.handleRemoveCoupon = this.handleRemoveCoupon.bind(this);

    this.state = {
      couponCode: "",
    };
  }

  handleCouponCodeChange(event) {
    this.setState({
      couponCode: event.target.value,
    });
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleApplyCoupon(couponCode, email, subtotal) {
    sendGAEvent({
      category: "Checkout",
      action: "Apply Coupon",
      label: `${couponCode}`,
    });
    const data = {
      couponCode: couponCode,
      email: email,
      subtotal: subtotal,
      promotionRules: this.props.promotions,
      timeZone: this.props.restaurant.settings.time_zone,
      timeForamt: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      deliveryPostalCode: this.props.deliveryAddress
        ? this.props.deliveryAddress.postalCode
        : "",
      pickupDate: this.props.pickupDate,
      pickupTime: this.props.pickupTime,
    };
    this.props.applyCoupon(this.props.restaurant._id, data, (coupon) => {
      this.setState({
        couponCode: "",
        email: "",
      });
    });
  }

  handleRemoveCoupon(coupon) {
    this.props.removeCouponFromCart(coupon);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { coupons = [], subtotal } = this.props;

    return (
      <>
        <form className="row border-bottom pb-3 pt-3 mb-3">
          <div className="col-11 text-left col-cart-angkor">
            <label className="mr-3">{`E-COUPON Code`}</label>
            <div>
              <small className="font-italic">{`* Paper coupon is not accepted here. Pleasse present your paper coupon to the cashier!`}</small>
            </div>
            <div>
              <small className="font-italic">{`* One coupon per order!`}</small>
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="couponCode"
                value={this.state.couponCode}
                placeholder={`Coupon code`}
                onChange={this.handleCouponCodeChange}
                disabled={coupons.length > 0}
              />
            </div>
            <div className="form-group">
              <div>
                <small className="font-italic">{`* Please enter email address!`}</small>
              </div>
              <input
                type="text"
                className="form-control"
                id="email"
                value={this.state.email}
                placeholder={`Email address`}
                onChange={this.handleEmailChange}
                disabled={coupons.length > 0}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                this.handleApplyCoupon(
                  this.state.couponCode,
                  this.state.email,
                  subtotal
                )
              }
              disabled={
                coupons.length > 0 ||
                !this.state.couponCode ||
                !this.state.email
              }
            >{`Apply`}</button>
          </div>
        </form>

        {this.renderAlert()}
        {coupons.map((coupon, index) => (
          <div
            className="row border-bottom pb-3 pt-3 mb-3 bg-light"
            key={index}
          >
            <div className="col-8 text-left col-cart-angkor">
              <span>{`Coupon: `}</span>
              <span className="text-uppercase">{`${coupon.couponCode}`}</span>
            </div>
            <div className="col-3 text-right col-cart-angkor">
              <span>
                <strong>{Numeral(-1 * coupon.amount).format("$0,0.00")}</strong>
              </span>
            </div>
            <div className="col-1 text-left col-cart-angkor">
              <button
                className="btn btn-danger btn-sm btn-sm-angkor"
                onClick={() => this.handleRemoveCoupon(coupon)}
              >
                {`X`}
              </button>
            </div>
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.tenant.restaurant,
    coupons: state.cart.coupons,
    coupon: state.cart.coupon,
    errorMessage: state.cart.errorMessage,
    promotions: state.tenant.promotions || [],
    deliveryMethod: state.order.deliveryMethod,
    deliveryAddress: state.order.deliveryAddress,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeCouponFromCart: (coupon) => dispatch(removeCouponFromCart(coupon)),
  applyCoupon: (restaurantId, data, callback) =>
    dispatch(applyCoupon(restaurantId, data, callback)),
  applyPromotions: (
    promotions,
    timezone,
    timeFormat,
    deliveryMethod,
    couponAmount
  ) =>
    dispatch(
      applyPromotions(
        promotions,
        timezone,
        timeFormat,
        deliveryMethod,
        couponAmount
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCoupon);
