import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchMenus, 
  selectRestaurant, 
  fetchBusinessHoursByTenantId,
  fetchBusinessHours
} from "../../actions/tenant";

import { SELECT_RESTAURANT } from "../../actions/types";
import Location from "../Locations/Location";

import MenuList from "./MenuList";
import Loading from "../Common/Loading";
import PreOrderForLaterWarning from "./PreOrderForLaterWarning";

const uuidv4 = require("uuid/v4");

class DineInMenu extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.props.fetchMenus(this.props.restaurant._id);
    }
    if (this.props.restaurant) {
      this.props.fetchBusinessHours(this.props.restaurant._id);
    } else if (this.props.tenant) {
      this.props.fetchBusinessHoursByTenantId(this.props.tenant._id);
    }
  }

  componentWillUnmount() { }

  handleSelectRestaurant(restaurant) {
    this.props.selectRestaurant(restaurant, dispatch => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant }
      });
      this.props.fetchMenus(restaurant._id);
    });
  }

  render() {
    const {
      tenant,
      restaurants = [],
      restaurant,
      menus = [],
      promotions = [],
      holidayHours = [],
      promotionItems = []
    } = this.props;

    if (!restaurant) {
      return (
        <section>
          <h2 className="text-center">Menus</h2>
          <hr />
          <h6 className="p-3 mb-3 bg-info text-white">
            Menu varies at different location, please select location to view
            menu.
          </h6>

          {restaurants.map((restaurant, index) => (
            <Location
              key={restaurant._id}
              restaurant={restaurant}
              showButtons={false}
              onSelectLocation={this.handleSelectRestaurant}
            />
          ))}
        </section>
      );
    }
    if (menus.length <= 0) {
      return <Loading className="center" />;
    }

    return (
      <>
        <PreOrderForLaterWarning
          restaurant={restaurant}
          holidayHours={holidayHours}
        />
        {menus && menus.length > 0 && (
          <div className="row">
            <div className="col-12">
              <MenuList
                tenant={tenant}
                restaurant={restaurant}
                menus={menus.filter(menu => menu.online_ordering === false)}
                promotionItems={promotionItems}
                promotions={promotions}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
      businessHour =>
        businessHour.restaurant.valueOf() === state.tenant.restaurant._id
    )
    : [];
  const otherPromos = (state.tenant.promotions || []).filter(
    promo =>
      ["Buy one, get one free", "% discount on selected items"].includes(
        promo.type
      ) === false
  );
  return {
    tenant: state.tenant.tenant,
    restaurants: state.tenant.restaurants,
    restaurant: state.tenant.restaurant,
    holidayHours: holidayHours,
    menus: state.tenant.menus || [],
    promotions: state.tenant.promotions || [],
    promotionItems: (state.tenant.promotionItems || []).concat(
      otherPromos.length > 0
        ? [
          {
            _id: uuidv4(),
            is_promotion: true,
            name: "Other",
            items: otherPromos
          }
        ]
        : []
    )
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenus: restaurantId => dispatch(fetchMenus(restaurantId)),
  selectRestaurant: (restaurant, callback) =>
    dispatch(selectRestaurant(restaurant, callback)),
  fetchBusinessHoursByTenantId: tenantId =>
    dispatch(fetchBusinessHoursByTenantId(tenantId)),
  fetchBusinessHours: (restaurantId) => dispatch(fetchBusinessHours(restaurantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DineInMenu);
