import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';

import {
  changeOrderType,
} from "../../actions/order";

class ChooseOrderType extends Component {
  componentDidMount() {
  }

  render() {
    const { restaurant, tenant } = this.props;

    if (restaurant.settings.online_ordering) {
      return (
        <>
          <div className="row pt-3 pb-3 mb-3">
            <div className="col-12 ">
              <h3 className="pb-3 border-bottom">
                <strong>START YOUR ORDER</strong>
              </h3>
            </div>
          </div>
          <ul className="list-unstyled">
            {restaurant.settings.delivery_options.support_pickup === true &&
              <li className="media pb-3 border-bottom">
  
                <div className="media-body">
                  <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-8">
                      <h5 className="mt-0 text-sm-center text-md-left">{`PICK UP`}</h5>
                      <p>{tenant.settings.descriptions.pickup_order.title}</p>
                      {tenant.settings.descriptions.pickup_order.subtitles.length > 0 &&
                        <ul>
                          {
                            tenant.settings.descriptions.pickup_order.subtitles.map((subtitle, index) => (
                              <li key={index}>{subtitle}</li>
                            ))
                          }
                        </ul>
                      }
                    </div>
                    <div className="col-12 col-sm-12 text-sm-center col-md-4 text-md-left">
                      <button className="btn btn-angkor" onClick={() => this.props.changeOrderType('ToGo', 'Pickup', ()=> {this.props.history.push('/pickuptime')})}>{`ORDER NOW`}</button>
                    </div>
                  </div>
                </div>
              </li>
            }
            {restaurant.settings.delivery_options.support_delivery === true &&
              <li className="media pt-3 pb-3 border-bottom">
  
                <div className="media-body">
                  <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-8">
                      <h5 className="mt-0 text-sm-center  text-md-left">{`DELIVERY`}</h5>
                      <p>{restaurant.settings.descriptions.delivery_order.title}</p>
                      {restaurant.settings.descriptions.delivery_order.subtitles.length > 0 &&
                        <ul>
                          {
                            restaurant.settings.descriptions.delivery_order.subtitles.map((subtitle, index) => (
                              <li key={index}>{subtitle}</li>
                            ))
                          }
                        </ul>
                      }
                    </div>
                    <div className="col-12 col-sm-12 text-sm-center col-md-4  text-md-left">
                      <button className="btn btn-angkor" onClick={() => this.props.changeOrderType('ToGo', 'Delivery', ()=> {this.props.history.push('/deliveryaddress')})}>{`ORDER NOW`}</button>
                    </div>
                  </div>
                </div>
              </li>
            }
            {/* <li className="media pt-3 pb-3 border-bottom">
  
            <div className="media-body">
              <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-8">
                  <h5 className="mt-0">{`CATERING`}</h5>
                  <p>Delicious catering combinations, available for pickup and perfect for any occassion</p>
                </div>
                <div className="col-12 col-sm-12 col-md-4 ">
                  <button className="btn btn-angkor" onClick={() => onSelectOrderType('Catering')}>{`ORDER NOW`}</button>
                </div>
              </div>
            </div>
          </li> */}
          </ul>
        </>
      );
    } 
    else {
      return (
        // <div className="alert alert-warning"
        // >{`Sorry, Online ordering is temporarily paused, it will be resumed on may 1st.`}</div>
        <></>
      )
    }

  }
}

const mapStateToProps = state => {
  return {
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  changeOrderType: (orderType, deliveryMethod, callback) => dispatch(changeOrderType(orderType, deliveryMethod, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChooseOrderType));
