import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import {
  renderEmailField,
  renderPasswordField,
  email,
  required,
} from "../../utils/renderFields";

import { sendGAEvent } from "../../utils/analytics";

function validate(formProps) {
  const errors = {};

  if (!formProps.email) {
    errors.email = "Please enter your email";
  } else {
    let emailValid = formProps.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (!emailValid) {
      errors.email = formProps.email + " is not valid email address";
    }
  }
  if (!formProps.password) {
    errors.password = "Please enter password";
  }

  return errors;
}

const logRegisterAccountGAEvent = () => {
  sendGAEvent({
    category: "Checkout",
    action: "Register to proceed",
    label: ``,
  });
};

const logForgotPasswordGAEvent = () => {
  sendGAEvent({
    category: "Checkout",
    action: "Forgot Password",
    label: ``,
  });
};

class LoginToProceed extends Component {
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { loginError, handleSubmit, pristine, submitting } = this.props;
    const pathname = window.location.pathname;

    return (
      <div className="card mb-3">
        <div className="card-header text-center">
          <strong>LOGIN TO MY ACCOUNT</strong>
        </div>
        <div className="card-body text-secondary">
          <form onSubmit={handleSubmit}>
            {this.renderAlert()}
            <div className="form-group">
              <label>Email</label>
              <Field
                name="email"
                className="form-control"
                component={renderEmailField}
                validate={[required, email]}
                disabled={this.props.couponEmail}
                type="email"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <Field
                name="password"
                className="form-control"
                component={renderPasswordField}
                validate={[required]}
                type="password"
              />
            </div>
            {loginError && (
              <div className="alert alert-danger">
                <strong>{loginError}</strong>
              </div>
            )}
            <div className="controls text-center">
              <button
                type="submit"
                disabled={pristine || submitting}
                className="btn btn-angkor btn-block"
              >
                <span>{`LOGIN TO PROCEED`}</span>
              </button>
            </div>
            <div className="clearfix" />
            <hr />
            <div>
              <div id="forgot-password">
                <Link
                  to="/forgot-password"
                  onClick={() => logForgotPasswordGAEvent()}
                >
                  {" "}
                  Forgot Your Password?
                </Link>
              </div>
              <div id="register">
                <Link
                  to={`/register?from=${pathname}&step=2`}
                  onClick={() => logRegisterAccountGAEvent()}
                >
                  {" "}
                  Create An Account
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    couponEmail: state.cart.email,
    errorMessage: state.auth.error,
    initialValues: {
      email: state.cart.email,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "loginToProceed", // a unique identifier for this form
    validate, // <--- validation function given to redux-form
  })(LoginToProceed)
);
