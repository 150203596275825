import React from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";

const LocationBar = (props) => {
  const { restaurants = [] } = props;
  if (restaurants.length > 1) {
    const cookies = new Cookies();
    const selectedLocation = cookies.get("selectedLocation");
    if (selectedLocation) {
      const locationElements = selectedLocation.split("|");
      return (
        <div className="alert alert-info">
          <div className="row">
            <div className="col-9 text-left">
              <i className="fas fa-map-marker-alt mr-1" />{" "}
              <span className="mr-3">{locationElements[2]}</span><br className="mobile" />
              <i className="fas fa-phone-alt mr-1" />
              {locationElements[1]}
            </div>
            <div className="col-3"><Link to="/locations">Change Location</Link></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    restaurant: state.tenant.restaurant,
    restaurants: state.tenant.restaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LocationBar);
