import React, { Component } from "react";
import PropTypes from "prop-types";
import PickupTimeslotRow from "./PickupTimeslotRow";
import moment from 'moment-timezone';
import Moment from "moment";
import {getOpeningHourRange} from '../Common/OpeningHourRange';

const calculateTimeslots = (
  startAt,
  endAt,
  intervalInMinutes,
  timeslotsPerRow,
  restaurant
) => {


  if (!intervalInMinutes || intervalInMinutes <= 0) {
    intervalInMinutes = 15
  }
  let timeslots = [];
  let duration = moment.duration(endAt.diff(startAt));
  let minutes = duration.asMinutes();
  let count = Math.ceil(minutes / intervalInMinutes);

  const numberOfRows = count % timeslotsPerRow === 0 ? (count / timeslotsPerRow) + 1 : Math.ceil(count / timeslotsPerRow);
  
  for (let i = 0; i < numberOfRows; i++) {
    let array = [];
    for (let j = 0; j < timeslotsPerRow; j++) {
      if (i * timeslotsPerRow + j <= count) {
        array.push(
          moment(startAt).tz(restaurant.settings.time_zone).add(
            (i * timeslotsPerRow + j) * intervalInMinutes,
            "minutes"
          )
        );
      }
    }

    timeslots.push(array);
  }

  return timeslots;
};

class PickupTimeTable extends Component {
  render() {
    const {
      onlineOrderStart,
      onlineOrderEnd,
      businessHours,
      holidayHours,
      intervalInMinutes,
      timeslotsPerRow,
      currentTime,
      currentDate,
      pickupDate,
      pickupDates,
      onSelectPickupTime,
      pickupTime,
      restaurant,
      leadTime,
      isValidPickupTime
    } = this.props;

    let timeRange = getOpeningHourRange(restaurant, pickupDate, businessHours, holidayHours, onlineOrderStart, onlineOrderEnd);

    let timeslots = calculateTimeslots(
      timeRange.startAt,
      timeRange.endAt,
      intervalInMinutes,
      timeslotsPerRow,
      restaurant
    );

    const isValidPickupDate = pickupDates.filter(pd => moment(pd).format("YYYY-MM-DD") === moment(pickupDate).format("YYYY-MM-DD")).length > 0;

    return (
      <>
      {!isValidPickupTime && pickupTime &&
        <div className="alert alert-danger" role="alert">
          Sorry, you have ordered some items that are not available for sale at your selected pickup time {moment(pickupTime).format(restaurant.settings.time_format)}. Plese go back and remove those items from shopping cart.
      </div>
      }
      <div className="pb-3">
        {timeslots.map((timeslotArray, index) => (
          <PickupTimeslotRow
            key={index}
            timeslotArray={timeslotArray}
            currentTime={currentTime}
            currentDate={currentDate}
            pickupDate={pickupDate}
            isValidPickupDate={isValidPickupDate}
            onTimeslotClick={onSelectPickupTime}
            pickupTime={pickupTime}
            restaurant={restaurant}
            leadTime={leadTime}
          />
        ))}
      </div>
      </>
    );
  }
}

PickupTimeTable.propTypes = {
  intervalInMinutes: PropTypes.number.isRequired,
  currentTime: PropTypes.instanceOf(Moment).isRequired,
  leadTime: PropTypes.number.isRequired,
  pickupDate: PropTypes.instanceOf(Moment).isRequired,
  currentDate: PropTypes.instanceOf(Moment).isRequired,
  onSelectPickupTime: PropTypes.func.isRequired
};

export default PickupTimeTable;
