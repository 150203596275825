import React, { Component } from "react";
import Numeral from "numeral";

import MenuItemTags from "./MenuItemTags";

import spicy_mild_img from "../../assets/spicy_mild_16.png";
import spicy_medium_img from "../../assets/spicy_medium_16.png";
import AddToCartModal from "./AddToCartModal";

import sold_out_48_32_img from "../../assets/sold_out_48_32.png";

import "./MenuItem.css";

const moment = require("moment-timezone");

class MenuItem extends Component {
  render() {
    const {
      item,
      onlineOrdering,
      showOnlineOrdering,
      tenant,
      menuDurationName,
      menuDurationTimeStart,
      menuDurationTimeEnd,
      restaurant,
      fromOnlineOrder,
      pickupDate,
      pickupTime,
      sectionName
    } = this.props;

    const pickupDateTime =
      pickupDate && pickupTime
        ? moment.utc(
            moment(pickupDate)
              .tz(restaurant.settings.time_zone)
              .startOf("day")
              .add(moment(pickupTime).hours(), "hours")
              .add(moment(pickupTime).minutes(), "minutes")
          )
        : moment();

    const isSoldOut =
      item.sold_out_until &&
      moment(item.sold_out_until)
        .tz(restaurant.settings.time_zone)
        .isAfter(pickupDateTime.tz(restaurant.settings.time_zone));

    let isMenuAvailableAtSelectedPickupTime = true;

    if (
      menuDurationName !== "all" &&
      menuDurationTimeStart &&
      menuDurationTimeEnd
    ) {
      const menuAvailableFrom = moment(pickupDate)
        .tz(restaurant.settings.time_zone)
        .startOf("day")
        .add(moment(menuDurationTimeStart, restaurant.settings.time_format).hours(), "hours")
        .add(moment(menuDurationTimeStart, restaurant.settings.time_format).minutes(), "minutes");
      const menuAvailableUntil = moment(pickupDate)
        .tz(restaurant.settings.time_zone)
        .startOf("day")
        .add(moment(menuDurationTimeEnd, restaurant.settings.time_format).hours(), "hours")
        .add(moment(menuDurationTimeEnd, restaurant.settings.time_format).minutes(), "minutes");

        isMenuAvailableAtSelectedPickupTime = pickupDateTime.isBetween(menuAvailableFrom, menuAvailableUntil, undefined, "[]");
    }
    return (
      <div className="row clickable pt-3 pb-3 border-bottom">
        <div className="col-12 text-center col-sm-3 text-sm-left">
          <div className="menu-item-picture">
            {item.photos &&
              item.photos.map((itemPhoto, index) => (
                <img
                  className="img-fluid mb-2"
                  key={index}
                  src={
                    process.env.REACT_APP_S3_BASE_URL +
                    tenant.slug +
                    "/" +
                    itemPhoto.url
                  }
                  alt="Menu Item"
                />
              ))}
            {/* {isSoldOut && (
            <img src={sold_out_img} alt="Sold out" className="menu-item-sold-out-image img-fluid"/>
          )} */}
          </div>
        </div>
        <div className="col-12 col-sm-9 text-center text-sm-left">
          <div>
            <strong>
              {item.cd} {item.cd ? "." : ""} {item.name}
            </strong>
          </div>
          <div className="text-muted small">{item.description}</div>
          {item.items && (
            <ul className="list-unstyled">
              {item.items.map((individual_item) => (
                <li key={individual_item._id}>
                  <span className="text-muted mr-2">
                    {individual_item.name}
                  </span>
                  {individual_item.spicy === "mild" && (
                    <img src={spicy_mild_img} alt="" />
                  )}
                  {individual_item.spicy === "medium" && (
                    <img src={spicy_medium_img} alt="" />
                  )}
                  {individual_item.spicy === "hot" && (
                    <>
                      <img src={spicy_medium_img} alt="" />
                      <img src={spicy_medium_img} alt="" />
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}

          <MenuItemTags tags={item.tags} />
          <ul className="list-unstyled">
            {item.price && (
              <li>
                <strong>{Numeral(item.price).format("$0,0.00")}</strong>
              </li>
            )}
            {item.sizes.map((size) => (
              <li key={size._id}>
                <strong>
                  {size.name} {Numeral(size.price).format("$0,0.00")}
                </strong>
              </li>
            ))}
          </ul>

          {isSoldOut && (
            <div className="alert alert-warning">
              <img
                src={sold_out_48_32_img}
                alt={"Sold out"}
                className="img-fluid mr-3"
              />
              <strong>{`Will be available again after ${moment(
                item.sold_out_until
              )
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}`}</strong>
            </div>
          )}
          {showOnlineOrdering &&
            onlineOrdering &&
            fromOnlineOrder &&
            !isSoldOut && 
            isMenuAvailableAtSelectedPickupTime && (
              <AddToCartModal
                menuItem={item}
                menuDurationName={menuDurationName}
                menuDurationTimeStart={menuDurationTimeStart}
                menuDurationTimeEnd={menuDurationTimeEnd}
                sectionName={sectionName}
              />
            )}
        </div>
      </div>
    );
  }
}

export default MenuItem;
