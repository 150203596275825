import React, { Component } from "react";
import { connect } from "react-redux";
import CouponListView from "./CouponListView";

import {
  fetchCustomerCoupons,
} from "../../actions/user";
import { Redirect } from "react-router";

class Coupons extends Component {
  constructor(props) {
    super(props);

    this.handleViewProfile = this.handleViewProfile.bind(this);

    this.state = {
      redirectTo: undefined
    }
  }

  componentDidMount() {
    this.props.fetchCustomerCoupons();
  }

  handleViewProfile() {
    this.setState({
      redirectTo: "/profile"
    })
  }

  render() {
    const {coupons = [], restaurant} = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleViewProfile}
              >
                Profile
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Coupons
              </button>
            </li>
          </ol>
        </nav>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>COUPONS</strong>
            </h3>
          </div>
        </div>
        <CouponListView
          coupons={coupons}
          restaurant={restaurant}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    coupons: state.user.coupons,
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCustomerCoupons: () => dispatch(fetchCustomerCoupons()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupons);
