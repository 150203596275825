import React, { Component } from "react";
import Numeral from "numeral";

class GiftCardListView extends Component {


  componentDidMount() {

  }


  render() {
    const {giftCards = []} = this.props;

    const redeemedGiftCards = giftCards.filter(giftCard => giftCard.balance <= 0);
    const validGiftCards = giftCards.filter(giftCard => giftCard.balance > 0);

    return (
      <div className="row">
        <div className="col-12 col-lg-6 mb-3">
        <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                {"Valid Gift Cards"}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="col-lg-4 d-none d-lg-inline text-left">
              <strong>Number</strong>
            </div>
            <div className="col-lg-4 d-none d-lg-inline text-right">
              <strong>Pending Balance</strong>
            </div>
            <div className="col-lg-4 d-none d-lg-inline text-right">
              <strong>Balance</strong>
            </div>
          </div>
          {validGiftCards.map((giftCard, index) => (
            <React.Fragment key={index}>
              <div
                className="row pt-3 pb-3 border-bottom"
              >
                <div className="col-12 col-lg-4 text-left ">
                  <span className="d-inline d-lg-none">
                    <strong>Number: </strong>
                  </span>
                  <span className="text-uppercase">{giftCard.giftCardNumber}</span>
                </div>
                <div className="col-12 col-lg-4 text-left  text-lg-right">
                  <span className="d-inline d-lg-none">
                    <strong>Pending Balance: </strong>
                  </span>
                  {Numeral(giftCard.pendingBalance).format(
                    "$0,0.00"
                  )}
                </div>
                <div className="col-12 col-lg-4 text-left text-lg-right">
                  <span className="d-inline d-lg-none">
                    <strong>Balance: </strong>{" "}
                  </span>{" "}
                  {Numeral(giftCard.balance).format(
                    "$0,0.00"
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
        </div>
        <div className="col-12 col-lg-6 mb-3">
        <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                {"Redeemed Gift Cards"}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="col-lg-4 d-none d-lg-inline text-left">
              <strong>Number</strong>
            </div>
            <div className="col-lg-4 d-none d-lg-inline text-right">
              <strong>Pending Balance</strong>
            </div>
            <div className="col-lg-4 d-none d-lg-inline text-right">
              <strong>Balance</strong>
            </div>
          </div>
          {redeemedGiftCards.map((giftCard, index) => (
            <React.Fragment key={index}>
              <div
                className="row pt-3 pb-3 border-bottom"
              >
                <div className="col-12 col-lg-4 text-left ">
                  <span className="d-inline d-lg-none">
                    <strong>Number: </strong>
                  </span>
                  <span className="text-uppercase">{giftCard.giftCardNumber}</span>
                </div>
                <div className="col-12 col-lg-4 text-left  text-lg-right">
                  <span className="d-inline d-lg-none">
                    <strong>Pending Balance: </strong>
                  </span>
                  {Numeral(giftCard.pendingBalance).format(
                    "$0,0.00"
                  )}
                </div>
                <div className="col-12 col-lg-4 text-left text-lg-right">
                  <span className="d-inline d-lg-none">
                    <strong>Balance: </strong>{" "}
                  </span>{" "}
                  {Numeral(giftCard.balance).format(
                    "$0,0.00"
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
        </div>
      </div>
  
    );
  }
}

export default GiftCardListView;
