import React, { Component } from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import Numeral from "numeral";
import { sendGAEvent } from "../../utils/analytics";
import { Redirect } from "react-router-dom";

import { fetchOrders } from "../../actions/order";

import { fetchOnlineOrderMenus } from "../../actions/tenant";
import {
  addToCart,
  applyPromotions,
  getSubtotal,
  getTax,
  getTotal,
  getQuantity,
} from "../../actions/cart";
import history from "../history";

const cookies = new Cookies();
const user = cookies.get("customer");

class Reorder extends Component {
  constructor(props) {
    super(props);

    this.handleCreateAccountButtonClick =
      this.handleCreateAccountButtonClick.bind(this);
    this.handleLoginButtonClick = this.handleLoginButtonClick.bind(this);

    this.handleReorder = this.handleReorder.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    if (user) {
      this.props.fetchOrders();
    }
    if (this.props.restaurant) {
      const order_type = this.props.orderType ? this.props.orderType : "ToGo";
      this.props.fetchOnlineOrderMenus(this.props.restaurant._id, order_type);
    }
  }

  handleCreateAccountButtonClick() {
    this.setState({
      redirectTo: "/register",
    });
  }

  handleLoginButtonClick() {
    this.setState({
      redirectTo: "/login",
    });
  }

  handleReorder(items) {
    sendGAEvent({
      category: "Order",
      action: "Reorder",
      label: ``,
    });
    const menuItemDurationMap = {};
    this.props.menus.forEach((menu) => {
      menu.sections.forEach((section) => {
        section.items.forEach((item) => {
          menuItemDurationMap[item._id.toString()] = menu.duration;
        });
      });
    });

    items.forEach((item) => {
      const matchMenuDuration = menuItemDurationMap[item.menuItem.toString()];

      const menuItemOptions = [];
      item.options.forEach((option) => {
        menuItemOptions.push({
          option_name: option.name,
          option_value: option.value,
          option_quantity: option.quantity,
          option_price: option.price,
          option_total: option.total,
        });
      });

      this.props.addToCart(
        item.menuItem,
        item.cd,
        item.name,
        item.printName,
        item.size,
        menuItemOptions,
        item.quantity,
        item.basePrice,
        item.baseTotal,
        item.extraPrice,
        item.extraTotal,
        item.specialInstructions,
        {
          name: matchMenuDuration.name,
          timeStart: matchMenuDuration.time_start,
          timeEnd: matchMenuDuration.time_end,
        },
        () => {}
      );
    });

    if (this.props.tenant.settings.coupon === true) {
      this.props.applyPromotions(
        this.props.promotions,
        this.props.restaurant.settings.time_zone,
        this.props.restaurant.settings.time_format,
        this.props.deliveryMethod
      );
    }
  }

  render() {
    const { authenticated, orders = [] } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (!authenticated && !user) {
      return (
        <>
          <h3 className="text-center">{`Quickly reorder items from your previous orders!`}</h3>
          <p className="text-center">
            <small>{`Kindly login or create an account to start saving your orders for quick reorder.`}</small>
          </p>
          <div className="row mb-3">
            <div className="col-6">
              <button
                className="btn btn-primary btn-block"
                onClick={() =>
                  history.push({
                    pathname: "/register",
                    state: { from: "/orderonline" },
                  })
                }
              >{`Create Account`}</button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary btn-block"
                onClick={() =>
                  history.push({
                    pathname: "/login",
                    state: { from: "/orderonline" },
                  })
                }
              >{`Login`}</button>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        {orders.length > 0 && (
          <div className="alert alert-info">
            {" "}
            {`Please choose one of the following orders to reorder`}
          </div>
        )}
        {orders.length === 0 && (
          <div className="alert alert-info">
            {" "}
            {`No order has been placed yet`}
          </div>
        )}
        {orders.map((order) => (
          <div className="card" key={order._id}>
            <div className="card-body">
              <div className="row pb-3 border-bottom">
                <div className="col-8 pb-1">Order #: {order.orderNumber}</div>
                <div className="col-4 pb-1 text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.handleReorder(order.items)}
                  >{`Reorder`}</button>
                </div>
              </div>
              {order.items &&
                order.items.map((item) => (
                  <div key={item._id} className="row pt-3 pb-3 border-bottom">
                    <div className="col-12">
                      <strong>{`${item.quantity}X ${item.name}`}</strong>

                      {item.size && (
                        <div className="row">
                          <div className="col-12">
                            <span className="text-muted ml-3">
                              <small>Size: {item.size}</small>
                            </span>
                          </div>
                        </div>
                      )}
                      {item.options.map((option, index) => (
                        <div className="row" key={index}>
                          <div className="col-12">
                            <span className="text-muted ml-3">
                              <small>
                                {`${option.name}:`}
                                {option.value && (
                                  <span>{` ${option.value}`}</span>
                                )}
                                {option.total && (
                                  <span>{` (${Numeral(option.total).format(
                                    "$0,0.00"
                                  )})`}</span>
                                )}
                              </small>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant._id
      )
    : [];
  const validMenus = state.tenant.onlineOrderMenus || [];

  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    authenticated: state.auth.authenticated,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.order.error,
    message: state.order.message,
    orders: state.order.orders,
    orderType: state.order.orderType,
    holidayHours: holidayHours,
    menus: validMenus,
    promotionRules: state.tenant.promotions || [],
    promotionItems: state.tenant.promotionItems || [],
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    menu: state.order.menu,
    items: state.cart.items,
    promotions: state.cart.promotions,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    deliveryAddress: state.order.deliveryAddress,
    deliveryMethod: state.order.deliveryMethod,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrders: () => dispatch(fetchOrders()),
  addToCart: (
    id,
    cd,
    name,
    printName,
    size,
    options,
    discounts,
    discountTotal,
    quantity,
    basePrice,
    baseTotal,
    extraPrice,
    extraTotal,
    specialInstructions,
    callback
  ) =>
    dispatch(
      addToCart(
        id,
        cd,
        name,
        printName,
        size,
        options,
        discounts,
        discountTotal,
        quantity,
        basePrice,
        baseTotal,
        extraPrice,
        extraTotal,
        specialInstructions,
        callback
      )
    ),
  applyPromotions: (promotions, timezone, timeFormat, deliveryMethod) =>
    dispatch(applyPromotions(promotions, timezone, timeFormat, deliveryMethod)),
  fetchOnlineOrderMenus: (restaurantId, orderType) =>
    dispatch(fetchOnlineOrderMenus(restaurantId, orderType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reorder);
