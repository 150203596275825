import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./Home/Home";
import NotFound from "./NotFound";
import Contact from "./Contact/Contact";
import Menus from "./Menu/Menus";

// Import authentication related pages
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import RequireAuth from "./Auth/RequireAuth";

import Profile from "./Customer/Profile";
import ChangePasword from "./Customer/ChangePassword";
import UpdateProfile from "./Customer/UpdateProfile";

import PaymentMethodList from "./Customer/PaymentMethodList";
import Orders from "./Customer/Orders";
import Coupons from "./Customer/Coupons";
import UserGiftCards from "./Customer/UserGiftCards";

import Cart from "./Cart/Cart";
import OrderAhead from "./OrderAhead/OrderAhead";
import Checkout from "./Checkout/Checkout";
import MakeReservation from "./Reservation/MakeReservation";
import Locations from "./Locations/Locations";
import GiftCards from "./GiftCards/GiftCards";
import Gallery from "./Gallery/Gallery";
import Promotions from "./Promotions/Promotions";
// import Announcements from "./Announcement/Announcements";

import ChooseOrderType from "./OrderOnline/ChooseOrderType";
import ChoosePickupTime from "./OrderOnline/ChoosePickupTime";
import BuildOrder from "./OrderOnline/BuildOrder";

import "./Main.css";
import RegisterFromReferral from "./Referral/RegisterFromReferral";
import Order from "./Order/Order";

import DineInMenu from "./Menu/DineInMenu";
import ChooseDeliveryAddress from "./OrderOnline/ChooseDeliveryAddress";

import CreateGiftCard from "./GiftCards/CreateGiftCard";

import GiftCardCheckout from "./GiftCards/Checkout";

import LocationBar from "./Header/LocationBar";
import AnnoucementBar from "./Header/AnnoucementBar";
// import ErrorBoundary from "../ErrorBoundary";

class ConditionalRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;
    return (
      <Route
        {...props}
        render={(props) =>
          this.props.condition ? <Component {...props} /> : <NotFound />
        }
      />
    );
  }
}

export class Main extends Component {
  render() {
    const { tenant, promotions = [], announcements = [] } = this.props;
    const showOnlineOrdering = tenant.settings.online_ordering === true;
    const showOnlineReservation = tenant.settings.online_reservation === true;
    const showMembership = tenant.settings.membership === true;

    return (
      <main
        className="main-content container"
        style={{
          paddingTop:
            showOnlineOrdering || showOnlineReservation || showMembership
              ? "66px"
              : "66px",
        }}
      >
        {/* <ErrorBoundary> */}
        <AnnoucementBar announcements={announcements} />
        <LocationBar />
        <Switch>
          <Redirect exact from="/" to="/home" />

          <Route exact path="/home" component={Home} />
          <Route exact path="/menus" component={Menus} />
          <Route exact path="/dinein" component={DineInMenu} />

          <Route exact path="/gallery" component={Gallery} />
          <Route
            exact
            path="/promotions"
            condition={tenant.settings.coupon === true && promotions.length > 0}
            component={Promotions}
          />
          {/* <Route
            exact
            path="/announcements"
            condition={announcements.length > 0}
            component={Announcements}
          /> */}
          <Route exact path="/contact" component={Contact} />

          <ConditionalRoute
            exact
            path="/register"
            condition={tenant.settings.membership === true}
            component={Register}
          />
          <ConditionalRoute
            exact
            path="/login"
            condition={tenant.settings.membership === true}
            component={Login}
          />
          <ConditionalRoute
            exact
            path="/logout"
            condition={tenant.settings.membership === true}
            component={Logout}
          />
          <ConditionalRoute
            exact
            path="/forgot-password"
            condition={tenant.settings.membership === true}
            component={ForgotPassword}
          />
          <ConditionalRoute
            exact
            path="/reset-password"
            condition={tenant.settings.membership === true}
            component={ResetPassword}
          />
          <ConditionalRoute
            exact
            path="/profile"
            condition={tenant.settings.membership === true}
            component={RequireAuth(Profile)}
          />
          <ConditionalRoute
            exact
            path="/profile/change-password"
            condition={tenant.settings.membership === true}
            component={RequireAuth(ChangePasword)}
          />
          <ConditionalRoute
            exact
            path="/profile/update-profile"
            condition={tenant.settings.membership === true}
            component={RequireAuth(UpdateProfile)}
          />
          <ConditionalRoute
            exact
            path="/profile/payment-methods"
            condition={tenant.settings.membership === true}
            component={RequireAuth(PaymentMethodList)}
          />

          <ConditionalRoute
            exact
            path="/profile/orders"
            condition={tenant.settings.membership === true}
            component={RequireAuth(Orders)}
          />

          <ConditionalRoute
            exact
            path="/profile/coupons"
            condition={tenant.settings.membership === true}
            component={RequireAuth(Coupons)}
          />
          <ConditionalRoute
            exact
            path="/profile/giftcards"
            condition={tenant.settings.membership === true}
            component={RequireAuth(UserGiftCards)}
          />

          <ConditionalRoute
            exact
            path="/cart"
            condition={tenant.settings.online_ordering === true}
            component={Cart}
          />
          <ConditionalRoute
            exact
            path="/orderonline"
            condition={tenant.settings.online_ordering === true}
            component={ChooseOrderType}
          />
          <ConditionalRoute
            exact
            path="/deliveryaddress"
            condition={tenant.settings.online_ordering === true}
            component={ChooseDeliveryAddress}
          />
          <ConditionalRoute
            exact
            path="/pickuptime"
            condition={tenant.settings.online_ordering === true}
            component={ChoosePickupTime}
          />
          <ConditionalRoute
            exact
            path="/buildorder"
            condition={tenant.settings.online_ordering === true}
            component={BuildOrder}
          />
          <ConditionalRoute
            exact
            path="/orderahead"
            condition={
              tenant.settings.online_ordering === true &&
              tenant.settings.online_reservation === true
            }
            component={OrderAhead}
          />
          <ConditionalRoute
            exact
            path="/checkout"
            condition={tenant.settings.online_ordering === true}
            component={Checkout}
          />
          <ConditionalRoute
            exact
            path="/order"
            condition={tenant.settings.online_ordering === true}
            component={Order}
          />

          <ConditionalRoute
            exact
            path="/reservation"
            condition={tenant.settings.online_reservation === true}
            component={MakeReservation}
          />

          <ConditionalRoute
            exact
            path="/locations"
            condition={tenant.restaurants.length > 1}
            component={Locations}
          />

          <ConditionalRoute
            path="/referral/:token"
            condition={tenant.settings.loyalty_reward_program === true}
            component={RegisterFromReferral}
          />

          <ConditionalRoute
            path="/gift-checkout"
            condition={tenant.settings.gift_card === true}
            component={GiftCardCheckout}
          />

          <ConditionalRoute
            path="/gift/:id"
            condition={tenant.settings.gift_card === true}
            component={CreateGiftCard}
          />

          <ConditionalRoute
            path="/gift"
            condition={tenant.settings.gift_card === true}
            component={GiftCards}
          />

          <Route path="/*" component={NotFound} />
        </Switch>
        {/* </ErrorBoundary> */}
      </main>
    );
  }
}

export default Main;
