import axios from "axios";
import Cookies from "universal-cookie";

import {
  FETCH_USER,
  UPDATE_USER_PROFILE,
  USER_ERROR,
  ADD_PAYMENT_METHOD,
  FETCH_PAYMENT_METHODS,
  UPDATE_PAYMNET_METHOD,
  DELETE_PAYMENT_METHOD,
  INVITE_BY_EMAIL,
  FETCH_BY_REFERRAL_TOKEN,
  AUTH_ERROR,
  AUTH_USER,
  FETCH_CUSTOMER_COUPONS,
  FETCH_CUSTOMER_GIFTCARDS,
} from "./types";

import { errorHandler, patchData, postData, getData, deleteData } from "./index";

const cookies = new Cookies();

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export function fetchUser(uid) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${uid}`; 
  return dispatch => getData(FETCH_USER, USER_ERROR, true, url, dispatch);  
}

export function updateUserProfile(userId, formProps, callback) {
  console.log("updateUserProfile formProps", formProps)
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${userId}`; 
  return dispatch => patchData(UPDATE_USER_PROFILE, USER_ERROR, true, url, dispatch, formProps, callback)  
}

export function changePassword(userId, { currentPassword, newPassword }, successCallback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${userId}/changepassword`;
  // return dispatch =>
  //   patchData(CHANGE_PASSWORD, USER_ERROR, true, url, dispatch, {
  //     currentPassword,
  //     newPassword
  //   });

    return function (dispatch) {
      axios.patch(url,  {
        currentPassword,
        newPassword
      })
        .then(response => {
          cookies.set('ctoken', response.data.token, { path: '/', maxAge: COOKIE_MAX_AGE });
          cookies.set('customer', response.data.user, { path: '/', maxAge: COOKIE_MAX_AGE });
          successCallback()
          dispatch({ type: AUTH_USER});
        })
        .catch((error) => { 
          let errorMessage = "Email address and/or password is not correct. Please try again";
          dispatch({
            type: AUTH_ERROR,
            payload: errorMessage
          });
        });
    }
}

export function fetchPaymentMethods() {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/paymentmethods`;
  return dispatch =>
    getData(FETCH_PAYMENT_METHODS, USER_ERROR, true, url, dispatch);
}

export function addPaymentMethod(stripeToken, email, phone, successCallback) {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/paymentmethods`;
  const data = {
    customer: customerId,
    stripeTokenId: stripeToken._id,
    stripeToken: stripeToken,
    email: email,
    phone: phone
  }
  return dispatch =>
    postData(ADD_PAYMENT_METHOD, USER_ERROR, true, url, dispatch, data, successCallback);
}


export function updatePaymentMethod(paymentMethodId, stripeToken, email, phone, successCallback) {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/paymentmethods/${paymentMethodId}`;
  const data = {
    stripeTokenId: stripeToken._id,
    stripeToken: stripeToken,
    email: email,
    phone: phone
  }
  return dispatch =>
    patchData(UPDATE_PAYMNET_METHOD, USER_ERROR, true, url, dispatch, data, successCallback);
}

export function deletePaymentMethod(paymentMethodId) {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/paymentmethods/${paymentMethodId}`;
  return dispatch =>
    deleteData(DELETE_PAYMENT_METHOD, USER_ERROR, true, url, dispatch);
}

export function inviteByEmail(formProps, refferalLink, callback) {
  
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/referral/email`;

  const data = {
    inviteeEmail: formProps.inviteeEmail,
    refferalLink: refferalLink
  }

  return dispatch =>
    postData(INVITE_BY_EMAIL, USER_ERROR, true, url, dispatch, data, callback);
}

export function fetchUserByReferralToken(referralToken) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/referraltoken/${referralToken}`; 
  return dispatch => getData(FETCH_BY_REFERRAL_TOKEN, USER_ERROR, false, url, dispatch);  
}

export function registerFromReferral({ email, firstName, lastName, password, telephone }, referrerId, successCallback) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/auth/register`, { email, firstName, lastName, password, telephone, referrerId })
      .then(response => {
        cookies.set('ctoken', response.data.token, { path: '/' });
        cookies.set('customer', response.data.user, { path: '/' });
        successCallback()
        dispatch({ type: AUTH_USER });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}

export function fetchCustomerCoupons() {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/coupons`;
  return dispatch =>
    getData(FETCH_CUSTOMER_COUPONS, USER_ERROR, true, url, dispatch);
}

export function fetchCustomerGiftCards() {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/giftcards`;
  return dispatch =>
    getData(FETCH_CUSTOMER_GIFTCARDS, USER_ERROR, true, url, dispatch);
}