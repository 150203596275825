import React from "react";
import * as Icon from "react-feather";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

momentLocalizer(moment);

export const phoneNumber = value =>
  value && !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const email = value =>
value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
'Invalid email address' : undefined;

export const required = value => value ? undefined : 'Required';

export const renderField = ({
  className,
  input,
  type,
  placeholder,
  readonly,
  meta: { touched, error },
}) => (
  <div>
    <input
      className={className}
      {...input}
      readOnly={readonly}
      type={type}
      placeholder={placeholder}
    />
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderField2 = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div className="form-group">
    <label className="col-form-label">{label}</label>
    <div>
      <input {...input} type={type} placeholder={label} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderCheckboxField = ({
  input,
  type,
  meta: { touched, error },
}) => (
  <div>
    <input className="form-check-input" {...input} type={type} />
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderEmailField = ({
  input,
  placeholder,
  disabled,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">@</span>
      </div>
      <input
        className="form-control"
        {...input}
        type="email"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderUserNameField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.User size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="text"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPasswordField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Lock size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="password"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPhoneField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Phone size={16} />
        </span>
      </div>
       <input
        className="form-control"
        {...input}
        type="text"
        placeholder={placeholder}
      /> 

    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPhoneField2 = ({
  value,
  setValue,
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Phone size={16} />
        </span>
      </div>
      <PhoneInput
        defaultCountry="CA"
        value={value}
        onChange={setValue}
      />
    </div>
  </div>
);

export const renderPriceField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.DollarSign size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="number"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderDatePicker = ({ input: { onChange, value } }) => (
  <DateTimePicker
    onChange={onChange}
    format="DD MMM YYYY"
    time={false}
    value={!value ? null : new Date(value)}
  />
);

export const renderTimePicker = ({ input: { onChange, value } }) => (
  <DateTimePicker
    onChange={onChange}
    date={false}
    value={!value ? null : new Date(value)}
  />
);

export const renderTextAreaField = ({
  className,
  input,
  type,
  placeholder,
  readonly,
  meta: { touched, error },
}) => (
  <div>
    <textarea
      className={className}
      {...input}
      readOnly={readonly}
      placeholder={placeholder}
    />
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderRecaptchaField = ({
  input,
  siteKey,
  meta: { touched, error },
}) => (
  <div>
    <div className="d-flex justify-content-center">
      <ReCAPTCHA sitekey={siteKey} size="normal" onChange={input.onChange} />
    </div>

    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

