import React from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';
import moment from 'moment-timezone';

const Timeslot = ({ time, isAvailable, onClick, pickupTime, restaurant }) => {
  return (
    <>
      {isAvailable === true && (
        <li
          className={ pickupTime && moment(time).isSame(pickupTime, 'minute') ? 'time-selected' : ''}
          role="radio"
          tabIndex="-1"
          aria-checked="false"
          onClick={() => onClick(time)}
        >
          {time.tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)}
        </li>
      )}
      {isAvailable === false && (
        <li
          className={"disable-slot"}
          role="radio"
          tabIndex="-1"
          aria-checked="false"
        >
          {time.tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)}
        </li>
      )}
    </>
  );
};

Timeslot.propTypes = {
  time: PropTypes.instanceOf(Moment).isRequired,
  isAvailable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Timeslot;
