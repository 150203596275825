import React, { Component } from "react";
import { connect } from "react-redux";

import CartItems from "./CartItems";
import CartPromotions from "./CartPromotions";
import Subtotal from "./Subtotal";
import Tax from "./Tax";
import Total from "./Total";
import DeliveryFee from "./DeliveryFee";

import * as Icon from "react-feather";

import { Redirect } from "react-router-dom";

import {
  removeFromCart,
  removePromotionFromCart,
  decreaseQuantity,
  increaseQuantity,
  getSubtotal,
  getTax,
  getGST,
  getPST,
  getTotal,
  getQuantity,
  clearCart,
  applyPromotions,
  getDeliveryGST,
  getDeliveryFee,
} from "../../actions/cart";
import CartDiscounts from "./CartDiscounts";
import DeliveryWarning from "./DeliveryWarning";

import { sendGAEvent } from "../../utils/analytics";
import CartNextOrderCoupons from "./CartNextOrderCoupons";

import CancelOrderLink from "../OrderOnline/CancelOrderLink";

import { cancelOrder } from "../../actions/order";

import { CANCEL_ORDER } from "../../actions/types";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleRemovePromotion = this.handleRemovePromotion.bind(this);
    this.handleIncreaseQuantity = this.handleIncreaseQuantity.bind(this);
    this.handleDecreaseQuantity = this.handleDecreaseQuantity.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  handlePrevious() {
    sendGAEvent({
      category: "Cart",
      action: "Continue Shopping",
      label: ``,
    });
    this.setState({ redirectTo: "/buildorder" });
  }

  handleNext() {
    sendGAEvent({
      category: "Checkout",
      action: "Proceed to checkout",
      label: ``,
    });
    this.setState({ redirectTo: "/checkout" });
  }

  handleRemoveItem(item) {
    sendGAEvent({
      category: "Cart",
      action: "Remove menu item from cart",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
    };
    this.props.removeFromCart(item, promoData);
  }

  handleRemovePromotion(promotion) {
    sendGAEvent({
      category: "Cart",
      action: "Remove promotion from cart",
      label: `${promotion.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
    };
    this.props.removePromotionFromCart(promotion, promoData);
  }

  handleDecreaseQuantity(item) {
    sendGAEvent({
      category: "Cart",
      action: "Decrease quantiy",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
    };
    this.props.decreaseQuantity(item, promoData);
  }

  handleIncreaseQuantity(item) {
    sendGAEvent({
      category: "Cart",
      action: "Increase quantity",
      label: `${item.cd}-${item.name}`,
    });
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
    };
    this.props.increaseQuantity(item, promoData);
  }

  handleCancelOrder() {
    sendGAEvent({
      category: "Checkout",
      action: "Cancel Order",
      label: ``,
    });
    this.props.clearCart();
    this.props.cancelOrder((dispatch) => {
      dispatch({ type: CANCEL_ORDER, payload: {} });
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const {
      items,
      promotions,
      subtotal,
      tax,
      gst,
      pst,
      total,
      showPreviousButton = true,
      discounts,
      restaurant,
      deliveryFee,
      deliveryGST,
      deliveryMethod,
      minimumOrderAmount,
      deliveryAddress,
      deliveryDistance,
      tenant,
    } = this.props;

    const changeable = true;

    // if (!restaurant) {
    //   return <ChooseRestaurant />;
    // }
    return (
      <>
        {this.props.messages && (
          <ul className="mt-3">
            {this.props.messages.map((message, index) => (
              <li key={index} className="alert alert-info">
                {message}
              </li>
            ))}
          </ul>
        )}

        {deliveryMethod === "Delivery" && !deliveryAddress && (
          <div className="alert alert-danger">
            {`Missing delivery address. Plesae click Continue Shopping button to enter delivery address.`}
          </div>
        )}

        <div className="card mt-3">
          <div className="card-header">
            <div className="row">
              <div className="col-8 col-cart-angkor">
                <strong>{`Item`}</strong>
              </div>
              <div className="col-3 col-cart-angkor text-right">
                <strong>{`Price`}</strong>
              </div>
              <div className="col-1 col-cart-angkor">{` `}</div>
            </div>
          </div>
          <div className="card-body">
            <CartItems
              items={items}
              handleRemoveItem={this.handleRemoveItem}
              changeable={changeable}
            />
            <CartPromotions
              promotions={promotions}
              handleRemovePromotion={this.handleRemovePromotion}
              changeable={changeable}
            />
            {(!items || items.length === 0) &&
              (!promotions || promotions.length === 0) && (
                <div className="row border-bottom pb-3">
                  <div className="col-12">
                    Your cart is empty.
                    {/* <button
                      className="btn btn-link"
                      onClick={this.handlePrevious}
                    >
                      {`Order Now`}
                    </button> */}
                  </div>
                </div>
              )}

            <Subtotal subtotal={subtotal} />

            <Tax
              tax={tax}
              taxRate={restaurant ? restaurant.settings.tax_rate : 0}
              gst={gst}
              gstRate={restaurant ? restaurant.settings.gst_rate : 0}
              pst={pst}
              pstRate={restaurant ? restaurant.settings.pst_rate : 0}
            />

            {deliveryMethod === "Delivery" && (
              <DeliveryFee
                deliveryFee={deliveryFee}
                deliveryGST={deliveryGST}
                gstRate={restaurant ? restaurant.settings.gst_rate : 0}
                deliveryDistance={deliveryDistance}
              />
            )}
            <CartDiscounts discounts={discounts} />
            <Total total={total} />

            <CartNextOrderCoupons
              tenantName={tenant.name}
              discounts={discounts}
            />

            {deliveryMethod === "Delivery" && (
              <DeliveryWarning
                subtotal={subtotal}
                minimumOrderAmount={
                  restaurant.settings.delivery_options.minimum_order_amount
                }
              />
            )}
          </div>
          {changeable && showPreviousButton && (
            <div className="card-footer">
              <div className="row">
                {showPreviousButton && (
                  <div className="col-4 text-left">
                    <button
                      className="btn btn-secondary btn-block"
                      onClick={this.handlePrevious}
                    >
                      <Icon.ChevronLeft size={16} />{" "}
                      <span className="d-none d-sm-inline">
                        CONTINUE SHOPPING
                      </span>
                    </button>
                  </div>
                )}

                <div className="col-4 text-center">
                  <CancelOrderLink onCancelOrder={this.handleCancelOrder} />
                </div>
                <div className="col-4 text-right">
                  <button
                    className="btn btn-angkor btn-block"
                    disabled={
                      deliveryMethod === "Delivery"
                        ? subtotal < minimumOrderAmount || !deliveryAddress
                          ? true
                          : false
                        : subtotal > 0
                        ? false
                        : true
                    }
                    onClick={this.handleNext}
                  >
                    <span className="d-none d-sm-inline">
                      PROCEED TO CHECKOUT
                    </span>
                    <Icon.ChevronRight size={16} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    items: state.cart.items,
    promotions: state.cart.promotions,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    gst: getGST(state, gstRate),
    pst: getPST(state, pstRate),
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    removable: true,
    promotionRules: state.tenant.promotions || [],
    messages: state.cart.messages,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    restaurant: state.tenant.restaurant,
    orderType: state.order.orderType,
    deliveryMethod: state.order.deliveryMethod,
    deliveryFee: getDeliveryFee(state),
    deliveryGST: getDeliveryGST(state, gstRate),
    deliveryAddress: state.order.deliveryAddress,
    deliveryDistance: state.order.deliveryAddress?.distanceInMeter,
    postalCode: state.order.deliveryAddress?.postalCode,
    minimumOrderAmount:
      state.tenant.restaurant.settings.delivery_options.minimum_order_amount,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (item, promoData) =>
    dispatch(removeFromCart(item, promoData)),
  removePromotionFromCart: (promo, promoData) =>
    dispatch(removePromotionFromCart(promo, promoData)),
  increaseQuantity: (item, promoData) =>
    dispatch(increaseQuantity(item, promoData)),
  decreaseQuantity: (item, promoData) =>
    dispatch(decreaseQuantity(item, promoData)),
  cancelOrder: (callback) => dispatch(cancelOrder(callback)),
  clearCart: () => dispatch(clearCart()),
  applyPromotions: (promotions, timezone, timeFormat, deliveryMethod) =>
    dispatch(applyPromotions(promotions, timezone, timeFormat, deliveryMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
