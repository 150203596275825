import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";

import { inviteByEmail } from "../../actions/user";
import {
  renderEmailField,

} from "../../utils/renderFields";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const user = cookies.get("customer");

const form = reduxForm({
  form: "inviteByEmail",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.inviteeEmail) {
    errors.inviteeEmail = "Please enter email address";
  }

  return errors;
}

export class InviteByEmail extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      message: 'Click the following link to join the AngkorDelight and get $5 off of your first order.',
      referralLink: undefined
    }

  }

  componentDidMount() {
    if (user) {
      const chunks = window.location.href.split("/");
      const referralLink =  chunks[0] +  "//" + chunks[2] + "/referral/" + user.referralToken;

      this.setState({
        referralLink: referralLink
      })
    }
  }

  handleFormSubmit(formProps) {
    this.props.inviteByEmail(formProps, this.state.referralLink, () => {
      $(".close").click();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <>
      <button className="btn btn-circle-email btn-circle btn-xl"
        disabled={!user}
        data-toggle="modal"
        data-target="#inviteByEmailModal"
        data-backdrop="static">
        <i className="fas fa-envelope" />
      </button>
      <p>Invite with<br />Email</p>
      <div
        className="modal fade"
        id="inviteByEmailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="inviteByEmailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="inviteByEmailModalLabel">
                  {`Invite with Email`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                {this.renderAlert()}
                <div>
                  <label>Message</label>
                  <div>
                    <p>{this.state.message}</p>
                    <p>{this.state.referralLink}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Enter the email address of the person you wish to invite:</label>
                  <Field
                    name="inviteeEmail"
                    className="form-control"
                    component={renderEmailField}
                    type="email"
                  />
                </div>

              </div>

              <div className="modal-footer d-block text-center">
                {/* <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                  </button> */}
                <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                  <i className="fas fa-share-square" /> {` `}{`Send Invitation`}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.user.error,
    message: state.user.message
  };
};

const mapDispatchToProps = dispatch => ({
  inviteByEmail: (formProps, referralLink, callback) => dispatch(inviteByEmail(formProps, referralLink, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(InviteByEmail));
