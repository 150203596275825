import React, { Component } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";

import { sendContactForm } from "../../actions/index";
import { clearMessage } from "../../actions/messaging";

import Map from "../Contact/Map";
import ContactForm from "../Contact/ContactForm";

const form = reduxForm({
  form: "register",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.email) {
    errors.email = "Please enter email address";
  }

  if (!formProps.telephone) {
    errors.telphone = "Please enter phone number";
  }

  if (!formProps.subject) {
    errors.telphone = "Please enter subject";
  }

  if (!formProps.message) {
    errors.telphone = "Please enter message";
  }

  return errors;
}

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleGotIt = this.handleGotIt.bind(this);

    this.state = {
      redirectTo: undefined,
      zoom: 12
    };
  }

  handleFormSubmit(formProps) {
    this.props.sendContactForm(formProps, this.props.tenant.email);
  }

  handleGotIt() {
    this.setState({ redirectTo: "/" });

    this.props.clearMessage();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurants = [] } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.props.message) {
      return (
        <div className="card border-success text-center mx-auto">
          <div className="card-body">
            <div className="alert alert-success">
              <span>{this.props.message}</span>
            </div>
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={this.handleGotIt}
            >
              GOT IT
            </button>
          </div>
        </div>
      );
    }
    return (
      <>
        <section>
          <h2 className="text-center">Our Location</h2>
          <hr />
          <div className="row">
            {restaurants.map(restaurant => (
              <div className="col-12 col-md-6" key={restaurant._id}>
                <div className="text-muted mb-3">
                  <i className="fas fa-home mr-3" />
                  {restaurant.name}
                </div>
                <div className="text-muted mb-3">
                  <i className="fas fa-map-marker mr-3" />
                  {`${restaurant.address_1}, ${restaurant.city_town}, ${
                    restaurant.state_province
                  } ${restaurant.postal_code}`}
                </div>
                <div className="text-muted mb-3">
                  <i className="fas fa-phone-alt mr-3" />
                  {restaurant.phone}
                </div>
              </div>
            ))}
          </div>
        </section>

        <section>
          <Map restaurants={restaurants} />
        </section>
        <section>
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <ContactForm />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.communication.error,
    message: state.communication.message,
    restaurants: state.tenant.restaurants || []
  };
};

const mapDispatchToProps = dispatch => ({
  sendContactForm: (formProps, tenantEmail) =>
    dispatch(sendContactForm(formProps, tenantEmail)),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(Contact));
