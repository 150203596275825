import moment from "moment-timezone";

function determineRushHourLeadTime(
  deliveryMethod,
  currentDateTime,
  timeFormat,
  dayOfWeekRushHourSettings
) {
  if (!dayOfWeekRushHourSettings.disabled) {
    const rushHourStart = moment(
      dayOfWeekRushHourSettings.rush_hour_start,
      timeFormat
    );
    const rushHourEnd = moment(
      dayOfWeekRushHourSettings.rush_hour_end,
      timeFormat
    );
    const startAt = moment(currentDateTime)
      .startOf("day")
      .add(rushHourStart.hours(), "hours")
      .add(rushHourStart.minutes(), "minutes");
    const endAt = moment(currentDateTime)
      .startOf("day")
      .add(rushHourEnd.hours(), "hours")
      .add(rushHourEnd.minutes(), "minutes");

    if (currentDateTime.isAfter(startAt) && currentDateTime.isBefore(endAt)) {
      return deliveryMethod === "Delivery"
        ? dayOfWeekRushHourSettings.delivery_order_lead_time
        : dayOfWeekRushHourSettings.online_order_lead_time;
    }
    return undefined;
  } else {
    return undefined;
  }
}

export function calculateOrderLeadTime(deliveryMethod, restaurantSettings) {
  const currentDateTime = moment().tz(restaurantSettings.time_zone);
  const dayOfWeek = currentDateTime.day();

  let leadTime =
    deliveryMethod === "Delivery"
      ? restaurantSettings.delivery_order_lead_time
      : restaurantSettings.online_order_lead_time;
  let rushHourLeadTime = undefined;
  switch (dayOfWeek) {
    case 0:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.sunday
      );
      break;
    case 1:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.monday
      );
      break;
    case 2:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.tuesday
      );
      break;
    case 3:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.wednesday
      );
      break;
    case 4:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.thursday
      );
      break;
    case 5:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.friday
      );
      break;
    case 6:
      rushHourLeadTime = determineRushHourLeadTime(
        deliveryMethod,
        currentDateTime,
        restaurantSettings.time_format,
        restaurantSettings.rush_hour_settings.saturday
      );
      break;
    default:
      rushHourLeadTime = undefined;
      break;
  }

  return rushHourLeadTime ? rushHourLeadTime : leadTime;
}
