import React from 'react';

import CartPromotion from './CartPromotion';

const CartPromotions = ({ promotions = [], handleRemovePromotion, changeable }) => {
  return (
    <>
    {promotions.map((promotion, index) => (
      <CartPromotion
        key={index}
        promotion={promotion}
        handleRemovePromotion={handleRemovePromotion}
        changeable={changeable}
      />
    ))}
    </>
  );
};

export default CartPromotions;
