import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_PROMOTION_FROM_CART,
  CLEAR_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  VALIDATE_AND_APPLY_PROMOTIONS,
  APPLY_COUPON,
  CART_ERROR,
  REMOVE_COUPON_FROM_CART,
  PREAUTH_GIFT_CARD,
  REMOVE_GIFT_CARD_FROM_CART,
  FETCH_GIFT_CARD_BY_GIFT_CARD_NUMBER,
} from "../actions/types";

// import Numeral from "numeral";
// import moment from "moment-timezone";

import { validateAndApplyPromotions } from "../utils/promotionEvaluator";
import { isSame } from "../utils/equalUtil";

const initialState = {
  items: [],
  promotions: [],
  pickupInfo: {},
  coupons: [],
  discounts: [],
  redeemedGiftCards: [],
  giftCardMessage: undefined,
  couponMessage: undefined,
  giftCardRedemptions: [],
};
function increaseQuantity(items, data) {
  return items.map((item, index) => {
    if (!isSame(item, data)) {
      return item;
    }
    let options = [];
    let extraTotal = 0;
    item.options.forEach((op) => {
      extraTotal +=
        (op.option_quantity + 1) * (op.option_price ? op.option_price : 0);
      options.push({
        option_name: op.option_name,
        option_value: op.option_value,
        option_id: op.option_id,
        option_quantity: op.option_quantity + 1,
        option_price: op.option_price,
        option_total:
          op.option_total && op.option_price
            ? op.option_total + op.option_price
            : undefined,
      });
    });

    return {
      ...item,
      quantity: item.quantity + 1,
      baseTotal: item.baseTotal + item.basePrice,
      extraTotal: extraTotal,
      options: options,
    };
  });
}

function addToCart(items, data) {
  const sameItemHasBeenOrdered =
    items.filter((item) => isSame(item, data)).length > 0;
  if (sameItemHasBeenOrdered) {
    return items.map((item, index) => {
      if (!isSame(item, data)) {
        return item;
      }
      return {
        ...item,
        quantity: item.quantity + data.quantity,
        baseTotal: item.baseTotal + data.baseTotal,
        extraTotal: item.extraTotal + data.extraTotal,
      };
    });
  } else {
    return [...items, data];
  }
}

function decreaseQuantity(items, data) {
  return items.map((item, index) => {
    if (!isSame(item, data)) {
      return item;
    }
    let options = [];
    let extraTotal = 0;
    item.options.forEach((op) => {
      extraTotal +=
        (op.option_quantity - 1) * (op.option_price ? op.option_price : 0);
      options.push({
        option_name: op.option_name,
        option_value: op.option_value,
        option_id: op.option_id,
        option_quantity: op.option_quantity - 1,
        option_price: op.option_price,
        option_total:
          op.option_total && op.option_price
            ? op.option_total - op.option_price
            : undefined,
      });
    });

    return {
      ...item,
      quantity: item.quantity - 1,
      baseTotal: item.baseTotal - item.basePrice,
      extraTotal: extraTotal,
      options: options,
    };
  });
}

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TO_CART:
      const _items = addToCart(state.items, action.payload.data);
      if (action.payload.promoData.isCouponEnabled) {
        const result = validateAndApplyPromotions(
          _items,
          state.promotions,
          action.payload.promoData.promotionRules,
          action.payload.promoData.timezone,
          action.payload.promoData.timeFormat,
          action.payload.promoData.deliveryMethod,
          action.payload.promoData.pickupTime,
          action.payload.promoData.pickupDate,
          action.payload.promoData.couponAmount,
          action.payload.promoData.deliveryDistance,
          action.payload.promoData.postalCode,
          action.payload.promoData.deliveryFee,
          action.payload.promoData.deliveryGST,
        );
        return {
          ...state,
          items: result.items,
          promotions: result.promotions,
          discounts: result.cartDiscounts.discounts,
          discountTotal: result.cartDiscounts.discountTotal,
          messages: result.messages,
        };
      } else {
        return {
          ...state,
          items: _items,
        };
      }
    case VALIDATE_AND_APPLY_PROMOTIONS:
      const result1 = validateAndApplyPromotions(
        state.items,
        state.promotions,
        action.payload.promotionRules,
        action.payload.timezone,
        action.payload.timeFormat,
        action.payload.deliveryMethod,
        action.payload.pickupTime,
        action.payload.pickupDate,
        action.payload.couponAmount,
        action.payload.promoData.deliveryDistance,
        action.payload.promoData.postalCode,
        action.payload.promoData.deliveryFee,
        action.payload.promoData.deliveryGST,
      );
      return {
        ...state,
        items: result1.items,
        promotions: result1.promotions,
        discounts: result1.cartDiscounts.discounts,
        discountTotal: result1.cartDiscounts.discountTotal,
        messages: result1.messages,
      };

    case REMOVE_FROM_CART:
      const _items1 = state.items.filter(
        (item) => item.uuid !== action.payload.uuid
      );

      if (action.payload.promoData.isCouponEnabled) {
        const result = validateAndApplyPromotions(
          _items1,
          state.promotions,
          action.payload.promoData.promotionRules,
          action.payload.promoData.timezone,
          action.payload.promoData.timeFormat,
          action.payload.promoData.deliveryMethod,
          action.payload.promoData.pickupTime,
          action.payload.promoData.pickupDate,
          action.payload.promoData.couponAmount,
          action.payload.promoData.deliveryDistance,
          action.payload.promoData.postalCode,
          action.payload.promoData.deliveryFee,
          action.payload.promoData.deliveryGST,
        );

        return {
          ...state,
          items: result.items,
          promotions: result.promotions,
          discounts: result.cartDiscounts.discounts,
          discountTotal: result.cartDiscounts.discountTotal,
          messages: result.messages,
        };
      } else {
        return {
          ...state,
          items: _items1,
        };
      }
    case REMOVE_PROMOTION_FROM_CART:
      const _promotions = state.promotions.filter(
        (promotion) => promotion.uuid !== action.payload.uuid
      );
      if (action.payload.promoData.isCouponEnabled) {
        const result = validateAndApplyPromotions(
          state.items,
          _promotions,
          action.payload.promoData.promotionRules,
          action.payload.promoData.timezone,
          action.payload.promoData.timeFormat,
          action.payload.promoData.deliveryMethod,
          action.payload.promoData.pickupTime,
          action.payload.promoData.pickupDate,
          action.payload.promoData.couponAmount,
          action.payload.promoData.deliveryDistance,
          action.payload.promoData.postalCode,
          action.payload.promoData.deliveryFee,
          action.payload.promoData.deliveryGST,
        );
        return {
          ...state,
          items: result.items,
          promotions: result.promotions,
          discounts: result.cartDiscounts.discounts,
          discountTotal: result.cartDiscounts.discountTotal,
          messages: result.messages,
        };
      } else {
        return {
          ...state,
          promotions: _promotions,
        };
      }

    case INCREASE_QUANTITY:
      const _items2 = increaseQuantity(state.items, action.payload.item);
      if (action.payload.promoData.isCouponEnabled) {
        const result = validateAndApplyPromotions(
          _items2,
          state.promotions,
          action.payload.promoData.promotionRules,
          action.payload.promoData.timezone,
          action.payload.promoData.timeFormat,
          action.payload.promoData.deliveryMethod,
          action.payload.promoData.pickupTime,
          action.payload.promoData.pickupDate,
          action.payload.promoData.couponAmount,
          action.payload.promoData.deliveryDistance,
          action.payload.promoData.postalCode,
          action.payload.promoData.deliveryFee,
          action.payload.promoData.deliveryGST,
        );
        return {
          ...state,
          items: result.items,
          promotions: result.promotions,
          discounts: result.cartDiscounts.discounts,
          discountTotal: result.cartDiscounts.discountTotal,
          messages: result.messages,
        };
      } else {
        return {
          ...state,
          items: _items2,
        };
      }
    case DECREASE_QUANTITY:
      const _items3 = decreaseQuantity(state.items, action.payload.item);
      if (action.payload.promoData.isCouponEnabled) {
        const result = validateAndApplyPromotions(
          _items3,
          state.promotions,
          action.payload.promoData.promotionRules,
          action.payload.promoData.timezone,
          action.payload.promoData.timeFormat,
          action.payload.promoData.deliveryMethod,
          action.payload.promoData.pickupTime,
          action.payload.promoData.pickupDate,
          action.payload.promoData.couponAmount,
          action.payload.promoData.deliveryDistance,
          action.payload.promoData.postalCode,
          action.payload.promoData.deliveryFee,
          action.payload.promoData.deliveryGST,
        );
        return {
          ...state,
          items: result.items,
          promotions: result.promotions,
          discounts: result.cartDiscounts.discounts,
          discountTotal: result.cartDiscounts.discountTotal,
          messages: result.messages,
        };
      } else {
        return {
          ...state,
          items: _items3,
        };
      }
    case CLEAR_CART:
      return {
        ...state,
        email: undefined,
        items: [],
        promotions: [],
        discounts: [],
        coupons: [],
        messages: [],
        redeemedGiftCards: [],
        giftCardMessage: undefined,
        couponMessage: undefined,
        giftCardRedemptions: []
      };
    case APPLY_COUPON:
      const applyCouponResult = validateAndApplyPromotions(
        state.items,
        state.promotions,
        action.payload.promotionRules,
        action.payload.timezone,
        action.payload.timeFormat,
        action.payload.deliveryMethod,
        action.payload.pickupTime,
        action.payload.pickupDate,
        action.payload.couponAmount,
        action.payload.deliveryDistance,
        action.payload.postalCode,
        action.payload.deliveryFee,
        action.payload.deliveryGST,
      );

      const couponAppliedAlready =
        state.coupons.filter(
          (coupon) =>
            coupon.couponCode.toLowerCase() ===
            action.payload.coupon.couponCode.toLowerCase()
        ).length > 0;
      if (couponAppliedAlready) {
        return {
          ...state,
          items: applyCouponResult.items,
          promotions: applyCouponResult.promotions,
          discounts: applyCouponResult.cartDiscounts.discounts,
          discountTotal: applyCouponResult.cartDiscounts.discountTotal,
          messages: applyCouponResult.messages,
          message: undefined,
          errorMessage: undefined,
        };
      } else {
        return {
          ...state,
          items: applyCouponResult.items,
          promotions: applyCouponResult.promotions,
          discounts: applyCouponResult.cartDiscounts.discounts,
          discountTotal: applyCouponResult.cartDiscounts.discountTotal,
          coupon: action.payload.coupon,
          coupons: [...state.coupons, action.payload.coupon],
          messages: applyCouponResult.messages,
          message: undefined,
          email: action.payload.email,
          errorMessage: undefined,
        };
      }

    case REMOVE_COUPON_FROM_CART:
      return {
        ...state,
        coupons: state.coupons.filter(
          (coupon) => coupon.couponCode !== action.payload.couponCode
        ),
      };
    case PREAUTH_GIFT_CARD:
      if (state.giftCardRedemptions.map(redemption => redemption.giftCardNumber).includes(action.payload.giftCard.giftCardNumber)) {
        return {
          ...state,
          giftCardMessage: `Gift card #${action.payload.giftCard.giftCardNumber} has already been redeemed.`
        }
      } else {
        return {
          ...state,
          redeemedGiftCards: [
            ...state.redeemedGiftCards,
            {
              giftCardNumber: action.payload.giftCard.giftCardNumber,
              balanceBeforeRedeem: action.payload.balanceBeforeRedeem,
              amountToRedeem: action.payload.amountToRedeem,
              balanceAfterRedeem: action.payload.balanceAfterRedeem,
            },
          ],          
          giftCardRedemptions: [
            ...state.giftCardRedemptions, action.payload.giftCardRedemption
          ],
          giftCard: undefined,
          giftCardMessage: undefined
        };
      }


    case REMOVE_GIFT_CARD_FROM_CART:
      return {
        ...state,
        redeemedGiftCards: state.redeemedGiftCards.filter(
          (redeemedGiftCard) =>
            redeemedGiftCard.giftCardNumber !==
            action.payload.giftCardNumber
        ),
        giftCardRedemptions: state.giftCardRedemptions.filter(giftCardRedemption => giftCardRedemption._id !== action.payload.giftCardRedemptionId)
      };
    case FETCH_GIFT_CARD_BY_GIFT_CARD_NUMBER:
      return {
        ...state,
        giftCard: action.payload.giftCard,
      };
    case CART_ERROR:
      return { ...state, errorMessage: action.payload.message || action.payload.couponMessage };
    default:
      return state;
  }
}
