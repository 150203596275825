import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import "./Logout.css";

class Logout extends Component {
  componentWillMount() {
    this.props.logout();
  }

  render() {
    return (
      <div className="container h-100 login">
        <div className="alert alert-info">
          You are safely loged out. See you next time!
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { logout }
)(Logout);
