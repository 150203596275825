import React, { Component } from "react";
import { connect } from "react-redux";
import * as Icon from "react-feather";
import { changeDeliveryAddress } from "../../actions/order";
import { withRouter } from "react-router";
import axios from "axios";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { isPointWithinRadius } from "geolib";

class ChooseDeliveryAddress extends Component {
  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
    this.handleSuiteApartmentNumberChange =
      this.handleSuiteApartmentNumberChange.bind(this);
    this.handleDeliveryAddressTypeChange =
      this.handleDeliveryAddressTypeChange.bind(this);

    this.handleBusinessNameChange = this.handleBusinessNameChange.bind(this);
    this.handleOtherPublicVenueNameChange =
      this.handleOtherPublicVenueNameChange.bind(this);

    this.handleClear = this.handleClear.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isInRange = this.isInRange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getDeliveryDistance = this.getDeliveryDistance.bind(this);

    this.state = {
      address: props.deliveryAddress ? props.deliveryAddress.address : "",
      suite_apartment_number: props.deliveryAddress
        ? props.deliveryAddress.suiteApartmentNumber
        : "",
      delivery_address_type: props.deliveryAddress
        ? props.deliveryAddress.deliveryAddressType
        : "",
      business_name: props.deliveryAddress
        ? props.deliveryAddress.businessName
        : "",
      other_public_venue_name: props.deliveryAddress
        ? props.deliveryAddress.otherPublicVenueName
        : "",
      postal_code: "",
      lat_lng: undefined,
      delivery_distance: undefined,
    };
  }

  handleNext() {
    this.props.changeDeliveryAddress(
      this.state.address,
      this.state.postal_code,
      this.state.suite_apartment_number,
      this.state.delivery_address_type,
      this.state.delivery_address_type === "Business"
        ? this.state.business_name
        : undefined,
      this.state.delivery_address_type === "Other"
        ? this.state.other_public_venue_name
        : undefined,
      this.state.delivery_distance,
      () => this.props.history.push("/pickuptime")
    );
  }

  async getDeliveryDistance(destination_place_id) {
    const url = `${process.env.REACT_APP_API_BASE}/api/eater/map/direction?origin=${this.props.restaurant.latitude},${this.props.restaurant.longitude}&destination=place_id:${destination_place_id}`;
    try {
      const response = await axios.get(url);
      return response.data.direction.routes[0].legs[0].distance.value;
    } catch (error) {
      console.log(error);
    }
  }

  async handleBlur() {
    try {
      const results = await geocodeByAddress(this.state.address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;

      const deliveryDistance = await this.getDeliveryDistance(
        results[0].place_id
      );
      this.setState({
        postal_code: postalCode,
        lat_lng: latLng,
        address: this.state.address,
        delivery_distance: deliveryDistance,
        fetching: "complete"
      });
    } catch (error) {
      console.log(error);
      this.setState({
        fetching: "failure"
      });
    }
  }

  handleSuiteApartmentNumberChange(event) {
    this.setState({ suite_apartment_number: event.target.value });
  }

  handleDeliveryAddressTypeChange(event) {
    this.setState({ delivery_address_type: event.target.value });
  }

  handleBusinessNameChange(event) {
    this.setState({ business_name: event.target.value });
  }

  handleOtherPublicVenueNameChange(event) {
    this.setState({ other_public_venue_name: event.target.value });
  }

  handleChange = (address) => {
    this.setState({ address, fetching: "inprogress" });
  };

  handleClear() {
    this.setState({
      address: "",
      suite_apartment_number: "",
      delivery_address_type: "",
      business_name: "",
      other_public_venue_name: "",
    });
  }

  async handleSelect(address) {
    // this.setState({ address });
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;

      const deliveryDistance = await this.getDeliveryDistance(
        results[0].place_id
      );

      this.setState({
        postal_code: postalCode,
        lat_lng: latLng,
        address: address,
        delivery_distance: deliveryDistance,
      });
    } catch (error) {
      console.log(error);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  isInRange() {
    const postalCodes =
      this.props.restaurant.settings.delivery_options.delivery_area_postal_codes
        ?.split(",")
        .map((postalCode) => postalCode.trim(" "));

    const isInRangeByPostalCode =
      postalCodes
        .map((postalCode) => postalCode.substring(0, 3))
        .filter(
          (postalCode) =>
            postalCode.toLowerCase() ===
            this.state.postal_code.toLowerCase().substring(0, 3)
        ).length > 0;

    const restaurantLatLong = {
      latitude: this.props.restaurant.latitude,
      longitude: this.props.restaurant.longitude,
    };

    const isWithinRadius = this.state.lat_lng
      ? isPointWithinRadius(
          restaurantLatLong,
          this.state.lat_lng,
          this.props.restaurant.settings.delivery_options.delivery_area_radius *
            1000
        )
      : false;

    return isInRangeByPostalCode && isWithinRadius;
  }

  render() {
    const isInRange = this.isInRange();

    const searchOptions = {
      location: new window.google.maps.LatLng(
        this.props.restaurant.latitude,
        this.props.restaurant.longitude
      ),
      radius:
        this.props.restaurant.settings.delivery_options.delivery_area_radius *
        1000,
    };

    return (
      <>
        <div className="row">
          <div className="col">
            <h4>Provide Your Delivery Address</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}> */}
            {this.renderAlert()}
            <div className="form-group">
              <label>Enter Street Address*</label>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                shouldFetchSuggestions={this.state.address.length > 3}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "form-control",
                        onBlur: this.handleBlur,
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };

                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            {this.state.fetching === "complete" &&
              (!this.state.postal_code || !this.state.delivery_distance) && (
                <div className="alert alert-danger">
                  Oops.Something went wrong, either postal code or deivery
                  distance could not be determined. Please re-enter your
                  address.
                </div>
              )}
            {this.state.fetching === "complete" && !isInRange && (
              <div className="alert alert-danger">
                Sorry the address you entered is not in our delivery range.
                Change to pickup order
              </div>
            )}
            {this.state.address && isInRange && (
              <div className="alert alert-info">
                Postal Code: {this.state.postal_code}, Distance:{" "}
                {this.state.delivery_distance / 1000} km
              </div>
            )}
            <div className="form-group">
              <label>Suite / Apartment #</label>
              <input
                type="text"
                className="form-control"
                name="suite_apartment_number"
                value={this.state.suite_apartment_number}
                onChange={this.handleSuiteApartmentNumberChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label>Select Address Type*</label>
                <select
                  name="delivery_address_type"
                  className="form-control"
                  value={this.state.delivery_address_type}
                  onChange={this.handleDeliveryAddressTypeChange}
                >
                  <option value="">{`Select Address Type`}</option>
                  <option value={"Business"}>{`Business`}</option>
                  <option value={"Home"}>
                    {`Private Residence (House, Apt)`}
                  </option>
                  <option value={"Other"}>{`Other / Public Venue`}</option>
                </select>
              </div>
              <div className="form-group col-lg-6">
                <label>
                  {this.state.delivery_address_type === "Business"
                    ? "Business"
                    : this.state.delivery_address_type === "Other"
                    ? "Other/Public Venue"
                    : ""}
                </label>
                {this.state.delivery_address_type === "Business" && (
                  <input
                    type="text"
                    className="form-control"
                    name="business_name"
                    placeholder="Enter Company Name"
                    value={this.state.business_name}
                    onChange={this.handleBusinessNameChange}
                  />
                )}
                {this.state.delivery_address_type === "Other" && (
                  <input
                    type="text"
                    className="form-control"
                    name="other_public_venue_name"
                    placeholder="Name of the school, church, etc"
                    value={this.state.other_public_venue_name}
                    onChange={this.handleOtherPublicVenueNameChange}
                  />
                )}
              </div>
            </div>

            <div className="row pt-3 pb-3 mt-3 border-top">
              <div className="col-4 text-left">
                <button
                  className="btn btn-secondary btn-block"
                  onClick={() => this.props.history.push("/orderonline")}
                >
                  <Icon.ChevronLeft size={24} />{" "}
                  <span className="d-none d-sm-inline">BACK</span>
                </button>
              </div>
              <div className="col-4 text-left">
                <button
                  className="btn btn-warning btn-block"
                  onClick={this.handleClear}
                >
                  <span className="d-none d-sm-inline">CLEAR</span>
                  <Icon.XCircle size={24} />
                </button>
              </div>
              <div className="col-4 text-right">
                <button
                  className="btn btn-angkor btn-block"
                  disabled={
                    !this.state.address ||
                    !this.state.delivery_address_type ||
                    !this.state.postal_code ||
                    !this.state.delivery_distance
                  }
                  onClick={this.handleNext}
                >
                  <span className="d-none d-sm-inline">CONTINUE</span>
                  <Icon.ChevronRight size={24} />
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    deliveryAddress: state.order.deliveryAddress,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeDeliveryAddress: (
    delivery_address,
    postal_code,
    suite_apartment_number,
    delivery_address_type,
    business_name,
    other_public_venue_name,
    delivery_distance,
    callback
  ) =>
    dispatch(
      changeDeliveryAddress(
        delivery_address,
        postal_code,
        suite_apartment_number,
        delivery_address_type,
        business_name,
        other_public_venue_name,
        delivery_distance,
        callback
      )
    ),
});

ChooseDeliveryAddress = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDeliveryAddress);

export default withRouter(ChooseDeliveryAddress);
