import React, { Component } from "react";
import { connect } from "react-redux";

import LazyLoad from "react-lazyload";

const Loading = (className) => (
  <div className={className}>
    <h5>
      <i className="fa fa-lg fa-spinner fa-spin mr-3" /> Loading...
    </h5>
  </div>
);

class Gallery extends Component {
  componentDidMount() {}

  render() {
    const { tenant } = this.props;

    if (!tenant) {
      return <></>;
    }
    const gallery = tenant.gallery;
    return (
      <section>
        <h2 className="text-center">Gallery</h2>
        <hr />
        <div className="row">
          {gallery.map((gallery_item) => (
            <LazyLoad
              key={gallery_item._id}
              placeholder={
                <Loading className="col-12 col-sm-6 col-md-4 mb-3" />
              }
            >
              <div
                className="col-12 col-sm-6 col-md-4 mb-3"
                key={gallery_item._id}
              >
                <div className="card text-center">
                  <picture>
                    <source
                      type="image/webp"
                      srcset={
                        process.env.REACT_APP_S3_BASE_URL +
                        tenant.slug +
                        "/" +
                        gallery_item.image.url.replace(".jpg", "") +
                        ".webp"
                      }
                    />
                    <source
                      type="image/jpeg"
                      srcset={
                        process.env.REACT_APP_S3_BASE_URL +
                        tenant.slug +
                        "/" +
                        gallery_item.image.url.replace(".jpg", "") +
                        ".jpg"
                      }
                    />
                    <img
                      className="img-fluid mb-2"
                      src={
                        process.env.REACT_APP_S3_BASE_URL +
                        tenant.slug +
                        "/" +
                        gallery_item.image.url.replace(".jpg", "") +
                        ".jpg"
                      }
                      alt="Menu Item"
                    />
                  </picture>
                  <div className="card-body">
                    <p
                      className="card-title text-truncate"
                      data-toggle="tooltip"
                      title={gallery_item.caption}
                    >
                      {gallery_item.caption}
                    </p>
                  </div>
                </div>
              </div>
            </LazyLoad>
          ))}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
