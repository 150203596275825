import React from "react";

import moment from "moment-timezone";
import Numeral from "numeral";


const OrderListView = ({ orders, handleViewDetail }) => {
  return (
    <React.Fragment>
      <div className="row pb-3 border-bottom">
        <div className="col-lg-2 d-none d-lg-inline text-left"><strong>Order Number</strong></div>
        <div className="col-lg-2 d-none d-lg-inline text-right"><strong>Amount</strong></div>
        <div className="col-lg-2 d-none d-lg-inline text-left"><strong>Status</strong></div>
        <div className="col-lg-3 d-none d-lg-inline text-left"><strong>Request Pickup Time</strong>
        </div>
        <div className="col-lg-3 d-none d-lg-inline text-left">
          <strong>Restaurant</strong>
        </div>
      </div>
      {orders.map(order => (
        <React.Fragment key={order._id}>
          <div
            className="row clickable-row pt-3 pb-3 border-bottom"
            onClick={() => handleViewDetail(order)}
          >
            <div className="col-12 col-lg-2 text-left text-lg-left">
              <span className="d-inline d-lg-none"><strong>Order Number: </strong> </span> {order.orderNumber}
            </div>
            <div className="col-12 col-lg-2 text-left text-lg-right">
              <span className="d-inline d-lg-none"><strong>Total: </strong></span>{Numeral(order.total).format("$0,0.00")}
            </div>
            <div className="col-12 col-lg-2 text-left text-lg-left">
              <span className="d-inline d-lg-none"><strong>Status: </strong></span>{order.status}
            </div>
            <div className="col-12 col-lg-3 text-left text-lg-left">
              <span className="d-inline d-lg-none"><strong>Requested Pickup Time: </strong></span>{moment(order.adjustedPickupTime ? order.adjustedPickupTime : order.pickupTime).tz(order.restaurant.settings.time_zone).format(order.restaurant.settings.datetime_format)}
            </div>
            <div className="col-12 col-lg-3 text-left text-lg-left"><span className="d-inline d-lg-none"><strong>Restaurant: </strong></span>{order.restaurant.name}</div>
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default OrderListView;
