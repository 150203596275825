import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";

import { login } from "../../actions/auth";
import {
  renderEmailField,
  renderPasswordField,
  email,
  required,
} from "../../utils/renderFields";
import "./Login.css";

import { sendGAEvent } from "../../utils/analytics";

const form = reduxForm({
  form: "login",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.email) {
    errors.email = "Please enter email";
  }
  if (!formProps.password) {
    errors.password = "Please enter password";
  }

  return errors;
}

export class Login extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      redirectToReferrer: false,
    };
  }

  handleFormSubmit(formProps) {
    sendGAEvent({
      category: "Auth",
      action: "Login Form Submit",
      label: `${formProps.email.split("@")[0]}`,
    });
    this.props.login(
      {
        tenantId: this.props.tenant._id,
        email: formProps.email,
        password: formProps.password,
      },
      () => {
        this.setState({ redirectToReferrer: true });
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <>
        {/* <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>LOGIN</strong>
            </h3>
          </div>
        </div> */}
        <div className="row h-100 align-items-center">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header text-center">Login</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      name="email"
                      className="form-control"
                      component={renderEmailField}
                      validate={[required, email]}
                      type="email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Field
                      name="password"
                      className="form-control"
                      component={renderPasswordField}
                      validate={[required]}
                      type="password"
                    />
                  </div>
                  <div className="controls text-center">
                    {/* <button type="button" className="btn btn-default button-remember-me">
                <span className="glyphicon glyphicon-unchecked button-rememember-me-glyph">
                  Remember Me
                  </span>
              </button> */}
                    <button
                      type="submit"
                      className="btn btn-angkor btn-block"
                      disabled={pristine || submitting}
                    >
                      LOGIN
                    </button>
                  </div>
                  <div className="clearfix" />
                  <hr />
                  <div>
                    <div id="forgot-password">
                      <Link to="/forgot-password"> Forgot Your Password?</Link>
                    </div>
                    <div className="pt-3">
                      <span>
                        Not a member yet?{" "}
                        <Link to="/register">Create an Account</Link>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: ({ tenantId, email, password }, callback) =>
    dispatch(login({ tenantId, email, password }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Login));
