import ReactGA from "react-ga4";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export function sendGAEvent({ category, action, label }) {
  ReactGA.set({
    'dimension1': cookies.get("guid"),
  });

  ReactGA.event({
    category: category,
    action: action,
    transport: "beacon",
    label: label,
  });
}
