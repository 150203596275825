import React from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

const OrderInfoCard = ({ order }) => {
  if (!order) {
    return <></>;
  }

  const pickupTimeRange = `${moment(order.pickupTime).tz(order.restaurant.settings.time_zone).format(order.restaurant.settings.time_format)} - ${ moment(order.pickupTime).add(order.restaurant.settings.pickup_time_interval_in_minutes, 'minutes').tz(order.restaurant.settings.time_zone).format(order.restaurant.settings.time_format)}`;
  const adjustedPickupTimeRange = order.adjustedPickupTime ? `${moment(order.adjustedPickupTime).tz(order.restaurant.settings.time_zone).format(order.restaurant.settings.time_format)} - ${ moment(order.adjustedPickupTime).add(order.restaurant.settings.pickup_time_interval_in_minutes, 'minutes').tz(order.restaurant.settings.time_zone).format(order.restaurant.settings.time_format)}` : "";

  return (
    <div className="card">
      <div className="card-body">
        <div className="row pb-3 border-bottom">
          <div className="col-12 col-sm-6 col-md-2 pb-1">
            Order #: {order.orderNumber}
          </div>
          <div className="col-12 col-sm-6 col-md-3 pb-1">
            Status: {order.status}
          </div>
          <div className="col-12 col-sm-6 col-md-3 pb-1">
            <span>Order Type: {order.orderType} </span>
          </div>
          <div className="col-12 col-sm-6 col-md-4 pb-1">
            <span>Delivery Method: {order.deliveryMethod} </span>
          </div>
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-12 col-sm-6 pb-1">
            {`Requested ${order.deliveryMethod} Time: `}
            {pickupTimeRange}
          </div>
          {order.adjustedPickupTime && (
            <div className="col-12 col-sm-6 pb-1">
              {`Adjusted ${order.deliveryMethod} Time: `}
              {adjustedPickupTimeRange}
            </div>
          )}
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-12 col-sm-6 pb-1">
            <span>
              Received At:{" "}
              {moment(order.receivedAt)
                .tz(order.restaurant.settings.time_zone)
                .format(order.restaurant.settings.datetime_format)}
            </span>
          </div>
          <div className="col-12 col-sm-6 pb-1">
            {order.status === "Accepted" && (
              <span>
                Accepted At:{" "}
                {moment(order.confirmedAt)
                  .tz(order.restaurant.settings.time_zone)
                  .format(order.restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Cancelled" && (
              <span>
                Cancelled At:{" "}
                {moment(order.confirmedAt)
                  .tz(order.restaurant.settings.time_zone)
                  .format(order.restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Rejected" && (
              <span>
                Rejected At:{" "}
                {moment(order.confirmedAt)
                  .tz(order.restaurant.settings.time_zone)
                  .format(order.restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Fulfilled" && (
              <span>
                Fulfilled At:{" "}
                {moment(order.confirmedAt)
                  .tz(order.restaurant.settings.time_zone)
                  .format(order.restaurant.settings.datetime_format)}
              </span>
            )}
          </div>
        </div>
        {order.deliveryMethod === "Delivery" && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-12 pb-1">
              <div>Delivery Address: {order.deliveryAddress.address}</div>
              {order.deliveryAddress.suiteApartmentNumber &&
                <div>Suite/Apartment Number: {order.deliveryAddress.suiteApartmentNumber}</div>
              }
              {order.deliveryAddress.businessName &&
                <div>Business Name: {order.deliveryAddress.businessName}</div>
              }
              {order.deliveryAddress.otherVenueName &&
                <div>Other: {order.deliveryAddress.otherVenueName}</div>
              }
            </div>
          </div>
        )}
        {order.deliveryMethod === "Pickup" && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-12 col-sm-6 pb-1">
              {`Restaurant: ${order.restaurant.name}`}
            </div>
            <div className="col-12 col-sm-6 pb-1">
              <div>Pickup Address: <span className="mr-3">{order.restaurant.address_1}</span> {order.restaurant.address_2 &&
                <span className="mx-3">{order.restaurant.address_2}</span>}
                <span className="mr-3">{order.restaurant.city_town}, {order.restaurant.state_province}</span>
                <span>{order.restaurant.phone}</span>
              </div>
            </div>
          </div>
        )}
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8">
            <span>
              <strong>Item</strong>
            </span>
          </div>
          <div className="col-4 text-right">
            <span>
              <strong>Price</strong>
            </span>
          </div>
        </div>
        {order.items &&
          order.items.map(item => (
            <div key={item._id} className="row pt-3 pb-3 border-bottom">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <strong>{`${item.quantity}X ${item.name}`}</strong>
                  </div>
                  <div className="col-4 text-right">
                    <strong>
                      {Numeral(item.baseTotal + item.extraTotal).format(
                        "$0,0.00"
                      )}
                    </strong>
                  </div>
                </div>
                {item.size && (
                  <div className="row">
                    <div className="col-12">
                      <span className="text-muted ml-3">
                        <small>Size: {item.size}</small>
                      </span>
                    </div>
                  </div>
                )}
                {item.options.map((option, index) => (
                  <div className="row" key={index}>
                    <div className="col-12">
                      <span className="text-muted ml-3">
                        <small>
                          {`${option.name}:`}
                          {option.value && <span>{` ${option.value}`}</span>}
                          {option.total && (
                            <span>{` (${Numeral(option.total).format(
                              "$0,0.00"
                            )})`}</span>
                          )}
                        </small>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        {order.promotions &&
          order.promotions.map(promotion => (
            <div key={promotion._id} className="row pt-3 pb-3 border-bottom">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <strong>{`${promotion.quantity}X ${promotion.promotionName
                      }`}</strong>
                  </div>
                  <div className="col-4 text-right">
                    <strong>
                      {Numeral(promotion.amount).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <span className="promotion-label">{`You saved: ${Numeral(
                      promotion.discountAmount
                    ).format("$0,0.00")}`}</span>
                  </div>
                </div>
                <hr className="dashed-line" />
                {promotion.discountedItems &&
                  promotion.discountedItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="row">
                        <div className="col-8">
                          <span>{item.name}</span>
                        </div>
                        <div className="col-4 text-right">
                          <div className="text-muted font-italic">
                            {Numeral(item.baseTotal + item.extraTotal).format(
                              "$0,0.00"
                            )}
                          </div>
                          {item.discountAmount > 0 && (
                            <div className="text-muted font-italic">
                              {Numeral(-1 * item.discountAmount).format(
                                "$0,0.00"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {item.size && (
                        <div className="row">
                          <div className="col-12">
                            <span className="text-muted ml-3">
                              <small>Size: {item.size}</small>
                            </span>
                          </div>
                        </div>
                      )}
                      {item.options &&
                        item.options.map((option, index1) => (
                          <div className="row" key={index1}>
                            <div className="col-12">
                              <span className="text-muted ml-3">
                                <small>
                                  {`${option.name}:`}
                                  {option.value && (
                                    <span>{` ${option.value}`}</span>
                                  )}
                                  {option.total && (
                                    <span>{` (${Numeral(option.total).format(
                                      "$0,0.00"
                                    )})`}</span>
                                  )}
                                </small>
                              </span>
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ))}
        {order.discounts &&
          order.discounts.map((discount, index) => (
            <div className="row pt-3 pb-3 border-bottom" key={index}>
              <div className="col-8">
                <strong>{discount.name}</strong>
              </div>
              <div className="col-4 text-right">
                {discount.amount && (
                  <strong>
                    {Numeral(-1 * discount.amount).format("$0,0.00")}
                  </strong>
                )}
              </div>
            </div>
          ))}
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>Subtotal</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.subtotal).format("$0,0.00")}
          </div>
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>GST</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.gst).format("$0,0.00")}
          </div>
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>PST</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.pst).format("$0,0.00")}
          </div>
        </div>
        {order.deliveryMethod === "Delivery" && order.deliveryFee && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-8 text-left">
              <span>Delivery Fee</span>
            </div>
            <div className="col-4 text-right">
              {Numeral(order.deliveryFee).format("$0,0.00")}
            </div>
          </div>
        )}
        {order.deliveryMethod === "Delivery" && order.deliveryGST && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-8 text-left">
              <span>Delivery GST</span>
            </div>
            <div className="col-4 text-right">
              {Numeral(order.deliveryGST).format("$0,0.00")}
            </div>
          </div>
        )}
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>
              <strong>Total</strong>
            </span>
          </div>
          <div className="col-4 text-right">
            <strong>{Numeral(order.total).format("$0,0.00")}</strong>
          </div>
        </div>
      </div>
    </div >
  );
};

export default OrderInfoCard;
