import React, { Component } from "react";
import { connect } from "react-redux";
import Numeral from "numeral";

import {
  removeGiftCardRedemption,
  redeemGiftCard,
  preauthGiftCard,
  fetchGiftCard,
  getTotal,
} from "../../actions/cart";
import { sendGAEvent } from "../../utils/analytics";

class RedeemGiftCards extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleGiftCardNumberChange =
      this.handleGiftCardNumberChange.bind(this);
    this.handleRedeemGiftCard = this.handleRedeemGiftCard.bind(this);
    this.handleRemoveGiftCardRedemption =
      this.handleRemoveGiftCardRedemption.bind(this);
    this.handleFetchGiftCard = this.handleFetchGiftCard.bind(this);
    this.handleAmountToRedeemChange =
      this.handleAmountToRedeemChange.bind(this);
    this.handlePreAuthGiftCard = this.handlePreAuthGiftCard.bind(this);
    this.state = {
      giftCardNumber: "",
    };
  }

  handleGiftCardNumberChange(event) {
    this.setState({
      giftCardNumber: event.target.value,
    });
  }

  handleAmountToRedeemChange(event) {
    this.setState({
      amountToRedeem: Number(event.target.value),
    });
  }

  handleRedeemGiftCard(giftCard, amountToRedeem) {
    sendGAEvent({
      category: "Checkout",
      action: "Redeem Gift Card",
      label: `${giftCard.giftCardNumber}`,
    });

    this.props.redeemGiftCard(giftCard, amountToRedeem, () => {
      this.setState({
        giftCardNumber: "",
        amountToRedeem: undefined,
      });
    });
  }

  handlePreAuthGiftCard(giftCardNumber, amountToRedeem) {
    sendGAEvent({
      category: "Checkout",
      action: "Preauth Gift Card",
      label: `${giftCardNumber}`,
    });

    this.props.preauthGiftCard(
      this.props.tenant._id,
      giftCardNumber,
      amountToRedeem,
      () => {
        this.setState({
          giftCardNumber: "",
          amountToRedeem: undefined,
        });
      }
    );
  }

  handleRemoveGiftCardRedemption(giftCardRedmptionId) {
    this.props.removeGiftCardRedemption(
      this.props.tenant._id,
      giftCardRedmptionId
    );
  }

  handleFetchGiftCard(giftCardNumber) {
    this.props.fetchGiftCard(this.props.tenant._id, giftCardNumber, (data) => {
      this.setState({
        amountToRedeem: Numeral(
          Numeral(
            data.giftCard.pendingBalance < this.props.total
              ? data.giftCard.pendingBalance
              : this.props.total
          ).format("$0,0.00")
        ).value(),
      });
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { giftCard, giftCardRedemptions = [] } = this.props;
    return (
      <>
        {this.renderAlert()}
        <form>
          <div className="row">
            <div className="col-11 text-left col-cart-angkor">
              <label className="mr-3">{`E-GIFTCARD Number`}</label>
              <div>
                <small className="font-italic">{`* Physical gift card is not accepted here. Pleasse present your physical gift card to the cashier!`}</small>
              </div>

              <div className="form-group input-group">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="giftCardNumber"
                  value={this.state.giftCardNumber}
                  placeholder={`Giftcard number`}
                  onChange={this.handleGiftCardNumberChange}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      this.handleFetchGiftCard(this.state.giftCardNumber)
                    }
                    disabled={!this.state.giftCardNumber}
                  >{`Check Balance`}</button>
                </div>
              </div>
            </div>
          </div>
          {giftCard && (
            <>
              <div className="row">
                <div className="col-11 text-left col-cart-angkor">
                  <div className="pb-3">
                    <strong>
                      Balance:{" "}
                      {Numeral(giftCard.pendingBalance).format("$0,0.00")}
                    </strong>
                  </div>
                  <div className="form-group input-group">
                    <input
                      type="number"
                      className="form-control mr-2"
                      id="amountToRedeem"
                      value={this.state.amountToRedeem}
                      onChange={this.handleAmountToRedeemChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          this.handlePreAuthGiftCard(
                            giftCard.giftCardNumber,
                            this.state.amountToRedeem
                          )
                        }
                        disabled={
                          !this.state.amountToRedeem ||
                          this.state.amountToRedeem < 0 ||
                          this.state.amountToRedeem >
                            Numeral(
                              Numeral(giftCard.pendingBalance).format("$0,0.00")
                            ).value()
                        }
                      >{`Redeem`}</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>

        {giftCardRedemptions.length > 0 && (
          <div className="row pb-3">
            <div className="col-8 text-left col-cart-angkor">
              <span>{`Gift Card Number `}</span>
            </div>
            <div className="col-3 text-right col-cart-angkor">
              <span>{`Amount To Redeem`}</span>
            </div>
          </div>
        )}
        {giftCardRedemptions.map((giftCardRedemption, index) => (
          <div className="row pb-3" key={index}>
            <div className="col-8 text-left col-cart-angkor">
              <span className="text-uppercase">{`${giftCardRedemption.giftCardNumber}`}</span>
            </div>
            <div className="col-3 text-right col-cart-angkor">
              <span>
                <strong>
                  {Numeral(giftCardRedemption.amountToRedeem).format("$0,0.00")}
                </strong>
              </span>
            </div>
            <div className="col-1 text-left col-cart-angkor">
              <button
                className="btn btn-danger btn-sm btn-sm-angkor"
                onClick={() =>
                  this.handleRemoveGiftCardRedemption(giftCardRedemption._id)
                }
              >
                {`X`}
              </button>
            </div>
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    redeemedGiftCards: state.cart.redeemedGiftCards,
    errorMessage: state.cart.giftCardMessage,
    promotions: state.tenant.promotions || [],
    deliveryMethod: state.order.deliveryMethod,
    deliveryAddress: state.order.deliveryAddress,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    giftCard: state.cart.giftCard,
    giftCardRedemptions: state.cart.giftCardRedemptions,
    total: getTotal(state, gstRate, pstRate),
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeGiftCardRedemption: (tenantId, giftCardRedemptionId) =>
    dispatch(removeGiftCardRedemption(tenantId, giftCardRedemptionId)),
  redeemGiftCard: (giftCard, amountToRedeem, callback) =>
    dispatch(redeemGiftCard(giftCard, amountToRedeem, callback)),
  preauthGiftCard: (tenantId, giftCardNumber, amountToRedeem, callback) =>
    dispatch(
      preauthGiftCard(tenantId, giftCardNumber, amountToRedeem, callback)
    ),
  fetchGiftCard: (tenantId, giftCardNumber, callback) =>
    dispatch(fetchGiftCard(tenantId, giftCardNumber, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedeemGiftCards);
