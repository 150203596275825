import { getData } from './index';

import {
  FETCH_RESTAURANTS,
  TENANT_ERROR,
  FETCH_BUSINESS_HOURS,
  FETCH_TODAY_BUSINESS_HOURS,
  FETCH_MENUS,
  FETCH_GIFTCARDPRODUCTS,
  FETCH_ONLINE_ORDER_MENUS,
  SELECT_LOCATION,
  FETCH_LOYALTY_REWARDS,
  FETCH_TENANT,
  FETCH_PICKUP_DATES,
  FETCH_ANNOUNCEMENTS,
  FETCH_COUPONS,
  FETCH_MENU,
  FETCH_PROMOTIONS,
  FETCH_PLASTIC_CUTLERY_ITEMS,
  FETCH_GIFT_CARD_PRODUCT,
  FETCH_MOST_ORDERED_ITEMS
} from './types';

import moment from 'moment';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export function selectLocation(restaurant) {
  return {
    type: SELECT_LOCATION,
    payload: { restaurant: restaurant }
  };
}

export function selectRestaurant(restaurant, callback) {
  return dispatch => callback(dispatch)
}

export function fetchRestaurants(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/restaurants`;
  console.log(url)
  return dispatch => getData(FETCH_RESTAURANTS, TENANT_ERROR, false, url, dispatch);
}

export function fetchBusinessHours(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/businesshours`;

  return dispatch => getData(FETCH_BUSINESS_HOURS, TENANT_ERROR, false, url, dispatch);
}

export function fetchMenus(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/menus`;
  return dispatch => getData(FETCH_MENUS, TENANT_ERROR, false, url, dispatch);
}

export function fetchMenusOnly(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/menusonly`;
  return dispatch => getData(FETCH_MENUS, TENANT_ERROR, false, url, dispatch);
}

export function fetchMenu(menuId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/menus/${menuId}`;
  return dispatch => getData(FETCH_MENU, TENANT_ERROR, false, url, dispatch);
}

export function fetchOnlineOrderMenus(restaurantId, orderType) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/menus?onlineOrdering=${true}`;
  return dispatch => getData(FETCH_ONLINE_ORDER_MENUS, TENANT_ERROR, false, url, dispatch);
}

export function fetchPlasticCutleryItems(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/plastic-cutlery-items`;
  return dispatch => getData(FETCH_PLASTIC_CUTLERY_ITEMS, TENANT_ERROR, false, url, dispatch);
}

export function fetchGiftCardProducts(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/giftcardproducts`;
  return dispatch => getData(FETCH_GIFTCARDPRODUCTS, TENANT_ERROR, false, url, dispatch);
}

export function fetchGiftCardProduct(gitcardProductId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/giftcardproducts/${gitcardProductId}`;
  return dispatch => getData(FETCH_GIFT_CARD_PRODUCT, TENANT_ERROR, false, url, dispatch);
}

export function fetchTodayBusinessHour(restaurantId) {
  const dayOfWeek = moment().day();
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/businesshours/${dayOfWeek}`;
  return dispatch => getData(FETCH_TODAY_BUSINESS_HOURS, TENANT_ERROR, false, url, dispatch);
}

export function fetchLoyaltyRewards() {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/loyaltyrewards`;
  return dispatch => getData(FETCH_LOYALTY_REWARDS, TENANT_ERROR, false, url, dispatch);
}

export function fetchTenant(slug) {  
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${slug}`;
  return dispatch => getData(FETCH_TENANT, TENANT_ERROR, false, url, dispatch);
}

export function fetchBusinessHoursByTenantId(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/businesshours`;
  return dispatch => getData(FETCH_BUSINESS_HOURS, TENANT_ERROR, false, url, dispatch);
}

export function fetchPickupDates(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/pickupdates`;
  return dispatch => getData(FETCH_PICKUP_DATES, TENANT_ERROR, false, url, dispatch);
}

export function fetchAnnouncements(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/announcements`;
  return dispatch => getData(FETCH_ANNOUNCEMENTS, TENANT_ERROR, false, url, dispatch);
}

export function fetchCoupons(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/coupons`;
  return dispatch => getData(FETCH_COUPONS, TENANT_ERROR, false, url, dispatch);
}

export function fetchPromotions(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/promotions`;
  return dispatch => getData(FETCH_PROMOTIONS, TENANT_ERROR, false, url, dispatch);
}

export function fetchMostOrderedItems(tenantId) {
  const eaterUUID = cookies.get("guid");
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/get-most-ordered-items?eaterUUID=${eaterUUID}`;
  return dispatch => getData(FETCH_MOST_ORDERED_ITEMS, TENANT_ERROR, false, url, dispatch);
}
