import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPromotions } from "../../actions/tenant";
import moment from "moment-timezone";
class Promotions extends Component {
  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchPromotions(this.props.tenant._id);
    }
  }

  render() {
    const { tenant, promotions = [] } = this.props;

    const now = moment().tz(tenant.settings.time_zone);

    const validPromotions = promotions.filter(promotion => {
      if (promotion.available_option === "Unlimited") {
        return true;
      }

      if (promotion.available_from) {
        const effectiveDate = moment(promotion.available_from).tz(tenant.settings.time_zone).startOf("day");
        if (now.isBefore(effectiveDate)) {
          return false;
        }
      }

      if (promotion.available_until) {
        const expireDate = moment(promotion.available_until).tz(tenant.settings.time_zone).endOf("day");
        if (now.isAfter(expireDate)) {
          return false;
        }
      }
      return true;
    });

    return (
      <>
        <section className="text-center">
          <h2>Promotions</h2>
          <hr />
          <p>
            We’re changing the rules of the game! We’re constantly coming up
            with great promotions designed especially for your needs. Our
            time-limited and carefully crafted-for-you promotions enable you to
            enjoy a great deal on your favorite meals everytime you simply don’t
            feel like cooking.
          </p>
        </section>
        {/* <PromotionList tenant={tenant} restaturant={restaurant} promotions={promotions} /> */}
        {validPromotions.map(promotion => (
          <div className="row mb-3 pb-3 border-bottom" key={promotion._id}>
            <div className="col-12 col-md-4">
              {promotion.image && promotion.image.url && (
                <img
                  className="card-img-top"
                  src={process.env.REACT_APP_S3_BASE_URL + `${tenant.slug +
                    "/" +
                    promotion.image.url
                    }`}
                  alt="Promotion"
                />
              )}
              {!promotion.image && (
                <img
                  className="card-img-top"
                  src={`https://via.placeholder.com/250x150.png?text=${promotion.name
                    }`}
                  alt="Promotion"
                />
              )}
            </div>
            <div className="col-12 col-md-8 text-center text-md-left">
              <h2>{promotion.name}</h2>
              <p>{promotion.description}</p>
              {promotion.conditions && promotion.conditions.length > 0 && (
                <>
                  <h5 className="pt-3">{`Conditions:`}</h5>
                  <ul className="list-unstyled">
                    {promotion.conditions && promotion.conditions.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              )}
              {promotion.instructions && promotion.instructions.length > 0 && (
                <>
                  <h5>{`How to redeem:`}</h5>
                  <ul className="list-unstyled">
                    {promotion.instructions.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        ))}
        <section className="text-center">
          <h2>Be The First One To Know About It</h2>
          <hr />
          <p>
            Follow us on social media so that you can be the first one to know
            every time we’ll be releasing new promotions.
          </p>
        </section>
        <section className="text-center">
          <h2>Social Media</h2>
          <hr />
          <div className="row">
            <div className="col-12">
              <ul className="list-inline mr-3">
                {tenant.facebook_url && (
                  <li className="list-inline-item">
                    <a href={tenant.facebook_url}>
                      <i className="fab fa-facebook-square fa-3x btn-facebook" />
                    </a>
                  </li>
                )}
                {tenant.twitter_url && (
                  <li className="list-inline-item">
                    <a href={tenant.twitter_url}>
                      <i className="fab fa-twitter-square fa-2x btn-twitter" />
                    </a>
                  </li>
                )}
                {tenant.wechat_url && (
                  <li className="list-inline-item">
                    <a href={tenant.wechat_url}>
                      <i className="fab fa-weixin fa-2x btn-wechat" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    promotions: state.tenant.promotions || []
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPromotions: tenantId => dispatch(fetchPromotions(tenantId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions);
