import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";

import { fetchUserByReferralToken, registerFromReferral } from "../../actions/user";
import normalizePhone from "../../utils/normalizePhone";
import {
  renderField,
  renderEmailField,
  renderPhoneField,
  renderPasswordField
} from "../../utils/renderFields";

const form = reduxForm({
  form: "register",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = "Please enter a first name";
  }

  if (!formProps.lastName) {
    errors.lastName = "Please enter a last name";
  }

  if (!formProps.email) {
    errors.email = "Please enter an email";
  }

  if (!formProps.password) {
    errors.password = "Please enter a password";
  }

  return errors;
}

class RegisterFromReferral extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      redirectToReferrer: false
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    this.props.fetchUserByReferralToken(token);
  }

  handleFormSubmit(formProps) {

    this.props.registerFromReferral(formProps, this.props.referrer._id, () => {
      this.setState({ redirectToReferrer: true });
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    const { handleSubmit, pristine, submitting, referrer } = this.props;

    return (
      <>
        {/* <div className="row pb-3 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>REGISTER</strong>
            </h3>
          </div>
        </div> */}
        {referrer && referrer.local && (
          <h5 className="text-center">
            <strong>
              Referred By: {referrer.local.firstName} {referrer.local.lastName}
            </strong>
          </h5>
        )}
        <div className="row h-100 align-items-center pt-3">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header text-center">Register</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                  {this.renderAlert()}

                  <div className="form-group">
                    <label>First Name</label>
                    <Field
                      name="firstName"
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <Field
                      name="lastName"
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <Field
                      name="telephone"
                      className="form-control"
                      component={renderPhoneField}
                      type="text"
                      normalize={normalizePhone}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      name="email"
                      className="form-control"
                      component={renderEmailField}
                      type="email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Field
                      name="password"
                      className="form-control"
                      component={renderPasswordField}
                      type="password"
                    />
                  </div>

                  <div className="controls text-center">
                    <button
                      type="submit"
                      disabled={pristine || submitting}
                      className="btn btn-angkor btn-block"
                    >
                      REGISTER
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    referrer: state.user.referrer
  };
};

const mapDispatchToProps = dispatch => ({
  registerFromReferral: (formProps, referrerId, callback) => dispatch(registerFromReferral(formProps, referrerId, callback)),
  fetchUserByReferralToken: referralToken =>
    dispatch(fetchUserByReferralToken(referralToken))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(RegisterFromReferral));
