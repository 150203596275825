import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import {
  selectRestaurant,
  fetchBusinessHoursByTenantId,
  fetchBusinessHours,
  fetchGiftCardProducts,
} from "../../actions/tenant";
import { Redirect, NavLink } from "react-router-dom";

import { fetchUser } from "../../actions/user";

import { SELECT_RESTAURANT } from "../../actions/types";

import LoyaltyRewards from "../../assets/loyalty-rewards.png";
import ReferralImg from "../../assets/referral.png";
import InviteByEmail from "../Referral/InviteByEmail";
import InviteByFacebook from "../Referral/InviteByFacebook";
import InviteByTwitter from "../Referral/InviteByTwitter";

import MenuItemCarousel from "./MenuItemCarousel";

import "./Home.css";

import Map from "../Contact/Map";
import ContactForm from "../Contact/ContactForm";
import OpeningHours from "../Common/OpeningHours";
import Announcements from "../Announcement/Announcements";
import HolidayHours from "../Common/HolidayHours";
import BannerCarousel from "./BannerCarousel";
import Location from "./Location";

import { sendGAEvent } from "../../utils/analytics";

const cookies = new Cookies();

class Home extends Component {
  constructor(props) {
    super(props);

    this.handleViewMenus = this.handleViewMenus.bind(this);
    this.handleOrderOnline = this.handleOrderOnline.bind(this);
    this.handleOrderGiftCard = this.handleOrderGiftCard.bind(this);
    this.handleMakeReservation = this.handleMakeReservation.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    const user = cookies.get("customer");
    if (user) {
      const userId = user._id.toString();
      this.props.fetchUser(userId);
    }
    if (this.props.restaurant) {
      this.props.fetchBusinessHours(this.props.restaurant._id);
      this.props.fetchGiftCardProducts(this.props.restaurant._id);
    } else if (this.props.tenant) {
      this.props.fetchBusinessHoursByTenantId(this.props.tenant._id);
    }
  }

  handleViewMenus(restaurant) {
    sendGAEvent({
      category: "Home",
      action: "Click See Menu",
      label: ``,
    });
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/menus" });
    });
  }

  handleOrderOnline(restaurant) {
    sendGAEvent({
      category: "Home",
      action: "Click Order Online",
      label: ``,
    });

    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/orderonline" });
    });
  }

  handleOrderGiftCard(restaurant) {
    sendGAEvent({
      category: "Home",
      action: "Click Order Gift Card",
      label: ``,
    });
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/gift" });
    });
  }

  handleMakeReservation(restaurant) {
    sendGAEvent({
      category: "Home",
      action: "Click Make Reservation",
      label: ``,
    });
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/reservation" });
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    const {
      tenant,
      authenticated,
      restaurants = [],
      announcements = [],
      holidayHours = [],
    } = this.props;

    if (!tenant) {
      return <></>;
    }
    const showOnlineOrdering = tenant.settings.online_ordering === true;
    const showOnlineReservation = tenant.settings.online_reservation === true;
    const showGiftCard = tenant.settings.gift_card === true;

    const showLoyaltyRewardProgram =
      tenant.settings.loyalty_reward_program === true;
    const loyalty_points_per_order = tenant.settings.loyalty_points_per_order;
    const loyalty_points_per_dollar = tenant.settings.loyalty_points_per_dollar;
    const loyalty_points_per_referral =
      tenant.settings.loyalty_points_per_referral;

    return (
      <>
        <div className="row">
          <div className="col-12 text-center">
            {tenant.carousel_items && tenant.carousel_items.length > 0 && (
              <BannerCarousel tenant={tenant} />
            )}
            {(!tenant.carousel_items || tenant.carousel_items.length === 0) &&
              tenant.banner && (
                <img
                  className="img-fluid"
                  src={
                    process.env.REACT_APP_S3_BASE_URL +
                    tenant.slug +
                    "/" +
                    tenant.banner.url
                  }
                  alt="Banner"
                />
              )}
          </div>
        </div>

        {restaurants.length === 1 && (
          <>
            <section className="text-center">
              <div className="row">
                {showOnlineOrdering && (
                  <div
                    className={
                      showOnlineReservation
                        ? "col-12 text-center offset-md-2 col-md-4 mb-2"
                        : "col-12 text-center offset-md-4 col-md-4 mb-2"
                    }
                  >
                    <button
                      className="btn btn-angkor btn-block"
                      onClick={() => this.handleOrderOnline(restaurants[0])}
                    >
                      <span className="font-weight-bolder cta">{`ORDER NOW`}</span>
                    </button>
                    {showGiftCard && (
                      <button
                        className="btn btn-angkor btn-block"
                        onClick={() => this.handleOrderGiftCard(restaurants[0])}
                      >
                        <span className="font-weight-bolder cta">{`BUY GIFT CARD`}</span>
                      </button>
                    )}
                  </div>
                )}
                {!showOnlineOrdering && (
                  <>
                    <div
                      className={
                        showOnlineReservation
                          ? "col-12 text-center col-md-4 mb-2"
                          : "col-12 text-center offset-md-2 col-md-4 mb-2"
                      }
                    >
                      <button
                        className="btn btn-angkor btn-block"
                        onClick={() => this.handleViewMenus(restaurants[0])}
                      >
                        {`See Menu`}
                      </button>
                    </div>
                    <div className={"col-12 text-center col-md-4 mb-2"}>
                      <a
                        className="btn btn-angkor btn-block"
                        href={`tel:${tenant.phone}`}
                        role="button"
                      >
                        {`Call To Order`}
                      </a>
                    </div>
                  </>
                )}

                {showOnlineReservation && (
                  <div className="col-12 text-center col-md-4 mb-2">
                    <button
                      className="btn btn-angkor btn-block"
                      onClick={() => this.handleMakeReservation(restaurants[0])}
                    >
                      {`Reserve Table`}
                    </button>
                  </div>
                )}
              </div>
            </section>
            <section className="text-center">
              <div className="row">
                <div
                  className={
                    holidayHours.length > 0 ? "col-12 col-lg-6" : "col-12"
                  }
                >
                  <h2>Opening Hours</h2>
                  <hr />
                  <OpeningHours restaurant={restaurants[0]} />
                </div>
                {holidayHours.length > 0 && (
                  <div className="col-12 col-lg-6">
                    <h2>Holiday Hours</h2>
                    <hr />
                    <HolidayHours
                      holidayHours={holidayHours}
                      restaurant={restaurants[0]}
                    />
                  </div>
                )}
              </div>
            </section>
          </>
        )}
        {restaurants.length > 1 && (
          <section className="text-center">
            <h2>Our Locations</h2>
            <hr />

            <div className="card-deck">
              {restaurants.map((restaurant, index) => (
                <Location
                  key={restaurant._id}
                  restaurant={restaurant}
                  handleViewMenus={() => this.handleViewMenus(restaurant)}
                  handleOrderOnline={() => this.handleOrderOnline(restaurant)}
                  handleOrderGiftCard={() =>
                    this.handleOrderGiftCard(restaurant)
                  }
                  handleMakeReservation={() =>
                    this.handleMakeReservation(restaurant)
                  }
                  holidayHours={holidayHours.filter(
                    (holidayHours) =>
                      holidayHours.restaurant.valueOf() === restaurant._id
                  )}
                />
              ))}
            </div>
          </section>
        )}

        {tenant.gallery && tenant.gallery.length > 0 && (
          <section className="text-center">
            <h2>Our Food</h2>
            <hr />
            <div className="row">
              <div className="col-12">
                <MenuItemCarousel tenant={tenant} />
              </div>
            </div>
          </section>
        )}

        <section>
          <div className="row">
            <div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <ContactForm />
            </div>
          </div>

          <Map restaurants={restaurants} />
        </section>

        <section className="text-center">
          <h2>About Us</h2>
          <hr />
          <div className="row">
            <div className="col-12">
              <p>{tenant.brief_description}</p>
              <p>{tenant.full_description}</p>
            </div>
          </div>
        </section>

        {announcements.length > 0 && (
          <Announcements showPicture={false} showDefaultPicture={false} />
        )}

        <section className="text-center">
          <h2>Social Media</h2>
          <hr />
          <div className="row">
            <div className="col-12">
              <ul className="list-inline mr-3">
                {tenant.facebook_url && (
                  <li className="list-inline-item border p-3">
                    <a href={tenant.facebook_url}>
                      <i className="fab fa-facebook-square fa-5x btn-facebook" />
                    </a>
                  </li>
                )}
                {tenant.twitter_url && (
                  <li className="list-inline-item border p-3">
                    <a href={tenant.twitter_url}>
                      <i className="fab fa-twitter-square fa-5x btn-twitter" />
                    </a>
                  </li>
                )}
                {tenant.wechat_url && (
                  <li className="list-inline-item border p-3">
                    <a href={tenant.wechat_url}>
                      <i className="fab fa-weixin fa-5x btn-wechat" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </section>
        {showLoyaltyRewardProgram && (
          <section className="text-center">
            <h2>Loyalty and Rewards</h2>
            <hr />
            <div className="row pt-3">
              <div className="col-12 col-md-6 pb-3">
                <div className=" card">
                  <img
                    className="card-img-top"
                    src={LoyaltyRewards}
                    alt="Loyalty and Rewards"
                  />
                  <div className="card-body text-center">
                    <p className="card-text">
                      {`Accumulate and redeem points. You will get ${loyalty_points_per_dollar} points for every dollar spent and ${loyalty_points_per_order} points every time you purchase!`}
                    </p>
                    {!authenticated && (
                      <>
                        <NavLink to="/login">Login in</NavLink> or{" "}
                        <NavLink to="/register">register</NavLink> to get points
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 pb-3">
                <div className=" card">
                  <img
                    className="card-img-top"
                    src={ReferralImg}
                    alt="Loyalty and Rewards"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      {`Refer a friend and earn points!`}
                    </h5>

                    <hr />
                    <p className="card-text">
                      {`Invite your friends to our restaturants and be rewarded with ${loyalty_points_per_referral} points as soon as they make their first order. And if they love our dishes just as much as you do, you will continue to be rewarded again and again!`}
                    </p>
                    {!authenticated && (
                      <p className="card-text">
                        <NavLink to="/login">Login in</NavLink> or{" "}
                        <NavLink to="/register">register</NavLink> to earn more
                        points via sharing with friends
                      </p>
                    )}

                    <div className="row">
                      <div className="col-4">
                        <InviteByFacebook />
                      </div>
                      <div className="col-4">
                        <InviteByTwitter />
                      </div>
                      <div className="col-4">
                        <InviteByEmail />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    authenticated: state.auth.authenticated,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    restaurants: state.tenant.restaurants || [],
    restaurantId: state.tenant.restaurantId,
    announcements: state.tenant.announcements || [],
    user: state.user.user,
    holidayHours: state.tenant.businessHours || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectRestaurant: (restaurantId, callback) =>
    dispatch(selectRestaurant(restaurantId, callback)),
  fetchUser: (userId) => dispatch(fetchUser(userId)),
  fetchBusinessHoursByTenantId: (tenantId) =>
    dispatch(fetchBusinessHoursByTenantId(tenantId)),
  fetchBusinessHours: (restaurantId) =>
    dispatch(fetchBusinessHours(restaurantId)),
  fetchGiftCardProducts: (restaurantId) =>
    dispatch(fetchGiftCardProducts(restaurantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
