import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

const CreditCardBrand = ({brand, last4}) => {

  if (brand === 'Visa') {
    return (<span><i className="fab fa-cc-visa fa-2x"/><strong>{` `}**** **** ****{` `}{last4}</strong></span>)
  } else if (brand === 'MasterCard') {
    return (<span><i className="fab fa-cc-mastercard fa-2x"/><strong>{` `}**** **** ****{` `}{last4}</strong></span>)
  } else if (brand === 'American Express') {
    return (<span><i className="fab fa-cc-amex fa-2x"/><strong>{` `}**** ****** *{last4}</strong></span>)
  } else return (<></>)
}

class PaymentMethodView extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }


  render() {
    const { paymentMethod } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-8">           
            <CreditCardBrand brand={paymentMethod.stripeToken.card.brand} last4={paymentMethod.stripeToken.card.last4} /> 
          </div>
          <div className="col-4 text-right">
            <button className="btn btn-light" onClick={this.props.onDelete}><Icon.Trash size={16} /> {` `} <span className="d-none d-md-inline">Delete</span></button>{` `}
            <button className="btn btn-light" onClick={this.props.onEdit} ><Icon.Edit3 size={16} /> {` `} <span className="d-none d-md-inline">Edit</span></button>
          </div>
        </div>
        <div className="jumbotron">
          <div className="row">
            <div className="col-12 col-sm-6 pb-3">
              <h5><strong>Card Holder Name</strong></h5>
              <span>{paymentMethod.stripeToken.card.name}</span>
            </div>

            <div className="col-12 col-sm-6">
              <h5><strong>Email</strong></h5>
              <span>{paymentMethod.email}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 pb-3">
              <h5><strong>Expires</strong></h5>
              <span>{paymentMethod.stripeToken.card.exp_month} / {paymentMethod.stripeToken.card.exp_year}</span>
            </div>

            <div className="col-12 col-sm-6 pb-3">
              <h5><strong>Billing Information</strong></h5>
              <span>{paymentMethod.stripeToken.card.address_line1}</span>
              <br />
              <span>{paymentMethod.stripeToken.card.address_city}, {paymentMethod.stripeToken.card.address_state} {paymentMethod.stripeToken.card.address_zip}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <h5><strong>Currency</strong></h5>
              <span>{paymentMethod.stripeToken.card.currency}</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodView);
