import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import Numeral from "numeral";

import { addToCart, getDeliveryGST, getDeliveryFee } from "../../actions/cart";

import { sendGAEvent } from "../../utils/analytics";
import moment from "moment-timezone";

const form = reduxForm({
  form: "addToCart",
  destroyOnUnmount: true,
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class AddToCartModal extends Component {
  constructor(props) {
    super(props);

    this.handlePlus = this.handlePlus.bind(this);
    this.handleMinus = this.handleMinus.bind(this);

    this.handleSizesChange = this.handleSizesChange.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);

    this.handleSpecialInstructionsChange =
      this.handleSpecialInstructionsChange.bind(this);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    let options = [];
    let selectedOptionItemCountMap = {};
    if (props.menuItem) {
      props.menuItem.options
        .filter((option) => option.selection_type === "Single")
        .forEach((option) => {
          options.push({
            option_name: option.name,
            option_value: option.items[0].name,
            option_id: option.items[0]._id,
            option_min_selected: option.min_selected,
            option_max_selected: option.max_selected,
            option_number_of_free_options: option.number_of_free_options,
            option_information: option.information,
            option_quantity: 1,
            option_price: option.items[0].additional_cost,
            option_total: option.items[0].additional_cost,
          });
          selectedOptionItemCountMap[option._id] = 0;
        });
    }

    this.state = {
      item: props.menuItem,
      duration: {
        name: props.menuDurationName,
        timeStart: props.menuDurationTimeStart,
        timeEnd: props.menuDurationTimeEnd,
      },
      size:
        props.menuItem &&
        props.menuItem.sizes &&
        props.menuItem.sizes.length > 0
          ? props.menuItem.sizes[0].name
          : "",
      options: options,
      discounts: [],
      quantity: 1,
      basePrice: props.menuItem
        ? props.menuItem.price
          ? props.menuItem.price
          : props.menuItem.sizes
          ? props.menuItem.sizes[0].price
          : 0
        : 0,
      baseTotal: props.menuItem
        ? props.menuItem.price
          ? props.menuItem.price
          : props.menuItem.sizes
          ? props.menuItem.sizes[0].price
          : 0
        : 0,
      extraPrice: 0,
      extraTotal: 0,
      specialInstructions: "",
      selectedOptionItemCountMap: selectedOptionItemCountMap,
    };
  }

  componentDidMount() {}

  handlePlus() {
    sendGAEvent({
      category: "Cart",
      action: "Increase quantiy",
      label: `${this.state.item.cd ? this.state.item.cd + " - " : ""}${
        this.state.item.name
      }`,
    });
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1,
      baseTotal: (prevState.quantity + 1) * prevState.basePrice,
      extraTotal: (prevState.quantity + 1) * prevState.extraPrice,
      options: prevState.options.map((op) => {
        op = {
          ...op,
          option_quantity: op.option_quantity + 1,
          option_total:
            op.option_total && op.option_price
              ? op.option_total + op.option_price
              : undefined,
        };
        return op;
      }),
    }));
  }

  handleMinus() {
    sendGAEvent({
      category: "Cart",
      action: "Decrease quantiy",
      label: `${this.state.item.cd ? this.state.item.cd + " - " : ""}${
        this.state.item.name
      }`,
    });
    this.setState((prevState) => ({
      quantity: prevState.quantity - 1,
      baseTotal: (prevState.quantity - 1) * prevState.basePrice,
      extraTotal: (prevState.quantity - 1) * prevState.extraPrice,
      options: prevState.options.map((op) => {
        op = {
          ...op,
          option_quantity: op.option_quantity - 1,
          option_total:
            op.option_total && op.option_price
              ? op.option_total - op.option_price
              : undefined,
        };
        return op;
      }),
    }));
  }

  handleSpecialInstructionsChange(event) {
    sendGAEvent({
      category: "Cart",
      action: "Enter Special Instructions",
      label: `${this.state.item.cd ? this.state.item.cd + " - " : ""}${
        this.state.item.name
      }`,
    });
    this.setState({ specialInstructions: event.target.value });
  }

  handleOptionsChange(event) {
    const target = event.target;

    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let options = Array.from(this.state.item.options);

    if (target.type === "radio") {
      options
        .filter((option) => option._id === name)
        .forEach((option) => {
          option.items
            .filter((item) => item._id === value)
            .forEach((item) => {
              let array = [...this.state.options]; // make a separate copy of the array
              let found = false;
              for (let o of array) {
                if (o.option_name === option.name) {
                  o.option_value = item.name;
                  o.option_id = item._id;
                  o.option_quantity = this.state.quantity;
                  o.option_price = item.additional_cost;
                  o.option_total = item.additional_cost
                    ? this.state.quantity * item.additional_cost
                    : 0;
                  found = true;
                  break;
                }
              }

              if (!found) {
                array.push({
                  option_name: option.name,
                  option_value: item.name,
                  option_id: item._id,
                  option_quantity: this.state.quantity,
                  option_price: item.additional_cost,
                  option_total: item.additional_cost
                    ? this.state.quantity * item.additional_cost
                    : 0,
                });
              }

              this.setState((prevState) => ({
                extraPrice:
                  prevState.extraPrice +
                  (item.additional_cost
                    ? item.additional_cost * prevState.quantity
                    : 0),
                options: array,
              }));

              this.setState((prevState) => ({
                extraTotal: array.reduce((accumulator, currentValue) => accumulator + (currentValue.option_total ? currentValue.option_total : 0), 0),
              }));
            });
        });
    }
    if (target.type === "checkbox") {
      const selected_option_id = name.split("|")[0];
      const selected_option_item_id = name.split("|")[1];
      const selected_option = options.find(
        (option) => option._id === selected_option_id
      );

      if (selected_option) {
        if (target.checked) {
          if (
            selected_option.max_selected > 0 &&
            this.state.selectedOptionItemCountMap[selected_option_id] >
              selected_option.max_selected - 1
          ) {
            alert(
              `You can only select ${selected_option.max_selected} options.`
            );
            event.preventDefault();
            return;
          }
        }

        const selected_option_item = selected_option.items.find(
          (item) => item._id === selected_option_item_id
        );

        if (selected_option_item) {
          
          if (value) {
            this.setState((prevState) => {
              const _options = [
                ...prevState.options,
                {
                  option_name: selected_option_item.name,
                  option_id: selected_option_item._id,
                  option_quantity: prevState.quantity,
                  option_price: selected_option_item.additional_cost,
                  option_total: selected_option_item.additional_cost
                    ? prevState.quantity *
                      selected_option_item.additional_cost
                    : 0,
                },
              ];
              const result = {
                extraPrice:
                  prevState.extraPrice +
                  (selected_option_item.additional_cost
                    ? selected_option_item.additional_cost
                    : 0),
                options: _options,
                extraTotal: _options.reduce((accumulator, currentValue) => accumulator + (currentValue.option_total ? currentValue.option_total : 0), 0),
                selectedOptionItemCountMap: {
                  ...prevState.selectedOptionItemCountMap,
                  [selected_option._id]:
                    prevState.selectedOptionItemCountMap[selected_option._id] +
                    1,
                },
              };
              return result;
            });
          } else {
            this.setState((prevState) => {
              const _options = prevState.options.filter(
                (state_option) =>
                  state_option.option_id !== selected_option_item_id
              );
              return {
                extraPrice:
                  prevState.extraPrice -
                  (selected_option_item.additional_cost
                    ? selected_option_item.additional_cost
                    : 0),
                options: _options,
                extraTotal: _options.reduce((accumulator, currentValue) => accumulator + (currentValue.option_total ? currentValue.option_total : 0), 0),
                selectedOptionItemCountMap: {
                  ...prevState.selectedOptionItemCountMap,
                  [selected_option._id]:
                    prevState.selectedOptionItemCountMap[selected_option._id] -
                    1,
                },
              };
            });
          }
        }
      }
    }

    sendGAEvent({
      category: "Cart",
      action: "Change Options",
      label: `${this.state.item.cd ? this.state.item.cd + " - " : ""}${
        this.state.item.name
      }`,
    });
  }

  handleSizesChange(event) {
    sendGAEvent({
      category: "Cart",
      action: "Change Size",
      label: `${this.state.item.cd ? this.state.item.cd + " - " : ""}${
        this.state.item.name
      }`,
    });
    const target = event.target;
    const sizeId = target.value;
    const basePrice = this.state.item.sizes.filter(
      (itemsize) => itemsize._id === sizeId
    )[0].price;
    const sizeName = this.state.item.sizes.filter(
      (itemsize) => itemsize._id === sizeId
    )[0].name;
    this.setState((prevState) => ({
      size: sizeName,
      basePrice: basePrice,
      baseTotal: prevState.quantity * basePrice,
    }));
  }

  handleFormSubmit() {
    const label = `${this.props.sectionName}: ${
      this.state.item.cd ? this.state.item.cd + " - " : ""
    }${this.state.item.name}`;
    sendGAEvent({
      category: "Cart",
      action: "Add menu item to cart",
      label: label,
    });

    this.props.addToCart(
      this.state.item._id,
      this.state.item.cd,
      this.state.item.name,
      this.state.item.printName,
      this.state.size,
      this.state.options,
      this.state.quantity,
      this.state.basePrice,
      this.state.baseTotal,
      this.state.extraPrice,
      this.state.extraTotal,
      this.state.specialInstructions,
      this.state.duration,
      this.props.tenant.settings.coupon,
      this.props.promotions,
      this.props.restaurant.settings.time_zone,
      this.props.restaurant.settings.time_format,
      this.props.deliveryMethod,
      this.props.pickupTime,
      this.props.pickupDate,
      this.props.deliveryDistance,
      this.props.postalCode,
      this.props.deliveryFee,
      this.props.deliveryGST,
      () => {
        let basePrice = this.props.menuItem.price
          ? this.props.menuItem.price
          : this.props.menuItem.sizes
          ? this.props.menuItem.sizes[0].price
          : 0;
        let baseTotal = basePrice;
        let extraPrice = 0;
        let extraTotal = 0;
        let sizeName =
          this.props.menuItem &&
          this.props.menuItem.sizes &&
          this.props.menuItem.sizes.length > 0
            ? this.props.menuItem.sizes[0].name
            : "";

        let options = [];
        if (this.props.menuItem) {
          this.props.menuItem.options
            .filter((option) => option.selection_type === "Single")
            .forEach((option) => {
              options.push({
                option_name: option.name,
                option_value: option.items[0].name,
                option_id: option.items[0]._id,
                option_quantity: 1,
                option_price: option.items[0].additional_cost,
                option_total: option.items[0].additional_cost,
              });
            });
        }

        this.setState(
          (prevState) => ({
            quantity: 1,
            basePrice: basePrice,
            baseTotal: baseTotal,
            extraPrice: extraPrice,
            extraTotal: extraTotal,
            specialInstructions: "",
            size: sizeName,
            options: options,
            discounts: [],
          }),
          () => {
            $(".close").click();
          }
        );
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      menuItem = { sizes: [], options: [], discounts: [] },
      restaurant,
      handleSubmit,
      buttonLabel,
      fixedWidth,
    } = this.props;

    const _menuItemOptions = menuItem.options
    .filter(
      (option) =>
        !option.hidden_from_public && (option.items && option.items.filter(optionitem => !optionitem.hidden_from_public ).length > 0));
    
    return (
      <div className="pt-3">
        <button
          type="button"
          className={`btn btn-angkor ${fixedWidth ? "btn-block" : ""}`}
          data-toggle="modal"
          data-target={`#addToCartModal_${menuItem._id}`}
          data-backdrop="static"
        >
          {buttonLabel ? buttonLabel : `Add To Cart`}
        </button>
        <div
          className="modal fade"
          id={`addToCartModal_${menuItem._id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={`#addToCartModalLabel_${menuItem._id}`}
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-angkor">

                  <h5
                    className="modal-title"
                    id={`addToCartModalLabel_${menuItem._id}`}
                  >
                    {`${menuItem.cd ? menuItem.cd + "." : ""} ${menuItem.name}`}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
       
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        {menuItem.description && (
                          <div className="form-group">
                            <label>Description</label>
                            <div>
                              <small className="text-muted font-italic">
                                {menuItem.description}
                              </small>
                            </div>
                          </div>
                        )}
                        {menuItem.price && (
                          <div className="form-group">
                            <label>Price</label>
                            <div>
                              {Numeral(menuItem.price).format("$0,0.00")}
                            </div>
                          </div>
                        )}
                        {menuItem.sizes.length > 0 && (
                          <div className="form-group">
                            <div className="bg-light py-2">
                              <div className="font-weight-bold">Sizes</div>
                              {/* <div>
                                <small className="text-muted font-italic">{`Choose up to 1`}</small>
                              </div> */}
                            </div>
                            {menuItem.sizes.map((itemsize, index) => (
                              <div
                                key={itemsize._id}
                                className={index === 0 ? "pt-2" : ""}
                              >
                                <label>
                                  <input
                                    name="sizes"
                                    type="radio"
                                    value={itemsize._id}
                                    checked={this.state.size === itemsize.name}
                                    onChange={this.handleSizesChange}
                                  />{" "}
                                  {itemsize.name}{" "}
                                  {Numeral(itemsize.price).format("$0,0.00")}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                        {_menuItemOptions
                          .filter(
                            (option) =>
                              !option.hidden_from_public &&
                              option.selection_type === "Single"
                          )
                          .map((option, index1) => (
                            <div
                              key={`option_${index1}`}
                              className="form-group"
                            >
                              <div className="bg-light py-2">
                                <div className="font-weight-bold">
                                  {option.name}
                                </div>
                                {/* <div>
                                  <small className="text-muted font-italic">{`Choose up to 1`}</small>
                                </div> */}
                              </div>
                              {option.items
                                .filter(
                                  (optionitem) => !optionitem.hidden_from_public
                                )
                                .map((optionitem, index) => (
                                  <div
                                    key={`optionitem_${index}`}
                                    className="form-check"
                                  >
                                    <label className="form-check-label">
                                      <input
                                        name={option._id}
                                        className="form-check-input"
                                        type="radio"
                                        value={optionitem._id}
                                        // defaultChecked={optionitem.pre_selected}
                                        checked={
                                          this.state.options
                                            ? this.state.options.filter(
                                                (o1) =>
                                                  o1.option_id ===
                                                  optionitem._id
                                              ).length > 0
                                            : false
                                        }
                                        onChange={this.handleOptionsChange}
                                      />{" "}
                                      {optionitem.name}{" "}
                                      {optionitem.additional_cost > 0 && (
                                        <span>
                                          {` + ${Numeral(
                                            optionitem.additional_cost
                                          ).format("$0,0.00")}`}
                                        </span>
                                      )}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          ))}
                        {restaurant.settings.menu_extra_option &&
                          _menuItemOptions
                            .filter(
                              (option) =>
                                !option.hidden_from_public &&
                                option.selection_type === "Multiple"
                            )
                            .map((option, index1) => (
                              <div
                                key={`option_${index1}`}
                                className="form-group"
                              >
                                <div className="bg-light py-2">
                                  <div className="font-weight-bold">
                                    {option.name}
                                  </div>
                                  {option.max_selected > 0 && (
                                    <div>
                                      <small className="text-muted font-italic">{`Choose up to ${option.max_selected}`}</small>
                                    </div>
                                  )}
                                  {option.information && (
                                    <div>
                                      <small className="text-muted font-italic">
                                        {option.information}
                                      </small>
                                    </div>
                                  )}
                                </div>

                                {option.items
                                  .filter(
                                    (optionitem) =>
                                      !optionitem.hidden_from_public
                                  )
                                  .map((optionitem, index) => (
                                    <div
                                      key={`optionitem_${index}`}
                                      className={
                                        index === 0
                                          ? "form-check pt-2"
                                          : "form-check"
                                      }
                                    >
                                      <label className="form-check-label">
                                        <input
                                          name={`${option._id}|${optionitem._id}`}
                                          className="form-check-input"
                                          type="checkbox"
                                          value={optionitem._id}
                                          checked={
                                            this.state.options
                                              ? this.state.options.filter(
                                                  (o1) =>
                                                    o1.option_id ===
                                                    optionitem._id
                                                ).length > 0
                                              : false
                                          }
                                          onChange={this.handleOptionsChange}
                                        />
                                        <span className="mr-2">
                                          {optionitem.name}
                                        </span>
                                        {optionitem.additional_cost > 0 && (
                                          <span>
                                            {` + ${Numeral(
                                              optionitem.additional_cost
                                            ).format("$0,0.00")}`}
                                          </span>
                                        )}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            ))}

                        {restaurant.settings.menu_special_request_allowed && (
                          <div className="form-group">
                            <label>Special Instructions</label>
                            <div>
                              <textarea
                                name="specialInstructions"
                                className="form-control"
                                value={this.state.specialInstructions}
                                onChange={this.handleSpecialInstructionsChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {` `}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.handleMinus}
                    disabled={this.state.quantity <= 1}
                  >
                    <Icon.Minus size={16} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handlePlus}
                  >
                    <Icon.Plus size={16} />
                  </button>
                  <strong>
                    {Numeral(
                      this.state.baseTotal + this.state.extraTotal
                    ).format("$0,0.00")}
                  </strong>{" "}
                  <button
                    className="btn btn-angkor"
                    onClick={this.handleAddToCart}
                  >
                    <span className="badge badge-info">
                      {this.state.quantity}
                    </span>{" "}
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    promotions: state.tenant.promotions || [],
    deliveryMethod: state.order.deliveryMethod,
    pickupDate:
      state.order.pickupDate ||
      moment().tz(state.tenant.restaurant.settings.time_zone),
    pickupTime:
      state.order.pickupTime ||
      moment().tz(state.tenant.restaurant.settings.time_zone),
    initialValues: {},
    deliveryDistance: state.order.deliveryAddress?.distanceInMeter,
    postalCode: state.order.deliveryAddress?.postalCode,
    deliveryFee: getDeliveryFee(state),
    deliveryGST: getDeliveryGST(state, gstRate),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (
    id,
    cd,
    name,
    printName,
    size,
    options,
    discounts,
    discountTotal,
    quantity,
    basePrice,
    baseTotal,
    extraPrice,
    extraTotal,
    specialInstructions,
    duration,
    isCouponEnabled,
    promotions,
    timeZone,
    timeFormat,
    deliveryMethod,
    pickupTime,
    pickupDate,
    deliveryDistance,
    postalCode,
    deliveryFee,
    deliveryGST,
    callback
  ) =>
    dispatch(
      addToCart(
        id,
        cd,
        name,
        printName,
        size,
        options,
        discounts,
        discountTotal,
        quantity,
        basePrice,
        baseTotal,
        extraPrice,
        extraTotal,
        specialInstructions,
        duration,
        isCouponEnabled,
        promotions,
        timeZone,
        timeFormat,
        deliveryMethod,
        pickupTime,
        pickupDate,
        deliveryDistance,
        postalCode,
        deliveryFee,
        deliveryGST,
        callback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddToCartModal));
