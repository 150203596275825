import React, { Component } from "react";
import Numeral from "numeral";

class PromotionItem extends Component {
  render() {
    const {
      tenant,
      promotion,
    } = this.props;

    return (
      <div className="row clickable pt-3 pb-3 border-bottom">
        <div className="col-12 text-center col-sm-3 text-sm-left">
          {promotion.image && promotion.image.url && (
            <img
              className="img-fluid"
              src={process.env.REACT_APP_S3_BASE_URL + `${tenant.slug}/${promotion.image.url}`}
              alt="Promotion"
            />
          )}
          {!promotion.image && (
            <img
              className="img-fluid"
              src={`https://via.placeholder.com/250x150.png?text=${promotion.name}`}
              alt="Promotion"
            />
          )}
        </div>
        <div className="col-12 col-sm-9 text-center text-sm-left">
          <div>
            <strong>{promotion.name}</strong>
          </div>
          <div>{promotion.description}</div>
          <div>
            {promotion.type === 'Free delivery' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${promotion.discount_percentage
                    }% discount to delivery fee`}</li>
                </ul>
                <div className="pt-3"><strong className="text-muted">Condition:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`Only once in cart`}</li>
                  <li className="mr-3 text-muted">{`Total price greater or equal to ${Numeral(promotion.minimum_order_amount).format("$0,0.00")
                    }`}</li>
                </ul>
                <div className="bg-secondary mt-3 p-3 text-white">
                  {`Deal is applied automatically when all conditions are met`}
                </div>
              </>
            )
            }
            {promotion.type === 'Payment method reward' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${promotion.discount_percentage
                    }% discount to cart value`}</li>
                </ul>
                <div className="pt-3"><strong className="text-muted">Condition:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`Only once in cart`}</li>
                </ul>
                <div className="bg-secondary mt-3 p-3 text-white">
                  {`Deal is applied automatically when all conditions are met`}
                </div>
              </>
            )
            }
            {promotion.type === 'Buy one, get one free' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${promotion.discount_on_cheapest_item
                    }% discount to the cheapest item`}</li>
                  {promotion.discount_on_most_expensive_item > 0 && <li>{`${promotion.discount_on_most_expensive_item
                    }% discount to the most expensive item`}</li>}
                </ul>
              </>
            )}
            {promotion.type === '% discount on cart' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${promotion.discount_percentage
                    }% discount to cart value`}</li>
                </ul>
                <div className="pt-3"><strong className="text-muted">Condition:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`Only once in cart`}</li>
                  <li className="mr-3 text-muted">{`Total price greater or equal to ${Numeral(promotion.minimum_order_amount).format("$0,0.00")
                    }`}</li>
                </ul>
                <div className="bg-secondary mt-3 p-3 text-white">
                  {`Deal is applied automatically when all conditions are met`}
                </div>
              </>
            )}
            {promotion.type === 'Fixed discount amount on cart' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${Numeral(promotion.discount_amount).format("$0,0.00")
                    } discount to cart value`}</li>
                </ul>
                <div className="pt-3"><strong className="text-muted">Condition:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`Only once in cart`}</li>
                  <li className="mr-3 text-muted">{`Total price greater or equal to ${Numeral(promotion.minimum_order_amount).format("$0,0.00")
                    }`}</li>
                </ul>
                <div className="bg-secondary mt-3 p-3 text-white">
                  {`Deal is applied automatically when all conditions are met`}
                </div>
              </>
            )}
            {promotion.type === '% discount on selected items' && (
              <>
                <div className="pt-3"><strong className="text-muted">What you get:</strong></div>
                <ul className="list-unstyled">
                  <li className="mr-3 text-muted">{`${promotion.discount_on_items_group_1
                    }% discount to specific item`}</li>

                </ul>
              </>
            )}
          </div>

        </div>
      </div>
    );
  }
}

export default PromotionItem;
