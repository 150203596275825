import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { changePassword } from "../../actions/user";
import PropTypes from "prop-types";
import { renderPasswordField } from "../../utils/renderFields";

import { sendGAEvent } from "../../utils/analytics";

const form = reduxForm({
  form: "changePassword",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.currentPassword) {
    errors.currentPassword = "Please enter current password";
  }

  if (!formProps.newPassword) {
    errors.newPassword = "Please enter new password";
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = "Please confirm new password";
  }

  if (formProps.newPassword !== formProps.passwordConfirm) {
    errors.passwordConfirm = "Passwords must match";
  }

  return errors;
}

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }
  static contextTypes = {
    router: PropTypes.object,
  };

  handleFormSubmit({ currentPassword, newPassword }) {
    sendGAEvent({
      category: "User",
      action: "ChangePassword Form Submit",
      label: `UserId: ${this.props.userId}`,
    });
    this.props.changePassword(this.props.userId, {
      currentPassword,
      newPassword,
    }, () => {
      this.setState({ redirectTo: "/profile" });
    });
  }

  handleCancel() {
    this.setState({ redirectTo: "/profile" });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <div className="row h-100 align-items-center">
        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
          <div className="card">
            <div className="card-header text-center">Change Password</div>
            <div className="card-body">
              <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                {this.renderAlert()}
                <div className="form-group">
                  <label>Current Password</label>
                  <Field
                    name="currentPassword"
                    component={renderPasswordField}
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <Field
                    name="newPassword"
                    component={renderPasswordField}
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <Field
                    name="passwordConfirm"
                    component={renderPasswordField}
                    type="password"
                  />
                </div>
                <div className="row pb-3">
                  <div className="col-6 text-left">
                    <button
                      type="button"
                      className="btn btn-secondary btn-block"
                      onClick={this.handleCancel}
                    >
                      {`Cancel`}
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button
                      type="submit"
                      className="btn btn-angkor btn-block"
                      disabled={pristine || submitting}
                    >
                      CHANGE PASSWORD
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.user.error,
    message: state.user.message,
    userId: state.user.user._id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassword: (userId, { currentPassword, newPassword }, callback) =>
    dispatch(changePassword(userId, { currentPassword, newPassword }, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ChangePassword));
