import React, { Component } from "react";
import { connect } from "react-redux";

import { Elements, StripeProvider } from "react-stripe-elements";
import CreditCardForm from './CreditCardForm';

class PaymentMethodAdd extends Component {


  componentDidMount() {

  }


  render() {
    const { onCancel, onSave } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY}>
        <div>
          <Elements>
            <CreditCardForm
              onSave={onSave}
              onCancel={onCancel}
            />
          </Elements>
        </div>
      </StripeProvider>


    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => ({
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodAdd);
