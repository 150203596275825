const first_reserved = ["myfoodcourt", "www", "orders"];
const second_reserved = ["app", "com", "ca", "net"];
const localhost_reserved = ["localhost", "172.16.1.74"];

export const getSubdomain = () => {
  let hostname = window.location.hostname.toLowerCase();
  if (process.env.NODE_ENV === "production" && !localhost_reserved.includes(hostname)) {
    hostname = hostname.replace("-staging", "");
    const hostnameSections = hostname.split(".");
    const first = hostnameSections[0];
    const second = hostnameSections[1];
    if (first_reserved.includes(first)) {
      if (second_reserved.includes(second)) {
        return first;
      } else {
        return second;
      }
    } else {
      return first;
    }
  } else {
    return process.env.REACT_APP_DEFAULT_TENANT;
  }
};
