import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export default class MenuItemCarousel extends React.Component {
  state = {
    currentIndex: 0,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  slideTo = (i) => this.setState({ currentIndex: i });

  onSlideChanged = (e) => this.setState({ currentIndex: e.item });

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  render() {
    const { responsive, currentIndex } = this.state;
    const { tenant } = this.props;

    return (
      <AliceCarousel
        dotsDisabled={true}
        buttonsDisabled={true}
        autoPlay={true}
        autoPlayInterval={2000}
        fadeOutAnimation={true}
        playButtonEnabled={false}
        responsive={responsive}
        slideToIndex={currentIndex}
        onSlideChanged={this.onSlideChanged}
      >
        {tenant.gallery.map((item, index) => (
          // <picture key={index}>
          //   <source
          //     type="image/webp"
          //     srcset={
          //       process.env.REACT_APP_S3_BASE_URL +
          //       tenant.slug +
          //       "/" +
          //       item.image.url.replace(".jpg", "") +
          //       ".webp"
          //     }
          //   />
          //   <source
          //     type="image/jpeg"
          //     srcset={
          //       process.env.REACT_APP_S3_BASE_URL +
          //       tenant.slug +
          //       "/" +
          //       item.image.url.replace(".jpg", "") +
          //       ".jpg"
          //     }
          //   />
          //   <img
          //     className="img-fluid mb-2"
          //     src={
          //       process.env.REACT_APP_S3_BASE_URL +
          //       tenant.slug +
          //       "/" +
          //       item.image.url.replace(".jpg", "") +
          //       ".jpg"
          //     }
          //     alt="Menu Item"
          //   />
          // </picture>
          <img
            className="img-fluid mb-2"
            key={index}
            src={
              process.env.REACT_APP_S3_BASE_URL +
              tenant.slug +
              "/" +
              item.image.url
            }
            alt="Menu Item"
          />
        ))}
      </AliceCarousel>
    );
  }
}
