import React, { Component } from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from "react-stripe-elements";
import Numeral from "numeral";
import "./PayWithCreditCard.css";
import $ from "jquery";

class PayWithCreditCard extends Component {
  constructor(props) {
    super(props);

    this.handleCardHolderNameChange = this.handleCardHolderNameChange.bind(
      this
    );
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);

    this.state = {
      complete: false,
      cardHolderName: props.customer.firstName + " " + props.customer.lastName,
      email: props.customer.email,
      phone: props.customer.phone,
      address: "",
      city: "",
      province: "",
      postalCode: "",
      customer: props.customer,
      errorMessage: undefined,
      currencyCode: props.currencyCode
    };
    this.submit = this.submit.bind(this);
  }

  handleCardHolderNameChange(event) {
    this.setState({ cardHolderName: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }

  handleAddressChange(event) {
    this.setState({ address: event.target.value });
  }

  handleCityChange(event) {
    this.setState({ city: event.target.value });
  }

  handleProvinceChange(event) {
    this.setState({ province: event.target.value });
  }

  handlePostalCodeChange(event) {
    this.setState({ postalCode: event.target.value });
  }

  async submit(ev) {
    ev.preventDefault();
    let result = await this.props.stripe.createToken({
      name: this.state.cardHolderName,
      // address_line1: this.state.address,
      // address_city: this.state.city,
      // address_state: this.state.province,
      // address_zip: this.state.postalCode,
      // address_country: "CA",
      currency: this.state.currencyCode
    });

    if (result.error) {
      this.setState({ errorMessage: result.error.message });
    }
    if (result.token) {
      this.props.onPlaceOrder(result.token);
      $(".close").click();
    }
  }

  renderAlert() {
    if (this.state.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.state.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="card bg-light">
        <div className="card-body">
          {this.renderAlert()}
          <form onSubmit={this.handleSubmit}>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Card Holder Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="cardHolderName"
                  value={this.state.cardHolderName}
                  onChange={this.handleCardHolderNameChange}
                />
              </div>
            </div>
            {/* <div className="row pb-3">
              <div className="col-12 col-md-6 col-card-angkor pb-3">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <div className="col-12 col-md-6 col-card-angkor">
                <label>Phone</label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handlePhoneChange}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  value={this.state.address}
                  onChange={this.handleAddressChange}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-md-4 col-card-angkor pb-3">
                <label>City</label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                />
              </div>
              <div className="col-8 col-md-4 col-card-angkor">
                <label>Province</label>
                <input
                  className="form-control"
                  type="text"
                  name="province"
                  value={this.state.province}
                  onChange={this.handleProvinceChange}
                />
              </div>
              <div className="col-4 col-md-4 col-card-angkor">
                <label>Postal Code</label>
                <PostalCodeElement
                // onChange={this.handlePostalCodeChange}
                />
              </div>
            </div> */}
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Card Number</label>
                <CardNumberElement />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-5 col-card-angkor">
                <label>Expiry Date</label>
                <CardExpiryElement />
              </div>
              <div className="col-3 col-card-angkor">
                <label>CVC</label>
                <CardCVCElement />
              </div>
              <div className="col-4 col-card-angkor">
                <label>Postal Code</label>
                <PostalCodeElement />
              </div>
            </div>

            {/* <CardElement
            className="form-control"
            {...createOptions(this.props.fontSize)}
          /> */}
            {/* <div className="pt-3">
              <button
                className="btn btn-angkor btn-block"
                onClick={this.submit}
              >
                <strong>
                  Pay {Numeral(this.props.amount).format("$0,0.00")}
                </strong>
              </button>
            </div> */}

            <div className="row">
              <div className="col-12 text-center">
                <button className="btn btn-angkor btn-block" onClick={this.submit}>
                  <span className="d-inline">
                    <strong>
                      {`Pay ${Numeral(this.props.amount).format("$0,0.00")}`}
                    </strong>
                  </span>{" "}
                  {/* <Icon.Check size={24} /> */}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectStripe(PayWithCreditCard);
