import React from "react";
import Numeral from "numeral";
import "./CartItem.css";

const CartItem = ({
  item,
  handleRemoveItem,
  changeable
}) => {

  return (
    <>
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-12">
          <div className="row">
            <div className="col-8 text-left col-cart-angkor">
              <strong>{`${item.quantity}X ${item.name}`}</strong>
              <ul className="list-unstyled ml-3">
                {item.size && item.size.length >0 && (
                      <li className="text-muted font-italic">
                        <small>{item.size}</small>
                      </li>
                    )}
                {item.options.map((option, index) => (
                  <li className="text-muted font-italic" key={index}>
                    <small>
                      {`${option.option_name}`}
                      {option.option_value && <span>{` ${option.option_value}`}</span>}
                      {option.option_total > 0 && (
                        <span>{`: (${Numeral(option.option_total).format(
                          "$0,0.00"
                        )})`}</span>
                      )}
                    </small>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-3 text-right col-cart-angkor">
              <strong>{Numeral(item.baseTotal + item.extraTotal).format("$0,0.00")}</strong>   
            </div>
            {changeable && (
              <div className="col-1 text-left col-cart-angkor">
                <button
                  className="btn btn-danger btn-sm btn-sm-angkor"
                  onClick={() => handleRemoveItem(item)}
                >
                  {`X`}
                </button>
              </div>
            )}
          </div>
          {item.specialInstructions && item.specialInstructions.length > 0 && (
            <div className="row">
              <div className="col-12  col-cart-angkor">
                <small>Special Requests: {item.specialInstructions}</small>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};


export default CartItem;
