import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { register } from "../../actions/auth";
import normalizePhone from "../../utils/normalizePhone";
import {
  renderUserNameField,
  renderEmailField,
  renderPhoneField,
  renderPasswordField,
  required,
  phoneNumber,
  email
} from "../../utils/renderFields";
import queryString from "query-string";

import {sendGAEvent} from "../../utils/analytics";

const form = reduxForm({
  form: "register",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = "Please enter a first name";
  }

  if (!formProps.lastName) {
    errors.lastName = "Please enter a last name";
  }

  if (!formProps.email) {
    errors.email = "Please enter an email";
  }

  if (!formProps.telephone) {
    errors.telephone = "Please enter telephone number";
  }

  if (!formProps.password) {
    errors.password = "Please enter a password";
  }

  return errors;
}

class Register extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      redirectToReferrer: false
    };
  }

  handleFormSubmit(formProps) {
    sendGAEvent({
      category: "Auth",
      action: "Register Form Submit",
      label: `${formProps.firstName} ${formProps.lastName}`
    });
    this.props.register(this.props.tenant._id, formProps, () => {
      this.setState({ redirectToReferrer: true });
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const queryParameters = queryString.parse(this.props.location.search);
    const step = queryParameters.step;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    let from1
    if (queryParameters.from) {
      from1 = `${queryParameters.from}?step=${step}`;
    } else {
      from1 = from;
    }

    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from1} />;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <>
        {/* <div className="row pb-3 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>REGISTER</strong>
            </h3>
          </div>
        </div> */}
        <div className="row h-100 align-items-center pt-3">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header text-center">Register</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>First Name</label>
                    <Field
                      name="firstName"
                      className="form-control"
                      component={renderUserNameField}
                      validate={[required]}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <Field
                      name="lastName"
                      className="form-control"
                      component={renderUserNameField}
                      validate={[required]}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <Field
                      name="telephone"
                      className="form-control"
                      component={renderPhoneField}
                      placeholder="___-___-____"
                      validate={[required, phoneNumber]}
                      type="text"
                      normalize={normalizePhone}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      name="email"
                      className="form-control"
                      component={renderEmailField}
                      validate={[required, email]}
                      type="email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Field
                      name="password"
                      className="form-control"
                      component={renderPasswordField}
                      type="password"
                    />
                  </div>

                  <div className="controls text-center">
                    <button
                      type="submit"
                      disabled={pristine || submitting}
                      className="btn btn-angkor btn-block"
                    >
                      REGISTER
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  register: (tenantId, formProps, callback) => dispatch(register(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(Register));
