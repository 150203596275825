
import React from "react";

const CartNextOrderCoupons = ({ tenantName, discounts = [] }) => {

  const next_order_coupons = discounts.filter(discount => discount.is_next_order_coupon === true);
  return (
    <>
      {next_order_coupons.map((discount, index) => (
        <React.Fragment key={index}>
          <div className="row pt-3" key={index}>
            <div
              className="col-12 text-left col-cart-angkor"
            >
              <div className="bg-info text-white">{`Congratulations! You are qualified for coupon: ${discount.discount_name}! You will receive an E-COUPON code by email once the order is confirmed by ${tenantName}!`}</div>
            </div>

          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default CartNextOrderCoupons;