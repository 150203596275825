import {
  FETCH_RESTAURANTS,
  FETCH_RESTAURANT,
  FETCH_BUSINESS_HOURS,
  FETCH_MENUS,
  FETCH_PROMOTIONS,
  SELECT_RESTAURANT,
  FETCH_TODAY_BUSINESS_HOURS,
  FETCH_ONLINE_ORDER_MENUS,
  SELECT_LOCATION,
  FETCH_GIFTCARDPRODUCTS,
  FETCH_LOYALTY_REWARDS,
  FETCH_TENANT,
  FETCH_PICKUP_DATES,
  FETCH_ANNOUNCEMENTS,
  FETCH_COUPONS,
  FETCH_MENU,
  TENANT_ERROR,
  FETCH_PLASTIC_CUTLERY_ITEMS,
  FETCH_GIFT_CARD_PRODUCT,
  FETCH_MOST_ORDERED_ITEMS,
} from "../actions/types";

import moment from "moment-timezone";

import { getCookie } from "../utils/cookieUtil";

const INITIAL_STATE = {
  message: "",
  error: "",
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SELECT_LOCATION:
      document.cookie = `selectedLocation=${action.payload.restaurant._id}|${action.payload.restaurant.phone}|${action.payload.restaurant.address_1}; SameSite=Lax;`;

      return {
        ...state,
        restaurant: action.payload.restaurant,
        onlineOrderMenus: undefined,
        menus: undefined,
      };
    case SELECT_RESTAURANT:
      document.cookie = `selectedLocation=${action.payload.restaurant._id}|${action.payload.restaurant.phone}|${action.payload.restaurant.address_1}; SameSite=Lax;`;

      return {
        ...state,
        restaurant: action.payload.restaurant,
        onlineOrderMenus: undefined,
        menus: undefined,
      };
    case FETCH_RESTAURANTS:
      return { ...state, restaurants: action.payload.restaurants };
    case FETCH_RESTAURANT:
      return { ...state, restaurant: action.payload.restaurant };
    case FETCH_BUSINESS_HOURS:
      return { ...state, businessHours: action.payload.businessHours };
    case FETCH_TODAY_BUSINESS_HOURS:
      return { ...state, todayBusinessHours: action.payload.businessHours };
    case FETCH_MENUS:
      return {
        ...state,
        menus: action.payload.menus,
        // promotions: action.payload.promotions,
        promotionItems: action.payload.promotion_items,
      };
    case FETCH_MENU:
      return { ...state, menu: action.payload.menu };
    case FETCH_ONLINE_ORDER_MENUS:
      return {
        ...state,
        onlineOrderMenus: action.payload.menus,
        // promotions: action.payload.promotions,
        promotionItems: action.payload.promotion_items,
      };
    case FETCH_PLASTIC_CUTLERY_ITEMS:
      return {
        ...state,
        plasticCutleryItems: action.payload.plasticCutleryItems,
      };
    case FETCH_PROMOTIONS:
      return { ...state, promotions: action.payload.promotions };
    case FETCH_GIFTCARDPRODUCTS:
      const time_zone = state.restaurant?.settings?.time_zone ?? state.tenant.settings.time_zone;
      const now = moment().tz(time_zone);
      const validGiftCardProducts = action.payload.giftCardProducts.filter(
        (giftCardProduct) =>
          (!giftCardProduct.validFrom ||
            (giftCardProduct.validFrom &&
              moment(giftCardProduct.validFrom)
                .tz(time_zone)
                .isBefore(now))) &&
          (!giftCardProduct.validUntil ||
            (giftCardProduct.validUntil &&
              moment(giftCardProduct.validUntil)
                .tz(time_zone)
                .isAfter(now)))
      );

      return { ...state, giftCardProducts: validGiftCardProducts };
    case FETCH_GIFT_CARD_PRODUCT:
      return { ...state, giftCardProduct: action.payload.giftCardProduct };
    case FETCH_LOYALTY_REWARDS:
      return { ...state, loyaltyrewards: action.payload.loyaltyrewards };
    case FETCH_PICKUP_DATES:
      return { ...state, pickupDates: action.payload.pickupDates };
    case FETCH_TENANT:
      let selectedLocationCookie = getCookie("selectedLocation");
      if (
        action.payload.tenant.restaurants.length === 1 &&
        !selectedLocationCookie
      ) {
        document.cookie = `selectedLocation=${action.payload.tenant.restaurants[0]._id}|${action.payload.tenant.restaurants[0].phone}${action.payload.tenant.restaurants[0].address_1}; SameSite=Lax;`;
        selectedLocationCookie = document.getCookie("selectedLocation");
      }
      const selectedRestaurantId = selectedLocationCookie?.split("|")[0];
      const selectedRestaurant = selectedRestaurantId
        ? action.payload.tenant.restaurants.filter(
            (restaurant) => restaurant._id === selectedRestaurantId
          )[0]
        : undefined;
      return {
        ...state,
        tenant: action.payload.tenant,
        restaurants: action.payload.tenant.restaurants,
        restaurant: selectedRestaurant,
        announcements: action.payload.announcements,
        promotions: action.payload.promotions,
      };
    case FETCH_ANNOUNCEMENTS:
      return { ...state, announcements: action.payload.announcements };
    case FETCH_COUPONS:
      return { ...state, coupons: action.payload.coupons };
    case FETCH_MOST_ORDERED_ITEMS:
      return { ...state, mostOrderedItems: action.payload.mostOrderedItems };
    case TENANT_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
