import React from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';
import moment from 'moment-timezone';

const DeliveryTime = ({ deliveryDate, deliveryTime, onlineOrderLeadTime, restaurant, pickupTimeRange }) => {

  return (
    <div>
      <h4>
        <strong>{moment(deliveryTime).tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)} - { moment(deliveryTime).add(pickupTimeRange, 'minutes').tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)} </strong>
      </h4>
      <p>{moment(deliveryDate).tz(restaurant.settings.time_zone).format(restaurant.settings.date_format)}</p>
    </div>
  );
};

DeliveryTime.propTypes = {
  deliveryDate: PropTypes.instanceOf(Moment),
  deliveryTime: PropTypes.instanceOf(Moment)
};

export default DeliveryTime;
