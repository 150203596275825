import React from "react";
import PropTypes from "prop-types";

import Moment from "moment";
import moment from "moment-timezone";

const PickupTime = ({
  pickupTimeType,
  pickupDate,
  pickupTime,
  onlineOrderLeadTime,
  restaurant,
  pickupTimeRange
}) => {
  return (
    <div>
      {pickupTimeType === "Schedule" && (
        <>
          <h4>
            <strong>
              {moment(pickupTime)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.time_format)}{" "}
              -{" "}
              {moment(pickupTime)
                .add(pickupTimeRange, "minutes")
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.time_format)}{" "}
            </strong>
          </h4>
          <p>
            {moment(pickupDate)
              .tz(restaurant.settings.time_zone)
              .format(restaurant.settings.date_format)}
          </p>
        </>
      )}
      {pickupTimeType === "ASAP" && (
        <span>
          {`${onlineOrderLeadTime} minutes after order placed`}{" "}
        </span>
      )}
    </div>
  );
};

PickupTime.propTypes = {
  pickupDate: PropTypes.instanceOf(Moment),
  pickupTime: PropTypes.instanceOf(Moment),
};

export default PickupTime;
