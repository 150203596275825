import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Cookies from "universal-cookie";
import moment from "moment-timezone";

import normalizePhone from "../../utils/normalizePhone";
import {
  renderField,
  renderEmailField,
  renderPhoneField,
} from "../../utils/renderFields";

import {
  fetchRestaurants,
} from "../../actions/tenant";
import { saveReservation } from "../../actions/reservation";

const form = reduxForm({
  form: "reservationForm",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.restaurant) {
    errors.restaurant = "Please select restaurant";
  }

  if (!formProps.reservedDate) {
    errors.reservedDate = "Please enter date";
  }

  if (!formProps.reservedTime) {
    errors.reservedTime = "Please enter time";
  }

  if (!formProps.partySize) {
    errors.partySize = "Please enter party size";
  }

  if (!formProps.nameReservedUnder) {
    errors.nameReservedUnder = "Please enter name";
  }

  if (!formProps.email) {
    errors.email = "Please enter email address";
  }

  if (!formProps.phone) {
    errors.phone = "Please enter phone number";
  }

  return errors;
}

const cookies = new Cookies();

class MakeReservation extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleGotIt = this.handleGotIt.bind(this);
    this.generatePartySizeOptions = this.generatePartySizeOptions.bind(this);
    this.generateReservationDates = this.generateReservationDates.bind(this);
    this.generateReservationTimes = this.generateReservationTimes.bind(this);

    this.state = {};
  }

  componentDidMount() {
    
  }

  handleFormSubmit(formProps) {
    this.props.saveReservation(formProps, data => {
      this.setState({ reservation: data.reservation });
    });
  }

  generatePartySizeOptions(maxPartySize) {
    let options = [];
    for (var i = 1; i <= maxPartySize; i++) {
      options.push({ partySize: i, description: i + " People" });
    }

    return options;
  }

  generateReservationDates(businessHours, futureReservationDays) {
    let dates = [];
    let i = 0;
    let j = 0;

    if (businessHours.length > 0) {
      while (i <= futureReservationDays) {
        let reservationDate = moment().add(j, "days");

        let businessHoursOnReservedDay = businessHours.filter(
          businessHour => businessHour.day_of_week === reservationDate.day()
        );

        if (
          businessHoursOnReservedDay.length > 0 &&
          businessHoursOnReservedDay[0].is_open === true
        ) {
          dates.push(reservationDate);
          i++;
        }
        j++;
      }
    }

    return dates;
  }

  generateReservationTimes(
    businessHourStart,
    onlineReservationStart,
    businessHourEnd,
    onlineReservationEnd,
    intervalInMinutes
  ) {

    let times = [];
    let startAt_1 = moment(businessHourStart).add(
      onlineReservationStart,
      "minutes"
    );

    let endAt = moment(businessHourEnd).subtract(
      onlineReservationEnd,
      "minutes"
    );
    let reservationTime = moment(startAt_1);
    while (reservationTime.isBefore(endAt)) {
      times.push(reservationTime);
      reservationTime = moment(reservationTime).add(
        intervalInMinutes,
        "minutes"
      );
    }

    return times;
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  handleGotIt() {
    this.setState({ reservation: undefined, redirect: true });
  }

  render() {
    const {
      restaurants = [],
      businessHours = [],
      selectedRestaurantId,
      selectedReservedDate,
      handleSubmit,
      pristine,
      submitting
    } = this.props;

    let maxPartySize = 0;
    let futureReservationDays = 0;
    let intervalInMinutes = 30;
    let restaurantBusinessHours = [];
    let onlineReservationStart = 0;
    let onlineReservationEnd = 0;
    let businessHourStart;
    let businessHourEnd;
    let dateFormat;
    let timeFormat;
    if (restaurants.length > 0 && selectedRestaurantId !== undefined) {
      dateFormat = restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.date_format;
      timeFormat = restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.time_format;      
      maxPartySize = restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.max_party_size;
      futureReservationDays = restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.future_reservation_days;
      onlineReservationStart =  restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.online_reservation_start;
      onlineReservationEnd =  restaurants.filter(
        restaurant => restaurant._id === selectedRestaurantId
      )[0].settings.online_reservation_end;
      restaurantBusinessHours = businessHours.filter(
        businessHour => businessHour.restaurant === selectedRestaurantId
      );
      if (selectedReservedDate !== undefined) {
        let selectedReservedDay = moment(selectedReservedDate).day();

        let restaurantBusinessHours1 = businessHours.filter(
          businessHour =>
            businessHour.restaurant === selectedRestaurantId &&
            businessHour.day_of_week === selectedReservedDay
        );
        if (restaurantBusinessHours1.length > 0) {
          businessHourStart = moment(
            restaurantBusinessHours1[0].open_time,
            "hh:mm A"
          );
          businessHourEnd = moment(
            restaurantBusinessHours1[0].close_time,
            "hh:mm A"
          );
        }
      }
    }

    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.reservation) {
      return (
        <div className="h-100 row align-items-center">
          <div className="col">
            <div className="card border-success text-center mx-auto">
              <div className="card-body">
                <h3 className="card-title">
                  Thank you {this.state.reservation.nameReservedUnder}
                </h3>
                <h5 className="card-text">{`We will see you soon`}</h5>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {this.state.reservation.reservationNumber}
                        </p>
                        <p className="card-text">{`Reservation Number`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {moment(this.state.reservation.reservedDate).tz(this.state.reservation.restaurant.settings.time_zone).format(
                            this.state.reservation.restaurant.settings.date_format
                          )}
                          {` `}
                          {moment(this.state.reservation.reservedTime).tz(this.state.reservation.restaurant.settings.time_zone).format(
                            this.state.reservation.restaurant.settings.time_format
                          )}
                        </p>
                        <p className="card-text">{`Reservation Date Time`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={this.handleGotIt}
                >
                  GOT IT
                </button>
                <p className="card-text">
                  Reservation confirmation was sent to{" "}
                  {this.state.reservation.email}
                </p>
                <p className="card-text">
                  {`Your reservation was submitted at ${moment(
                    this.state.reservation.updatedAt
                  ).tz(this.state.reservation.restaurant.settings.time_zone).format(
                    this.state.reservation.restaurant.settings.datetime_format
                  )}}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {/* <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>RESERVE A TABLE</strong>
            </h3>
          </div>
        </div> */}
        <section className="text-center">
          <h2>Reserve A Table</h2>
          <hr />
        </section>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
              {this.renderAlert()}
              <div className="form-group">
                <label>Restaurant</label>
                <Field
                  name="restaurant"
                  className="form-control"
                  component="select"
                >
                  <option />
                  {restaurants.map(restaurant => (
                    <option key={restaurant._id} value={restaurant._id}>
                      {restaurant.name}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-sm-6">
                  <label>Date</label>
                  <Field
                    name="reservedDate"
                    className="form-control"
                    component="select"
                  >
                    <option />
                    {this.generateReservationDates(
                      restaurantBusinessHours,
                      futureReservationDays
                    ).map((reservationDate, index) => (
                      <option key={reservationDate} value={moment(reservationDate).format(dateFormat)}>
                        {moment(reservationDate).format(dateFormat)}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <label>Time</label>
                  <Field
                    name="reservedTime"
                    className="form-control"
                    component="select"
                  >
                    <option />
                    {this.generateReservationTimes(
                      businessHourStart,
                      onlineReservationStart,
                      businessHourEnd,
                      onlineReservationEnd,
                      intervalInMinutes
                    ).map((reservationTime, index) => (
                      <option key={moment(reservationTime)} value={moment(reservationTime)}>
                        {moment(reservationTime).format(timeFormat)}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-sm-6">
                  <label>Party Size</label>
                  <Field
                    name="partySize"
                    className="form-control"
                    component="select"
                  >
                    <option />
                    {this.generatePartySizeOptions(maxPartySize).map(
                      (partySizeOption, index) => (
                        <option key={index} value={partySizeOption.partySize}>
                          {partySizeOption.description}
                        </option>
                      )
                    )}
                  </Field>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <label>Name Reservered Under</label>
                  <Field
                    name="nameReservedUnder"
                    className="form-control"
                    component={renderField}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group  col-12 col-sm-6">
                  <label>Phone</label>
                  <Field
                    name="telephone"
                    className="form-control"
                    component={renderPhoneField}
                    type="text"
                    normalize={normalizePhone}
                  />
                </div>
                <div className="form-group  col-12 col-sm-6">
                  <label>Email</label>
                  <Field
                    name="email"
                    className="form-control"
                    component={renderEmailField}
                    type="email"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Note</label>
                <Field
                  name="note"
                  className="form-control"
                  component="textarea"
                  placeholder="Maximum 255 characters (no HTML allowed)"
                />
              </div>

              <div className="controls text-center pb-3">
                <button
                  type="submit"
                  disabled={pristine || submitting}
                  className="btn btn-angkor btn-block"
                >
                  {`BOOK NOW`}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const customer = cookies.get("customer");
  let data = {};
  if (customer) {
    data = {
      nameReservedUnder:
        customer.local.firstName + " " + customer.local.lastName,
      email: customer.local.email,
      telephone: customer.local.telephone
    };
  }

  let _businessHours = [];
  state.tenant.restaurants.forEach(restaurant => {
    _businessHours.push({restaurant: restaurant.id, day_of_week: 0, is_open: restaurant.regular_business_hours.sunday.is_open, open_time: restaurant.regular_business_hours.sunday.open_time, close_time: restaurant.regular_business_hours.sunday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 1, is_open: restaurant.regular_business_hours.monday.is_open, open_time: restaurant.regular_business_hours.monday.open_time, close_time: restaurant.regular_business_hours.monday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 2, is_open: restaurant.regular_business_hours.tuesday.is_open, open_time: restaurant.regular_business_hours.tuesday.open_time, close_time: restaurant.regular_business_hours.tuesday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 3, is_open: restaurant.regular_business_hours.wednesday.is_open, open_time: restaurant.regular_business_hours.wednesday.open_time, close_time: restaurant.regular_business_hours.wednesday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 4, is_open: restaurant.regular_business_hours.thursday.is_open, open_time: restaurant.regular_business_hours.thursday.open_time, close_time: restaurant.regular_business_hours.thursday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 5, is_open: restaurant.regular_business_hours.friday.is_open, open_time: restaurant.regular_business_hours.friday.open_time, close_time: restaurant.regular_business_hours.friday.close_time});
    _businessHours.push({restaurant: restaurant.id, day_of_week: 6, is_open: restaurant.regular_business_hours.saturday.is_open, open_time: restaurant.regular_business_hours.saturday.open_time, close_time: restaurant.regular_business_hours.saturday.close_time});
  })
  return {
    restaurants: (state.tenant.restaurants || []).filter(
      restaurant => restaurant.settings.online_reservation === true
    ),
    businessHours: _businessHours,
    errorMessage: state.reservation.error,
    message: state.reservation.message,
    initialValues: data,
    selectedRestaurantId: selector(state, "restaurant"),
    selectedReservedDate: selector(state, "reservedDate")
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRestaurants: (tenantId) => dispatch(fetchRestaurants(tenantId)),
  saveReservation: (formProps, callback) =>
    dispatch(saveReservation(formProps, callback))
});

MakeReservation = form(MakeReservation);

const selector = formValueSelector("reservationForm");

MakeReservation = connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeReservation);

export default MakeReservation;

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(form(MakeReservation));
