import React, { Component } from "react";
import { connect } from "react-redux";

import GiftCard from "./GiftCard";

import { fetchGiftCardProducts } from "../../actions/tenant";

class GiftCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchGiftCardProducts(this.props.tenant._id);
    }
  }

  render() {
    const { giftCardProducts = [], tenant } = this.props;
    return (
      <>
        <section className="text-center">
          <h2>Gift Cards</h2>
          <hr />
        </section>

        <div className="row">
          {giftCardProducts.map((giftCardProduct) => (
            <div className="col-12 col-sm-6 col-lg-4 pb-3" key={giftCardProduct._id}>
              <GiftCard
                key={giftCardProduct._id}
                slug={tenant.slug}
                giftCardProduct={giftCardProduct}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    giftCardProducts: state.tenant.giftCardProducts || [],
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCardProducts: (tenantId) =>
    dispatch(fetchGiftCardProducts(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
