import React, { Component } from "react";
import { connect } from "react-redux";

class Announcements extends Component {
  componentDidMount() {}

  render() {
    const {
      announcements = [],
      tenant,
      showPicture = true,
      showDefaultPicture = true
    } = this.props;

    return (
      <>
        <section className="text-center">
          <h2>
            <i className="fas fa-bullhorn fa-2x mr-3" />{" "}
          </h2>
          <hr />
        </section>
        {announcements
          .filter(announcement => announcement.disabled === false)
          .map(announcement => (
            <div className="row pb-3 mb-3 border-bottom" key={announcement._id}>
              {showPicture && (
                <div className="col-12 col-md-4">
                  {announcement.image && announcement.image.url && (
                    <img
                      className="card-img-top"
                      src={process.env.REACT_APP_S3_BASE_URL + tenant.slug + "/" + announcement.image.url}
                      alt="Announcement"
                    />
                  )}
                  {!announcement.image && showDefaultPicture && (
                    <img
                      className="card-img-top"
                      src={`https://via.placeholder.com/250x150.png?text=${
                        announcement.title
                      }`}
                      alt="Announcement"
                    />
                  )}
                </div>
              )}
              {showPicture && (
                <div className="col-12 col-md-8 text-center text-md-left">
                  <h5>{announcement.title}</h5>
                  <p>{announcement.short_description}</p>
                  <p>{announcement.description}</p>
                </div>
              )}
              {!showPicture && (
                <div className="col-12 text-center">
                  <h5>{announcement.title}</h5>
                  <p>{announcement.short_description}</p>
                  <p>{announcement.description}</p>
                </div>
              )}
            </div>
          ))}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    announcements: state.tenant.announcements || [],
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcements);
