import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import "./Header.css";

import { getQuantity } from "../../actions/cart";
import * as Icon from "react-feather";

import io from 'socket.io-client';

// Connect to socket.io server
export const socket = io.connect(
  process.env.REACT_APP_API_BASE
);

class Header extends Component {


  componentDidMount() { }

  render() {
    const { tenant, quantity, authenticated, restaurant } = this.props;
    const cookies = new Cookies();
    const user = cookies.get("customer");
    let userName = user ? user.local.firstName + " " + user.local.lastName : "";
    if (!tenant) {
      return <></>;
    }
    const showOnlineOrdering = tenant.settings.online_ordering === true;
    const showOnlineReservation = tenant.settings.online_reservation === true;

    const showGiftcard = tenant.settings.gift_card === true;

    const showMembership = tenant.settings.membership === true;
    return (
      <header className="main-header container fixed-top text-center">
        <nav className="navbar navbar-angkor navbar-expand ">
          <a className="navbar-brand" href="/">
            {tenant && tenant.logo && tenant.logo.url && (
              <img
                className="img-fluid"
                // src={process.env.REACT_APP_API_BASE + `/api/eater/images?imageUrl=${encodeURIComponent(tenant.slug + '/logo/' + tenant.logo.url)}`}
                src={process.env.REACT_APP_S3_BASE_URL + `${encodeURIComponent(tenant.slug + '/logo/' + tenant.logo.url)}`}
                alt="Logo"
              />
            )}
          </a>
          <div className="collapse navbar-collapse flex-column">
            {restaurant && (
              <>
              <span className="d-none d-md-inline navbar-text navbar-text-angkor ml-auto">
                <Icon.MapPin size={16} /> {` `} {restaurant.address_1}
                {`, `} {restaurant.city_town} {` `}
                <Icon.Phone size={16} /> {` `} {restaurant.phone}
              </span>
              <span className="d-inline d-md-none navbar-text navbar-text-angkor ml-auto">
                <small>
                  <Icon.Phone size={16} /> {` `} {restaurant.phone}
                </small>
              </span>
              </>
            )}
            {!restaurant && (
              <span className="d-inline navbar-text navbar-text-angkor ml-auto">
                <small>{`No Location Chosen`}</small>
              </span>
            )}

            <ul className="navbar-nav ml-auto">
              {showOnlineOrdering && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/orderonline">
                    {/* <Icon.PlusCircle className="d-inline d-md-none" size={24} /> */}
                    <i className="fas fa-utensils fa-lg d-inline mr-1" />
                    <span className="d-none d-md-inline">
                      <strong>Order Online</strong>
                    </span>
                  </NavLink>
                </li>
              )}
              {showOnlineReservation && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/reservation">
                    {/* <Icon.Clock className="d-inline d-md-none" size={24} /> */}
                    <i className="fas fa-th-large fa-lg d-inline mr-1" />
                    <span className="d-none d-md-inline">
                      <strong>Reserve A Table</strong>
                    </span>
                  </NavLink>
                </li>
              )}

              {showMembership && !authenticated && (
                <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/register">
                    {/* <Icon.UserPlus className="d-inline d-md-none" size={24} /> */}
                    <i className="fas fa-user-plus fa-lg d-inline d-md-none" />
                    <span className="d-none d-md-inline">
                      <strong>Register</strong>
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    {/* <Icon.LogIn className="d-inline d-md-none" size={24} /> */}
                    <i className="fas fa-sign-in-alt fa-lg d-inline d-md-none" />
                    <span className="d-none d-md-inline">
                      <strong>Login</strong>
                    </span>
                  </NavLink>
                </li>
                </>
              )}

              {showMembership && authenticated && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    id="userDropdown"
                    href="/"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <Icon.User size={24} /> */}
                    <i className="fas fa-user fa-lg" />
                    {authenticated && (
                      <span className="d-none d-md-inline">
                        {` `} {userName}
                      </span>
                    )}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="userDropdown">
                    <>
                    <NavLink
                      className="dropdown-item"
                      to="/profile"
                    >{`My Profile`}</NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/payment-methods"
                    >{`Payment Methods`}</NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/orders"
                    >{`Order History`}</NavLink>

                    <div className="dropdown-divider" />
                    <NavLink
                      className="dropdown-item"
                      to="/logout"
                    >{`Logout`}</NavLink>
                    </>
                  </div>
                </li>
              )}
              {showOnlineOrdering && (
                <li className="nav-item" key={3}>
                  <NavLink
                    id="shopping-cart-angkor"
                    className="nav-link"
                    to="/cart"
                  >
                    <span className="shopping-cart-badge" data-count={quantity}>
                      {/* <Icon.ShoppingCart size={24} /> */}
                      <i className="fas fa-shopping-cart fa-lg" />
                    </span>
                  </NavLink>
                </li>
              )}
            </ul>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/home">
                  <Icon.Home className="d-inline d-md-none" size={16} />
                  <span className="d-none d-md-inline">Home</span>
                </NavLink>
              </li>
              {tenant.restaurants.length > 1 &&
                < li className="nav-item">
                  <NavLink className="nav-link" to="/locations">
                    <Icon.MapPin className="d-inline d-md-none" size={16} />
                    <span className="d-none d-md-inline">Locations</span>
                  </NavLink>
                </li>}

              <li className="nav-item">
                <NavLink className="nav-link" to="/menus">
                  <Icon.Menu className="d-inline d-md-none" size={16} />
                  <span className="d-none d-md-inline">Menus</span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/promotions">
                  <Icon.Percent className="d-inline d-md-none" size={16} />
                  <span className="d-none d-md-inline">Promotions</span>
                </NavLink>
              </li> */}
              {showGiftcard && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/giftcards">
                    <Icon.CreditCard className="d-inline d-md-none" size={16} />
                    <span className="d-none d-md-inline">Gift Cards</span>
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery">
                  <i className="far fa-images d-inline d-md-none" />
                  <span className="d-none d-md-inline">Gallery</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  <Icon.MessageSquare
                    className="d-inline d-md-none"
                    size={16}
                  />
                  <span className="d-none d-md-inline">Contact</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: ownProps.tenant,
    authenticated: state.auth.authenticated,
    restaurant: state.tenant.restaurant,
    // restaurants: state.tenant.restaurants,
    businesshour:
      state.tenant.todayBusinessHours &&
        state.tenant.todayBusinessHours.length > 0
        ? state.tenant.todayBusinessHours[0]
        : {},
    quantity: getQuantity(state)
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
