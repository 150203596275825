import React, { Component } from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { fetchUser } from "../../actions/user";
import * as Icon from "react-feather";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { sendGAEvent } from "../../utils/analytics";

const cookies = new Cookies();

class Profile extends Component {
  constructor(props) {
    super(props);

    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handlePaymentMethods = this.handlePaymentMethods.bind(this);

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleOrderHistory = this.handleOrderHistory.bind(this);
    this.handleCoupons = this.handleCoupons.bind(this);
    this.handleGiftCards = this.handleGiftCards.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    // Fetch user data prior to component mounting
    const userId = cookies.get("customer")._id.toString();
    this.props.fetchUser(userId);
  }

  handleUpdateProfile() {
    sendGAEvent({
      category: "User",
      action: "Click Update Profile",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/update-profile" });
  }

  handlePaymentMethods() {
    sendGAEvent({
      category: "User",
      action: "Click Payment Methods",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/payment-methods" });
  }

  handleChangePassword() {
    sendGAEvent({
      category: "User",
      action: "Click Change Password",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/change-password" });
  }

  handleOrderHistory() {
    sendGAEvent({
      category: "User",
      action: "Click Order History",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/orders" });
  }

  handleCoupons() {
    sendGAEvent({
      category: "User",
      action: "Click Coupons",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/coupons" });
  }

  handleGiftCards() {
    sendGAEvent({
      category: "User",
      action: "Click Gift Cards",
      label: ``,
    });
    this.setState({ redirectTo: "/profile/giftcards" });
  }

  render() {
    const { user } = this.props;
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (!user) {
      return <>Nouser</>;
    } else {
      return (
        <>
          <nav aria-label="breadcrumb" className="pt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <button type="button" className="btn btn-light" disabled>
                  Profile
                </button>
              </li>
            </ol>
          </nav>
          <div className="row pt-3 pb-6 mb-3 border-bottom">
            <div className="col-8">
              <h3>
                <strong>MY PROFILE</strong>
              </h3>
            </div>
            <div className="col-4 text-right">
              <NavLink className="dropdown-item" to="/logout">
                <strong>{`Logout`}</strong>
              </NavLink>
            </div>
          </div>
          <h4 className="mb-3">
            <Icon.User size={24} />
            {` `}{" "}
            {user.local.email
              ? user.local.firstName + " " + user.local.lastName
              : ""}
          </h4>
          <div className="row">
            <div className="col-12 col-md-4 text-center mb-3">
              <img src={user.qrcode} className="img-fluid" alt={`QRCode`} />
              <div>
                <strong>
                  {`Points`}:{" "}
                  {user.pointBalance && <span>{user.pointBalance}</span>}
                  {!user.pointBalance && <span>0</span>}
                </strong>
              </div>
            </div>

            <div className="col-12 col-md-8 mb-3">
              <div className="jumbotron">
                {user.local.email && (
                  <>
                    <div className="row">
                      <div className="col-4">
                        <strong>{`Full Name`}</strong>
                      </div>
                      <div className="col-8">
                        <span>
                          {user.local.firstName} {` `} {user.local.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <strong>{`Phone`}</strong>
                      </div>
                      <div className="col-8">{user.local.telephone}</div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <strong>{`Email`}</strong>
                      </div>
                      <div className="col-8">{user.local.email}</div>
                    </div>
                    {user.address && (
                      <div className="row">
                        <div className="col-4">
                          <strong>{`Address`}</strong>
                        </div>
                        <div className="col-8">
                          <span>{user.address.address_1}</span>
                          <br />
                          <span>
                            {user.address.city_town},{" "}
                            {user.address.state_province}{" "}
                            {user.address.postal_code}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleUpdateProfile}
                    >
                      <Icon.Edit3 size={16} />
                      {` `}Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-3">
            {/* <div className="col-12 col-sm-6 col-xl-3 mb-3">
              <div
                className="card clickable-card"
                onClick={this.handleChangePassword}
              >
                <div className="card-body text-center">
                  <i className="fa fa-lock fa-3x" />
                  <h5 className="card-title">{`Login & PIN`}</h5>
                  <p className="card-text">Password and 2-step verification</p>
                </div>
              </div>
            </div> */}
            <div className="col-12 col-sm-6 col-xl-3 mb-3">
              <div
                className="card clickable-card"
                onClick={this.handlePaymentMethods}
              >
                <div className="card-body text-center">
                  <i className="fa fa-credit-card fa-3x" />
                  <h5 className="card-title">{`Payment Methods`}</h5>
                  <p className="card-text">Credit cards and Paypal accounts</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 mb-3">
              <div
                className="card clickable-card"
                onClick={this.handleOrderHistory}
              >
                <div className="card-body text-center">
                  <i className="fa fa-file fa-3x" />
                  <h5 className="card-title">{`Order History`}</h5>
                  <p className="card-text">Past and pending orders</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 mb-3">
              <div className="card clickable-card" onClick={this.handleCoupons}>
                <div className="card-body text-center">
                  <i className="fa fa-percent fa-3x" />
                  <h5 className="card-title">{`Coupons`}</h5>
                  <p className="card-text">Unredeemed coupons</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 mb-3">
              <div
                className="card clickable-card"
                onClick={this.handleGiftCards}
              >
                <div className="card-body text-center">
                  <i className="fa fa-gift fa-3x" />
                  <h5 className="card-title">{`Gift Cards`}</h5>
                  <p className="card-text">Unredeemed gift cards</p>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-sm-6 col-xl-3 mb-3">
            <div className="card clickable-card">
              <div className="card-body text-center">
                <i className="fa fa-cog fa-3x" />
                <h5 className="card-title">{`Contact Preferences`}</h5>
                <p className="card-text">How you'd like to hear from us</p>
              </div>
            </div>
          </div> */}
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(fetchUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
