import axios from "axios";

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_PROMOTION_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CLEAR_CART,
  VALIDATE_AND_APPLY_PROMOTIONS,
  APPLY_COUPON,
  CART_ERROR,
  REMOVE_COUPON_FROM_CART,
  REDEEM_GIFT_CARD,
  PREAUTH_GIFT_CARD,
  REMOVE_GIFT_CARD_FROM_CART,
  FETCH_GIFT_CARD_BY_GIFT_CARD_NUMBER
} from './types';

import { couponErrorHandler, getData, patchData, deleteData } from './index';

import Numeral from "numeral";
import moment from "moment-timezone";

const uuidv4 = require('uuid/v4');

export function addToCart(
  id,
  cd,
  name,
  printName,
  size,
  options,
  quantity,
  basePrice,
  baseTotal,
  extraPrice,
  extraTotal,
  specialInstructions,
  duration,
  isCouponEnabled,
  promotions, 
  timeZone, 
  timeFormat, 
  deliveryMethod,
  pickupTime,
  pickupDate,
  deliveryDistance,
  postalCode,
  deliveryFee,
  deliveryGST,
  callback
) {
  const data = {
    id,
    cd,
    name,
    printName,
    size,
    options,
    quantity,
    basePrice,
    baseTotal,
    extraPrice,
    extraTotal,
    specialInstructions,
    duration,
    uuid: uuidv4()
  };
  const promoData = {
    isCouponEnabled,
    promotionRules: promotions, 
    timezone: timeZone, 
    timeFormat, 
    deliveryMethod,
    pickupTime,
    pickupDate,
    deliveryDistance,
    postalCode,
    deliveryFee,
    deliveryGST,
  };

  callback();
  return {
    type: ADD_TO_CART,
    payload: {data, promoData}
  };
}

export function removeFromCart(item, promoData) {
  return {
    type: REMOVE_FROM_CART,
    payload: { uuid: item.uuid, promoData }
  };
}

export function removePromotionFromCart(promo, promoData) {
  return {
    type: REMOVE_PROMOTION_FROM_CART,
    payload: { uuid: promo.uuid, promoData }
  };
}

export function increaseQuantity(item, promoData) {
  return {
    type: INCREASE_QUANTITY,
    payload: {item, promoData}
  };
}

export function decreaseQuantity(item, promoData) {
  return {
    type: DECREASE_QUANTITY,
    payload: {item, promoData}
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
    payload: {}
  };
}


export function getSubtotal(state) {
  const subtotal1 = state.cart.items.reduce((acc, item) => {
    return acc + item.baseTotal + item.extraTotal;
  }, 0);

  const subtotal2 = state.cart.promotions.reduce((acc, promo) => {
    return acc + promo.amount;
  }, 0);

  return Numeral(Numeral(subtotal1 + subtotal2).format("$0,0.00")).value();
}

export function getDeliveryFee(state) {
  let deliveryFee = 0;
  if (state.order.deliveryMethod === "Delivery") {
    const flat_delivery_fee = state.tenant.restaurant.settings.delivery_options.delivery_fee;
    const delivery_fee_ranges = state.tenant.restaurant.settings.delivery_options.delivery_fees;
    const delivery_distance = state.order.deliveryAddress.distanceInMeter / 1000;
    if (delivery_fee_ranges && delivery_fee_ranges.length > 0) {
      for (const dfr of delivery_fee_ranges) {
        if (delivery_distance > dfr.min_radius && delivery_distance <= dfr.max_radius) {
          deliveryFee = dfr.fee;
          break;
        }
      }
    } else if (flat_delivery_fee) {
      deliveryFee = flat_delivery_fee;
    } 
  } 
  return Numeral(Numeral(deliveryFee).format("$0,0.00")).value();
}

export function getDeliveryGST(state, gstRate) {
  const deliveryGST = (getDeliveryFee(state)) * (gstRate / 100);
  return Numeral(Numeral(deliveryGST).format("$0,0.00")).value();
}

export function getTax(state, gstRate, pstRate) {
  const tax = getGST(state, gstRate) + getPST(state, pstRate);
  return Numeral(Numeral(tax).format("$0,0.00")).value();
}

export function getGST(state, gstRate) {
  const gst = getSubtotal(state) * (gstRate / 100);
  return Numeral(Numeral(gst).format("$0,0.00")).value();
}

export function getPST(state, pstRate) {
  const pst = getSubtotal(state) * (pstRate / 100);
  return Numeral(Numeral(pst).format("$0,0.00")).value();
}

export function getTotal(state, gstRate, pstRate) {

  const subtotal2 = (state.cart.discounts || []).filter(discount => discount.is_next_order_coupon === false).reduce((acc, discount) => {
    return acc + discount.discount_amount;
  }, 0);

  const subtotal3 = (state.cart.coupons || []).reduce((acc, coupon) => {
    return acc + coupon.amount;
  }, 0);

  const amountToRedeem = (state.cart.giftCardRedemptions || []).reduce((acc, redemption) => {
    return acc + redemption.amountToRedeem
  }, 0);

  const total = getSubtotal(state) + getGST(state, gstRate) + getPST(state, pstRate) + getDeliveryFee(state) + getDeliveryGST(state, gstRate) - subtotal2 - subtotal3 - amountToRedeem;
  return Numeral(Numeral(total).format("$0,0.00")).value();
}

export function getQuantity(state) {
  return (state.cart.items ? state.cart.items.length : 0) + (state.cart.promotions ? state.cart.promotions.length : 0)
}

export function applyPromotions(promotions, restaurantTimezone, timeFormat, deliveryMethod, couponAmount) {

  return {
    type: VALIDATE_AND_APPLY_PROMOTIONS,
    payload: { promotionRules: promotions, timezone: restaurantTimezone, timeFormat: timeFormat, deliveryMethod: deliveryMethod, pickupTime: moment(), pickupDate: moment(), couponAmount }
  };
}

export function applyCoupon(restaurantId, data, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/apply-coupon`;

  return function (dispatch) {
    axios
      .post(url, {
        couponCode: data.couponCode,
        email: data.email,
        subtotal: data.subtotal,
        deliveryMethod: data.deliveryMethod,
        deliveryPostalCode: data.deliveryPostalCode,
      })
      .then((response) => {
        callback(response.data.coupon);
        dispatch({
          type: APPLY_COUPON,
          payload: {
            promotionRules: data.promotionRules,
            timezone: data.timeZone,
            timeFormat: data.timeFormat,
            deliveryMethod: data.deliveryMethod,
            deliveryPostalCode: data.deliveryPostalCode,
            pickupTime: data.pickupTime,
            pickupDate: data.pickupDate,
            couponAmount: response.data.coupon.amount,
            coupon: response.data.coupon,
            email: data.email,
            deliveryDistance: data.deliveryDistance,
            postalCode: data.deliveryPostalCode,
            deliveryFee: data.deliveryFee,
            deliveryGST: data.deliveryGST,
          }
        });
      })
      .catch((error) => {
        console.log(error)
        couponErrorHandler(dispatch, error.response, CART_ERROR);
      });
  }
}

export function removeCouponFromCart(coupon) {
  return {
    type: REMOVE_COUPON_FROM_CART,
    payload: { couponCode: coupon.couponCode }
  };
}

export function removeGiftCardRedemption(tenantId, giftCardRedemptionId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/giftcardredemptions/${giftCardRedemptionId}`;

  return (dispatch) => deleteData(REMOVE_GIFT_CARD_FROM_CART, CART_ERROR, false, url, dispatch);

}

export function redeemGiftCard(giftCard, amountToRedeem, callback) {
  callback();
  return {
    type: REDEEM_GIFT_CARD,
    payload: { 
      giftCard: giftCard,
      amountToRedeem: amountToRedeem
     }
  };
}

export function preauthGiftCard(tenantId, giftCardNumber, amountToRedeem, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/tenants/${tenantId}/giftcards/${giftCardNumber}/preauth`;
  const data = {
    amountToRedeem: amountToRedeem
  };

  return (dispatch) => patchData(PREAUTH_GIFT_CARD, CART_ERROR, false, url, dispatch, data, callback);
}

export function fetchGiftCard(tenantId, giftCardNumber, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eater/tenants/${tenantId}/giftcards/${giftCardNumber}`;
  return (dispatch) => getData(FETCH_GIFT_CARD_BY_GIFT_CARD_NUMBER, CART_ERROR, false, url, dispatch, callback);
}