import axios from "axios";

import {
  CREATE_GIFT_CARD_ORDER,
  SAVE_GIFT_CARD_ORDER,
  GIFT_CARD_ORDER_ERROR,
} from "./types";

import { errorHandler } from "./index";

export function createGiftCardOrder(
  {
    giftCardProductId,
    giftCardProduct,
    giftCardType,
    quantity,
    total
  },
  successCallback
) {
  successCallback();
  return {
    type: CREATE_GIFT_CARD_ORDER,
    payload: {
      giftCardProductId,
      giftCardProduct,
      giftCardType,
      quantity,
      total
    },
  };
}

export function saveGiftCardOrder(
  restaurantId,
  giftCardType,
  giftCardProduct,
  quantity,
  total,
  customer,
  paymentMethod,
  orderLink,
  successCallback
) {
  return function (dispatch) {
    axios
      .post(
        process.env.REACT_APP_API_BASE +
          `/api/eater/restaurants/${restaurantId}/giftcardorders`,
        {
          giftCardType,
          giftCardProductId: giftCardProduct._id,
          giftCardProductName: giftCardProduct.name,
          giftCardProductUnitPrice: giftCardProduct.price,
          quantity: quantity,
          giftCardQuantity: giftCardProduct.quantity * quantity,
          giftCardUnitPrice: giftCardProduct.unitPrice,
          price: total,
          customer,
          paymentMethod,
          orderLink,
        }
      )
      .then((response) => {
        successCallback(response.data);
        dispatch({ type: SAVE_GIFT_CARD_ORDER });
      })
      .catch((error) => {
        console.log(error);
        errorHandler(dispatch, error.response, GIFT_CARD_ORDER_ERROR);
      });
  };
}
