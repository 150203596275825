import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchUser, updateUserProfile } from "../../actions/user";

import {
  renderPhoneField,
  renderField,
  renderEmailField,
  renderUserNameField,
  required,
  phoneNumber,
} from "../../utils/renderFields";
import normalizePhone from "../../utils/normalizePhone";

import { sendGAEvent } from "../../utils/analytics";

const form = reduxForm({
  form: "updateProfile",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.local.firstName) {
    errors.firstName = "Please enter first name";
  }
  if (!formProps.local.lastName) {
    errors.lastName = "Please enter last name";
  }
  if (!formProps.local.telephone) {
    errors.telephone = "Please enter telephone";
  }

  return errors;
}

class UpdateProfile extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  handleFormSubmit(formProps) {
    sendGAEvent({
      category: "User",
      action: "Update Profile",
      label: `${formProps.local.firstName} ${formProps.local.lastName}`,
    });

    this.props.updateUserProfile(this.props.userId, formProps, () => {
      this.setState({ redirectTo: "/profile" });
    });
  }

  handleCancel() {
    this.setState({ redirectTo: "/profile" });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    console.log("redirect to ", this.state.redirectTo)
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    return (
      <>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>UPDATE PROFILE</strong>
            </h3>
          </div>
        </div>

        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group">
            <label>Email</label>
            <Field
              name="local.email"
              className="form-control-plaintext"
              component={renderEmailField}
              readonly
              type="text"
            />
          </div>
          <div className="form-group">
            <label>First Name</label>
            <Field
              name="local.firstName"
              className="form-control"
              component={renderUserNameField}
              validate={[required]}
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <Field
              name="local.lastName"
              className="form-control"
              component={renderUserNameField}
              validate={[required]}
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <Field
              name="local.telephone"
              className="form-control"
              component={renderPhoneField}
              type="text"
              validate={[required, phoneNumber]}
              normalize={normalizePhone}
            />
          </div>
          <div className="form-group">
            <label>Address 1</label>
            <Field
              name="address.address_1"
              className="form-control"
              component={renderField}
              type="text"
              placeholder="0 - 120 characters"
            />
          </div>
          <div className="form-group">
            <label>Address 2</label>
            <Field
              name="address.address_2"
              className="form-control"
              component={renderField}
              type="text"
              placeholder="0 - 120 characters"
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="city_town">City</label>
              <Field
                name="address.city_town"
                className="form-control"
                component={renderField}
                type="text"
                placeholder="0 - 50 characters"
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="state_province">Province</label>
              <Field
                name="address.state_province"
                className="form-control"
                component="select"
              >
                <option />
                <option value="SK">Saskatchewan</option>
                <option value="AB">Alberta</option>
              </Field>
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="postal_code">Postal Code</label>
              <Field
                name="address.postal_code"
                className="form-control"
                component={renderField}
                type="text"
                placeholder="0 - 30 characters"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Country</label>
            <Field
              name="address.country"
              className="form-control"
              component="select"
            >
              <option />
              <option value="CA">Canada</option>
              <option value="US">United States</option>
            </Field>
          </div>

          <div className="row pb-3">
            <div className="col-6 text-left">
              <button
                type="button"
                className="btn btn-secondary btn-block"
                onClick={this.handleCancel}
              >
                {`Cancel`}
              </button>
            </div>
            <div className="col-6 text-left">
              <button
                type="submit"
                className="btn btn-angkor btn-block"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.user.error,
    message: state.user.message,
    initialValues: state.user.user,
    userId: state.user.user._id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(fetchUser(userId)),
  updateUserProfile: (userId, formProps, callback) =>
    dispatch(updateUserProfile(userId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(UpdateProfile));
