import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import OrderInfoCard from "./OrderInfoCard";

import { fetchOrder, cancelExistingOrder } from "../../actions/order";

import "./OrderDetail.css";

import { sendGAEvent } from "../../utils/analytics";

class OrderDetailCard extends Component {
  render() {
    const { order } = this.props;
    return (
      <div className="row">
        <div className="col-12">
          <OrderInfoCard order={order} />
        </div>
      </div>
    );
  }
}
class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.handleCancelOrder = this.handleCancelOrder.bind(this);
  }

  componentDidMount() {
    if (this.props.orderId) {
      this.props.fetchOrder(this.props.tenant._id, this.props.orderId);
    }
  }

  handleCancelOrder(order) {
    sendGAEvent({
      category: "User",
      action: "Cancel Order Form Submit",
      label: `OrderId: ${this.props.orderId}`,
    });
    this.props.cancelExistingOrder(this.props.tenant._id, order.orderId);
  }

  render() {
    const { order, handleViewOrders, handleViewProfile } = this.props;

    if (!order) {
      return <></>;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewProfile}
              >
                Profile
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewOrders}
              >
                Orders
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                Order: {order.orderNumber}
              </button>
            </li>
          </ol>
        </nav>
        <OrderDetailCard ref={(el) => (this.componentRef = el)} order={order} />
        <div className="row pt-3 pb-3">
          <div className="col-3">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleViewOrders}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-9 text-right">
            <div className="row">
              <div className="col-12">
                {order.status === "Received" && (
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => this.handleCancelOrder(order)}
                  >
                    <Icon.Slash size={16} />
                    {` `}Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (tenantId, orderNumber) =>
    dispatch(fetchOrder(tenantId, orderNumber)),
  cancelExistingOrder: (tenantId, orderNumber) =>
    dispatch(cancelExistingOrder(tenantId, orderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
