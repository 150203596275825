import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { fetchOrders } from "../../actions/order";

import OrderDetail from "./OrderDetail";

import OrderListView from "./OrderListView";

import { sendGAEvent } from "../../utils/analytics";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.handleViewOrders = this.handleViewOrders.bind(this);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewProfile = this.handleViewProfile.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    this.props.fetchOrders();
  }

  handleViewDetail(order) {
    sendGAEvent({
      category: "User",
      action: "View Order",
      label: `orderNumber: ${order.orderNumber}`,
    });
    this.setState({ selectedOrder: order });
  }

  handleViewOrders() {
    this.setState({ selectedOrder: undefined });
    this.props.fetchOrders();
  }

  handleViewProfile() {
    this.setState({
      redirectTo: "/profile",
    });
  }

  render() {
    if (this.state.selectedOrder) {
      return (
        <OrderDetail
          orderId={this.state.selectedOrder._id}
          handleViewOrders={this.handleViewOrders}
          handleViewProfile={this.handleViewProfile}
        />
      );
    }

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleViewProfile}
              >
                Profile
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Orders
              </button>
            </li>
          </ol>
        </nav>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>ORDERS</strong>
            </h3>
          </div>
        </div>
        <OrderListView
          orders={this.props.orders}
          handleViewDetail={this.handleViewDetail}
          handleViewProfile={this.handleViewProfile}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.order.orders ? state.order.orders : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrders: () => dispatch(fetchOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
