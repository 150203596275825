import React from 'react';
import PropTypes from 'prop-types';

import CartItem from './CartItem';

const CartItems = ({ items, handleRemoveItem, changeable }) => {
  return (
    <>
    {items.map((item, index) => (
      <CartItem          
        key={item.uuid}
        item={item}
        handleRemoveItem={handleRemoveItem}
        changeable={changeable}
      />
    ))}
    </>
  );
};

CartItems.propTypes = {
  items: PropTypes.array
};

export default CartItems;
