import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HeaderV2 from "./components/Header/HeaderV2";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

import { fetchTenant } from "./actions/tenant";

// const serverLatencyCallback = list => {
//   list.getEntries().forEach(entry => {
//     ReactGA.timing({
//       category: "Load Peformance",
//       variable: "Server Latency",
//       value: entry.responseStart - entry.requestStart 
//     })
//   })
// }

// const downloadTimeCallback = list => {
//   list.getEntries().forEach(entry => {
//     ReactGA.timing({
//       category: "Load Peformance",
//       variable: "Download Time",
//       value: entry.responseEnd - entry.responseStart 
//     })
//   })
// }

const totalLoadTimeCallback = list => {
  list.getEntries().forEach(entry => {
    ReactGA.timing({
      category: "Load Peformance",
      variable: "Total Load Time",
      value: entry.responseEnd - entry.requestStart 
    })
  })
}

class App extends Component {
  componentDidMount() {
    this.props.fetchTenant(this.props.subdomain);
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log(position)
    // });
  }

  render() {
    const { tenant, promotions = [], announcements = [] } = this.props;
    if (!tenant) {
      return <></>;
    }
    if (process.env.NODE_ENV === "production" && tenant.settings && tenant.settings.google_analytics_tracking_id) {
      ReactGA.initialize(tenant.settings.google_analytics_tracking_id);
      ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

      var observer = new PerformanceObserver(totalLoadTimeCallback);
      observer.observe({entryTypes: ['navigation'] })
    }
    return (
      <>
        <Helmet title={tenant.name}>
          {tenant.favicon && tenant.favicon.url && (
            <link
              rel="shortcut icon"
              href={
                process.env.REACT_APP_S3_BASE_URL +
                `${tenant.slug}/${tenant.favicon.url}`
              }
            />
          )}
        </Helmet>
        <HeaderV2
          tenant={tenant}
          promotions={promotions}
          announcements={announcements}
        />
        <Main
          tenant={tenant}
          promotions={promotions}
          announcements={announcements}
        />
        <Footer
          tenant={tenant}
          promotions={promotions}
          announcements={announcements}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    subdomain: ownProps.subdomain,
    tenant: state.tenant.tenant,
    promotions: state.tenant.promotions,
    announcements: state.tenant.announcements
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTenant: slug => dispatch(fetchTenant(slug))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
