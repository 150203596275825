import React, { Component } from "react";
import { connect } from "react-redux";

import {
  selectRestaurant,
  fetchBusinessHoursByTenantId,
} from "../../actions/tenant";
import { Redirect } from "react-router-dom";

import Location from "./Location";

import { SELECT_RESTAURANT } from "../../actions/types";
import Map from "../Contact/Map";

class Locations extends Component {
  constructor(props) {
    super(props);

    this.handleViewMenus = this.handleViewMenus.bind(this);
    this.handleOrderOnline = this.handleOrderOnline.bind(this);
    this.handleMakeReservation = this.handleMakeReservation.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchBusinessHoursByTenantId(this.props.tenant._id);
    }
  }

  handleViewMenus(restaurant) {
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/menus" });
    });
  }

  handleOrderOnline(restaurant) {
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/orderonline" });
    });
  }

  handleMakeReservation(restaurant) {
    this.props.selectRestaurant(restaurant, (dispatch) => {
      dispatch({
        type: SELECT_RESTAURANT,
        payload: { restaurant: restaurant },
      });
      this.setState({ redirectTo: "/reservation" });
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const { restaurants, businessHours } = this.props;
    if (!restaurants || !businessHours) {
      return <></>;
    }

    return (
      <div className="row">
        <div className="col-12 col-lg-6">
          {restaurants.map((restaurant, index) => (
            <Location
              key={restaurant._id}
              restaurant={restaurant}
              holidayHours={businessHours.filter(
                (businesshour) =>
                  businesshour.restaurant.valueOf() === restaurant._id
              )}
              handleViewMenus={this.handleViewMenus}
              handleOrderOnline={this.handleOrderOnline}
              handleMakeReservation={this.handleMakeReservation}
            />
          ))}
        </div>
        <div className="col-12 col-lg-6 order-first order-lg-last">
          <Map restaurants={restaurants} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    restaurants: state.tenant.restaurants || [],
    restaurantId: state.tenant.restaurantId,
    businessHours: state.tenant.businessHours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBusinessHoursByTenantId: (tenantId) =>
    dispatch(fetchBusinessHoursByTenantId(tenantId)),
  selectRestaurant: (restaurant, callback) =>
    dispatch(selectRestaurant(restaurant, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
