import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import $ from "jquery";

import * as Icon from "react-feather";

class CancelOrderLink extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleYesClick = this.handleYesClick.bind(this);
    this.handleNoClick = this.handleNoClick.bind(this);

    this.state = {
      redirect: false
    };
  }

  handleYesClick() {

    this.props.onCancelOrder();
    this.setState({redirect: true})
    $(".close").click();
  }

  handleNoClick() {
    $(".close").click();
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to="/orderonline" />;
    }
    return (
      <>
      <button
        className="btn btn-warning btn-block"
        data-toggle="modal"
        data-target="#cancelOrderModal"
        data-backdrop="static">
        <span className="d-none d-sm-inline">CANCEL ORDER</span>{` `}<Icon.XCircle size={24} />
      </button>
      <div
        className="modal fade"
        id="cancelOrderModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="#cancelModallLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <form >
              <div className="modal-header bg-angkor">
                <h5 className="modal-title" id="cancelOrderModallLabel">
                  {`CANCEL ORDER CONFIRMATION`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <p>Are you sure you want to cancel this order?</p>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-angkor" onClick={this.handleYesClick}>YES</button>
                <button type="button" className="btn btn-secondary" onClick={this.handleNoClick}>NO</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect(
  null,
  {  }
)(CancelOrderLink);
