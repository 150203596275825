import React, { Component } from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from "react-stripe-elements";

import "./CreditCardForm.css";

import { sendGAEvent } from "../../utils/analytics";

class CreditCardForm extends Component {
  constructor(props) {
    super(props);

    this.handleCardHolderNameChange =
      this.handleCardHolderNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleAddress1Change = this.handleAddress1Change.bind(this);
    this.handleAddress2Change = this.handleAddress2Change.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);

    this.state = {
      complete: false,
      cardHolderName: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.name
        : "",
      email: props.paymentMethod ? props.paymentMethod.email : "",
      phone: props.paymentMethod ? props.paymentMethod.phone : "",
      address_1: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.address_line1
        : "",
      address_2: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.address_line2
        : "",
      city: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.address_city
        : "",
      province: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.address_state
        : "",
      postalCode: props.paymentMethod
        ? props.paymentMethod.stripeToken.card.address_zip
        : "",
      errorMessage: undefined,
    };
    this.submit = this.submit.bind(this);
  }

  handleCardHolderNameChange(event) {
    this.setState({ cardHolderName: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }

  handleAddress1Change(event) {
    this.setState({ address_1: event.target.value });
  }

  handleAddress2Change(event) {
    this.setState({ address_2: event.target.value });
  }

  handleCityChange(event) {
    this.setState({ city: event.target.value });
  }

  handleProvinceChange(event) {
    this.setState({ province: event.target.value });
  }

  handlePostalCodeChange(event) {
    this.setState({ postalCode: event.target.value });
  }

  async submit(ev) {
    ev.preventDefault();
    sendGAEvent({
      category: "User",
      action: "CreitCard Form Submit",
      label: `Name: ${this.state.cardHolderName}`,
    });
    let result = await this.props.stripe.createToken({
      name: this.state.cardHolderName,
      address_line1: this.state.address_1,
      address_line2: this.state.address_2,
      address_city: this.state.city,
      address_state: this.state.province,
      address_zip: this.state.postalCode,
      address_country: "CA",
      currency: "cad",
    });

    if (result.error) {
      this.setState({ errorMessage: result.error.message });
    }
    if (result.token) {
      if (this.props.paymentMethod) {
        this.props.onSave(
          this.props.paymentMethod._id,
          result.token,
          this.state.email,
          this.state.phone
        );
      } else {
        this.props.onSave(result.token, this.state.email, this.state.phone);
      }
    }
  }

  renderAlert() {
    if (this.state.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.state.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { onCancel } = this.props;
    return (
      <div className="card bg-light">
        <div className="card-body">
          <form onSubmit={this.handleSubmit}>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Card Holder Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="cardHolderName"
                  value={this.state.cardHolderName}
                  onChange={this.handleCardHolderNameChange}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-md-6 col-card-angkor pb-3">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>
              <div className="col-12 col-md-6 col-card-angkor">
                <label>Phone</label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handlePhoneChange}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  name="address_1"
                  value={this.state.address_1}
                  onChange={this.handleAddress1Change}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Address 2</label>
                <input
                  className="form-control"
                  type="text"
                  name="address_2"
                  value={this.state.address_2}
                  onChange={this.handleAddress2Change}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-md-4 col-card-angkor pb-3">
                <label>City</label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                />
              </div>
              <div className="col-12 col-md-4 col-card-angkor  pb-3">
                <label>Province</label>
                <input
                  className="form-control"
                  type="text"
                  name="province"
                  value={this.state.province}
                  onChange={this.handleProvinceChange}
                />
              </div>
              <div className="col-12 col-md-4 col-card-angkor">
                <label>Postal Code</label>
                <PostalCodeElement
                // onChange={this.handlePostalCodeChange}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12 col-card-angkor">
                <label>Card Number</label>
                <CardNumberElement />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-6 col-card-angkor">
                <label>Expiry Date</label>
                <CardExpiryElement />
              </div>
              <div className="col-6 col-card-angkor">
                <label>CVC</label>
                <CardCVCElement />
              </div>
              {/* <div className="col-4 col-card-angkor">
                <label>Postal Code</label>
                <PostalCodeElement />
              </div> */}
            </div>

            {/* <CardElement
            className="form-control"
            {...createOptions(this.props.fontSize)}
          /> */}
            {/* <div className="pt-3">
              <button
                className="btn btn-angkor btn-block"
                onClick={this.submit}
              >
                <strong>
                  Pay {Numeral(this.props.amount).format("$0,0.00")}
                </strong>
              </button>
            </div> */}
            {this.renderAlert()}
            <div className="row pb-3">
              <div className="col-6 text-left">
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={onCancel}
                >
                  {`Cancel`}
                </button>
              </div>
              <div className="col-6 text-left">
                <button
                  className="btn btn-angkor btn-block"
                  onClick={this.submit}
                >
                  {"Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectStripe(CreditCardForm);
