import React from "react";
import PropTypes from "prop-types";

import Numeral from "numeral";

const Tax = ({ tax, taxRate, gst, gstRate, pst, pstRate }) => {
  return (
    <>
      {/* <div className="row border-bottom pb-3 pt-3">
        <div className="col-8 text-left col-cart-angkor">
          <span>{`Tax (${taxRate}%)`}</span>
        </div>
        <div className="col-3 text-right col-cart-angkor">
          <span>
            <small>{Numeral(tax).format("$0,0.00")}</small>
          </span>
        </div>
      </div> */}
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-8 text-left col-cart-angkor">
          <span>{`GST (${gstRate}%)`}</span>
        </div>
        <div className="col-3 text-right col-cart-angkor">
          <span>
            <small>{Numeral(gst).format("$0,0.00")}</small>
          </span>
        </div>
      </div>
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-8 text-left col-cart-angkor">
          <span>{`PST (${pstRate}%)`}</span>
        </div>
        <div className="col-3 text-right col-cart-angkor">
          <span>
            <small>{Numeral(pst).format("$0,0.00")}</small>
          </span>
        </div>
      </div>
    </>
  );
};

Tax.propTypes = {
  tax: PropTypes.number.isRequired,
};

Tax.defaultProps = {
  tax: 0,
};

export default Tax;
