import axios from "axios";
import Cookies from "universal-cookie";

import {
  FETCH_RESERVATIONS,
  SAVE_RESERVATION,
  CANCEL_RESERVATION,
  RESERVATION_ERROR
} from "./types";

import { errorHandler, getData } from "./index";

const cookies = new Cookies();

export function cancelReservation() {
  const data = {};
  return {
    type: CANCEL_RESERVATION,
    payload: data
  };
}

export function saveReservation(
  {
    restaurant,
    nameReservedUnder,
    email,
    telephone,
    reservedDate,
    reservedTime,
    partySize
  },
  successCallback
) {
  let customerId = undefined;
  const customer = cookies.get("customer");
  if (customer) {
    customerId = customer._id;
  }
  const data = {
    restaurant: restaurant,
    customer: customerId,
    nameReservedUnder,
    email,
    telephone,
    reservedDate,
    reservedTime,
    partySize
  }
  return function(dispatch) {
    axios
      .post(process.env.REACT_APP_API_BASE + `/api/eater/reservations`, data)
      .then(response => {
        successCallback(response.data);
        dispatch({ type: SAVE_RESERVATION });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, RESERVATION_ERROR);
      });
  };
}

export function fetchReservations() {
  const customerId = cookies.get("customer")._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eater/customers/${customerId}/reservations`;
  return dispatch =>
    getData(FETCH_RESERVATIONS, RESERVATION_ERROR, true, url, dispatch);
}
