import Numeral from "numeral";
import moment from "moment-timezone";
import { isSame } from "./equalUtil";
import Cookies from "universal-cookie";

const uuidv4 = require("uuid/v4");

const isSameOrBeforeHMS = (
  promoStartTime,
  pickupTime,
  pickupDate,
  timeFormat,
  timezone
) => {
  const hours = moment(promoStartTime, timeFormat).hour();
  const minutes = moment(promoStartTime, timeFormat).minute();
  const seconds = moment(promoStartTime, timeFormat).second();
  const startOfPickupDate = moment(pickupDate).tz(timezone).startOf("day");
  const startTime = startOfPickupDate
    .add(hours, "hour")
    .add(minutes, "minute")
    .add(seconds, "second");
  const result = moment(startTime).isSameOrBefore(
    moment(pickupTime).tz(timezone)
  );
  return result;
};

const isSameOrAfterHMS = (
  promoEndTime,
  pickupTime,
  pickupDate,
  timeFormat,
  timezone
) => {
  const hours = moment(promoEndTime, timeFormat).hour();
  const minutes = moment(promoEndTime, timeFormat).minute();
  const seconds = moment(promoEndTime, timeFormat).second();
  const startOfPickupDate = moment(pickupDate).tz(timezone).startOf("day");
  const startTime = startOfPickupDate
    .add(hours, "hour")
    .add(minutes, "minute")
    .add(seconds, "second");
  const result = moment(startTime).isSameOrAfter(
    moment(pickupTime).tz(timezone)
  );
  return result;
};

const isValidPromotionRule = (
  promoRule,
  pickupTime,
  pickupDate,
  timeFormat,
  timezone
) => {
  const dayOfWeek = moment(pickupDate).tz(timezone).weekday();
  let isValid = false;
  isValid = promoRule.disabled;
  if (!isValid) {
    if (dayOfWeek === 0 && promoRule.sunday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.sunday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.sunday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 1 && promoRule.monday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.monday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.monday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 2 && promoRule.tuesday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.tuesday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.tuesday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 3 && promoRule.wednesday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.wednesday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.wednesday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 4 && promoRule.thursday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.thursday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.thursday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 5 && promoRule.friday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.friday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.friday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    } else if (dayOfWeek === 6 && promoRule.saturday_selected) {
      isValid =
        isSameOrBeforeHMS(
          promoRule.saturday_from,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        ) &&
        isSameOrAfterHMS(
          promoRule.saturday_until,
          moment(pickupTime),
          pickupDate,
          timeFormat,
          timezone
        );
    }
  }
  return isValid;
};

export const validateAndApplyPromotions = (
  items = [],
  promotions = [],
  promotionRules,
  timezone,
  timeFormat,
  deliveryMethod,
  pickupTime,
  pickupDate,
  couponAmount,
  deliveryDistance,
  postalCode,
  deliveryFee,
  deliveryGST
) => {

  const cookies = new Cookies();
  const selectedLocation = cookies.get("selectedLocation");
  let restaurantId;
  if (selectedLocation) {
    const locationElements = selectedLocation.split("|");
    restaurantId = locationElements[0];
  }

  const qualifiedPromotionRules = promotionRules.filter(
    (promotionRule) =>
      promotionRule.promo_apply_option === "Automatically" &&
      promotionRule.allowed_order_channel?.includes("Online") &&
      (!restaurantId || (promotionRule.restaurants && promotionRule.restaurants.includes(restaurantId)))
  );

  let result = {
    promotions: [],
    items: [],
    cartDiscounts: {},
    messages: [],
  };

  promotions.forEach((promo) => {
    const matchedPromotionRules = qualifiedPromotionRules.filter(
      (promotionRule) => promotionRule._id === promo.promotionId
    );
    if (matchedPromotionRules.length > 0) {
      const matchedPromotionRule = matchedPromotionRules[0];
      const isStillValid = isValidPromotionRule(
        matchedPromotionRule,
        pickupTime,
        pickupDate,
        timeFormat,
        timezone
      );
      if (!isStillValid) {
        result.messages.push(
          `${matchedPromotionRule.name} is available from ${moment(pickupTime)
            .tz(timezone)
            .format(timeFormat)}`
        );
      }
    }
  });

  if (result.messages.length > 0) {
    return result;
  }

  let subtotal = 0;

  // items.forEach(item => {
  //   subtotal += (item.baseTotal + item.extraTotal);
  // });

  let generatedPromotions = [];
  let discounted_item_uuids = [];

  promotions.forEach((promo) => {
    subtotal = subtotal + promo.amount;
    if (promo.discountedItems) {
      promo.discountedItems.forEach((item) => {
        discounted_item_uuids.push(item.uuid);
      });
    }
  });

  let remaining_items = items.filter(
    (item) => !discounted_item_uuids.includes(item.uuid)
  );

  let percentDiscountOnSelectedItemsPromotionRules =
    qualifiedPromotionRules.filter(
      (promotionRule) =>
        promotionRule.type === "% discount on selected items" &&
        promotionRule.disabled === false
    );
  percentDiscountOnSelectedItemsPromotionRules.forEach((promotionRule) => {
    let qualified_items = remaining_items.filter((item) =>
      promotionRule.eligible_items_group_1.includes(item.id)
    );
    remaining_items = remaining_items.filter(
      (item) => !promotionRule.eligible_items_group_1.includes(item.id)
    );

    qualified_items.forEach((item) => {
      const discount_amount =
        (promotionRule.discount_on_items_group_1 *
          (item.baseTotal + item.extraTotal)) /
        100.0;
      const remaining_amount =
        item.baseTotal + item.extraTotal - discount_amount;
      item.remainingAmount = remaining_amount;
      item.discountAmount = discount_amount;

      subtotal += remaining_amount;

      let existingPromos = promotions.filter(
        (promo) =>
          promo.promotionId === promotionRule._id &&
          isSame(promo.discountedItems[0], item)
      );
      let existingPromo = undefined;
      if (existingPromos && existingPromos.length > 0) {
        existingPromo = existingPromos[0];
      }
      if (existingPromo) {
        existingPromo.quantity += item.quantity;
        existingPromo.amount += remaining_amount;
        existingPromo.discountAmount += discount_amount;
        existingPromo.discountedItems.forEach((discountedItem) => {
          discountedItem.quantity += item.quantity;
          discountedItem.baseTotal += item.baseTotal;
          discountedItem.extraTotal += item.extraTotal;
          discountedItem.remainingAmount += item.remainingAmount;
          discountedItem.discountAmount += item.discountAmount;
        });
      } else {
        let discounted_items = [];
        discounted_items.push(item);
        discounted_item_uuids.push(item.uuid);
        generatedPromotions.push({
          promotionId: promotionRule._id,
          promotionName: promotionRule.name,
          quantity: item.quantity,
          amount: remaining_amount,
          discountAmount: discount_amount,
          couponCode: promotionRule.coupon_code,
          discountedItems: discounted_items,
          uuid: uuidv4(),
        });
      }
    });
  });

  let buyOneGetOneFreePromotionRules = qualifiedPromotionRules.filter(
    (promotionRule) =>
      promotionRule.type === "Buy one, get one free" &&
      promotionRule.disabled === false
  );

  buyOneGetOneFreePromotionRules
    .filter((rule) =>
      isValidPromotionRule(rule, pickupTime, pickupDate, timeFormat, timezone)
    )
    .forEach((promotionRule) => {
      if (
        !promotionRule.allowed_delivery_method ||
        promotionRule.allowed_delivery_method === "Any type" ||
        promotionRule.allowed_delivery_method === deliveryMethod
      ) {
        let qualified_items = [];
        qualified_items = remaining_items.filter((item1) => {
          const is_qualified =
            promotionRule.eligible_items_group_1.includes(item1.id) ||
            promotionRule.eligible_items_group_2.includes(item1.id);
          return is_qualified;
        });

        remaining_items = remaining_items.filter(
          (item) =>
            !promotionRule.eligible_items_group_1.includes(item.id) &&
            !promotionRule.eligible_items_group_2.includes(item.id)
        );

        if (!promotionRule.must_be_same_item) {
          while (qualified_items.length > 1) {
            let most_expensive_item = qualified_items.reduce(
              (accumulator, currentValue) => {
                return (currentValue.baseTotal +
                  (currentValue.extraTotal ? currentValue.extraTotal : 0)) /
                  currentValue.quantity >
                  (accumulator.baseTotal +
                    (accumulator.extraTotal ? accumulator.extraTotal : 0)) /
                    accumulator.quantity
                  ? currentValue
                  : accumulator;
              }
            );

            let cheapest_item = qualified_items
              .filter((item) => isSame(item, most_expensive_item) === false)
              .reduce((accumulator, currentValue) => {
                return (currentValue.baseTotal +
                  (currentValue.extraTotal ? currentValue.extraTotal : 0)) /
                  currentValue.quantity <
                  (accumulator.baseTotal +
                    (accumulator.extraTotal ? accumulator.extraTotal : 0)) /
                    accumulator.quantity
                  ? currentValue
                  : accumulator;
              });

            let most_expensive_item_quantity = most_expensive_item.quantity;
            let cheapest_item_quantity = cheapest_item.quantity;
            let quantity = Math.min(
              most_expensive_item_quantity,
              cheapest_item_quantity
            );

            let remaining_most_expensive_item = undefined;
            let remaining_cheapest_item = undefined;
            if (most_expensive_item_quantity > quantity) {
              remaining_most_expensive_item = {
                ...most_expensive_item,
                quantity: most_expensive_item.quantity - quantity,
                baseTotal:
                  (most_expensive_item.baseTotal /
                    most_expensive_item.quantity) *
                  (most_expensive_item_quantity - quantity),
                extraTotal:
                  (most_expensive_item.extraTotal /
                    most_expensive_item.quantity) *
                  (most_expensive_item_quantity - quantity),
                uuid: uuidv4(),
              };
            }
            if (cheapest_item_quantity > quantity) {
              remaining_cheapest_item = {
                ...cheapest_item,
                quantity: cheapest_item.quantity - quantity,
                baseTotal:
                  (cheapest_item.baseTotal / cheapest_item.quantity) *
                  (cheapest_item_quantity - quantity),
                extraTotal:
                  (cheapest_item.extraTotal / cheapest_item.quantity) *
                  (cheapest_item_quantity - quantity),
                uuid: uuidv4(),
              };
            }
            const cheapest_item_discount_amount =
              ((cheapest_item.baseTotal +
                (cheapest_item.extraTotal ? cheapest_item.extraTotal : 0)) /
                cheapest_item.quantity) *
              (promotionRule.discount_on_cheapest_item / 100) *
              quantity;

            const cheapest_item_remaining_amount =
              ((cheapest_item.baseTotal +
                (cheapest_item.extraTotal ? cheapest_item.extraTotal : 0)) /
                cheapest_item.quantity) *
              ((100 - promotionRule.discount_on_cheapest_item) / 100) *
              quantity;

            const most_expensive_item_discount_amount =
              ((most_expensive_item.baseTotal +
                (most_expensive_item.extraTotal
                  ? most_expensive_item.extraTotal
                  : 0)) /
                most_expensive_item.quantity) *
              (promotionRule.discount_on_most_expensive_item / 100) *
              quantity;

            const most_expensive_item_remaining_amount =
              ((most_expensive_item.baseTotal +
                (most_expensive_item.extraTotal
                  ? most_expensive_item.extraTotal
                  : 0)) /
                most_expensive_item.quantity) *
              quantity;

            cheapest_item.discountAmount = Numeral(
              Numeral(cheapest_item_discount_amount).format("$0,0.00")
            ).value();
            most_expensive_item.discountAmount = Numeral(
              Numeral(most_expensive_item_discount_amount).format("$0,0.00")
            ).value();

            // subtotal += (cheapest_item_remaining_amount + most_expensive_item_remaining_amount);

            let existingPromos = promotions.filter(
              (promo) =>
                promo.promotionId === promotionRule._id &&
                ((isSame(promo.discountedItems[0], most_expensive_item) &&
                  isSame(promo.discountedItems[1], cheapest_item)) ||
                  (isSame(promo.discountedItems[0], cheapest_item) &&
                    isSame(promo.discountedItems[1], most_expensive_item)))
            );
            let existingPromo = undefined;
            if (existingPromos && existingPromos.length > 0) {
              existingPromo = existingPromos[0];
            }

            if (existingPromo) {
              existingPromo.quantity += quantity;
              existingPromo.amount +=
                cheapest_item_remaining_amount +
                most_expensive_item_remaining_amount;
              existingPromo.discountAmount +=
                cheapest_item_discount_amount +
                most_expensive_item_discount_amount;
              if (
                isSame(existingPromo.discountedItems[0], most_expensive_item)
              ) {
                existingPromo.discountedItems[0].quantity += quantity;
                existingPromo.discountedItems[0].baseTotal +=
                  (most_expensive_item.baseTotal /
                    most_expensive_item.quantity) *
                  quantity;
                existingPromo.discountedItems[0].extraTotal +=
                  (most_expensive_item.extraTotal /
                    most_expensive_item.quantity) *
                  quantity;
                existingPromo.discountedItems[0].discountAmount +=
                  most_expensive_item_discount_amount;
              }
              if (
                isSame(existingPromo.discountedItems[1], most_expensive_item)
              ) {
                existingPromo.discountedItems[1].quantity += quantity;
                existingPromo.discountedItems[1].baseTotal +=
                  (most_expensive_item.baseTotal /
                    most_expensive_item.quantity) *
                  quantity;
                existingPromo.discountedItems[1].extraTotal +=
                  (most_expensive_item.extraTotal /
                    most_expensive_item.quantity) *
                  quantity;
                existingPromo.discountedItems[1].discountAmount +=
                  most_expensive_item_discount_amount;
              }
              if (isSame(existingPromo.discountedItems[0], cheapest_item)) {
                existingPromo.discountedItems[0].quantity += quantity;
                existingPromo.discountedItems[0].baseTotal +=
                  (cheapest_item.baseTotal / cheapest_item.quantity) * quantity;
                existingPromo.discountedItems[0].extraTotal +=
                  (cheapest_item.extraTotal / cheapest_item.quantity) *
                  quantity;
                existingPromo.discountedItems[0].discountAmount +=
                  cheapest_item_discount_amount;
              }
              if (isSame(existingPromo.discountedItems[1], cheapest_item)) {
                existingPromo.discountedItems[1].quantity += quantity;
                existingPromo.discountedItems[1].baseTotal +=
                  (cheapest_item.baseTotal / cheapest_item.quantity) * quantity;
                existingPromo.discountedItems[1].extraTotal +=
                  (cheapest_item.extraTotal / cheapest_item.quantity) *
                  quantity;
                existingPromo.discountedItems[1].discountAmount +=
                  cheapest_item_discount_amount;
              }
            } else {
              let discounted_items = [];
              discounted_items.push(most_expensive_item);
              discounted_items.push(cheapest_item);

              generatedPromotions.push({
                promotionId: promotionRule._id,
                promotionName: promotionRule.name,
                quantity: quantity,
                amount:
                  cheapest_item_remaining_amount +
                  most_expensive_item_remaining_amount,
                discountAmount:
                  cheapest_item_discount_amount +
                  most_expensive_item_discount_amount,
                couponCode: promotionRule.coupon_code,
                discountedItems: discounted_items,
                uuid: uuidv4(),
              });
            }

            discounted_item_uuids.push(most_expensive_item.uuid);
            discounted_item_uuids.push(cheapest_item.uuid);

            if (remaining_most_expensive_item) {
              remaining_items.push(remaining_most_expensive_item);
            }
            if (remaining_cheapest_item) {
              remaining_items.push(remaining_cheapest_item);
            }

            qualified_items = remaining_items.filter(
              (item) =>
                promotionRule.eligible_items_group_1.includes(item.id) ||
                promotionRule.eligible_items_group_2.includes(item.id)
            );

            remaining_items = remaining_items.filter(
              (item) =>
                !promotionRule.eligible_items_group_1.includes(item.id) &&
                !promotionRule.eligible_items_group_2.includes(item.id)
            );
          }

          if (qualified_items.length === 1) {
            let firstItem = qualified_items.shift();

            if (firstItem.quantity > 1) {
              let quantity = Math.floor(firstItem.quantity / 2);
              let remainder = firstItem.quantity % 2;
              if (remainder > 0) {
                let item0 = {};
                item0 = Object.assign(item0, firstItem);
                item0.quantity = 1;
                item0.baseTotal = firstItem.baseTotal / firstItem.quantity;
                item0.extraTotal = firstItem.extraTotal / firstItem.quantity;
                item0.uuid = uuidv4();
                remaining_items.push(item0);
              }

              let amount =
                ((firstItem.baseTotal + firstItem.extraTotal) /
                  firstItem.quantity) *
                quantity;
              let discountAmount =
                (promotionRule.discount_on_cheapest_item / 100) * amount;

              discountAmount = Numeral(
                Numeral(discountAmount).format("$0,0.00")
              ).value();

              let item1 = {};
              item1 = Object.assign(item1, firstItem);
              item1.quantity = quantity;
              item1.baseTotal =
                (firstItem.baseTotal / firstItem.quantity) * quantity;
              item1.extraTotal =
                (firstItem.extraTotal / firstItem.quantity) * quantity;
              item1.uuid = uuidv4();

              let item2 = {};
              item2 = Object.assign(item2, firstItem);
              item2.quantity = quantity;
              item2.baseTotal =
                (firstItem.baseTotal / firstItem.quantity) * quantity;
              item2.extraTotal =
                (firstItem.extraTotal / firstItem.quantity) * quantity;
              item2.discountAmount = discountAmount;
              item2.uuid = uuidv4();

              let discounted_items = [];
              discounted_items.push(item1);
              discounted_items.push(item2);

              const totatAmount =
                item1.baseTotal +
                item1.extraTotal +
                item2.baseTotal +
                item2.extraTotal -
                item2.discountAmount;

              generatedPromotions.push({
                promotionId: promotionRule._id,
                promotionName: promotionRule.name,
                quantity: quantity,
                amount: totatAmount,
                discountAmount: discountAmount,
                couponCode: promotionRule.coupon_code,
                discountedItems: discounted_items,
                uuid: uuidv4(),
              });
            } else {
              remaining_items.push(firstItem);
              let message =
                firstItem.name +
                "is eligible for '" +
                promotionRule.name +
                "' promotion, please add another one";
              result.messages.push(message);
            }
          }
        } else {
          const most_qualified_items = qualified_items.filter(
            (item) => item.quantity > 1
          );
          most_qualified_items.forEach((firstItem) => {
            let quantity = Math.floor(firstItem.quantity / 2);
            let remainder = firstItem.quantity % 2;
            if (remainder > 0) {
              let item0 = {};
              item0 = Object.assign(item0, firstItem);
              item0.quantity = 1;
              item0.baseTotal = firstItem.baseTotal / firstItem.quantity;
              item0.extraTotal = firstItem.extraTotal / firstItem.quantity;
              item0.uuid = uuidv4();
              remaining_items.push(item0);

              let message =
                item0.name +
                "is eligible for '" +
                promotionRule.name +
                "' promotion, please add another one";
              result.messages.push(message);
            }

            let amount =
              ((firstItem.baseTotal + firstItem.extraTotal) /
                firstItem.quantity) *
              quantity;
            let discountAmount =
              (promotionRule.discount_on_cheapest_item / 100) * amount;

            discountAmount = Numeral(
              Numeral(discountAmount).format("$0,0.00")
            ).value();

            let item1 = {};
            item1 = Object.assign(item1, firstItem);
            item1.quantity = quantity;
            item1.baseTotal =
              (firstItem.baseTotal / firstItem.quantity) * quantity;
            item1.extraTotal =
              (firstItem.extraTotal / firstItem.quantity) * quantity;
            item1.uuid = uuidv4();

            let item2 = {};
            item2 = Object.assign(item2, firstItem);
            item2.quantity = quantity;
            item2.baseTotal =
              (firstItem.baseTotal / firstItem.quantity) * quantity;
            item2.extraTotal =
              (firstItem.extraTotal / firstItem.quantity) * quantity;
            item2.discountAmount = discountAmount;
            item2.uuid = uuidv4();

            let discounted_items = [];
            discounted_items.push(item1);
            discounted_items.push(item2);

            const totatAmount =
              item1.baseTotal +
              item1.extraTotal +
              item2.baseTotal +
              item2.extraTotal -
              item2.discountAmount;

            generatedPromotions.push({
              promotionId: promotionRule._id,
              promotionName: promotionRule.name,
              quantity: quantity,
              amount: totatAmount,
              discountAmount: discountAmount,
              couponCode: promotionRule.coupon_code,
              discountedItems: discounted_items,
              uuid: uuidv4(),
            });
          });
          const less_qualified_items = qualified_items.filter(
            (item) => item.quantity === 1
          );
          less_qualified_items.forEach((item) => {
            remaining_items.push(item);
            let message =
              item.name +
              "is eligible for '" +
              promotionRule.name +
              "' promotion, please add another one";
            result.messages.push(message);
          });
        }
      }
    });

  result.promotions = generatedPromotions.concat(promotions);
  result.items = remaining_items;

  let new_subtotal = 0;
  result.promotions.forEach((promo) => {
    new_subtotal += promo.amount;
  });

  result.items.forEach((item) => {
    new_subtotal += item.baseTotal + item.extraTotal;
  });

  if (couponAmount === undefined || couponAmount === null) {
    new_subtotal -= 0;
  } else {
    new_subtotal -= couponAmount;
  }

  //cart level discount
  result.cartDiscounts.discounts = [];
  result.cartDiscounts.discountTotal = 0;

  let qualifiedFixedDiscountOnCartPromos = qualifiedPromotionRules.filter(
    (promotionRule) =>
      promotionRule.type === "Fixed discount amount on cart" &&
      promotionRule.disabled === false &&
      (!promotionRule.allowed_delivery_method ||
        promotionRule.allowed_delivery_method === "Any type" ||
        promotionRule.allowed_delivery_method === deliveryMethod)
  );

  qualifiedFixedDiscountOnCartPromos.forEach((promotionRule) => {
    if (
      promotionRule.minimum_order_amount_option === "Not applicable" ||
      new_subtotal >= promotionRule.minimum_order_amount
    ) {
      const discount_amount = promotionRule.discount_amount;
      if (promotionRule.is_next_order_coupon) {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: 0,
          is_next_order_coupon: true,
          coupon_amount: promotionRule.discount_amount,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        result.cartDiscounts.discountTotal += discount_amount;
      } else {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: discount_amount,
          is_next_order_coupon: false,
          coupon_amount: 0,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        new_subtotal -= discount_amount;
        result.cartDiscounts.discountTotal += discount_amount;
      }
    }

    if (
      new_subtotal >=
        promotionRule.highlight_custom_selection_cart_value_exceeds &&
      new_subtotal < promotionRule.minimum_order_amount
    ) {
      if (promotionRule.is_next_order_coupon) {
        let message =
          "Purchase " +
          Numeral(promotionRule.minimum_order_amount - new_subtotal).format(
            "$0,0.00"
          ) +
          " more to get " +
          promotionRule.name +
          " coupon";
        result.messages.push(message);
      } else {
        let message =
          "Purchase " +
          Numeral(promotionRule.minimum_order_amount - new_subtotal).format(
            "$0,0.00"
          ) +
          " more to get " +
          Numeral(promotionRule.discount_amount).format("$0,0.00") +
          " discount";
        result.messages.push(message);
      }
    }
  });

  let qualifiedPercentDiscountOnCartPromos = qualifiedPromotionRules.filter(
    (promotionRule) =>
      promotionRule.type === "% discount on cart" &&
      promotionRule.disabled === false
  );

  qualifiedPercentDiscountOnCartPromos.forEach((promotionRule) => {
    if (
      promotionRule.minimum_order_amount_option === "Not applicable" ||
      new_subtotal >= promotionRule.minimum_order_amount
    ) {
      const discount_amount =
        (promotionRule.discount_percentage / 100) * new_subtotal;
      if (promotionRule.is_next_order_coupon) {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: 0,
          is_next_order_coupon: true,
          coupon_amount: promotionRule.discount_amount,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        result.cartDiscounts.discountTotal += discount_amount;
      } else {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: discount_amount,
          is_next_order_coupon: false,
          coupon_amount: 0,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        new_subtotal -= discount_amount;
        result.cartDiscounts.discountTotal += discount_amount;
      }
      new_subtotal -= discount_amount;
      result.cartDiscounts.discountTotal += discount_amount;
    }

    if (
      new_subtotal >=
        promotionRule.highlight_custom_selection_cart_value_exceeds &&
      new_subtotal < promotionRule.minimum_order_amount
    ) {
      let message =
        "Purchase " +
        Numeral(promotionRule.minimum_order_amount - new_subtotal).format(
          "$0,0.00"
        ) +
        " more to get " +
        promotionRule.discount_percentage +
        "% discount";
      result.messages.push(message);
    }
  });

  let qualifiedFreeDeliveryPromos = qualifiedPromotionRules.filter(
    (promotionRule) =>
      promotionRule.type === "Free delivery" &&
      promotionRule.disabled === false &&
      promotionRule.allowed_delivery_method === deliveryMethod &&
      ((promotionRule.free_delivery_condition === "Distance" &&
        promotionRule.delivery_distance_in_meter >= deliveryDistance) ||
        (promotionRule.free_delivery_condition === "PostalCode" &&
          promotionRule.delivery_area_postal_codes.include(postalCode)))
  );

  qualifiedFreeDeliveryPromos.forEach((promotionRule) => {
    if (
      promotionRule.minimum_order_amount_option === "Not applicable" ||
      new_subtotal >= promotionRule.minimum_order_amount
    ) {
      let discount_amount = promotionRule.discount_amount;

      if (discount_amount === undefined || discount_amount === null) {
        const discount_percentage = promotionRule.discount_percentage;
        discount_amount =
          (deliveryFee + deliveryGST) * (discount_percentage / 100);
      }

      if (promotionRule.is_next_order_coupon) {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: 0,
          is_next_order_coupon: true,
          coupon_amount: promotionRule.discount_amount,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        result.cartDiscounts.discountTotal += discount_amount;
      } else {
        result.cartDiscounts.discounts.push({
          promotion_id: promotionRule._id,
          discount_name: promotionRule.name,
          coupon_code: promotionRule.coupon_code,
          discount_amount: discount_amount,
          is_next_order_coupon: false,
          coupon_amount: 0,
          coupon_code_expire_in: promotionRule.coupon_code_expire_in,
          coupon_code_expire_unit: promotionRule.coupon_code_expire_unit,
        });
        new_subtotal -= discount_amount;
        result.cartDiscounts.discountTotal += discount_amount;
      }
    }

    if (
      new_subtotal >=
        promotionRule.highlight_custom_selection_cart_value_exceeds &&
      new_subtotal < promotionRule.minimum_order_amount
    ) {
      if (promotionRule.is_next_order_coupon) {
        let message =
          "Purchase " +
          Numeral(promotionRule.minimum_order_amount - new_subtotal).format(
            "$0,0.00"
          ) +
          " more to get " +
          promotionRule.name +
          " coupon";
        result.messages.push(message);
      } else {
        let message =
          "Purchase " +
          Numeral(promotionRule.minimum_order_amount - new_subtotal).format(
            "$0,0.00"
          ) +
          " more to get " +
          Numeral(promotionRule.discount_amount).format("$0,0.00") +
          " discount";
        result.messages.push(message);
      }
    }
  });

  return result;
};
