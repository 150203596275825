import React, { Component } from "react";
import { connect } from "react-redux";

import { Elements, StripeProvider } from "react-stripe-elements";
import CreditCardForm from './CreditCardForm';

class PaymentMethodEdit extends Component {

  componentDidMount() {

  }

  render() {
    const { paymentMethod, onCancel, onSave } = this.props;
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY}>
      <div>
        <Elements>
          <CreditCardForm
            paymentMethod={paymentMethod}
            onSave={onSave}
            onCancel={onCancel}
          />
        </Elements>
      </div>
    </StripeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodEdit);
