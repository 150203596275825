//===========================================================
// Auth Actions
//===========================================================
export const AUTH_USER = "auth_user",
  UNAUTH_USER = "unauth_user",
  AUTH_ERROR = "auth_error",
  FORGOT_PASSWORD_REQUEST = "forgot_password_request",
  RESET_PASSWORD_REQUEST = "reset_password_request",
  REGISTER_PAGE_UNLOADED = "register_page_unload",
  LOGIN_PAGE_UNLOADED = "login_page_unload",
  PROTECTED_TEST = "protected_test";

//===========================================================
// User Profile Actions
//===========================================================
export const FETCH_USER = "fetch_user",
  UPDATE_USER_PROFILE = 'update_user_profile',
  CHANGE_PASSWORD = 'change_password',
  FETCH_PAYMENT_METHODS = 'fetch_payment_methods',
  ADD_PAYMENT_METHOD = 'add_payment_method',
  UPDATE_PAYMNET_METHOD = 'update_payment_method',
  DELETE_PAYMENT_METHOD = 'delete_payment_method',
  INVITE_BY_EMAIL = 'invite_by_email',
  INVITE_BY_SOCIAL_NETOWRK = 'invite_by_social_network',
  FETCH_BY_REFERRAL_TOKEN = "fetch_by_referral_token",
  FETCH_CUSTOMER_COUPONS = "fetch_customer_coupons",
  FETCH_CUSTOMER_GIFTCARDS = "fetch_customer_giftcards",
  USER_ERROR = 'user_error';

//===========================================================
// Restaurant Actions
//===========================================================
export const FETCH_RESTAURANTS = "fetch_restaurants",
  FETCH_RESTAURANT = "fetch_restaurant",
  SELECT_LOCATION = "select_location",
  FETCH_BUSINESS_HOURS = "fetch_business_hours",
  FETCH_TODAY_BUSINESS_HOURS = "fetch_today_business_hours",
  FETCH_MENUS = "fetch_menus",
  FETCH_MENU = "fetch_menu",
  FETCH_PROMOTIONS = "fetch_promotions",
  FETCH_GIFTCARDPRODUCTS = "fetch_giftcardproducts",
  FETCH_GIFT_CARD_PRODUCT = "fetch_gift_card_product",
  SELECT_RESTAURANT = "select_restaurant",
  VIEW_MENUS = "view_menus",
  VIEW_MENU = "view_menu",
  FETCH_ONLINE_ORDER_MENUS = "fetch_online_order_menu",
  FETCH_LOYALTY_REWARDS = "fetch_loyalty_rewards",
  FETCH_TENANT = "fetch_tenant",
  FETCH_PICKUP_DATES = "fetch_pickup_dates",
  FETCH_ANNOUNCEMENTS = "fetch_announcements",
  FETCH_COUPONS = "fetch_coupons",
  FETCH_PLASTIC_CUTLERY_ITEMS = "fetch_plastic_cutlery_items",
  TENANT_ERROR = "tenant_error";

//===========================================================
// Order Actions
//===========================================================
export const CANCEL_ORDER = "cancel_order",
  SAVE_ORDER = "save_order",
  CHANGE_PICKUP_LOCATION = "change_pickup_location",
  CHANGE_PICKUP_DATE = "change_pickup_date",
  CHANGE_PICKUP_TIME_TYPE = "change_pickup_time_type",
  CHANGE_PICKUP_TIME = "change_pickup_time",
  CHANGE_PICKUP_PERSON = "change_pickup_person",
  CHANGE_CUSTOMER = "change_customer",
  LOGIN_TO_PROCEED = "login_to_proceed",
  CHANGE_SPECIAL_REQUEST = "change_special_request",
  CHANGE_ORDER_INSTRUCTIONS = "change_order_instructions",
  FETCH_ORDER = "fetch_order",
  FETCH_ORDERS = "fetch_orders",
  CHANGE_ORDER_TYPE = "change_order_type",
  CANCEL_EXISTING_ORDER = "cancel_existing_order",
  CHANGE_PAYMENT_METHOD = "change_payment_method",
  SELECT_MENU = "select_menu",
  CHANGE_PICKUP_TIME_WITH_MENU = "change_pickup_time_with_menu",
  CHANGE_DELIVERY_ADDRESS = "change_delivery_address",
  CLEAR_DELIVERY_ADDRESS = "clear_delivery_address",
  CHANGE_DELIVERY_METHOD = "change_delivery_method",
  CREATE_GIFT_CARD_ORDER = "create_gift_card_oder",
  SAVE_GIFT_CARD_ORDER = "save_gift_card_order",
  GIFT_CARD_ORDER_ERROR = "gift_card_order_error",
  CHECK_CUSTOMER_BLOCKED = "check_customer_blocked",
  FETCH_MOST_ORDERED_ITEMS = "fetch_most_ordered_items",
  ORDER_ERROR = "order_error";

//===========================================================
// Reservation Actions
//===========================================================
export const CANCEL_RESERVATION = "cancel_reservation",
  SAVE_RESERVATION = "save_reservation",
  FETCH_RESERVATIONS = "fetch_reservations",
  RESERVATION_ERROR = "reservation_error";

//===========================================================
// Cart Actions
//===========================================================
export const ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  INCREASE_QUANTITY = "increase_quantity",
  DECREASE_QUANTITY = "decrease_quantity",
  CLEAR_CART = "clear_cart",
  CART_ERROR = "cart_error",
  CHECKOUT_SUCCESS = "checkout_success",
  CHECKOUT_REQUEST = "checkout_request",
  VALIDATE_AND_APPLY_PROMOTIONS = "validate_and_apply_promotions",
  REMOVE_PROMOTION_FROM_CART = "remove_promotion_from_cart",
  APPLY_COUPON = "apply_coupon",
  REMOVE_COUPON_FROM_CART = "remove_coupon_from_cart",
  FETCH_GIFT_CARD_BY_GIFT_CARD_NUMBER = "fetch_gift_card_by_gift_card_number",
  REDEEM_GIFT_CARD = "redeem_gift_card",
  PREAUTH_GIFT_CARD = "pre_auth_gift_card",
  REMOVE_GIFT_CARD_FROM_CART = "remove_gift_card_from_cart",
  CHARGE = "charge";

//===========================================================
// Messaging Actions
//===========================================================
export const FETCH_CONVERSATIONS = "fetch_conversations",
  FETCH_RECIPIENTS = "fetch_recipients",
  START_CONVERSATION = "start_conversation",
  FETCH_SINGLE_CONVERSATION = "fetch_single_conversation",
  FETCH_PARTICIPANT = "fetch_participant",
  CLEAR_MESSAGE = "clear_message",
  CHAT_ERROR = "chat_error",
  SEND_REPLY = "send_reply";

//===========================================================
// Page Actions
//===========================================================
export const SEND_CONTACT_FORM = "send_contact_form",
  STATIC_ERROR = "static_error";
