import React from "react";
import { Link } from "react-router-dom";

const GiftCard = ({ slug, giftCardProduct }) => {
  return (
    <Link to={`/gift/${giftCardProduct.id}`}>
      <div className="card clickable">
        {giftCardProduct.image && giftCardProduct.image.url && (
          <img
            className="card-img-top"
            src={
              process.env.REACT_APP_S3_BASE_URL +
              `${encodeURIComponent(slug + "/" + giftCardProduct.image.url)}`
            }
            alt="Gift Card"
          />
        )}
        <div className="card-body">
          <h5 className="card-title text-center">{giftCardProduct.name}</h5>
        </div>
      </div>
    </Link>
  );
};

export default GiftCard;
