import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import OrderInfoCard from "../Customer/OrderInfoCard";

import { fetchOrder, cancelExistingOrder } from "../../actions/order";
import queryString from "query-string";

import { sendGAEvent } from "../../utils/analytics";

class OrderDetailCard extends Component {
  render() {
    const { order } = this.props;
    return (
      <div className="row">
        <div className="col-12">
          <OrderInfoCard order={order} />
        </div>
      </div>
    );
  }
}
class Order extends Component {
  constructor(props) {
    super(props);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);

    const queryParameters = queryString.parse(this.props.location.search);
    const orderId = queryParameters.id;
    this.state = {
      orderId: orderId,
    };
  }

  componentDidMount() {
    if (this.state.orderId) {
      sendGAEvent({
        category: "User",
        action: "View Order",
        label: `orderId: ${this.state.orderId}`,
      });
      this.props.fetchOrder(this.props.tenant._id, this.state.orderId);
    }
  }

  handleCancelOrder(order) {
    sendGAEvent({
      category: "User",
      action: "Cancel Order",
      label: `orderId: ${this.state.orderId}`,
    });
    this.props.cancelExistingOrder(this.props.tenant._id, order._id);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { order } = this.props;

    if (!order) {
      return <></>;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                Order: {order.orderNumber}
              </button>
            </li>
          </ol>
        </nav>
        {this.renderAlert()}
        <OrderDetailCard ref={(el) => (this.componentRef = el)} order={order} />
        <div className="row pt-3 pb-3">
          <div className="col-3"></div>
          <div className="col-9 text-right">
            <div className="row">
              <div className="col-12">
                {order.status === "Received" && (
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => this.handleCancelOrder(order)}
                  >
                    <Icon.Slash size={16} />
                    {` `}Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
    error: state.order.error,
    message: state.order.message,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (tenantId, orderNumber) =>
    dispatch(fetchOrder(tenantId, orderNumber)),
  cancelExistingOrder: (tenantId, orderNumber) =>
    dispatch(cancelExistingOrder(tenantId, orderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
