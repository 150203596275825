import {
  CREATE_GIFT_CARD_ORDER,
  SAVE_GIFT_CARD_ORDER,
  GIFT_CARD_ORDER_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_GIFT_CARD_ORDER:
      return { ...state, giftCardOrder: { ...action.payload } };
    case SAVE_GIFT_CARD_ORDER:
      return {
        ...state,
        customer: null,
        paymentMethod: null,
      };
    case GIFT_CARD_ORDER_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
