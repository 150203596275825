import { combineReducers } from 'redux';  
import { reducer as formReducer } from 'redux-form';  
import { connectRouter } from 'connected-react-router';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import communicationReducer from './communication_reducer';
import tenantReducer from './tenant_reducer';
import cartReducer from './cart_reducer';
import orderReducer from './order_reducer';
import reservationReducer from './reservation_reducer';
import giftCardOrderReducer from './gift_card_order_reducer';

const rootReducer = (history) => combineReducers({  
  router: connectRouter(history),
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  communication: communicationReducer,
  tenant: tenantReducer,
  cart: cartReducer,
  order: orderReducer,
  reservation: reservationReducer,
  giftCardOrder: giftCardOrderReducer
});

export default rootReducer;  
