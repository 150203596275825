import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment-timezone";

import "../Pickup/Pickup.css";
import PickupTimeTable from "../Pickup/PickupTimeTable";
import PickupDateTable from "../Pickup/PickupDateTable";

import CancelOrderLink from "./CancelOrderLink";

import { cancelOrder } from "../../actions/order";

import { clearCart } from "../../actions/cart";

import { CANCEL_ORDER } from "../../actions/types";

import * as Icon from "react-feather";

import {
  fetchPickupDates,
  fetchBusinessHoursByTenantId,
} from "../../actions/tenant";

import {
  changePickupDate,
  changePickupTime,
  changePickupTimeType,
} from "../../actions/order";

import { sendGAEvent } from "../../utils/analytics";

import {
  CHANGE_PICKUP_TIME,
  CHANGE_PICKUP_DATE,
  CHANGE_PICKUP_TIME_TYPE,
} from "../../actions/types";
import ChooseRestaurant from "./ChooseRestaurant";

import { getOpeningHourRange } from "../Common/OpeningHourRange";

import { calculateOrderLeadTime } from "../../utils/leadtime";

class ChoosePickupTime extends Component {
  constructor(props) {
    super(props);

    this.handleSelectPickupTimeType =
      this.handleSelectPickupTimeType.bind(this);

    this.handleSelectPickupDate = this.handleSelectPickupDate.bind(this);
    this.handleSelectPickupTime = this.handleSelectPickupTime.bind(this);

    this.resize = this.resize.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);

    this.state = {
      smallScreen: false,
      pickupDate: moment(),
      pickupTime: moment(),
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.props.fetchPickupDates(this.props.restaurant._id);
    }
    if (this.props.tenant) {
      this.props.fetchBusinessHours(this.props.tenant._id);
    }
    this.props.changePickupDate(moment(), (dispatch) => {
      dispatch({ type: CHANGE_PICKUP_DATE, payload: moment() });
    });
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({ smallScreen: true, timeslotsPerRow: 4 });
    } else if (window.innerWidth < 992) {
      this.setState({ smallScreen: true, timeslotsPerRow: 5 });
    } else if (window.innerWidth < 1200) {
      this.setState({ smallScreen: false, timeslotsPerRow: 10 });
    } else {
      this.setState({ smallScreen: false, timeslotsPerRow: 10 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  handleSelectPickupTimeType(selectedPickupTimeType) {
    sendGAEvent({
      category: "Checkout",
      action: "Select Pikcup Time Type",
      label: `${selectedPickupTimeType}`,
    });
    this.props.changePickupTimeType(selectedPickupTimeType, (dispatch) => {
      dispatch({
        type: CHANGE_PICKUP_TIME_TYPE,
        payload: selectedPickupTimeType,
      });
    });
  }

  handleSelectPickupDate(selectedDate, pickupDates) {
    sendGAEvent({
      category: "Checkout",
      action: "Select Pikcup Date",
      label: `${moment(selectedDate)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.date_format)}`,
    });
    const isValidPickupDate =
      pickupDates.filter(
        (pd) =>
          moment(pd).format("YYYY-MM-DD") ===
          moment(selectedDate).format("YYYY-MM-DD")
      ).length > 0;
    if (isValidPickupDate) {
      this.props.changePickupDate(selectedDate, (dispatch) => {
        dispatch({ type: CHANGE_PICKUP_DATE, payload: selectedDate });
      });
    } else {
      this.props.changePickupDate(pickupDates[0], (dispatch) => {
        dispatch({ type: CHANGE_PICKUP_DATE, payload: pickupDates[0] });
      });
    }
  }

  handleSelectPickupTime(selectedTime) {
    sendGAEvent({
      category: "Checkout",
      action: "Select Pikcup Time",
      label: `${moment(selectedTime)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.time_format)}`,
    });
    this.props.changePickupTime(selectedTime, (dispatch) => {
      dispatch({
        type: CHANGE_PICKUP_TIME,
        payload: selectedTime,
      });
    });
  }

  handleCancelOrder() {
    sendGAEvent({
      category: "Checkout",
      action: "Cancel Order",
      label: ``,
    });
    this.props.clearCart();
    this.props.cancelOrder((dispatch) => {
      dispatch({ type: CANCEL_ORDER, payload: {} });
    });
  }

  

  render() {
    const {
      holidayHours = [],
      restaurant,
      pickupDate,
      pickupTime,
      pickupDates = [],

      deliveryMethod,
    } = this.props;

    if (!restaurant) {
      return <ChooseRestaurant />;
    }


    let isValidPickupTime = true;

    const pickupTimeSensitivePromotionRules = this.props.promotionRules.filter(
      (promotionRule) => promotionRule.type === "Buy one, get one free"
    );

    const currentDateTime = moment().tz(restaurant.settings.time_zone);
    let timeRange = getOpeningHourRange(
      restaurant,
      currentDateTime,
      restaurant.regular_business_hours,
      holidayHours,
      restaurant.settings.online_order_start,
      restaurant.settings.online_order_end
    );
    let isOpen =
      timeRange.isOpen &&
      currentDateTime.isSameOrAfter(timeRange.startAt) &&
      currentDateTime.isSameOrBefore(timeRange.endAt);

   const leadTime = calculateOrderLeadTime(deliveryMethod, restaurant.settings);

    const nextButtonDisabled = !(
      this.props.pickupTimeType === "ASAP" ||
      (this.props.pickupDate && this.props.pickupTime && isValidPickupTime)
    );

    return (
      <React.Fragment>
        <div className="row pt-3 pb-3">
          <div className="col-12">
            <h3 className="pb-3 border-bottom">
              <strong>
                CHOOSE {deliveryMethod === "Delivery" ? "DELIVERY" : "PICKUP"}{" "}
                TIME
              </strong>
            </h3>
          </div>
        </div>

        {!isOpen && (
          <div className="row pb-3">
            <div className="col-12 text-warning">
              <i className="fas fa-clock mr-3"></i>
              <strong>{`We're closed now but you can pre-order for later. Please click Pre-order For Later to choose your preferred ${
                deliveryMethod === "Delivery" ? "delivery" : "pickup"
              }
 time`}</strong>
            </div>
          </div>
        )}

        {pickupTimeSensitivePromotionRules.map((promotionRule) => (
          <div className="row pb-3" key={promotionRule._id}>
            <div className="col-12 text-info">
              <i className="fas fa-bullhorn mr-3" />
              <strong>{promotionRule.description ?? promotionRule.name}</strong>
            </div>
          </div>
        ))}

        <div className="form-check ">
          <input
            className="form-check-input"
            type="radio"
            name="pickupTimeType"
            id="pickupTimeASAP"
            checked={this.props.pickupTimeType === "ASAP"}
            onChange={() => this.handleSelectPickupTimeType("ASAP")}
            disabled={!isOpen}
          ></input>
          <label className="form-check-label" for="pickupTimeASAP">
            <h5>ASAP</h5>
          </label>
        </div>
        <div className="form-check ">
          <input
            className="form-check-input"
            type="radio"
            name="pickupTimeType"
            id="pickupTimeSchedule"
            checked={this.props.pickupTimeType === "Schedule"}
            onChange={() => this.handleSelectPickupTimeType("Schedule")}
          ></input>
          <label className="form-check-label" for="pickupTimeSchedule">
            <h5>Pre-order For Later</h5>
          </label>
        </div>

        {this.props.pickupTimeType === "Schedule" && (
          <>
            <div className="row pb-3">
              <div className="col-12">
                <h4>
                  <Icon.Calendar size={24} />
                  {` `}
                  <strong>
                    {deliveryMethod === "Delivery" ? "Delivery" : "Pickup"} Date
                  </strong>
                </h4>

                <PickupDateTable
                  pickupDates={pickupDates}
                  onSelectPickupDate={this.handleSelectPickupDate}
                  pickupDate={pickupDate}
                  restaurant={restaurant}
                />
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-12">
                <h4>
                  <Icon.Clock size={24} />
                  {` `}
                  <strong>
                    {deliveryMethod === "Delivery" ? "Delivery" : "Pickup"} Time
                  </strong>
                </h4>
                <p>
                  <span className="mr-3">
                    Select an available{" "}
                    {deliveryMethod === "Delivery" ? "delivery" : "pickup"}{" "}
                    time.
                  </span>
                  {/* <span>
                <strong>
                  {moment.tz(restaurant.settings.time_zone).format("Z z")}
                </strong>
              </span> */}
                </p>

                <PickupTimeTable
                  onlineOrderStart={restaurant.settings.online_order_start}
                  onlineOrderEnd={restaurant.settings.online_order_end}
                  businessHours={restaurant.regular_business_hours}
                  holidayHours={holidayHours}
                  intervalInMinutes={
                    this.props.deliveryMethod === "Pickup"
                      ? restaurant.settings.pickup_time_interval_in_minutes
                      : restaurant.settings.delivery_time_interval_in_minutes
                  }
                  timeslotsPerRow={this.state.timeslotsPerRow}
                  currentTime={moment.tz(restaurant.settings.time_zone)}
                  currentDate={moment
                    .tz(restaurant.settings.time_zone)
                    .startOf("day")}
                  pickupDate={moment(this.props.pickupDate)
                    .tz(restaurant.settings.time_zone)
                    .startOf("day")}
                  pickupDates={pickupDates}
                  onSelectPickupTime={this.handleSelectPickupTime}
                  pickupTime={pickupTime}
                  restaurant={restaurant}
                  leadTime={leadTime}
                  isValidPickupTime={isValidPickupTime}
                />
              </div>
            </div>
          </>
        )}

        <div className="row pt-3 pb-3 mt-3 border-top">
          <div className="col-3 text-left">
            <button
              className="btn btn-secondary btn-block"
              onClick={() => {
                this.props.history.push(
                  deliveryMethod === "Delivery"
                    ? "/deliveryaddress"
                    : "/orderonline"
                );
              }}
            >
              <Icon.ChevronLeft size={24} />{" "}
              <span className="d-none d-sm-inline">BACK</span>
            </button>
          </div>
          <div className="col-5 text-left">
            <CancelOrderLink onCancelOrder={this.handleCancelOrder} />
          </div>
          <div className="col-4 text-right">
            <button
              className="btn btn-angkor btn-block"
              disabled={nextButtonDisabled}
              onClick={() => {
                this.props.history.push("/buildorder");
              }}
            >
              <span className="d-none d-sm-inline">CONTINUE</span>
              <Icon.ChevronRight size={24} />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant?._id
      )
    : [];

  // const currentDateTime = moment().tz(state.tenant.restaurant.settings.time_zone);
  // let timeRange = getOpeningHourRange(
  //   state.tenant.restaurant,
  //   currentDateTime,
  //   state.tenant.restaurant.regular_business_hours,
  //   holidayHours,
  //   state.tenant.restaurant.settings.online_order_start,
  //   state.tenant.restaurant.settings.online_order_end
  // );
  // let isOpen =
  //   timeRange.isOpen &&
  //   currentDateTime.isSameOrAfter(timeRange.startAt) &&
  //   currentDateTime.isSameOrBefore(timeRange.endAt);

  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    orderType: state.order.orderType,
    pickupTimeType: state.order.pickupTimeType,
    pickupDate:
      state.order.pickupDate ||
      moment().tz(state.tenant.restaurant?.settings.time_zone)?.startOf("day"),
    pickupTime: state.order.pickupTime,
    holidayHours: holidayHours,
    pickupDates: state.tenant.pickupDates,
    items: state.cart.items,
    deliveryMethod: state.order.deliveryMethod,
    promotionRules: state.tenant.promotions || [],
    pormotions: state.cart.promotions,
    businessHours: state.tenant.businessHours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPickupDates: (restaurantId) => dispatch(fetchPickupDates(restaurantId)),
  changePickupTimeType: (pickupTimeType, callback) =>
    dispatch(changePickupTimeType(pickupTimeType, callback)),
  changePickupDate: (pickupDate, callback) =>
    dispatch(changePickupDate(pickupDate, callback)),
  changePickupTime: (pickupTime, callback) =>
    dispatch(changePickupTime(pickupTime, callback)),
  cancelOrder: (callback) => dispatch(cancelOrder(callback)),
  clearCart: () => dispatch(clearCart()),
  fetchBusinessHours: (tenantId) =>
    dispatch(fetchBusinessHoursByTenantId(tenantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChoosePickupTime));
