import React from "react";
import moment from 'moment-timezone';

const HolidayHours = ({ holidayHours=[], restaurant }) => {
  return (
    <React.Fragment>
      {holidayHours.map((holidayHour, index) => (
        <div className="row mb-1 text-muted" key={index}>
          <div className="col-6 text-right font-weight-bold">
            {moment(holidayHour.date).tz(restaurant.settings.time_zone).format(restaurant.settings.date_format)}:
          </div>
          <div className="col-6 text-left">
            {holidayHour.is_open ? <span>{moment(holidayHour.open_time, restaurant.settings.time_format).format(restaurant.settings.time_format)} - {moment(holidayHour.close_time, restaurant.settings.time_format).format(restaurant.settings.time_format)}</span> : <span>CLOSED</span>}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}


export default HolidayHours;
