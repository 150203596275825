import axios from 'axios';
import Cookies from 'universal-cookie';

import {
  errorHandler
} from './index';

import {
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  PROTECTED_TEST
} from './types';

const cookies = new Cookies();

//==================================================
// Authentication actions
//==================================================

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export function login({ tenantId, email, password }, successCallback) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/auth/login`, { email: `${tenantId}|${email}`, password })
      .then(response => {
        cookies.set('ctoken', response.data.token, { path: '/', maxAge: COOKIE_MAX_AGE });
        cookies.set('customer', response.data.user, { path: '/', maxAge: COOKIE_MAX_AGE });
        successCallback()
        dispatch({ type: AUTH_USER});
      })
      .catch((error) => { 
        let errorMessage = "Email address and/or password is not correct. Please try again";
        dispatch({
          type: AUTH_ERROR,
          payload: errorMessage
        });
      });
  }
}

export function facebookLogin() {
  return function (dispatch) {
    axios.get(process.env.REACT_APP_API_BASE + `/api/eater/auth/facebook`, { })
      .then(response => {
        cookies.set('ctoken', response.data.token, { path: '/', maxAge: COOKIE_MAX_AGE });
        cookies.set('customer', response.data.user, { path: '/', maxAge: COOKIE_MAX_AGE  });
        dispatch({ type: AUTH_USER });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}

export function register(tenantId, { email, firstName, lastName, password, telephone }, successCallback) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/auth/register`, { tenantId, email, firstName, lastName, password, telephone })
      .then(response => {
        cookies.set('ctoken', response.data.token, { path: '/', maxAge: COOKIE_MAX_AGE });
        cookies.set('customer', response.data.user, { path: '/', maxAge: COOKIE_MAX_AGE });
        successCallback()
        dispatch({ type: AUTH_USER });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}

export function logout(error) {
  return function (dispatch) {
    dispatch({ type: UNAUTH_USER, payload: error || '' });
    cookies.remove('ctoken', { path: '/' });
    cookies.remove('customer', { path: '/' });

    // window.location.href = CLIENT_ROOT_URL + '/home';
  }
}

export function getForgotPasswordToken({email}, baseUrl, successCallback) {

  return function(dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/auth/forgot-password`, {email, baseUrl})
    .then((response) => {
      successCallback && successCallback();
      dispatch({
        type: FORGOT_PASSWORD_REQUEST,
        payload: response.data.message,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function resetPassword(token, {password}, successCallback) {
  return function(dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/auth/reset-password/${token}`, {password})
    .then((response) => {
      successCallback && successCallback();
      dispatch({
        type: RESET_PASSWORD_REQUEST,
        payload: response.data.message,
      });
      
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function protectedTest() {
  return function (dispatch) {
    axios.get(process.env.REACT_APP_API_BASE + `/api/eater/protected`, {
      headers: { 'Authorization': cookies.get('ctoken') }
    })
      .then(response => {
        dispatch({
          type: PROTECTED_TEST,
          payload: response.data.content
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}
