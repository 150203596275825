import React from 'react';
import PropTypes from 'prop-types';
import Timeslot from './Timeslot';
import moment from 'moment-timezone';
import Moment from 'moment';

const isAvailable = (timeslot, currentTime, leadTime, currentDate, pickupDate) => {
  return (
    pickupDate.isAfter(currentDate) ||
    (pickupDate.isSame(currentDate) &&
      timeslot.isAfter(moment(currentTime).add(leadTime, "minutes")))
  );
};

const PickupTimeslotRow = ({
  timeslotArray,
  currentTime,
  currentDate,
  pickupDate,
  isValidPickupDate,
  onTimeslotClick,
  pickupTime,
  restaurant,
  leadTime
}) => {
  return (
    <ul className="time-slot">
      {timeslotArray.map((timeslot, index) => (
        <Timeslot
          key={index}
          time={timeslot}
          isAvailable={isValidPickupDate && isAvailable(
            timeslot,
            currentTime,
            leadTime,
            currentDate,
            pickupDate
          )}
          onClick={() => onTimeslotClick(timeslot)}
          pickupTime={pickupTime}
          restaurant={restaurant}
        />
      ))}
    </ul>
  );
};

PickupTimeslotRow.propTypes = {
  timeslotArray: PropTypes.array.isRequired,
  currentTime: PropTypes.instanceOf(Moment).isRequired,
  leadTime: PropTypes.number.isRequired,
  pickupDate: PropTypes.instanceOf(Moment).isRequired,
  isValidPickupDate: PropTypes.bool,
  currentDate: PropTypes.instanceOf(Moment).isRequired
};

export default PickupTimeslotRow;
