import React, { Component } from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import normalizePhone from '../../utils/normalizePhone';
import { phoneNumber, email, required, renderEmailField, renderPhoneField, renderUserNameField } from "../../utils/renderFields";

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = "Please enter your first name";
  }

  if (!formProps.lastName) {
    errors.lastName = "Please enter your last name";
  }

  if (!formProps.email) {
    errors.email = "Please enter your email address";
  } else {
    let emailValid = formProps.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      errors.email = formProps.email + ' is not valid email address'
    }

  }
  if (!formProps.phone) {
    errors.phone = "Please enter your phone number";
  }

  return errors;
}

const form = reduxForm({
  form: "proceedAsGuest", 
  validate 
});
class ProceedAsGuest extends Component {

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
   
    return (
      <div className="card mb-3">
        <div className="card-header text-center"><strong>CHECKOUT AS A GUEST</strong></div>
        <div className="card-body text-secondary">
          <form onSubmit={handleSubmit}>
            <h5>
              <span>
                Please let us know this info so we can contact you about your order.
          </span>
            </h5>

            <div className="form-group">
              <label>Your first Name</label>
              <Field
                name="firstName"
                className="form-control"
                component={renderUserNameField}
                validate={[required]}
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Your last Name</label>
              <Field
                name="lastName"
                className="form-control"
                component={renderUserNameField}
                validate={[required]}
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Your email address</label>
              <Field
                name="email"
                className="form-control"
                component={renderEmailField}
                validate={[required, email]}
                disabled={this.props.couponEmail}
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <Field
                name="phone"
                className="form-control"
                component={renderPhoneField}
                validate={[required, phoneNumber]}
                placeholder="___-___-____"
                type="text"
                normalize={normalizePhone}
              />
            </div>
            <div className="controls text-center">
              <button
                type="submit"
                disabled={pristine || submitting}
                className="btn btn-angkor btn-block"
              >
                <span>{`PROCEED AS GUEST`}</span>
              </button>
            </div>
          </form>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    couponEmail: state.cart.email,
    initialValues: {
      email: state.cart.email
    }
  };
};

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ProceedAsGuest));