import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";

import { selectLocation } from "../../actions/tenant";
import OpeningHours from "../Common/OpeningHours";

class Location extends Component {
  constructor(props) {
    super(props);

    this.handleSelectLocation = this.handleSelectLocation.bind(this);
  }

  componentDidMount() { }

  handleSelectLocation(restaurant) {
    this.props.selectLocation(restaurant);
  }

  render() {
    const {
      restaurant,
      showButtons = true,
      holidayHours = [],
      selectedRestuaurant,
      onSelectLocation
    } = this.props;
    return (
      <div className="row pt-3 pb-3 border-bottom">
        <div className={showButtons ? "col-12 col-sm-8" : "col-12"}>
          <div className="row">
            <div className="col-12 col-sm-8 text-center text-sm-left">
              <h5>
                <strong>{restaurant.name}</strong>
              </h5>
            </div>
            <div className="col-12 col-sm-4 text-right mb-3">
              {(!selectedRestuaurant ||
                (selectedRestuaurant &&
                  selectedRestuaurant._id !== restaurant._id)) && (
                  <button
                    className="btn btn-danger btn-block"
                    onClick={
                      onSelectLocation
                        ? () => onSelectLocation(restaurant)
                        : () => this.handleSelectLocation(restaurant)
                    }
                  >
                    SELECT
                </button>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center text-sm-left">
              <address>
                {restaurant.address_1} <br />
                {restaurant.city_town}, {restaurant.state_province}{" "}
                {restaurant.postal_code} <br />
                {restaurant.phone} <br />
              </address>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center text-sm-left">
              <h5>Business Hours</h5>
              <div className="text-secondary">
                <OpeningHours restaurant={restaurant} />
              </div>
            </div>
            {holidayHours && holidayHours.length > 0 && (
              <div className="col-12 text-center text-sm-left">
                <h5>Holiday Hours</h5>
                <div className="text-secondary">
                  {holidayHours.map(businessHour => (
                    <div className="row" key={businessHour._id}>
                      <div className="col-4">
                        {moment(businessHour.date)
                          .tz(restaurant.settings.time_zone)
                          .format(restaurant.settings.datetime_format)}
                      </div>
                      <div className="col-8">
                        {businessHour.is_open ? (
                          <span>
                            {businessHour.open_time} - {businessHour.close_time}
                          </span>
                        ) : (
                            <span>CLOSED</span>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {showButtons && (
          <div className="col-12 col-sm-4 ">
            <div className="row mb-2">
              <div className="col-12">
                {showOnlineOrdering && (
                  <button
                    className="btn btn-angkor  btn-block"
                    onClick={() => handleOrderOnline(restaurant)}
                  >
                    {`See MENU & Order`}
                  </button>
                )}
                {!showOnlineOrdering && (
                  <button
                    className="btn btn-angkor btn-block"
                    onClick={() => handleViewMenus(restaurant)}
                  >
                    {`See MENU`}
                  </button>
                )}

              </div>
            </div>
            {showOnlineReservation && (
              <div className="row mb-2">
                <div className="col-12">
                  <button
                    className="btn btn-angkor btn-block"
                    onClick={() => handleMakeReservation(restaurant)}
                  >
                    {`Reserve Table`}
                  </button>
                </div>
              </div>
            )}
          </div>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedRestuaurant: state.tenant.restaurant
  };
};

const mapDispatchToProps = dispatch => ({
  selectLocation: restaurant => dispatch(selectLocation(restaurant))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Location);
