import React from "react";
import GoogleMapReact from "google-map-react";
import * as Icon from "react-feather";

const AnyReactComponent = ({ text }) => <Icon.MapPin color="red" />;

const Map = ({restaurants=[], height="100%"}) => {

  let defaultCenter = {};
    if (restaurants.length > 0) {
      defaultCenter = {
        lat: restaurants[0].latitude,
        lng: restaurants[0].longitude
      };
    }

    return (
      <div className="row">
      <div className="col-12">
        <div style={{ height: "350px", width: "100%" }}>
          {restaurants.length > 0 && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
              yesIWantToUseGoogleMapApiInternals={true}
              defaultCenter={defaultCenter}
              defaultZoom={11}
            >
              {restaurants.map(restaurant => (
                <AnyReactComponent
                  key={restaurant._id}
                  lat={restaurant.latitude}
                  lng={restaurant.longitude}
                  text={restaurant.name}
                />
              ))}
            </GoogleMapReact>
          )}
        </div>
      </div>
    </div>
    )
}

export default Map;