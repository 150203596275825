import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import LoginToProceed from "../Checkout/LoginToProceed";
import ProceedAsGuest from "../Checkout/ProceedAsGuest";
import * as Icon from "react-feather";
import moment from "moment-timezone";

import { saveGiftCardOrder } from "../../actions/giftcardorder";

import { sendGAEvent } from "../../utils/analytics";

import {
  osName,
  osVersion,
  browserName,
  deviceType,
  fullBrowserVersion,
  getUA,
} from "react-device-detect";

import { socket } from "../Header/Header";

import {
  loginToCheckout,
  changeCustomer,
  changePaymentMethod,
} from "../../actions/order";

import Numeral from "numeral";

import Cookies from "universal-cookie";

import PayOnlineModal from "../Checkout/PayOnlineModal";

const cookies = new Cookies();
const user = cookies.get("customer");

class GiftCardCheckout extends Component {
  constructor(props) {
    super(props);

    this.handleProceedAsGuest = this.handleProceedAsGuest.bind(this);
    this.handleLoginToProceed = this.handleLoginToProceed.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.handleGotIt = this.handleGotIt.bind(this);

    this.state = {
      paymentMethod: props.paymentMethod || "Offline",
      disabled: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {
      paymentMethod: props.paymentMethod || "Offline",
    };

    return newState;
  }

  handleProceedAsGuest(formProps) {
    sendGAEvent({
      category: "Checkout",
      action: "Proceed As Guest",
      label: `${formProps.firstName} ${formProps.lastName}`,
    });
    this.props.changeCustomer(
      this.props.tenant._id,
      formProps.firstName,
      formProps.lastName,
      formProps.email,
      formProps.phone
    );
  }

  handleLoginToProceed(formProps) {
    sendGAEvent({
      category: "Checkout",
      action: "Login To Proceed",
      label: `${formProps.email.split("@")[0]}`,
    });
    this.props.loginToCheckout(formProps, () => {});
  }

  handlePaymentMethodChange(event) {
    sendGAEvent({
      category: "Checkout",
      action: "Change Payment Method",
      label: `${event.target.value}`,
    });
    this.props.changePaymentMethod(event.target.value);
    this.setState({ paymentMethod: event.target.value });
  }

  handleGotIt() {
    sendGAEvent({
      category: "Checkout",
      action: "Click Got It",
      label: ``,
    });
    this.setState({ giftCardOrder: undefined, redirectTo: "/gift" });
  }

  saveOrder() {
    sendGAEvent({
      category: "Checkout",
      action: "Submit GiftCard Order: Start",
      label: ``,
    });
    let customer = {};

    const user = cookies.get("customer");

    if (user) {
      customer = {
        userId: user._id,
        firstName: user.local.firstName,
        lastName: user.local.lastName,
        email: user.local.email,
        phone: user.local.telephone,
      };
    } else {
      customer = { ...this.props.customer };
    }

    const chunks = window.location.href.split("/");
    const orderLink = chunks[0] + "//" + chunks[2] + "/giftcardorder";

    this.props.saveGiftCardOrder(
      this.props.restaurant._id,
      this.props.giftCardOrder.giftCardType,
      this.props.giftCardOrder.giftCardProduct,
      this.props.giftCardOrder.quantity,
      this.props.giftCardOrder.total,
      customer,
      this.state.paymentMethod,
      orderLink,
      (data) => {
        this.setState({ giftCardOrder: data.giftCardOrder });
        socket.emit(
          "new gift ard order",
          this.props.restaurant._id,
          data.giftCardOrder,
          this.props.restaurant.settings.time_zone
        );

        sendGAEvent({
          category: "Checkout",
          action: "Submit GiftCard Order: Complete",
          label: `; ${data.giftCardOrder.giftCardOrderNumber}: ${browserName}|${fullBrowserVersion}|${osName}|${osVersion}|${deviceType}|${getUA}`,
        });
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { authenticated, tenant, restaurant, giftCardOrder } = this.props;

    if (this.state.giftCardOrder) {
      return (
        <div className="h-100 row align-items-center">
          <div className="col">
            <div className="card border-success text-center mx-auto">
              <div className="card-body">
                <h3 className="card-title">
                  Thank you {this.state.giftCardOrder.nameOrderedUnder}
                </h3>
                <p className="card-text font-weight-lighter">{`Please call us ${tenant.phone} or come to restaurant to make payment`}</p>
                <p className="card-text font-weight-lighter">{`Digital gift cards will be sent to your email once payment is confirmed`}</p>
                <p className="card-text font-weight-lighter">{`Physical gift cards can be picked up at counter once payment is confirmed`}</p>
                <div className="row">
                  <div className="col-12 ">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {this.state.giftCardOrder.giftCardOrderNumber}
                        </p>
                        <p className="card-text">{`Order Number`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={this.handleGotIt}
                >
                  GOT IT
                </button>
                <p className="card-text font-weight-lighter font-italic pt-3">
                  {`Your order was submitted at ${moment(
                    this.state.giftCardOrder.updatedAt
                  )
                    .tz(restaurant.settings.time_zone)
                    .format(restaurant.settings.datetime_format)}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    let customer = {};
    if (user) {
      customer = {
        userId: user._id,
        firstName: user.local.firstName,
        lastName: user.local.lastName,
        email: user.local.email,
        phone: user.local.telephone,
      };
    } else {
      customer = this.props.customer;
    }

    const showMembership = tenant.settings.membership === true;
    const acceptOnlinePayment =
      tenant.settings?.online_payment_settings?.accept_online_payment === true;

    return (
      <React.Fragment>
        <div className="row pt-3 mb-3">
          <div className="col-12">
            <h3 className="pb-3 border-bottom">
              <Icon.Lock size={36} />
              {` `}
              <strong>SECURE CHECK OUT</strong>
            </h3>
          </div>
        </div>
        {!authenticated && !customer && (
          <div className="row">
            {showMembership && (
              <>
                <div className="col-12 col-sm-12 col-md-6">
                  <LoginToProceed onSubmit={this.handleLoginToProceed} />
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <ProceedAsGuest onSubmit={this.handleProceedAsGuest} />
                </div>
              </>
            )}
            {!showMembership && (
              <>
                <div className="col-12">
                  <ProceedAsGuest onSubmit={this.handleProceedAsGuest} />
                </div>
              </>
            )}
          </div>
        )}
        {(authenticated || customer) && (
          <>
            {this.renderAlert()}
            <div className="row">
              <div className="col-12 col-lg-6">
                <h4>How would you like to pay?</h4>
                <div className="form-group pb-3">
                  {acceptOnlinePayment && (
                    <div className="form-check pt-3">
                      <input
                        name="paymentMethod"
                        className="form-check-input"
                        value={`Online`}
                        onChange={this.handlePaymentMethodChange}
                        type="radio"
                        checked={this.state.paymentMethod === "Online"}
                      />
                      <label className="form-check-label">
                        <i className="fas fa-credit-card mr-2"> </i>
                        {`Pay now online`}
                      </label>
                    </div>
                  )}
                  <div className="form-check pt-3">
                    <input
                      name="paymentMethod"
                      className="form-check-input"
                      value={`Offline`}
                      onChange={this.handlePaymentMethodChange}
                      type="radio"
                      checked={this.state.paymentMethod === "Offline"}
                    />
                    <label className="form-check-label">
                      <i className="fas fa-cash-register mr-2"> </i>
                      {`Pay at counter`}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <h4>We accept: </h4>
                <ul>
                  <li>
                    <i className="fab fa-cc-visa fa-3x mr-3"></i>
                    {`Visa`}
                  </li>
                  <li>
                    <i className="fab fa-cc-mastercard fa-3x mr-3"></i>
                    {`Mastercard`}
                  </li>
                  <li>
                    <i className="fas fa-credit-card fa-3x mr-3"></i>
                    {`Debit`}
                  </li>
                  <li>
                    <i className="fas fa-money-bill-alt fa-3x mr-3"></i>
                    {`Cash`}
                  </li>
                </ul>
                {/* <img
                  src={visa_icon}
                  alt="visa"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <img
                  src={mastercard_icon}
                  alt="mastercard"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <img
                  src={default_icon}
                  alt="default"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <p className="text-info pt-3">
                  {`Important: Online payments are not charged until you
                        pick up your order. Please bring your method of
                        payment with you when you pick up your order in the
                        event there is an issue processing your payment
                        online.`}
                </p> */}
              </div>
            </div>
            {/* <div className="row">
            <div className="col-12"><OrderSummary /></div>
          </div> */}
            <div className="row pt-3 pb-3 mt-3 border-top">
              <div className="col-3 text-left">
                {/* <button
                  className="btn btn-secondary btn-block"
                  onClick={onPrevious}
                >
                  <Icon.ChevronLeft size={24} />{" "}
                  <span className="d-none d-sm-inline">BACK</span>
                </button> */}
              </div>
              <div className="col-5 text-left"></div>
              <div className="col-4 text-right">
                {this.state.paymentMethod === "Offline" && (
                  <button
                    className="btn btn-angkor btn-block"
                    disabled={this.state.disabled}
                    onClick={() => {
                      this.setState({ disabled: true });
                      this.saveOrder();
                    }}
                  >
                    <Icon.Check size={24} />
                    <span className="d-none d-sm-inline">
                      <strong>
                        COMPLETE ORDER (
                        {Numeral(giftCardOrder.total).format("$0,0.00")})
                      </strong>
                    </span>
                  </button>
                )}
                {this.state.paymentMethod === "Online" && (
                  <PayOnlineModal
                    customer={customer}
                    orderTotal={giftCardOrder.total}
                    onPlaceOrder={this.saveOrder}
                    currencyCode={restaurant.settings.currency_code}
                    restaurant={restaurant}
                    tenant={tenant}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.giftCardOrder.error,
    message: state.giftCardOrder.message,
    giftCardOrder: state.giftCardOrder.giftCardOrder,
    customer: state.order.customer,
    paymentMethod: state.order.paymentMethod,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginToCheckout: (email, password, callback) =>
    dispatch(loginToCheckout(email, password, callback)),
  changeCustomer: (tenantId, firstName, lastName, email, phone) =>
    dispatch(changeCustomer(tenantId, firstName, lastName, email, phone)),
  changePaymentMethod: (paymentMethod) =>
    dispatch(changePaymentMethod(paymentMethod)),
  saveGiftCardOrder: (
    restaurantId,
    giftCardType,
    giftCardProduct,
    quantity,
    total,
    customer,
    paymentMethod,
    orderLink,
    successCallback
  ) =>
    dispatch(
      saveGiftCardOrder(
        restaurantId,
        giftCardType,
        giftCardProduct,
        quantity,
        total,
        customer,
        paymentMethod,
        orderLink,
        successCallback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardCheckout);
