import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createStore, applyMiddleware } from "redux";

import reduxThunk from "redux-thunk";
import Cookies from "universal-cookie";

import rootReducer from "./reducers/index";
import App from "./App";
import "./index.css";
import { AUTH_USER } from "./actions/types";

import { getSubdomain } from "./SubdomainHelper";
import * as serviceWorker from './serviceWorker';
import history from "./components/history";

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
// const history = createBrowserHistory();


const store = createStoreWithMiddleware(rootReducer(history));

const cookies = new Cookies();
const token = cookies.get("ctoken");

if (token) {
  store.dispatch({ type: AUTH_USER });
}

const subdomain = getSubdomain();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App subdomain={subdomain} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();