import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import { clearDeliveryAddress } from "../../actions/order";
import DeliveryLocation from "./DeliveryLocation";

import PickupTime from "../Pickup/PickupTime";
import moment from 'moment';

import { calculateOrderLeadTime } from "../../utils/leadtime";
class DeliveryAddressCard extends Component {

  render() {

    const {
      deliveryAddress,
      pickupTimeType,
      pickupDate,
      pickupTime,
      restaurant,
    } = this.props;

    const pickupTimeRange = restaurant.settings.delivery_time_interval_in_minutes;
    const leadTime = calculateOrderLeadTime("Delivery", restaurant.settings);

    return (
      <div className="card mb-3">
        <div className="card-header">
          <strong>{`Delivery Address`}</strong>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <Icon.MapPin size={16} /> <strong>Location: </strong>
              <DeliveryLocation location={deliveryAddress} />
            </div>
          </div>
          <div className="row pt-3">
          <div className="col-12">
            <Icon.Clock size={16} /> <strong>Delivery Time </strong>
            <PickupTime pickupTimeType={pickupTimeType} pickupDate={moment(pickupDate)} pickupTime={pickupTime} onlineOrderLeadTime={leadTime} restaurant={restaurant} pickupTimeRange={pickupTimeRange}/>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    deliveryAddress: state.order.deliveryAddress,
    pickupTimeType: state.order.pickupTimeType,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    restaurant: state.tenant.restaurant,
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearDeliveryAddress: () => dispatch(clearDeliveryAddress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddressCard);
