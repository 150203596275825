import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { getForgotPasswordToken } from "../../actions/auth";
import PropTypes from "prop-types";

import { renderEmailField, required, email } from "../../utils/renderFields";
import "./Login.css";

import {sendGAEvent} from "../../utils/analytics";

const form = reduxForm({
  form: "forgotPassword",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.email) {
    errors.email = "Please enter your email";
  } else {
    let emailValid = formProps.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (!emailValid) {
      errors.email = formProps.email + " is not valid email address";
    }
  }
  return errors;
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.handleGotIt = this.handleGotIt.bind(this);

    this.state = {
      redirectTo: undefined
    };
  }
  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    if (this.props.authenticated) {
      this.context.router.push("/");
    }
  }

  componentWillUpdate() {
    if (this.props.authenticated) {
      this.context.router.push("/");
    }
  }

  handleFormSubmit(formProps) {
    sendGAEvent({
      category: "Auth",
      action: "ForgotPassword Form Submit",
      label: `${formProps.email.split("@")[0]}`
    });
    const chunks = window.location.href.split("/");
    const baseUrl =  chunks[0] +  "//" + chunks[2];
    this.props.getForgotPasswordToken(formProps, baseUrl, () => {});
  }

  handleGotIt() {
    this.setState({ redirectTo: "/" });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.props.message) {
      return (
        <div className="card border-success text-center mx-auto">
          <div className="card-body">
            <div className="alert alert-success">
              <span>{this.props.message}</span>
            </div>
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={this.handleGotIt}
            >
              GOT IT
            </button>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>FORGOT PASSWORD</strong>
            </h3>
          </div>
        </div>
        <div className="row h-100 align-items-center">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header text-center">Forgot Password</div>
              <div className="card-body">
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                  <h6>
                    Enter your registered email address to reset your password.
                  </h6>
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      name="email"
                      className="form-control"
                      component={renderEmailField}
                      validate={[required, email]}
                      type="email"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-angkor btn-block"
                    disabled={pristine || submitting}
                  >
                    RESET PASSWORD
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated
  };
};

const mapDispatchToProps = dispatch => ({
  getForgotPasswordToken: (formProps, baseUrl, callback) =>
    dispatch(getForgotPasswordToken(formProps, baseUrl, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ForgotPassword));
