import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment-timezone";
import Cookies from "universal-cookie";

import CheckoutStep2 from "./CheckoutStep2";
import CheckoutStep3 from "./CheckoutStep3";

import {
  cancelOrder,
  changePickupDate,
  changePickupTime,
  changeSpecialRequests,
  loginToCheckout,
  changeCustomer,
  changePickupPerson,
  changePaymentMethod,
  saveOrder,
} from "../../actions/order";

import {
  getSubtotal,
  getTax,
  getTotal,
  getQuantity,
  clearCart,
  getGST,
  getPST,
  getDeliveryGST,
  getDeliveryFee,
} from "../../actions/cart";

import { sendGAEvent } from "../../utils/analytics";

import {
  osName,
  osVersion,
  browserName,
  deviceType,
  fullBrowserVersion,
  getUA,
} from "react-device-detect";

import {
  CHANGE_PICKUP_TIME,
  CHANGE_PICKUP_DATE,
  CHANGE_SPECIAL_REQUEST,
  CANCEL_ORDER,
} from "../../actions/types";

import { socket } from "../../service/socket";
import queryString from "query-string";

import { calculateOrderLeadTime } from "../../utils/leadtime";

const cookies = new Cookies();

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.handleSelectPickupDate = this.handleSelectPickupDate.bind(this);
    this.handleSelectPickupTime = this.handleSelectPickupTime.bind(this);

    this.handleSpecialRequestsChange =
      this.handleSpecialRequestsChange.bind(this);

    this.handleProceedAsGuest = this.handleProceedAsGuest.bind(this);
    this.handleLoginToProceed = this.handleLoginToProceed.bind(this);

    this.handlePickupPersonChange = this.handlePickupPersonChange.bind(this);

    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);

    this.saveOrder = this.saveOrder.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);

    this.handleContinueShopping = this.handleContinueShopping.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.handleGotIt = this.handleGotIt.bind(this);

    this.resize = this.resize.bind(this);

    this.state = {
      smallScreen: false,
      pickupDate: moment(),
      pickupTime: moment(),
      stepNumber: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({ smallScreen: true, timeslotsPerRow: 4 });
    } else if (window.innerWidth < 992) {
      this.setState({ smallScreen: true, timeslotsPerRow: 5 });
    } else if (window.innerWidth < 1200) {
      this.setState({ smallScreen: false, timeslotsPerRow: 10 });
    } else {
      this.setState({ smallScreen: false, timeslotsPerRow: 10 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  handleSelectPickupDate(selectedDate, pickupDates) {
    sendGAEvent({
      category: "Checkout",
      action: "Select Pikcup Date",
      label: `${moment(selectedDate)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.date_format)}`,
    });
    const isValidPickupDate =
      pickupDates.filter(
        (pd) =>
          moment(pd).format("YYYY-MM-DD") ===
          moment(selectedDate).format("YYYY-MM-DD")
      ).length > 0;
    if (isValidPickupDate) {
      this.props.changePickupDate(selectedDate, (dispatch) => {
        dispatch({ type: CHANGE_PICKUP_DATE, payload: selectedDate });
      });
    } else {
      this.props.changePickupDate(pickupDates[0], (dispatch) => {
        dispatch({ type: CHANGE_PICKUP_DATE, payload: pickupDates[0] });
      });
    }
  }

  handleSelectPickupTime(selectedTime) {
    sendGAEvent({
      category: "Checkout",
      action: "Select Pikcup Time",
      label: `${moment(selectedTime)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.time_format)}`,
    });
    this.props.changePickupTime(selectedTime, (dispatch) => {
      dispatch({
        type: CHANGE_PICKUP_TIME,
        payload: selectedTime,
      });
    });
  }

  handleSpecialRequestsChange(specialRequests) {
    sendGAEvent({
      category: "Checkout",
      action: "Enter Special Requests",
      label: `${specialRequests}`,
    });
    this.props.changeSpecialRequests(specialRequests, (dispatch) => {
      dispatch({ type: CHANGE_SPECIAL_REQUEST, payload: specialRequests });
      this.setState((prevState) => {
        return { stepNumber: prevState.stepNumber + 1 };
      });
    });
  }

  handleProceedAsGuest(formProps) {
    sendGAEvent({
      category: "Checkout",
      action: "Proceed As Guest",
      label: `${formProps.firstName} ${formProps.lastName}`,
    });
    this.props.changeCustomer(
      this.props.tenant._id,
      formProps.firstName,
      formProps.lastName,
      formProps.email,
      formProps.phone
    );
  }

  handleLoginToProceed(formProps) {
    sendGAEvent({
      category: "Checkout",
      action: "Login To Proceed",
      label: `${formProps.email.split("@")[0]}`,
    });
    this.props.loginToCheckout(formProps, () => {});
  }

  handlePickupPersonChange(formProps) {
    sendGAEvent({
      category: "Checkout",
      action: "Change Pikcup Person",
      label: `${formProps.pickupPerson}`,
    });
    this.props.changePickupPerson(formProps.pickupPerson);
  }

  handlePaymentMethodChange(event) {
    sendGAEvent({
      category: "Checkout",
      action: "Change Payment Method",
      label: `${event.target.value}`,
    });
    this.props.changePaymentMethod(event.target.value);
    this.setState({ paymentMethod: event.target.value });
  }

  handleCancelOrder() {
    sendGAEvent({
      category: "Checkout",
      action: "Cancel Order",
      label: ``,
    });
    this.props.clearCart();
    this.props.cancelOrder((dispatch) => {
      dispatch({ type: CANCEL_ORDER, payload: {} });
      this.setState({ stepNumber: 0 });
    });
  }

  saveOrder(token) {
    sendGAEvent({
      category: "Checkout",
      action: "Submit Order: Start",
      label: ``,
    });
    let customer = {};

    const user = cookies.get("customer");

    if (user) {
      customer = {
        userId: user._id,
        firstName: user.local.firstName,
        lastName: user.local.lastName,
        email: user.local.email,
        phone: user.local.telephone,
      };
    } else {
      customer = { ...this.props.customer };
    }

    const chunks = window.location.href.split("/");
    const orderLink = chunks[0] + "//" + chunks[2] + "/order";

    // let { token } = await this.props.stripe.createToken({ name: this.props.customer.name });

    let _pickupTime = window.sessionStorage.getItem("pickupTime");

    if (this.props.pickupTimeType === "ASAP") {
      const leadTime = calculateOrderLeadTime(this.props.deliveryMethod, this.props.restaurant.settings);
      _pickupTime = moment()
        .tz(this.props.restaurant.settings.time_zone)
        .add(
          leadTime,
          "minutes"
        );
    }

    this.props.saveOrder(
      token,
      this.props.restaurant._id,
      this.props.items,
      this.props.promotions,
      this.props.discounts,
      this.props.discountTotal,
      this.props.subtotal,
      this.props.tax,
      this.props.gst,
      this.props.pst,
      this.props.total,
      this.props.specialRequests,
      this.props.pickupLocation,
      moment(this.props.pickupDate)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.datetime_format),
      // moment(this.props.pickupTime).tz(this.props.restaurant.settings.time_zone).format(this.props.restaurant.settings.datetime_format),
      moment(_pickupTime)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.datetime_format),
      this.props.pickupPerson,
      customer,
      this.state.paymentMethod,
      this.props.deliveryMethod,
      this.props.deliveryAddress,
      this.props.deliveryMethod === "Delivery" ? this.props.deliveryFee : 0,
      this.props.deliveryMethod === "Delivery" ? this.props.deliveryGST : 0,
      orderLink,
      this.props.coupons,
      this.props.redeemedGiftCards,
      this.props.pickupTimeType,
      (data) => {
        this.setState({ order: data.order, couponCode: data.couponCode });
        socket.emit(
          "new order",
          this.props.restaurant._id,
          data.order,
          this.props.restaurant.settings.time_zone
        );

        sendGAEvent({
          category: "Checkout",
          action: "Submit Order: Complete",
          label: `; ${data.order.orderNumber}: ${browserName}|${fullBrowserVersion}|${osName}|${osVersion}|${deviceType}|${getUA}`,
        });
      }
    );
  }

  handleGotIt() {
    sendGAEvent({
      category: "Checkout",
      action: "Click Got It",
      label: ``,
    });
    this.setState({ order: undefined, redirectTo: "/orderonline" });
  }

  handleContinueShopping() {
    sendGAEvent({
      category: "Checkout",
      action: "Click Continue Shopping",
      label: ``,
    });
    this.setState({ redirectTo: "/buildorder" });
  }

  handlePrevious() {
    sendGAEvent({
      category: "Checkout",
      action: "Click Previous",
      label: `Step: ${this.state.stepNumber - 1}`,
    });
    this.setState((prevState) => {
      return { stepNumber: prevState.stepNumber - 1 };
    });
  }

  handleNext() {
    sendGAEvent({
      category: "Checkout",
      action: "Click Next",
      label: `Step: ${this.state.stepNumber + 1}`,
    });
    this.setState((prevState) => {
      return { stepNumber: prevState.stepNumber + 1 };
    });
  }

  render() {
    const { restaurant, deliveryMethod } = this.props;
    if (this.state.order) {
      return (
        <div className="h-100 row align-items-center">
          <div className="col">
            <div className="card border-success text-center mx-auto">
              <div className="card-body">
                <h3 className="card-title">
                  Thank you {this.state.order.nameOrderedUnder}
                </h3>
                <h5 className="card-text">{`We will see you soon`}</h5>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {this.state.order.orderNumber}
                        </p>
                        <p className="card-text">{`Order Number`}</p>
                        {this.state.couponCode && (
                          <>
                            <p className="card-text text-uppercase">
                              {this.state.couponCode}
                            </p>
                            <p className="card-text">{`Coupon Code`}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {moment(this.state.order.pickupTime)
                            .tz(restaurant.settings.time_zone)
                            .format(restaurant.settings.datetime_format)}
                        </p>
                        <p className="card-text">{`${deliveryMethod} Time`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={this.handleGotIt}
                >
                  GOT IT
                </button>
                <p className="card-text pt-3">
                  Order confirmation was sent to {this.state.order.email}
                </p>
                <p className="card-text">
                  {`Your order was submitted at ${moment(
                    this.state.order.updatedAt
                  )
                    .tz(restaurant.settings.time_zone)
                    .format(restaurant.settings.datetime_format)}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (
      (!this.props.items || this.props.items.length <= 0) &&
      (!this.props.promotions || this.props.promotions.length <= 0) &&
      this.props.total < 0.01
    ) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <p className="alert alert-info pb-3 pt-3">
              Your cart is currently empty.
            </p>
            <button
              className="btn btn-secondary"
              onClick={this.handleContinueShopping}
            >
              CONTINUE SHOPPING
            </button>
          </div>
        </div>
      );
    }

    const queryParameters = queryString.parse(this.props.location.search);
    const step = queryParameters.step;

    return (
      <>
        {/* {!step && this.state.stepNumber === 0 && (
          <CheckoutStep1
            timeslotsPerRow={this.state.timeslotsPerRow}
            onPrevious={this.handleContinueShopping}
            onNext={this.handleNext}
            onCancelOrder={this.handleCancelOrder}
            onSelectPickupDate={this.handleSelectPickupDate}
            onSelectPickupTime={this.handleSelectPickupTime}
          />
        )} */}
        {!step && this.state.stepNumber === 0 && (
          <CheckoutStep2
            onPrevious={this.handleContinueShopping}
            // onNext={this.handleNext}
            onCancelOrder={this.handleCancelOrder}
            onSpecialRequestsChange={this.handleSpecialRequestsChange}
          />
        )}
        {(step || this.state.stepNumber === 1) && (
          <CheckoutStep3
            onPrevious={this.handlePrevious}
            onCancelOrder={this.handleCancelOrder}
            handleLoginToProceed={this.handleLoginToProceed}
            handleProceedAsGuest={this.handleProceedAsGuest}
            handlePaymentMethodChange={this.handlePaymentMethodChange}
            onPlaceOrder={this.saveOrder}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    tenant: state.tenant.tenant,
    authenticated: state.auth.authenticated,
    restaurantId: state.order.restaurantId,
    orderType: state.order.orderType,
    restaurant: state.tenant.restaurant,
    pickupTimeType: state.order.pickupTimeType,
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    pickupPerson: state.order.pickupPerson,
    deliveryMethod: state.order.deliveryMethod,
    deliveryAddress: state.order.deliveryAddress,
    items: state.cart.items,
    promotions: state.cart.promotions,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    gst: getGST(state, gstRate),
    pst: getPST(state, pstRate),
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    customer: state.order.customer,
    paymentMethod: state.order.paymentMethod,
    order: state.order.order,
    specialRequests: state.order.specialRequests,
    deliveryFee: getDeliveryFee(state),
    deliveryGST: getDeliveryGST(state, gstRate),
    coupons: state.cart.coupons,
    redeemedGiftCards: state.cart.redeemedGiftCards,
    isBlocked: state.order.isBlocked,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePickupDate: (pickupDate, callback) =>
    dispatch(changePickupDate(pickupDate, callback)),
  changePickupTime: (pickupTime, callback) =>
    dispatch(changePickupTime(pickupTime, callback)),
  changeSpecialRequests: (specialRequests, callback) =>
    dispatch(changeSpecialRequests(specialRequests, callback)),
  cancelOrder: (callback) => dispatch(cancelOrder(callback)),
  clearCart: () => dispatch(clearCart()),
  loginToCheckout: (email, password, callback) =>
    dispatch(loginToCheckout(email, password, callback)),
  changeCustomer: (tenantId, firstName, lastName, email, phone) =>
    dispatch(changeCustomer(tenantId, firstName, lastName, email, phone)),
  changePickupPerson: (pickupPerson) =>
    dispatch(changePickupPerson(pickupPerson)),
  changePaymentMethod: (paymentMethod) =>
    dispatch(changePaymentMethod(paymentMethod)),
  saveOrder: (
    token,
    restaurantId,
    items,
    promotions,
    discounts,
    discountTotal,
    subtotal,
    tax,
    gst,
    pst,
    total,
    includeUtensils,
    specialRequests,
    pickupLocation,
    pickupDate,
    pickupTime,
    pickupPerson,
    customer,
    payment,
    deliveryMethod,
    deliveryAddress,
    deliveryFee,
    deliveryGST,
    orderLink,
    coupons,
    redeemedGiftCards,
    pickupTimeType,
    callback
  ) =>
    dispatch(
      saveOrder(
        token,
        restaurantId,
        items,
        promotions,
        discounts,
        discountTotal,
        subtotal,
        tax,
        gst,
        pst,
        total,
        includeUtensils,
        specialRequests,
        pickupLocation,
        pickupDate,
        pickupTime,
        pickupPerson,
        customer,
        payment,
        deliveryMethod,
        deliveryAddress,
        deliveryFee,
        deliveryGST,
        orderLink,
        coupons,
        redeemedGiftCards,
        pickupTimeType,
        callback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
