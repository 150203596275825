import React, { Component } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import normalizePhone from "../../utils/normalizePhone";
import {
  renderField,
  renderTextAreaField,
  renderRecaptchaField,
} from "../../utils/renderFields";
import * as Icon from "react-feather";
import { sendContactForm } from "../../actions/index";
import { clearMessage } from "../../actions/messaging";

import { sendGAEvent } from "../../utils/analytics";

const form = reduxForm({
  form: "contactForm",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.email) {
    errors.email = "Please enter email address";
  }

  if (!formProps.telephone) {
    errors.telephone = "Please enter phone number";
  }

  if (!formProps.subject) {
    errors.subject = "Please enter subject";
  }

  if (!formProps.message) {
    errors.message = "Please enter message";
  }

  if (!formProps.captchaResponse) {
    errors.captchaResponse = "reCAPTCHA required.";
  }

  return errors;
}

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.handleGotIt = this.handleGotIt.bind(this);

    this.state = {
      redirectTo: undefined,
      captchaResponse: undefined,
    };
  }

  handleFormSubmit(formProps) {
    sendGAEvent({
      category: "Communication",
      action: "ContactUs Form Submit",

      label: ``,
    });
    this.props.sendContactForm(formProps, this.props.tenant.email);
  }

  handleGotIt() {
    this.setState({ redirectTo: "/" });

    this.props.clearMessage();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, tenant } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.props.message) {
      return (
        <div className="card border-success text-center mx-auto">
          <div className="card-body">
            <div className="alert alert-success">
              <span>{this.props.message}</span>
            </div>
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={this.handleGotIt}
            >
              GOT IT
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="card mb-3">
        <div className="card-body">
          <h4 className="card-title text-center">{`Contact ${tenant.name}`}</h4>

          <form
            className="well"
            onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
          >
            {this.renderAlert()}
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="name"
                    className="form-control"
                    component={renderField}
                    type="text"
                    placeholder={`Name`}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="email"
                    className="form-control"
                    component={renderField}
                    type="email"
                    placeholder={`Email`}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="telephone"
                    className="form-control"
                    component={renderField}
                    type="text"
                    normalize={normalizePhone}
                    placeholder={`Phone`}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="subject"
                    className="form-control"
                    component={renderField}
                    type="text"
                    placeholder={`Subject`}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <Field
                    name="message"
                    className="form-control"
                    component={renderTextAreaField}
                    placeholder={`Message`}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <Field
                name="captchaResponse"
                siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                component={renderRecaptchaField}
              />
            </div>

            <div className="controls text-center">
              <button
                type="submit"
                disabled={pristine || submitting}
                className="btn btn-angkor"
              >
                <Icon.Send size={16} />
                {` `} {`Send Message`}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.communication.error,
    message: state.communication.message,
    restaurants: state.tenant.restaurants || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendContactForm: (formProps, tenantEmail) =>
    dispatch(sendContactForm(formProps, tenantEmail)),
  clearMessage: () => dispatch(clearMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ContactForm));
