import React from "react";
import moment from "moment";
import {getOpeningHourRange} from '../Common/OpeningHourRange';

const PreOrderForLaterWarning = ({restaurant, holidayHours=[]}) => {
  let currentTime = moment().tz(restaurant.settings.time_zone);
  let timeRange = getOpeningHourRange(restaurant, currentTime, restaurant.regular_business_hours, holidayHours, restaurant.settings.online_order_start, restaurant.settings.online_order_end);
  let isOpen = timeRange.isOpen && (currentTime.isSameOrAfter(timeRange.startAt)) && (currentTime.isSameOrBefore(timeRange.endAt));
  if (!isOpen) {
    return (
      <div className="alert alert-warning d-flex align-items-left justify-content-start"><i className="fas fa-clock mr-3"></i> {`We're closed now but you can pre-order for later.`}</div>
    )
  } else {
    return (<></>)
  }
}

export default PreOrderForLaterWarning;