import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import { fetchGiftCardProduct } from "../../actions/tenant";
import { createGiftCardOrder } from "../../actions/giftcardorder";
import { renderField } from "../../utils/renderFields";
import Numeral from "numeral";

const form = reduxForm({
  form: "createGiftCardForm",
  validate,
});

const selector = formValueSelector("createGiftCardForm");

function validate(formProps) {

  const errors = {};

  if (!formProps.giftCardType) {
    errors.giftCardType = "Please select gift card type";
  }

  if (!formProps.quantity) {
    errors.quantity = "Please enter quantity";
  }

  return errors;
}

class CreateGiftCard extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.fetchGiftCardProduct(this.props.match.params.id);
    }
  }

  handleFormSubmit(formProps) {
    const data = {
      giftCardProductId: this.props.giftCardProduct.id,
      giftCardProduct: this.props.giftCardProduct,
      giftCardType: formProps.giftCardType,
      quantity: formProps.quantity,
      total: formProps.quantity * this.props.giftCardProduct.price
    };

    this.props.createGiftCardOrder(data, () => {
      this.setState({
        redirectTo: "/gift-checkout",
      });
    });
  }

  render() {
    const {
      giftCardProduct,
      tenant,
      selectedQuantity,
      handleSubmit,
    } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (!giftCardProduct) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12 col-md-3 col-lg-4 my-auto">
          <h5 className="clickable">
            <Link to="/gift">
              <i className="fas fa-angle-left mr-3" /> Gift cards
            </Link>
          </h5>

          <h5>
            <strong>Create gift card</strong>
          </h5>
        </div>
        <div className="col-12 offset-md-1 col-md-7 offset-lg-1 col-lg-6">
          <form onSubmit={handleSubmit(this.handleFormSubmit)}>
            <img
              className="card-img-top mb-3"
              src={
                process.env.REACT_APP_S3_BASE_URL +
                `${encodeURIComponent(
                  tenant.slug + "/" + giftCardProduct.image.url
                )}`
              }
              alt="Gift Card"
            />
            <div className="row">
              <div className="col-12">
                <span className="mr-3">{`Name:`}</span>
                <span>{giftCardProduct.name}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <span className="mr-3">{`Price:`}</span>
                <span>{Numeral(giftCardProduct.price).format("$0,00.00")}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <small>{`You will get ${giftCardProduct.quantity} of ${Numeral(
                  giftCardProduct.unitPrice
                ).format("$0,00.00")} gift cards`}</small>
              </div>
            </div>

            <div className="form-group mt-3 mb-3">
              <label>Gift Card Type</label>
              <div className="form-check">
                <Field
                  name="giftCardType"
                  className="form-check-input"
                  type="radio"
                  component={renderField}
                  id="gift_card_type_physical"
                  value="Digital"
                />
                <label
                  className="form-check-label"
                  htmlFor="gift_card_type_digital"
                >{`Digital `}</label>
                <div>
                  <small>
                    e-giftcards will be delivered via email once payment has
                    been confirmed
                  </small>
                </div>
              </div>
              <div className="form-check">
                <Field
                  name="giftCardType"
                  className="form-check-input"
                  type="radio"
                  component={renderField}
                  id="gift_card_type_physical"
                  value="Physical"
                />
                <label
                  className="form-check-label"
                  htmlFor="gift_card_type_physical"
                >{`Physical`}</label>
                <div>
                  <small>
                    gift cards can be picked up at restaurant counter once
                    payment has been confirmed
                  </small>
                </div>
              </div>
            </div>

            <div className="form-group mt-3 mb-3">
              <label>Quantity</label>
              <Field
                name="quantity"
                className="form-control mb-3"
                component={renderField}
                type="number"
              />
            </div>

            <div className="row">
              <div className="col-12">
                <span className="mr-3">{`Total price`}</span>
                <span>
                  {Numeral(giftCardProduct.price * selectedQuantity).format(
                    "$0,00.00"
                  )}
                </span>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12 text-right">
                <button className="btn btn-angkor" type="submit">
                  <span>CHECKOUT</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    giftCardProduct: state.tenant.giftCardProduct,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    selectedQuantity: selector(state, "quantity"),
    initialValues: {
      giftCardType: "Digital",
      quantity: 1,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCardProduct: (giftcardProductId) =>
    dispatch(fetchGiftCardProduct(giftcardProductId)),
  createGiftCardOrder: ({giftCardProductId, giftCardProduct, giftCardType, quantity, total}, callback) =>
  dispatch(createGiftCardOrder({giftCardProductId,  giftCardProduct, giftCardType, quantity, total}, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(CreateGiftCard));
