import React, { Component } from "react";
import { connect } from "react-redux";

import LoginToProceed from "./LoginToProceed";
import CancelOrderLink from "../OrderOnline/CancelOrderLink";
import ProceedAsGuest from "./ProceedAsGuest";
import * as Icon from "react-feather";

import {
  getSubtotal,
  getTax,
  getTotal,
  getQuantity,
  getGST,
  getPST,
} from "../../actions/cart";

import { fetchCustomerBlocked } from "../../actions/order";

// import visa_icon from "payment-icons/min/flat/visa.svg";
// import mastercard_icon from "payment-icons/min/flat/mastercard.svg";
// import amex_icon from "payment-icons/min/flat/amex.svg";
// import default_icon from "payment-icons/min/flat/default.svg";

import Numeral from "numeral";

import Cookies from "universal-cookie";

import PayOnlineModal from "./PayOnlineModal";

const cookies = new Cookies();
const user = cookies.get("customer");

class CheckoutStep3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: props.paymentMethod || "Offline",
      disabled: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {
      paymentMethod: props.paymentMethod || "Offline",
    };

    return newState;
  }

  componentDidMount() {
    const user = cookies.get("customer");
    if (user && user.local.email) {
      this.props.fetchCustomerBlocked(this.props.tenant._id, user.local.email);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    let customer = {};
    if (user) {
      customer = {
        userId: user._id,
        firstName: user.local.firstName,
        lastName: user.local.lastName,
        email: user.local.email,
        phone: user.local.telephone,
      };
    } else {
      customer = this.props.customer;
    }

    let order = {
      restaurantId: this.props.restaurant._id,
      items: this.props.items,
      subtotal: this.props.subtotal,
      tax: this.props.tax,
      gst: this.props.gst,
      pst: this.props.pst,
      total: this.props.total,
      specialRequests: this.props.specialRequests,
      pickupLocation: this.props.pickupLocation,
      pickupDate: this.props.pickupDate,
      pickupTime: this.props.pickupTime,
      pickupPerson: this.props.pickupPerson,
    };

    const {
      authenticated,
      tenant,
      restaurant,
      onPrevious,
      handleLoginToProceed,
      handleProceedAsGuest,
      handlePaymentMethodChange,
      onPlaceOrder,
      onCancelOrder,
      deliveryMethod,
      isBlocked,
    } = this.props;

    const showMembership = tenant.settings.membership === true;
    const acceptOnlinePayment =
      tenant.settings?.online_payment_settings?.accept_online_payment === true;

    return (
      <React.Fragment>
        <div className="row pt-3 mb-3">
          <div className="col-12">
            <h3 className="pb-3 border-bottom">
              <Icon.Lock size={36} />
              {` `}
              <strong>SECURE CHECK OUT</strong>
            </h3>
          </div>
        </div>
        {!authenticated && !customer && (
          <div className="row">
            {showMembership && (
              <>
                <div className="col-12 col-sm-12 col-md-6">
                  <LoginToProceed onSubmit={handleLoginToProceed} />
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <ProceedAsGuest onSubmit={handleProceedAsGuest} />
                </div>
              </>
            )}
            {!showMembership && (
              <>
                <div className="col-12">
                  <ProceedAsGuest onSubmit={handleProceedAsGuest} />
                </div>
              </>
            )}
          </div>
        )}
        {(authenticated || customer) && (
          <>
            {this.renderAlert()}
            {isBlocked && (
              <div className="alert alert-danger">
                {`The email you provided has been blocked for ordering due to suspicious activity. Please call us at ${restaurant.phone} to unblock your email account.`}
              </div>
            )}
            <div className="row">
              <div className="col-12 col-lg-6">
                <h4>How would you like to pay?</h4>
                <div className="form-group pb-3">
                  {acceptOnlinePayment && (
                    <div className="form-check pt-3">
                      <input
                        name="paymentMethod"
                        className="form-check-input"
                        value={`Online`}
                        onChange={handlePaymentMethodChange}
                        type="radio"
                        disabled={isBlocked}
                        checked={this.state.paymentMethod === "Online"}
                      />
                      <label className="form-check-label">
                        <i className="fas fa-credit-card mr-2"> </i>
                        {`Pay now online`}
                      </label>
                    </div>
                  )}
                  <div className="form-check pt-3">
                    <input
                      name="paymentMethod"
                      className="form-check-input"
                      value={`Offline`}
                      onChange={handlePaymentMethodChange}
                      type="radio"
                      disabled={isBlocked}
                      checked={this.state.paymentMethod === "Offline"}
                    />
                    <label className="form-check-label">
                      <i className="fas fa-cash-register mr-2"> </i>
                      {`Pay at ${
                        deliveryMethod === "Delivery" ? "delivery" : "pickup"
                      }`}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <h4>We accept: </h4>
                <ul>
                  <li>
                    <i className="fab fa-cc-visa fa-3x mr-3"></i>
                    {`Visa`}
                  </li>
                  <li>
                    <i className="fab fa-cc-mastercard fa-3x mr-3"></i>
                    {`Mastercard`}
                  </li>
                  <li>
                    <i className="fas fa-credit-card fa-3x mr-3"></i>
                    {`Debit`}
                  </li>
                  <li>
                    <i className="fas fa-money-bill-alt fa-3x mr-3"></i>
                    {`Cash`}
                  </li>
                </ul>
                {/* <img
                  src={visa_icon}
                  alt="visa"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <img
                  src={mastercard_icon}
                  alt="mastercard"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <img
                  src={default_icon}
                  alt="default"
                  className="pr-3"
                  width={75}
                  height={47}
                />
                <p className="text-info pt-3">
                  {`Important: Online payments are not charged until you
                        pick up your order. Please bring your method of
                        payment with you when you pick up your order in the
                        event there is an issue processing your payment
                        online.`}
                </p> */}
              </div>
            </div>
            {/* <div className="row">
            <div className="col-12"><OrderSummary /></div>
          </div> */}
            <div className="row pt-3 pb-3 mt-3 border-top">
              <div className="col-3 text-left">
                <button
                  className="btn btn-secondary btn-block"
                  onClick={onPrevious}
                >
                  <Icon.ChevronLeft size={24} />{" "}
                  <span className="d-none d-sm-inline">BACK</span>
                </button>
              </div>
              <div className="col-5 text-left">
                <CancelOrderLink onCancelOrder={onCancelOrder} />
              </div>
              <div className="col-4 text-right">
                {this.state.paymentMethod === "Offline" && (
                  <button
                    className="btn btn-angkor btn-block"
                    disabled={this.state.disabled || isBlocked}
                    onClick={() => {
                      this.setState({ disabled: true });
                      onPlaceOrder();
                    }}
                  >
                    <Icon.Check size={24} />
                    <span className="d-none d-sm-inline">
                      <strong>
                        COMPLETE ORDER (
                        {Numeral(this.props.total).format("$0,0.00")})
                      </strong>
                    </span>
                  </button>
                )}
                {this.state.paymentMethod === "Online" && (
                  <PayOnlineModal
                    customer={customer}
                    orderTotal={order.total}
                    onPlaceOrder={onPlaceOrder}
                    currencyCode={restaurant.settings.currency_code}
                    restaurant={restaurant}
                    isBlocked={isBlocked}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const taxRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.tax_rate
    : 0;
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    authenticated: state.auth.authenticated,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.order.error,
    message: state.order.message,
    items: state.cart.items,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    taxRate: taxRate,
    gst: getGST(state, gstRate),
    gstRate: gstRate,
    pst: getPST(state, pstRate),
    pstRate: pstRate,
    total: getTotal(state, gstRate, pstRate),
    quantity: getQuantity(state),
    pickupDate: state.order.pickupDate,
    pickupTime: state.order.pickupTime,
    customer: state.order.customer,
    pickupPerson: state.order.pickupPerson,
    specialRequests: state.order.specialRequests,
    paymentMethod: state.order.paymentMethod,
    deliveryMethod: state.order.deliveryMethod,
    email: state.cart.email,
    isBlocked: state.order.isBlocked,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerBlocked: (tenantId, email) =>
    dispatch(fetchCustomerBlocked(tenantId, email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStep3);
