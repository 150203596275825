import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import * as Icon from "react-feather";

import { selectLocation } from "../../actions/tenant";

import moment from "moment-timezone";

const AnyReactComponent = ({ text }) => <Icon.MapPin color="red" />;

class ChooseRestaurant extends Component {
  constructor(props) {
    super(props);

    this.handleSelectLocation = this.handleSelectLocation.bind(this);
    this.state = {
      zoom: 12,
    };
  }

  componentDidMount() {}

  getBusinessHour(restaurant) {
    const dayOfWeek = moment().day();
    const restaurantTimeZone = moment
      .tz(restaurant.settings.time_zone)
      .format("Z z");
    const regular_business_hours = this.props.restaurants.filter(
      (_restaurant) => _restaurant._id === restaurant._id
    )[0].regular_business_hours;

    let result = undefined;
    if (dayOfWeek === 0) {
      result = regular_business_hours.sunday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.sunday.open_time +
          " - " +
          regular_business_hours.sunday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 1) {
      result = regular_business_hours.monday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.monday.open_time +
          " - " +
          regular_business_hours.monday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 2) {
      result = regular_business_hours.tuesday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.tuesday.open_time +
          " - " +
          regular_business_hours.tuesday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 3) {
      result = regular_business_hours.wednesday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.wednesday.open_time +
          " - " +
          regular_business_hours.wednesday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 4) {
      result = regular_business_hours.thursday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.thursday.open_time +
          " - " +
          regular_business_hours.thursday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 5) {
      result = regular_business_hours.friday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.friday.open_time +
          " - " +
          regular_business_hours.friday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    } else if (dayOfWeek === 6) {
      result = regular_business_hours.saturday.is_open
        ? "OPEN TODAY " +
          regular_business_hours.saturday.open_time +
          " - " +
          regular_business_hours.saturday.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    }

    const filteredBusinessHours = this.props.businessHours.filter(
      (businessHour) =>
        businessHour.restaurant === restaurant._id &&
        moment().isSame(businessHour.date, "day")
    );

    if (filteredBusinessHours.length > 0) {
      const businessHour = filteredBusinessHours[0];

      result = businessHour.is_open
        ? "OPEN TODAY " +
          businessHour.open_time +
          " - " +
          businessHour.close_time +
          " " +
          restaurantTimeZone
        : "CLOSED TODAY";
    }

    return result;
  }

  handleSelectLocation(restaurant) {
    this.props.selectLocation(restaurant);
  }

  render() {
    const { restaurants = [] } = this.props;
    let defaultCenter = {};
    if (restaurants.length > 0) {
      defaultCenter = {
        lat: restaurants[0].latitude,
        lng: restaurants[0].longitude,
      };
    }

    return (
      <React.Fragment>
        {/* <div className="row pb-6 mb-3 border-bottom">
          <div className="col-12 col-sm-6">
            <h3>
              <strong>ORDER ONLINE</strong>
            </h3>
          </div>
          <div className="col-12 col-sm-6 text-right" />
        </div> */}
        <section className="text-center">
          <h2>Order Online</h2>
          <hr />
        </section>
        <h6 className="p-3 mb-3 bg-info text-white">
          Menu varies at different location, please select location to order.
        </h6>
        <div className="row">
          <div className="col-12 col-sm-6 d-none d-sm-block">
            <div style={{ height: "450px", width: "100%" }}>
              {restaurants.length > 0 && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                  }}
                  defaultCenter={defaultCenter}
                  defaultZoom={this.state.zoom}
                >
                  {restaurants.map((restaurant) => (
                    <AnyReactComponent
                      key={restaurant._id}
                      lat={restaurant.latitude}
                      lng={restaurant.longitude}
                      text={restaurant.name}
                    />
                  ))}
                </GoogleMapReact>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6 text-center text-sm-left pb-3">
            <ul className="list-unstyled">
              {restaurants.map((restaurant) => (
                <li
                  className="media pt-3 pb-3 border-bottom"
                  key={restaurant._id}
                >
                  <div className="media-body">
                    <h5 className="mt-0">{restaurant.name}</h5>
                    <address>
                      {restaurant.address_1} <br />
                      {restaurant.city_town}, {restaurant.state_province}{" "}
                      {restaurant.postal_code} <br />
                      {restaurant.phone} <br />
                    </address>
                    <p>
                      <strong>{this.getBusinessHour(restaurant)}</strong>
                    </p>
                    {restaurant.settings.online_ordering && (
                      <button
                        className="btn btn-angkor"
                        onClick={() => this.handleSelectLocation(restaurant)}
                      >{`ORDER FROM THIS RESTAURANT`}</button>
                    )}
                     {!restaurant.settings.online_ordering && (
                      <div className="alert alert-warning"
                      >{`ONLINE ORDERING PAUSED, WILL BE RESUMED ON MAY 1ST`}</div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant?._id
      )
    : [];
  return {
    restaurants: state.tenant.restaurants || [],
    restaurant: state.tenant.restaurant,
    businessHours: state.tenant.businessHours || [],
    holidayHours: holidayHours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectLocation: (restaurant) => dispatch(selectLocation(restaurant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRestaurant);
