import React, { Component } from "react";
import Numeral from "numeral";
import moment from "moment-timezone";

class CouponListView extends Component {

  componentDidMount() {

  }


  render() {
    const coupons = (this.props.coupons) || [];

    return (
      <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <strong className="text-secondary mr-2">
                  {coupons.length}
                </strong>
                {"Coupons"}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="col-lg-3 d-none d-lg-inline text-left">
              <strong>Name</strong>
            </div>
            <div className="col-lg-3 d-none d-lg-inline text-left">
              <strong>Code</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Amount</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline">
              <strong>Expires</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Redeemed?</strong> 
            </div>
          </div>
          {coupons.map((coupon, index) => (
            <React.Fragment key={index}>
              <div
                className="row pt-3 pb-3 border-bottom"
              >
                <div className="col-12 col-lg-3 text-left ">
                  <span className="d-inline d-lg-none">
                    <strong>Name: </strong>
                  </span>
                  <span>{coupon.name}</span>
                </div>
                <div className="col-12 col-lg-3 text-left ">
                  <span className="d-inline d-lg-none">
                    <strong>Code: </strong>
                  </span>
                  <span className="text-uppercase">{coupon.couponCode}</span>
                </div>
                <div className="col-12 col-lg-2 text-left">
                  <span className="d-inline d-lg-none">
                    <strong>Amount: </strong>{" "}
                  </span>{" "}
                  {Numeral(coupon.amount).format(
                    "$0,0.00"
                  )}
                </div>
                <div className="col-12 col-lg-2 text-left">
                  <span className="d-inline d-lg-none">
                    <strong>Expires: </strong>
                  </span>
                  {coupon.validUntil && moment(coupon.validUntil).tz(this.props.restaurant.settings.time_zone).format(this.props.restaurant.settings.date_format)}
                </div>
                
                <div className="col-12 col-lg-2 text-left">
                  <span className="d-inline d-lg-none">
                    <strong>Redeemeded?: </strong>
                  </span>
                  <span>{coupon.redeemedBy ? "Yes" : "No"}</span>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default CouponListView;
