import React from "react";
import OpeningHours from "../Common/OpeningHours";
import moment from "moment";

const Location = ({
  restaurant,
  handleViewMenus,
  handleOrderOnline,
  handleMakeReservation,
  handleOrderGiftCard,
  holidayHours,
}) => {
  const showOnlineOrdering = restaurant.settings.online_ordering === true;
  const showOnlineReservation = restaurant.settings.online_reservation === true;
  const showGiftCard = restaurant.settings.gift_card === true;
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <strong>{restaurant.name}</strong>
        </h5>
        <address className="card_text">
          {restaurant.address_1} <br />
          {restaurant.city_town}, {restaurant.state_province}{" "}
          {restaurant.postal_code} <br />
          {restaurant.phone} <br />
        </address>
        <h6>Opening Hours</h6>
        <OpeningHours restaurant={restaurant} />
        {holidayHours && holidayHours.length > 0 && (
          <>
            <h6>Holiday Hours</h6>

              {holidayHours.map((businessHour) => (
                <div className="row mb-1 text-muted" key={businessHour._id}>
                  <div className="col-6 font-weight-bold text-right">
                    {`${moment(businessHour.date)
                      .tz(restaurant.settings.time_zone)
                      .format(restaurant.settings.date_format)}:`}
                  </div>
                  <div className="col-6 text-left">
                    {businessHour.is_open ? (
                      <span>
                        {businessHour.open_time} - {businessHour.close_time}
                      </span>
                    ) : (
                      <span>CLOSED</span>
                    )}
                  </div>
                </div>
              ))}
        
          </>
        )}
        <div className="row mt-3">
          <div className="col-12">
            <div className="row mb-2">
              <div className="offset-lg-3 col-lg-6  col-12">
                {showOnlineOrdering && (
                  <button
                    className="btn btn-angkor  btn-block"
                    onClick={() => handleOrderOnline(restaurant)}
                  >
                    <span className="font-weight-bolder cta">{`ORDER NOW`}</span>
                  </button>
                )}
                {showGiftCard && (
                  <button
                    className="btn btn-angkor btn-block"
                    onClick={() => handleOrderGiftCard(restaurant)}
                  >
                    <span className="font-weight-bolder cta">{`BUY GIFT CARD`}</span>
                  </button>
                )}
                {!showOnlineOrdering && (
                  <button
                    className="btn btn-angkor btn-block"
                    onClick={() => handleViewMenus(restaurant)}
                  >
                    {`See MENU`}
                  </button>
                )}
              </div>
            </div>
            {showOnlineReservation && (
              <div className="row">
                <div className="offset-lg-3 col-lg-6  col-12">
                  <button
                    className="btn btn-angkor btn-block"
                    onClick={() => handleMakeReservation(restaurant)}
                  >
                    {`Reserve Table`}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
