import React, { Component } from "react";
import { connect } from "react-redux";

import order_ahead from './order-ahead.jpg';
import order_for_later from './order-for-later.jpg';

class OrderAhead extends Component {
  render() {

    return (
      <>
        <section className="text-center">
          <h2>Order Ahead</h2>
          <hr />
          <p>{`Cut the waiting time to mere seconds`}</p>
        </section>
        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <img src={order_ahead} className="img-fluid" alt="Order Ahead" />
          </div>
          <div className="col-12 col-md-6">
            <p className="pt-3">{`With our new "Table Reservations" & "Order Ahead" functions, you save precious hours on restaurant waiting times.`}</p>
            <p>{`You now have the chance to order your favorite meal before arriving at the restaurant.`}</p>
            <p>{`Skip playing the waiting game for a table and for your food when you’re at your hungriest.`}</p>
            <div className="text-center text-md-left">
              <button className="btn btn-angkor">{`Book A Table & Start Ordering`}</button>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <img src={order_for_later} className="img-fluid" alt="Order For Later" />
          </div>
          <div className="col-12 col-md-6">
            <p className="pt-3">{`We’re very proud of our "Order for later" function, because it gives YOU the chance to order your food in advance and pick it up or have it delivered to your house at any time of your choosing.`}</p>
            <p>{`You're in complete control of your order. Could it get any better than this?`}</p>
            <div className="text-center text-md-left">
              <button className="btn btn-angkor">{`Order For Later`}</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAhead);
