import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import "./Header.css";

import { getQuantity } from "../../actions/cart";

import * as Icon from "react-feather";

import { fetchGiftCardProducts } from "../../actions/tenant";

// Connect to socket.io server

export let socket ;

class HeaderV2 extends Component {
  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchGiftCardProducts(this.props.tenant._id);
    }
  }

  render() {
    const {
      tenant,
      quantity,
      authenticated,
      promotions = [],
      giftCardProducts = [],
    } = this.props;
    const cookies = new Cookies();
    const user = cookies.get("customer");
    let userName = user ? user.local.firstName + " " + user.local.lastName : "";
    if (!tenant) {
      return <></>;
    }
    const showOnlineOrdering = tenant.settings.online_ordering === true;
    const showOnlineReservation = tenant.settings.online_reservation === true;

    const showMembership = tenant.settings.membership === true;

    const showGiftcard =
      tenant.settings.gift_card === true && giftCardProducts.length > 0;

    return (
      <header className="main-header container fixed-top">
        <nav className="navbar navbar-angkor">
          <NavLink className="navbar-brand" to="/">
            {tenant && tenant.logo && tenant.logo.url && (
              <img
                // className="img-fluid"
                height="40px"
                src={
                  process.env.REACT_APP_S3_BASE_URL +
                  `${encodeURIComponent(tenant.slug + "/" + tenant.logo.url)}`
                }
                alt="Logo"
              />
            )}
          </NavLink>
          <ul className="navbar-nav order-last flex-row">
            <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/menus">
                <i className="fas fa-bars" />
                <span className="ml-1 d-none d-lg-inline">{`Menus`}</span>
              </NavLink>
            </li>
            {tenant.settings.coupon === true && promotions.length > 0 && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/promotions">
                  <i className="fas fa-tags" />
                  <span className="ml-1 d-none d-lg-inline">{`Promotions`}</span>
                </NavLink>
              </li>
            )}

            {/* {announcements.length > 0 && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/announcements">
                  <i className="fas fa-bullhorn" />
                  <span className="ml-1 d-none d-lg-inline">{`Announcements`}</span>
                </NavLink>
              </li>
            )} */}

            {showOnlineOrdering && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/buildorder">
                  <i className="fas fa-utensils" />
                  <span className="ml-1 d-none d-lg-inline">{`Order Online`}</span>
                </NavLink>
              </li>
            )}
            {showGiftcard && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/gift">
                  <Icon.Gift size={16} />
                  <span className="ml-1 d-none d-lg-inline">{`Gift Card`}</span>
                </NavLink>
              </li>
            )}
            {showOnlineReservation && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/reservation">
                  <i className="fas fa-th-large" />
                  <span className="ml-1 d-none d-lg-inline">
                    {`Reserve A Table`}
                  </span>
                </NavLink>
              </li>
            )}
            {tenant.restaurants.length > 1 && (
              <li className="nav-item mr-3">
                <NavLink className="nav-link" to="/locations">
                  <i className="fas fa-map-marker-alt" />
                  <span className="ml-1 d-none d-lg-inline">{`Locations`}</span>
                </NavLink>
              </li>
            )}
            {showMembership && (
              <li className="nav-item mr-3">
                <NavLink
                  className="nav-link"
                  to={authenticated ? "/profile" : "/login"}
                >
                  <i className="fas fa-user" />
                  <span className="ml-1 d-none d-lg-inline">
                    {user ? userName : "Login"}
                  </span>
                </NavLink>
              </li>
            )}

            {/* <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/gallery">
                <i className="fas fa-images" />
                <span className="ml-1 d-none d-lg-inline">{`Gallery`}</span>
              </NavLink>
            </li>
    
     
            <li className="nav-item mr-3">
              <NavLink className="nav-link" to="/contact">
                <i className="fas fa-comment-alt" />
                <span className=" ml-1 d-none d-lg-inline">{`Contact`}</span>
              </NavLink>
            </li> */}
           
            {showOnlineOrdering && (
              <li className="nav-item">
                <NavLink
                  id="shopping-cart-angkor"
                  className="nav-link"
                  to="/cart"
                >
                  <span className="shopping-cart-badge" data-count={quantity}>
                    <i className="fas fa-shopping-cart" />
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: ownProps.tenant,
    authenticated: state.auth.authenticated,
    restaurant: state.tenant.restaurant,
    giftCardProducts: state.tenant.giftCardProducts || [],
    // restaurants: state.tenant.restaurants,
    businesshour:
      state.tenant.todayBusinessHours &&
      state.tenant.todayBusinessHours.length > 0
        ? state.tenant.todayBusinessHours[0]
        : {},
    quantity: getQuantity(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCardProducts: (tenantId) => dispatch(fetchGiftCardProducts(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderV2);
