import React, { Component } from "react";

import * as Icon from "react-feather";

import { Elements, StripeProvider } from "react-stripe-elements";
import PayWithCreditCard from "./PayWithCreditCard";

import Numeral from "numeral";

class PayOnlineModal extends Component {

  componentDidMount() {}

  render() {
    const { currencyCode, customer, orderTotal, onPlaceOrder, restaurant, isBlocked } = this.props;
    return (
      <>
        <button
          className="btn btn-angkor btn-block"
          data-toggle="modal"
          data-target="#payOnlineModal"
          data-backdrop="static"
          disabled={isBlocked}
        >
          <span className="d-none d-sm-inline">{`Pay With Card`}</span>{" "}
          <Icon.ChevronRight size={24} />
        </button>

        <div
          className="modal fade"
          id="payOnlineModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#payOnlineModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-angkor">
                <h5 className="modal-title" id="payOnlineModalLabel">
                  {`Pay ${Numeral(orderTotal).format("$0,0.00")}`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <StripeProvider apiKey={restaurant.settings.online_payment_settings.stripe_api_publishable_key}>
                  <Elements>
                    <PayWithCreditCard
                      currencyCode={currencyCode}
                      amount={orderTotal}
                      customer={customer}
                      onPlaceOrder={onPlaceOrder}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PayOnlineModal;
