import React from 'react';
import PropTypes from 'prop-types';

import Numeral from 'numeral';

const Subtotal = ({ subtotal }) => {
  return (
    <div className="row border-bottom pb-3 pt-3">
      <div className="col-8 text-left col-cart-angkor">
        <span>Subtotal</span>
      </div>
      <div className="col-3 text-right col-cart-angkor">
        <span>
          <small>{Numeral(subtotal).format("$0,0.00")}</small>
        </span>
      </div>
    </div>
  )
};

Subtotal.propTypes = {
  subtotal: PropTypes.number.isRequired,
};

Subtotal.defaultProps = {
  subtotal: 0
};

export default Subtotal;