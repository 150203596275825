import axios from 'axios';
import Cookies from 'universal-cookie';
import { logout } from './auth';

import {
  STATIC_ERROR,
  SEND_CONTACT_FORM
} from './types';


const cookies = new Cookies();
//================================================================================
// Utility Actions
//================================================================================

export function couponErrorHandler(dispatch, error, type) {

  console.log(error)
  let errorMessage = error.data;

  if (error.status === 401) {
    errorMessage = 'Email address and/or password is not correct. Please try again';
    return dispatch(logout(errorMessage));
  }

  dispatch({
    type,
    payload: {
      couponMessage: error.data.message,
    }
  });
}

// Post Request
export function errorHandler(dispatch, error, type) {

  console.log(error)
  let errorMessage = error.data;

  if (error.status === 401) {
    errorMessage = 'Email address and/or password is not correct. Please try again';
    return dispatch(logout(errorMessage));
  }

  dispatch({
    type,
    payload: errorMessage
  });
}

// Post Request
export function postData(action, errorType, isAuthReq, url, dispatch, data, callback) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('ctoken') } };
  }

  axios.post(url, data, headers)
    .then((response) => {
      callback && callback()
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, errorType);
    });
}

// Get Request
export function getData(action, errorType, isAuthReq, url, dispatch, callback) {
  let headers = {};
  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('ctoken') } };
  }
  axios.get(url, headers)
    .then((response) => {
      callback && callback(response.data);
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => { 
      if (error.response) {
        errorHandler(dispatch, error.response, errorType);
      }
    });
}

export function patchData(action, errorType, isAuthReq, url, dispatch, data, callback) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('ctoken') } };
  }

  axios.patch(url, data, headers)
    .then((response) => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error)
      errorHandler(dispatch, error.response, errorType);
    });
}

// Put Request
export function putData(action, errorType, isAuthReq, url, dispatch, data) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('ctoken') } };
  }

  axios.put(url, data, headers)
    .then((response) => {
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, errorType);
    });
}

// Delete Request
export function deleteData(action, errorType, isAuthReq, url, dispatch) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('ctoken') } };
  }

  axios.delete(url, headers)
    .then((response) => {
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, errorType);
    });
}

//=============================================================================
// Static Page Actions
//=============================================================================
export function sendContactForm({ name, email, telephone, subject, message }, tenantEmail) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eater/communication/contact`, { senderName: name, senderEmail: email, senderTelephone: telephone, subject: subject, message: message, receiverEmail: tenantEmail })
      .then((response) => {
        dispatch({
          type: SEND_CONTACT_FORM,
          payload: response.data.message
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}
