import React from 'react';
import PropTypes from 'prop-types';

const PickupLocation = ({location}) => {
  return (
    <address>
      <h4><strong>{location.name}</strong></h4>
      <strong>{location.address}</strong><br/>
      {location.city_town}, {location.state_province}<br/>
      {location.phone}<br/>
    </address>
  )
}

PickupLocation.propTypes = {
  location: PropTypes.object,
}

export default PickupLocation;